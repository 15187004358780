<template>
  <div>
    <spinner-block v-model="progress">
      <div class="mb-3 row">
        <div class="col-12 col-md-2">
          <div class="small">Статус приглашения</div>
          <gui-select sm v-model="filter.client_b_c_status_id" @change="getList" :hilightOn="['']">
            <option value="1">Приглашение отправлено</option>
            <option value="2">Приглашение принято</option>
            <option value="3">Приглашение отклонено</option>
            <option value="">Все</option>
          </gui-select>
        </div>
        <div class="col-12 col-md-5">
        </div>
      </div>
      <div v-if="list && list.data && list.data.length">
        <table class="table table-hover">
          <thead>
          <tr>
            <th class="pr-0" style="width: 1rem;"></th>
            <th>Клиент</th>
            <th>Подписка</th>
            <th class="text-right"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="row in list.data" :key="row.id">
            <th class="pr-0">
              <i v-if="row.client_b_c_status_id == 1" class="fas fa-circle small text-warning" title="Отправлено"></i>
              <i v-if="row.client_b_c_status_id == 2" class="fas fa-circle small text-success" title="Принято"></i>
              <i v-if="row.client_b_c_status_id == 3" class="fas fa-circle small text-success" title="Отклонено"></i>
            </th>
            <td class="limit-half_length">
              <router-link :to="{name: 'client.detail', params: {id: row.id}}" class="main-link">
                {{ row.phone }}
              </router-link>
            </td>
            <td>
              <div v-if="row.subscriptions && row.subscriptions.length">
                Подписка
                <router-link :to="{name: 'subscription.detail', params: {id: row.subscriptions[0].id}}">
                  {{ row.subscriptions[0].tariff.name }}
                </router-link>
                до {{ toDate(row.subscriptions[0].deactivated_at) }}
              </div>
              <div v-if="row.subscription_clients && row.subscription_clients.length">
                Участник подписки
                <router-link
                    :to="{name: 'subscription.detail', params: {id: row.subscription_clients[0].subscription.id}}">
                  {{ row.subscription_clients[0].subscription.tariff.name }}
                </router-link>
                до
                {{ toDate(row.subscription_clients[0].subscription.deactivated_at) }}
              </div>
            </td>
            <td class="text-right">
              <button class="btn btn-danger btn-sm" @click.prevent="remove(row)">
                Отвязать
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div v-else class="alert alert-info">Список пуст</div>
    </spinner-block>
    <gui-paginate :data="list" ref="paginate" @change="getList"/>
  </div>
</template>

<script>

export default {
  data: function () {
    return {
      list: null,
      progress: true,
      filter: {
        client_b_c_status_id: '',
        search: '',
      }
    }
  },
  props: {
    data: Object
  },
  computed: {},
  beforeMount() {
    this.filter = storageLoad('filter_' + this.$route.name, this.filter);
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {

      let v = _.clone(this.filter);
      v.search = '';
      storageSet('filter_' + this.$route.name, v);

      axiosGetValue('/api/admin/business-company-clients/list', {
            page: this.$refs.paginate.currentPage,
            per_page: this.$refs.paginate.perPage,
            filter: Object.assign({}, this.filter, {
              business_company_id: this.$route.params.id
            }),
          },
          e => this.list = e, e => this.progress = e
      )
    },
    remove(row) {
      $.alertConfirm({
        content: 'Отвязать пользователя? Если он активен, ему отправится пуш уведомление. Если есть активная бизнес подписка, она не будет продлеваться в конце периода.'
      }, {
        ok: () => {
          axiosGetValue('/api/admin/business-company-clients/delete', {
                id: row.id
              },
              () => {
                toastSuccess('Пользователь отвязан от компании')
                this.getList()

              }
          )
        }
      })
    }
  }
}
</script>
