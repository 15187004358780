<template>
  <div>


    <div>
      button-back<br/>
      <button-back/>
    </div>

    <div>
      button-cancel<br/>
      <button-cancel/>
    </div>

    <div>
      button-create<br/>
      <button-create/>
    </div>

    <div>
      button-edit<br/>
      <button-edit/>&nbsp;
      <button-edit icon/>&nbsp;
      <button-edit sm icon/>
    </div>

    <div>
      button-remove<br/>
      <button-remove/>&nbsp;
      <button-remove icon/>&nbsp;
      <button-remove sm icon/>
    </div>

    <div>
      button-save<br/>
      <button-save/>&nbsp;
      <button-save icon/>&nbsp;
      <button-save sm icon/>
    </div>


    <spinner-block :modelValue="true">
      <card-block>
        <card-body>
          spinner-block
        </card-body>
      </card-block>
    </spinner-block>

    <div class="mb-5">
      <b>date-range-picker</b>
      <date-range-picker
          v-model="dateRange2"
          day
          week
          month
          quarter
          year
      />
      <i>Value: {{ dateRange2 }}</i>
    </div>
    <div class="mb-5">
      <b>gui-file</b>
      <gui-file v-model="file" label="gui-file"/>
      <i>Value: {{ file }}</i>
    </div>
    <div class="mb-5">
      <b>gui-file-picker</b>
      <gui-file-picker v-model="file1" label="gui-file-picker"
                       :file="file1Old" @delete="()=>file1Old=null"/>
      <i>Value: {{ file1 }}, {{ file1Old }}</i>
    </div>
    <div class="mb-5">
      <b>gui-checkbox</b>
      <gui-checkbox v-model="checkbox.a" label="gui-checkbox A" noMargin/>
      <gui-checkbox v-model="checkbox.b" label="gui-checkbox B" noMargin/>
      <gui-checkbox v-model="checkbox.c" label="gui-checkbox C" noMargin/>
      <i>Value: {{ checkbox }}</i>
    </div>
    <div class="mb-5">
      <b>gui-date</b>
      <gui-date v-model="date" label="gui-date"/>
      <i>Value: {{ date }}</i>
    </div>
    <div class="mb-5">
      <b>gui-input-date-range</b>
      <gui-input-date-range v-model="dateRange" label="gui-input-date-range"/>
      <i>Value: {{ dateRange }}</i>
    </div>
    <div class="mb-5">
      <b>gui-date-time</b>
      <gui-date-time v-model="dateTime" label="gui-date-time"/>
      <i>Value: {{ dateTime }}</i>
    </div>
    <div class="mb-5">
      <b>gui-html</b>
      <gui-html v-model="html" label="gui-html"/>
      <i>Value: {{ html }}</i>
    </div>
    <div class="mb-5">
      <b>gui-search</b>
      <gui-search v-model="search"/>
      <i>Value: {{ search }}</i>
    </div>
    <div class="mb-5">
      <b>gui-select</b>
      <gui-select v-model="select" :list="[
          {id: 1, name: 'Value 1'},
          {id: 2, name: 'Value 2'},
      ]" label="gui-select">
        <option value="" disabled>default</option>
      </gui-select>
      <i>Value: {{ select }}</i>
    </div>
    <div class="mb-5">
      <b>gui-input</b>
      <gui-input v-model="string" label="gui-input"/>
      <i>Value: {{ string }}</i>
    </div>
    <div class="mb-5">
      <b>gui-textarea</b>
      <gui-textarea v-model="textarea" label="gui-textarea" autosize/>
      <i>Value: {{ textarea }}</i>
    </div>
    <div class="mb-5">
      <b>gui-time</b>
      <gui-time v-model="time" label="gui-time"/>
      <i>Value: {{ time }}</i>
    </div>
    <div class="mb-5">
      <b>gui-multi-select</b>
      В разработке
      <i>Value: {{ multiSelect }}</i>
    </div>
    <div class="mb-5">
      <b>gui-phone-span</b>
      <div>
        <gui-phone-span v-model="phone" label="gui-phone-span"/>
      </div>
      <i>Value: {{ phone }}</i>
    </div>
    <div class="mb-5">
      <b>week-picker</b>
      <week-picker v-model="week" label="week-picker"/>
      <i>Value: {{ week }}</i>
    </div>

    <div class="mt-5">
      <button @click.prevent="fancybox($refs.modal1)">Modal 1</button>
      <div ref="modal1" style="display:none;">
        <div class="mb-3">
          <b>Title</b>
        </div>
        <div>Content</div>
        <div class="mt-3">
          <button @click.prevent="fancyboxClose">Close</button>
        </div>
      </div>
    </div>

    <div class="mt-5">
      <button @click.prevent="toastSuccess('message')">toastSuccess</button>
      <button @click.prevent="toastError('message')">toastError</button>
      <button @click.prevent="toastInfo('message')">toastInfo</button>
      <button @click.prevent="toastWarning('message')">toastWarning</button>
    </div>

    <div class="mt-5">
      <button @click.prevent="alertConfirm">alertConfirm</button>
      <button @click.prevent="alertConfirmForce">alertConfirmForce</button>
    </div>

  </div>
</template>

<script>

import WeekPicker from "@/components/Gui/Input/WeekPicker";
import DateRangePicker from "@/components/Gui/Input/DateRangePicker";

export default {
  components: {DateRangePicker, WeekPicker},
  data() {
    return {
      date: '',
      checkbox: {
        a: 1,
        b: 0,
        c: 0,
      },
      dateRange2: {
        date_from: '',
        date_to: '',
      },
      dateRange: {
        date_from: '',
        date_to: '',
      },
      dateTime: '',
      html: '',
      search: '',
      select: '',
      string: '',
      textarea: '',
      time: '',
      multiSelect: [],
      phone: '+79112223344',
      week: '',
      file: null,
      file1: null,
      file1Old: {name: 'favicon.ico', url: '/favicon.ico', url_see: '/favicon.ico'},
    }
  },
  created() {
  },
  methods: {
    alertConfirm() {
      $.alertConfirm()
    },
    alertConfirmForce() {
      $.alertConfirmForce()
    },
  }
}
</script>
