function CacheVariables($bus) {

    this.$bus = $bus;
    this.storage = {};
    this.progress = {};

    let v = this;

    this.subscribe = function (name, callback, progress) {

        if (callback) this.$bus.$on(name, callback);
        if (progress) this.$bus.$on(name + '_progress', progress);

        if (v.storage[name]) {
            if (callback) callback(v.storage[name]);
            if (progress) progress(false);
        } else if (!v.progress[name]) {
            v.load(name);
        }
    };

    this.setProgress = function (name, value) {

        this.$bus.$emit(name + '_progress', value)

    };

    this.load = function (name, callback, progress) {

        let args = name.split('|');
        let url = args[0] || '';
        let data = args[1] ? JSON.parse(args[1]) : {};

        this.setProgress(name, true);
        window.axiosGetValue(url, data, value => {
            this.save(name, value, callback);
        }, progress)

    };

    this.save = function (name, value, callback) {
        this.storage[name] = value;
        if (callback) callback(name);
        this.emit(name)
    };

    this.emit = function (name) {
        this.$bus.$emit(name, this.storage[name]);
        this.setProgress(name, false);
    };

    this.api = {
        dictionary: {
            getListSee: (params, callback, progress) => {
                let name = 'dictionary.getListSee|' + JSON.stringify(params);
                v.subscribe.call(this, name, callback, progress);
            }
        },
        places: {
            remoteList: (params, callback, progress) => {
                v.subscribe.call(this, 'places.remoteList|' + JSON.stringify(params), callback, progress);
            }
        },
        playgroundParts: {
            remoteList: (params, callback, progress) => {
                v.subscribe.call(this, 'playgroundParts.remoteList|' + JSON.stringify(params), callback, progress);
            }
        },
    };

    return this;

}

export default CacheVariables;
