import EmptyWrap from "@/components/Gui/EmptyWrap";

export default [
    {
        path: 'user',
        component: EmptyWrap,
        children: [
            {
                path: '',
                name: 'user.list',
                component: require('@/views/User/UserList').default,
            },
            {
                path: 'create',
                name: 'user.create',
                component: require('@/views/User/UserForm').default,
            },
            {
                path: ':id',
                component: require('@/views/User/Card/WrapBlock').default,
                children: [
                    {
                        path: 'detail',
                        name: 'user.detail',
                        component: require('@/views/User/Card/UserDetail').default,
                    },
                    {
                        path: 'organization',
                        name: 'user.organization',
                        component: require('@/views/User/Card/UserOrganizationTab').default,
                    },
                ]
            },
            {
                path: ':id/edit',
                name: 'user.edit',
                component: require('@/views/User/UserForm').default,
            },
        ]
    },
]