<template>
  <card-block>
    <spinner-block v-model="dataProgress">
      <template v-if="data">
        <card-header>
          <router-link :to="{name: 'client-call-squad.list'}">Вызовы отрядов</router-link><!--
                    -->:
          <span class="text-secondary">#{{ data.id }}</span>
          {{ data.name }}
        </card-header>

        <div class="m-3">

          <div class="row">
            <div class="col-12 col-md-6">
              <card-field :modelValue="data.client_call_squad_status ? data.client_call_squad_status.name : '—'"
                          title="Статус"/>

              <card-field title="Клиент">
                <router-link :to="{name: 'client.detail', params: {id: data.client_call.client_id}}">
                  #{{ data.client_call.client_id }} {{
                    data.client_call.client ? data.client_call.client.initials : '—'
                  }}
                </router-link>
              </card-field>


              <card-field title="Вызов клиента">
                <router-link :to="{name: 'client-call.detail', params: {id: data.client_call_id}}">
                  #{{ data.client_call_id }}
                  {{ data.client_call.client ? data.client_call.client.initials : '—' }}
                </router-link>
              </card-field>

            </div>
            <div class="col-12 col-md-6">

              <card-field title="Отряд">
                <router-link :to="{name: 'squad.detail', params: {id: data.squad_id}}">
                  {{ data.squad ? data.squad.name : '—' }}
                </router-link>
              </card-field>

              <card-field title="Смена отряда">
                <router-link :to="{name: 'squad-duty.detail', params: {id: data.squad_duty_id}}">
                  #{{ data.squad_duty_id }}
                  {{ data.squad ? data.squad.name : '—' }}
                </router-link>
              </card-field>


            </div>
          </div>


        </div>

        <tab-list>
          <tab-link to="client-call-squad.detail">Основные данные</tab-link>
        </tab-list>
        <card-body>
          <router-view :key="id" :data="data" @change="getData"></router-view>
        </card-body>
      </template>
    </spinner-block>
  </card-block>
</template>

<script>
export default {
  data: function () {
    return {
      data: null,
      dataProgress: true,
    }
  },
  props: {},
  computed: {
    id() {
      return this.$route.params.id;
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      if (this.id) {
        axiosGetValue('/api/admin/client-call-squad/get', {id: this.id}, e => this.data = e, e => this.dataProgress = e)
      }
    },
  },
}
</script>