import EmptyWrap from "@/components/Gui/EmptyWrap";

export default [
    {
        path: 'messenger',
        component: EmptyWrap,
        children: [
            {
                path: '',
                name: 'messenger.list',
                component: require('@/views/Messenger/MessengerList').default,
            },
            {
                path: 'create',
                name: 'messenger.create',
                component: require('@/views/Messenger/MessengerForm').default,
            },
            {
                path: ':id',
                component: require('@/views/Messenger/Card/WrapBlock').default,
                children: [
                    {
                        path: 'detail',
                        name: 'messenger.detail',
                        component: require('@/views/Messenger/Card/MessengerDetail').default,
                    },
                ]
            },
            {
                path: ':id/edit',
                name: 'messenger.edit',
                component: require('@/views/Messenger/MessengerForm').default,
            },
        ]
    },
]