import EmptyWrap from "@/components/Gui/EmptyWrap";

export default [
    {
        path: 'squad',
        component: EmptyWrap,
        children: [
            {
                path: '',
                name: 'squad.list',
                component: require('@/views/Squad/SquadList').default,
            },
            {
                path: 'create',
                name: 'squad.create',
                component: require('@/views/Squad/SquadForm').default,
            },
            {
                path: ':id',
                component: require('@/views/Squad/Card/WrapBlock').default,
                children: [
                    {
                        path: 'detail',
                        name: 'squad.detail',
                        component: require('@/views/Squad/Card/SquadDetail').default,
                    },
                    {
                        path: 'duty',
                        name: 'squad.duty',
                        component: require('@/views/Squad/Card/SquadDuty').default,
                    },
                    {
                        path: 'phone-call',
                        name: 'squad.phone-call',
                        component: require('@/views/Squad/Card/PhoneCallTab').default,
                    },
                    {
                        path: 'calls',
                        name: 'squad.client-calls',
                        component: require('@/views/Squad/Card/ClientCallTab').default,
                    },
                    {
                        path: 'client-call-squad',
                        name: 'squad.client-call-squad',
                        component: require('@/views/Squad/Card/ClientCallSquadTab').default,
                    },
                ]
            },
            {
                path: ':id/edit',
                name: 'squad.edit',
                component: require('@/views/Squad/SquadForm').default,
            },
        ]
    },
]