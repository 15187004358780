<template>
  <card-block>
    <spinner-block v-model="dataProgress">
      <div v-if="data">
        <card-header>
          <template v-if="id">
            <router-link :to="{name: 'squad-search-terms.detail', params: {id: id}}">Условие поиска</router-link>
            <!-- -->:
            редактирование

          </template>
          <template v-else>
            <router-link :to="{name: 'squad-search-terms.list'}">Условия поиска</router-link><!-- -->:
            создание
          </template>


        </card-header>
        <card-body>

          <gui-input v-model="data.name" :error="dataError.name" label="Название"/>
          <gui-input v-model="data.radius_search_max" :error="dataError.radius_search_max"
                     label="Максимальный радиус поиска, м"/>
          <gui-input v-model="data.radius_search_min" :error="dataError.radius_search_min"
                     label="Минимальный радиус поиска, м"/>
          <gui-input v-model="data.step_search_radius" :error="dataError.step_search_radius" label="Шаг поиска, м"/>
          <gui-input v-model="data.squad_searching_time_interval" :error="dataError.squad_searching_time_interval" label="Временной интервал поиска отрядов, с"/>
          <gui-input v-model="data.sort" :error="dataError.sort" label="Сортировка"/>

          <gui-squad-search-therm-search-type-select v-model="data.squad_search_term_search_type_id"
                                                     label="Способ поиска ближайшего отряда"
                                                     :error="dataError.squad_search_term_search_type_id"/>

          <div class="mt-5">
            <button-save v-if="!data.id" @click="add()"/>
            <button-save v-else @click="save"/>
            <button-back/>
          </div>
        </card-body>
      </div>
    </spinner-block>
  </card-block>
</template>

<script>


import GuiSquadSearchThermSearchTypeSelect from "@/components/Widget/GuiSquadSearchTermSearchTypeSelect";

export default {
  components: {GuiSquadSearchThermSearchTypeSelect},
  data() {
    return {
      data: null,
      dataDefault: {
        name: '',
        radius_search_max: '',
        radius_search_min: '',
        step_search_radius: '',
        squad_searching_time_interval: '',
        squad_search_term_search_type_id: '',
        sort: '',
      },
      dataError: {
        name: '',
        radius_search_max: '',
        radius_search_min: '',
        step_search_radius: '',
        squad_searching_time_interval: '',
        squad_search_term_search_type_id: '',
        sort: '',
      },
      dataProgress: false,
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    }
  },
  mounted() {
    this.init()
  },
  methods: {

    init() {
      if (this.id) {
        this.data = null
        this.loadData()
      } else {
        this.data = _.clone(this.dataDefault)
      }
    },
    loadData() {
      axiosGetValue('/api/admin/squad-search-terms/get', {id: this.id}, e => this.data = Object.assign({}, this.dataDefault, e), e => this.dataProgress = e)
    },

    save() {
      axiosFast({
        url: route('/api/admin/squad-search-terms/update'),
        data: {data: this.data, id: this.data.id},
        error: this.dataError,
        progress: e => this.dataProgress = e,
        result: () => {
          toastSuccess('Данные сохранены');
          this.$router.push({name: 'squad-search-terms.detail', params: {id: this.data.id}})
        },
      })
    },
    add() {
      axiosFast({
        url: route('/api/admin/squad-search-terms/create'),
        data: {data: this.data},
        error: this.dataError,
        progress: e => this.dataProgress = e,
        result: (data) => {
          toastSuccess('Данные добавлены');
          this.$router.push({name: 'squad-search-terms.detail', params: {id: data.id}})
        },
      })
    },
  },
}
</script>