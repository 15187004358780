const TOKEN_KEY = "access_token";
const REFRESH_TOKEN_KEY = "refresh_token";
const AUTH_USER = "auth_user";
const AUTH_ROLES = "auth_roles";
const AUTH_PERMISSIONS = "auth_permissions";

const TokenService = {
    getToken() {
        return localStorage.getItem(TOKEN_KEY);
    },

    saveToken(accessToken) {
        localStorage.setItem(TOKEN_KEY, accessToken);
    },

    removeToken() {
        localStorage.removeItem(TOKEN_KEY);
    },

    getRefreshToken() {
        return localStorage.getItem(REFRESH_TOKEN_KEY);
    },

    saveRefreshToken(refreshToken) {
        localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
    },

    removeRefreshToken() {
        localStorage.removeItem(REFRESH_TOKEN_KEY);
    },

    getUser() {
        return storageLoad(AUTH_USER);
    },

    saveUser(user) {
        storageSet(AUTH_USER, user);
    },

    removeUser() {
        localStorage.removeItem(AUTH_USER);
    },

    getRoles() {
        return storageLoad(AUTH_ROLES);
    },

    saveRoles(data) {
        storageSet(AUTH_ROLES, data);
    },

    removeRoles() {
        localStorage.removeItem(AUTH_ROLES);
    },

    getPermissions() {
        return storageLoad(AUTH_PERMISSIONS);
    },

    savePermissions(data) {
        storageSet(AUTH_PERMISSIONS, data);
    },

    removePermissions() {
        localStorage.removeItem(AUTH_PERMISSIONS);
    },

    loadUser() {
        return axiosGetValue('/api/admin/profile/user', {}, data => TokenService.saveUser(data))
    },

    loadPermissions() {
        return axiosGetValue('/api/admin/profile/permission', {}, data => {
            TokenService.saveRoles(data.roles)
            TokenService.savePermissions(data.permissions)
        })
    },

};

export {TokenService};