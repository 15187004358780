<template>
  <div ref="modal" style="display: none">
    <spinner-block v-model="progress">
      <div v-if="data">
        <div class="mb-3">
          <b>Условия тарифа:</b>
        </div>

        <card-field title="Версия">
          v{{ data.version }}
          <span class="text-secondary small">
            #{{ data.id }}
          </span>

        </card-field>
        <card-field :modelValue="data.tariff_detail.average_arrival_time_s" title="Среднее время прибытия, с"/>
        <card-field :modelValue="data.tariff_detail.average_help_time_m" title="Среднее время оказания помощи, мин."/>
        <card-field :modelValue="toTime(data.tariff_detail.created_at)" title="Создано"/>
        <card-field :modelValue="data.service_commission" title="Комиссия сервиса, %"/>

        <card-field title="Стоимость подачи экипажа">
          {{ data.cost_delivery }}&nbsp;&#8381;
          <s class="small" v-if="data.old_cost_delivery" :title="data.old_cost_delivery">{{
              data.old_cost_delivery
            }}&nbsp;&#8381;</s>
        </card-field>

        <card-field title="Стоимость базового времени">
          {{ data.cost_base_time }}&nbsp;&#8381;
          <s class="small" v-if="data.old_cost_base_time" :title="data.old_cost_base_time">{{
              data.old_cost_base_time
            }}&nbsp;&#8381;</s>
          за {{ data.duration_base_time }} мин.
        </card-field>

        <card-field title="Стоимость доп. времени">
          {{ data.cost_ext_time }}&nbsp;&#8381;
          <s class="small" v-if="data.old_cost_ext_time" :title="data.old_cost_ext_time">{{
              data.old_cost_ext_time
            }}&nbsp;&#8381;</s>
          за каждые {{ data.duration_ext_time }} мин.
        </card-field>

        <card-field :modelValue="data.squad_search_term_payment_type ? data.squad_search_term_payment_type.name : '—'"
                    title="Способ расчёта стоимости"/>

      </div>
    </spinner-block>
  </div>
</template>

<script>
export default {
  data: () => ({
    id: null,
    data: null,
    progress: false,
  }),
  methods: {
    openModal(id) {
      this.id = id
      axiosGetValue('/api/admin/tariff-detail-terms/get', {
            id: this.id
          },
          e => this.data = e,
          e => this.progress = e)

      this.fancybox(this.$refs.modal, () => {
        this.id = null
        this.data = null
      })
    }
  }
}
</script>