<template>
  <nav class="navbar navbar-expand-md navbar-dark sticky-top bg-dark">
    <a class="navbar-brand" href="/">GUARDEE</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
            aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarCollapse">
      <ul class="navbar-nav mr-auto">


        <li class="nav-item dropdown"
            v-if="can('module.user.view') || can('module.squad.view') || can('module.client.view') || can('module.organization.view')">
          <a class="nav-link dropdown-toggle" href="#" id="usersDropdown" role="button" data-toggle="dropdown"
             aria-haspopup="true" aria-expanded="false">
            Пользователи
          </a>
          <div class="dropdown-menu" aria-labelledby="usersDropdown">

            <router-link :to="{name: 'client.list'}" class="dropdown-item" activeClass="active"
                         v-if="can('module.client.view')">
              Клиенты
            </router-link>

            <router-link :to="{name: 'squad.list'}" class="dropdown-item" activeClass="active"
                         v-if="can('module.squad.view')">
              Отряды
            </router-link>

            <router-link :to="{name: 'user.list'}" class="dropdown-item" activeClass="active"
                         v-if="can('module.user.view')">
              Сотрудники
            </router-link>

            <router-link :to="{name: 'organization.list'}" class="dropdown-item" activeClass="active"
                         v-if="can('module.organization.view')">
              Организации
            </router-link>

            <router-link :to="{name: 'business_company.list'}" class="dropdown-item" activeClass="active"
                         v-if="can('module.business_company.view')">
              Бизнес компании
            </router-link>

          </div>
        </li>

        <li class="nav-item dropdown" v-if="
          can('module.subscription.view') ||
          can('module.subscription-chain.view') ||
          can('module.squad.duty-view') ||
          can('module.client_call.view') ||
          can('module.payment.view') ||
          can('module.chat.support-view') ||
          can('module.phone_call.view') ||
          can('module.error.view') ||
          false
          ">
          <a class="nav-link dropdown-toggle" href="#" id="workflowDropdown" role="button" data-toggle="dropdown"
             aria-haspopup="true" aria-expanded="false">
            Рабочий процесс
          </a>
          <div class="dropdown-menu" aria-labelledby="workflowDropdown">
            <router-link :to="{name: 'subscription-chain.list'}" class="dropdown-item" activeClass="active"
                         v-if="can('module.subscription_chain.view')">
              Цепочки подписок
            </router-link>
            <router-link :to="{name: 'subscription.list'}" class="dropdown-item" activeClass="active"
                         v-if="can('module.subscription.view')">
              Периоды подписок
            </router-link>
            <router-link :to="{name: 'squad-duty.list'}" class="dropdown-item" activeClass="active"
                         v-if="can('module.squad.duty-view')">
              Смены отрядов
            </router-link>
            <router-link :to="{name: 'client-call.list'}" class="dropdown-item" activeClass="active"
                         v-if="can('module.client_call.view')">
              Вызовы
            </router-link>
            <router-link :to="{name: 'payment.list'}" class="dropdown-item" activeClass="active"
                         v-if="can('module.payment.view')">
              Платежи
            </router-link>
            <router-link :to="{name: 'chat.list'}" class="dropdown-item" activeClass="active"
                         v-if="can('module.chat.support-view')">
              Чаты поддержки
            </router-link>
            <router-link :to="{name: 'phone-call.list'}" class="dropdown-item" activeClass="active"
                         v-if="can('module.phone_call.view')">
              Звонки
            </router-link>
            <router-link :to="{name: 'error.page'}" class="dropdown-item" activeClass="active"
                         v-if="can('module.error.view')">
              Скрининг ошибок
            </router-link>
          </div>
        </li>

        <li class="nav-item dropdown"
            v-if="
            can('module.messenger.view') ||
            can('module.content.view') ||
            can('module.dictionary.color-view') ||
            can('module.dictionary.place-view') ||
            can('module.faq.view') ||
            can('module.question.view') ||
            can('module.quiz.view') ||
            can('module.role.view') ||
            can('module.country.view') ||
            can('module.region.view') ||
            can('module.city.view') ||
            can('module.squad_search_terms.view') ||
            can('module.tariff.view') ||
            can('module.subscription_tariff.view') ||
            can('module.mobile_app.view') ||
            can('module.client_document_group.view') ||
            can('module.client_document_type.view') ||
            can('module.discount.view') ||
            can('module.promocode.view') ||
            false
            ">
          <a class="nav-link dropdown-toggle" href="#" id="dictionaryDropdown" role="button" data-toggle="dropdown"
             aria-haspopup="true" aria-expanded="false">
            Справочники
          </a>
          <div class="dropdown-menu" aria-labelledby="dictionaryDropdown">


            <router-link :to="{name: 'squad-search-terms.list'}" class="dropdown-item" activeClass="active"
                         v-if="can('module.squad_search_terms.view')">
              Условия поиска
            </router-link>

            <router-link :to="{name: 'tariff.list'}" class="dropdown-item" activeClass="active"
                         v-if="can('module.tariff.view')">
              Тарифы вызовов
            </router-link>

            <router-link :to="{name: 'subscription-tariff.list'}" class="dropdown-item" activeClass="active"
                         v-if="can('module.subscription_tariff.view')">
              Тарифы подписки
            </router-link>

            <router-link :to="{name: 'messenger.list'}" class="dropdown-item" activeClass="active"
                         v-if="can('module.messenger.view')">
              Мессенджеры
            </router-link>

            <router-link :to="{name: 'content.list'}" class="dropdown-item" activeClass="active"
                         v-if="can('module.content.view')">
              Контент
            </router-link>

            <router-link :to="{name: 'dictionary-color.list'}" class="dropdown-item" activeClass="active"
                         v-if="can('module.dictionary.color-view')">
              Цвета одежды
            </router-link>

            <router-link :to="{name: 'dictionary-place.list'}" class="dropdown-item" activeClass="active"
                         v-if="can('module.dictionary.place-view')">
              Места
            </router-link>

            <router-link :to="{name: 'faq.list'}" class="dropdown-item" activeClass="active"
                         v-if="can('module.faq.view')">
              FAQ (устарел)
            </router-link>

            <router-link :to="{name: 'question.detail', params: {id: 1}}" class="dropdown-item" activeClass="active"
                         v-if="can('module.question.view')">
              Вопрос-ответ клиентов
            </router-link>

            <router-link :to="{name: 'question.detail', params: {id: 2}}" class="dropdown-item" activeClass="active"
                         v-if="can('module.question.view')">
              Вопрос-ответ отрядов
            </router-link>

            <router-link :to="{name: 'quiz.list'}" class="dropdown-item" activeClass="active"
                         v-if="can('module.quiz.view')">
              Опросники
            </router-link>

            <router-link :to="{name: 'role.list'}" class="dropdown-item" activeClass="active"
                         v-if="can('module.role.view')">
              Роли
            </router-link>
            <router-link :to="{name: 'country.list'}" class="dropdown-item" activeClass="active"
                         v-if="can('module.country.view')">
              Страны
            </router-link>

            <router-link :to="{name: 'region.list'}" class="dropdown-item" activeClass="active"
                         v-if="can('module.region.view')">
              Регионы
            </router-link>

            <router-link :to="{name: 'city.list'}" class="dropdown-item" activeClass="active"
                         v-if="can('module.city.view')">
              Города
            </router-link>

            <router-link :to="{name: 'mobile-app.list'}" class="dropdown-item" activeClass="active"
                         v-if="can('module.mobile_app.view')">
              Мобильные приложения
            </router-link>

            <router-link :to="{name: 'document-group.list'}" class="dropdown-item" activeClass="active"
                         v-if="can('module.client_document_group.view')">
              Документы, типы
            </router-link>
            <router-link :to="{name: 'document-type.list'}" class="dropdown-item" activeClass="active"
                         v-if="can('module.client_document_type.view')">
              Документы, страницы
            </router-link>
            <router-link :to="{name: 'discount.list'}" class="dropdown-item" activeClass="active"
                         v-if="can('module.discount.view')">
              Скидки
            </router-link>
            <router-link :to="{name: 'promocode.list'}" class="dropdown-item" activeClass="active"
                         v-if="can('module.promocode.view')">
              Промокоды
            </router-link>

          </div>
        </li>

        <li class="nav-item dropdown" v-if="
          can('module.submit_form_1.view') ||
          can('module.quiz_answer.view') ||
          false
          ">
          <a class="nav-link dropdown-toggle" href="#" id="formDropdown" role="button" data-toggle="dropdown"
             aria-haspopup="true" aria-expanded="false">
            Заявки
          </a>
          <div class="dropdown-menu" aria-labelledby="formDropdown">
            <router-link :to="{name: 'submit-form.list'}" class="dropdown-item" activeClass="active"
                         v-if="can('module.submit_form_1.view')">
              Форма обратной связи
            </router-link>
            <router-link :to="{name: 'quiz-answer.list'}" class="dropdown-item" activeClass="active"
                         v-if="can('module.quiz_answer.view')">
              Ответы на опросники
            </router-link>

          </div>
        </li>


        <li class="nav-item" v-if="can('module.report.view')">
          <router-link :to="{name: 'report.list'}" class="nav-link">
            Отчёты
          </router-link>
        </li>

        <li class="nav-item" v-if="can('module.map.view')">
          <router-link :to="{name: 'map.page'}" class="nav-link">
            Карта
          </router-link>
        </li>

      </ul>

      <div class="navbar-text">

        <ul class="navbar-nav mr-2 d-inline-block"
            v-if="can('module.chat.support-view') && oldChats && oldChats.length">
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle no-icon py-0" href="#" id="formDropdownNotify" role="button"
               data-toggle="dropdown"
               aria-haspopup="true" aria-expanded="false">
              <i class="fas fa-bell text-danger"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="formDropdownNotify">
              <template v-for="chat in oldChats" :key="chat.uuid">
                <router-link :to="{name: 'chat.detail', params: {uuid: chat.uuid}}" class="dropdown-item text-secondary"
                             activeClass="">
                  {{ chat.name }}
                  <span class="small">{{ toTime(chat.last_message_date) }}</span>
                </router-link>
              </template>

            </div>
          </li>
        </ul>

        <router-link :to="{name: 'user.detail', params: {id: user.id}}" v-if="user">
          {{ user.email ? user.email : '—' }}
        </router-link>
        &nbsp;
        <a href="#" @click.prevent="handleSignOut">
          Выйти
        </a>
      </div>

    </div>
  </nav>
</template>

<style>
.dropdown-toggle.no-icon::after {
  display: none;
}
</style>

<script>

import {mapActions, mapState} from "vuex";
import {TokenService} from "@/services/token.service";


export default {
  data() {
    return {
      user: TokenService.getUser(),
    }
  },
  mounted() {
    if (this.can('module.chat.support-view')) {
      this.$store.dispatch('chat/updateOldChats');
    }
  },
  computed: {
    ...mapState({
      oldChats: (state) => state.chat.oldChats,
    })
  },
  methods: {

    ...mapActions("auth", ["signOut"]),
    async handleSignOut() {
      this.signOut().then(() => {
        this.$router.push("/login")
      }).catch(async (err) => {
        console.log('err', err)
        alert('Failed')
      })
    }
  }
}
</script>
