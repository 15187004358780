import EmptyWrap from "@/components/Gui/EmptyWrap";

export default [
    {
        path: 'phone-call',
        component: EmptyWrap,
        children: [
            {
                path: '',
                name: 'phone-call.list',
                component: require('@/views/PhoneCall/PhoneCallList').default,
            },
        ]
    },
]