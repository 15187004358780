<template>
  <div>
    <div ref="modal" style="display: none">

      <div class="mb-3">
        <b>Отчёт по клиентам</b>
      </div>
      <a href="#"></a>
      <gui-input-date-range v-model="filter" fromKey="dateFrom" toKey="dateTo" label="Дата регистрации клиента"/>

      <div class="mt-3">
        <button class="btn btn-success mr-2" @click.prevent="download">
          Скачать
        </button>
        <button class="btn btn-secondary" @click.prevent="fancyboxClose()">
          Отмена
        </button>
      </div>

    </div>
  </div>

</template>

<script>

export default {
  components: {},
  data() {
    return {
      filter: {
        dateFrom: '',
        dateTo: '',
      }
    }
  },
  methods: {
    openModal() {
      this.fancybox(this.$refs.modal)
    },
    download() {
      loadAsPostForm('/api/admin/report/client', {filter: this.filter})
    },
  },
}
</script>