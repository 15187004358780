import EmptyWrap from "@/components/Gui/EmptyWrap";

export default [{
    path: 'organization',
    component: EmptyWrap,
    children: [
        {
            path: '',
            name: 'organization.list',
            component: require('@/views/Organization/OrganizationList').default,
        },
        {
            path: 'create',
            name: 'organization.create',
            component: require('@/views/Organization/OrganizationForm').default,
        },
        {
            path: ':id',
            component: require('@/views/Organization/Card/WrapBlock').default,
            children: [
                {
                    path: 'detail',
                    name: 'organization.detail',
                    component: require('@/views/Organization/Card/OrganizationDetail').default,
                },
                {
                    path: 'payment',
                    name: 'organization.payment',
                    component: require('@/views/Organization/Card/PaymentTab').default,
                },
                {
                    path: 'client-call-squad',
                    name: 'organization.client-call-squad',
                    component: require('@/views/Organization/Card/ClientCallSquadTab').default,
                },
                {
                    path: 'squad',
                    name: 'organization.squad',
                    component: require('@/views/Organization/Card/SquadTab').default,
                },
                {
                    path: 'squad-duty',
                    name: 'organization.squad-duty',
                    component: require('@/views/Organization/Card/SquadDutyTab').default,
                },
            ]
        },
        {
            path: ':id/edit',
            name: 'organization.edit',
            component: require('@/views/Organization/OrganizationForm').default,
        },
    ]
}]