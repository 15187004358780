<template>
  <div>
    <div v-if="data && data.data && data.data.length">
      <table class="table table-hover">
        <thead>
        <tr>
          <th class="pr-0" style="width: 1rem;"></th>
          <th>Подписка</th>
          <th v-if="ownerId">Является владельцем</th>
          <th>Дата начала</th>
          <th>Дата окончания</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="row in data.data" :key="row.id">
          <th class="pr-0">
            <i class="fas fa-circle small"
               :title="row.is_active ? 'Активен' : 'Неактивен'"
               :class="row.is_active ? 'text-success' : 'text-danger'"></i>
          </th>
          <td class="limit-third_length">
            <router-link :to="{name: 'subscription.detail', params: {id: row.id}}" class="main-link">
              #{{ row.id }} {{ row.client ? row.client.phone : '—' }} {{ row.tariff ? row.tariff.name : '—' }}
            </router-link>
          </td>
          <td v-if="ownerId">
            {{ ownerId == row.client_id ? 'Да' : 'Нет' }}
          </td>
          <td>
            {{ toTime(row.activated_at) }}
          </td>
          <td>
            {{ toTime(row.deactivated_at) }}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div v-else class="alert alert-info">Список пуст</div>
  </div>
</template>

<script>

export default {
  props: {
    data: {
      type: [Object],
      default: null
    },
    ownerId: [String, Number]
  }
}
</script>