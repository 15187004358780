<template>
  <div>

    <card-field :modelValue="answer.values.map(e=>e.value).join(', ')" :title="answer.name"
                v-for="(answer, i) in data.answers" :key="i"/>
  </div>
</template>

<script>

export default {
  components: {},
  data() {
    return {}
  },
  props: {
    data: Object,
  },
  mounted() {
  },
  methods: {}
}
</script>