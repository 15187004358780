import EmptyWrap from "@/components/Gui/EmptyWrap";

export default [
    {
        path: 'client-call',
        component: EmptyWrap,
        children: [
            {
                path: '',
                name: 'client-call.list',
                component: require('@/views/ClientCall/ClientCallList').default,
            },
            {
                path: ':id',
                component: require('@/views/ClientCall/Card/WrapBlock').default,
                children: [
                    {
                        path: 'detail',
                        name: 'client-call.detail',
                        component: require('@/views/ClientCall/Card/ClientCallDetail').default,
                    },
                    {
                        path: 'review',
                        name: 'client-call.review',
                        component: require('@/views/ClientCall/Card/ClientCallQuizAnsweTabl').default,
                    },
                    {
                        path: 'payment',
                        name: 'client-call.payment',
                        component: require('@/views/ClientCall/Card/PaymentTab').default,
                    },
                    {
                        path: 'client-call-squad',
                        name: 'client-call.client-call-squad',
                        component: require('@/views/ClientCall/Card/ClientCallSquadTab').default,
                    },
                    {
                        path: 'phone-calls',
                        name: 'client-call.phone-calls',
                        component: require('@/views/ClientCall/Card/PhoneCallTab').default,
                    },
                    {
                        path: 'chat',
                        name: 'client-call.chat',
                        component: require('@/views/ClientCall/Card/ClientCallChatTab').default,
                    },
                    {
                        path: 'client-call-squad-map',
                        name: 'client-call.client-call-squad-map',
                        component: require('@/views/ClientCall/Card/ClientCallSquadMap').default,
                    },
                ]
            },
        ]
    },
]