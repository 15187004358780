<template>
  <span>{{ toGeo(modelValue) }}</span> <i v-if="toGeo(modelValue) !== '—'" class="fas fa-location-dot cursor-pointer"
                                          @click.prevent="$refs.modal.openModal(modelValue)"></i>
  <geo-point-on-map-modal ref="modal"/>
</template>

<script>

import GeoPointOnMapModal from "@/components/Gui/Input/GeoPointOnMapModal";

export default {
  components: {GeoPointOnMapModal},
  props: {
    modelValue: [Object, String, Boolean],
  },
}
</script>
