import App from './App.vue'
import router from './router'
import {store} from './store'
import {createApp} from 'vue'
import 'bootstrap/dist/css/bootstrap.min.css'
import ApiService from "@/services/api.service";
import "@fortawesome/fontawesome-free/css/all.min.css"
import "jquery-confirm/css/jquery-confirm.css"
import "./style/main.css"
import "ol/ol.css";
import "./style/chat.less"
import "pikaday/css/pikaday.css"
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css"
import 'tinymce/tinymce.min'
import 'tinymce/themes/silver';
import './plugins/tinymce-lang';
import '@fancyapps/fancybox/dist/jquery.fancybox.min.css';
import '@vueform/multiselect/themes/default.scss';
import {TokenService} from "@/services/token.service";
import {installChat} from './services/core/chat.service'

let $ = require('jquery');
window.$ = $;
window.jQuery = $;

require('bootstrap/dist/js/bootstrap.bundle.min');
require('./inc/bootstrap');
require('./inc/common');
require('@fancyapps/fancybox');
require('jquery-confirm');
require('./inc/dialogs');

window.VueApp = window.Vue = createApp(App)
    .use(store)
    .use(router);

window.objectPath = require('object-path');


window.app = {
    permissions: []
};

require("./inc/vue-init");


ApiService.init(process.env.VUE_APP_API_URL);
ApiService.setHeader();

if (TokenService.getToken()) {
    TokenService.loadPermissions();
}

VueApp.use(installChat);

VueApp.mount('#app');
