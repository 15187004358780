<template>
  <card-block>
    <spinner-block v-model="dataProgress">
      <template v-if="data">
        <card-header>
          <router-link :to="{name: 'squad-duty.list'}">Смены</router-link><!--
                    -->:
          <span class="text-secondary">#{{ data.id }}</span>
          {{ data.squad ? data.squad.name : '—' }}
          от {{ toTime(data.started_at) }}
        </card-header>
        <tab-list>
          <tab-link to="squad-duty.detail">Общая информация</tab-link>
          <tab-link to="squad-duty.client-calls" v-if="can('module.client_call.view')">Вызовы</tab-link>
          <tab-link to="squad-duty.payment" v-if="can('module.payment.view')">Оплаты</tab-link>
          <tab-link to="squad-duty.phone-call" v-if="can('module.phone_call.view')">Телефонные звонки</tab-link>
          <tab-link to="squad-duty.client-call-squad" v-if="can('module.client_call_squad.view')">Поиск отряда
          </tab-link>
          <tab-link to="squad-duty.statistic">Статистика</tab-link>
          <tab-link to="squad-duty.breaks" v-if="can('module.squad.duty_break-view')">Перерывы</tab-link>
        </tab-list>
        <card-body>
          <router-view :key="id" :data="data" @change="getData"></router-view>
        </card-body>
      </template>
    </spinner-block>
  </card-block>
</template>

<script>
export default {
  data: function () {
    return {
      data: null,
      dataProgress: true,
    }
  },
  props: {},
  computed: {
    id() {
      return this.$route.params.id;
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      if (this.id) {
        axiosGetValue('/api/admin/squad-duty/get', {id: this.id}, e => this.data = e, e => this.dataProgress = e)
      }
    },
  },
}
</script>