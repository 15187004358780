<template>
  <div>
    <card-field title="Стоимость согласно тарифу, руб" :modelValue="data.need_paid"/>
    <card-field title="Оплачено клиентом, руб" :modelValue="data.client_paid"/>

    <spinner-block v-model="progress" class="mt-5">
      <payment-list-block :data="listData" can_refresh @update="getList"/>
    </spinner-block>
    <gui-paginate :data="listData" ref="paginate" @change="getList"/>
  </div>
</template>

<script>

import PaymentListBlock from "@/views/Payment/PaymentListBlock";

export default {
  components: {PaymentListBlock},
  data() {
    return {
      listData: null,
      progress: null,
      filter: {
        client_id: null,
      }
    }
  },
  props: {
    data: Object,
  },
  mounted() {
    this.filter.subscription_id = this.data.id
    this.getList()
  },
  methods: {
    getList() {
      axiosGetValue('/api/admin/payment/list', {
            filter: this.filter,
            page: this.$refs.paginate.currentPage,
            per_page: this.$refs.paginate.perPage,
          },
          e => this.listData = e, e => this.progress = e
      )
    },
  }
}
</script>