import EmptyWrap from "@/components/Gui/EmptyWrap";

export default [
    {
        path: 'squad-duty',
        component: EmptyWrap,
        children: [
            {
                path: '',
                name: 'squad-duty.list',
                component: require('@/views/SquadDuty/SquadDutyList').default,
            },
            {
                path: ':id',
                component: require('@/views/SquadDuty/Card/WrapBlock').default,
                children: [
                    {
                        path: 'detail',
                        name: 'squad-duty.detail',
                        component: require('@/views/SquadDuty/Card/SquadDutyDetail').default,
                    },
                    {
                        path: 'statistic',
                        name: 'squad-duty.statistic',
                        component: require('@/views/SquadDuty/Card/SquadDutyStatistic').default,
                    },
                    {
                        path: 'breaks',
                        name: 'squad-duty.breaks',
                        component: require('@/views/SquadDuty/Card/SquadDutyBreaks').default,
                    },
                    {
                        path: 'calls',
                        name: 'squad-duty.client-calls',
                        component: require('@/views/SquadDuty/Card/ClientCallTab').default,
                    },
                    {
                        path: 'payment',
                        name: 'squad-duty.payment',
                        component: require('@/views/SquadDuty/Card/PaymentTab').default,
                    },
                    {
                        path: 'phone-call',
                        name: 'squad-duty.phone-call',
                        component: require('@/views/SquadDuty/Card/PhoneCallTab').default,
                    },
                    {
                        path: 'client-call-squad',
                        name: 'squad-duty.client-call-squad',
                        component: require('@/views/SquadDuty/Card/ClientCallSquadTab').default,
                    },
                ]
            },
        ]
    },
]