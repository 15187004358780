<template>
  <input type="text"
         :name="name || id"
         :id="id"
         class="form-control" readonly
         :class="{'is-invalid': error && error.length > 0, 'form-control-sm':sm}"
         :placeholder="placeholder || label"
         :modelValue="val"
         @input="e=>val=e.target.value"
         ref="date"
  />
</template>

<script>

export default {
  components: {},
  data: function () {
    return {
      el: false
    }
  },
  props: {
    f: Boolean,
    sm: Boolean,
    error: [Array, Object, String],
    id: String,
    name: String,
    modelValue: String,
    label: String,
    placeholder: String,
  },
  computed: {

    val: {
      get() {
        if (!this.modelValue) return '';
        let d = this.moment(this.modelValue);
        let format = 'DD.MM.YYYY';
        return d.startOf('isoWeek').format(format) + ' — ' + d.endOf('isoWeek').format(format);
      },
      set(v) {
        let r = this.moment(v, 'DD.MM.YYYY').format('YYYY-MM-DD');
        this.$emit('update:modelValue', r)
      }
    }

  },
  mounted() {
    this.el = makePikaday(this.$refs.date, {
      toString: (date, format) => {
        let d = this.moment(date);
        return d.startOf('isoWeek').format(format) + ' — ' + d.endOf('isoWeek').format(format)
      }
    })
  },
  unmounted() {
    if (this.el) this.el.destroy();
  },
  methods: {}
}
</script>