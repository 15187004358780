<template>
  <div class="card-header">
    <div class="navbar justify-content-between px-0 py-0">
      <div class="navbar-brand">
        <slot></slot>
      </div>
      <div>
        <slot name="right"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {}
  },
  props: {},
  computed: {},
  mounted() {

  },
  methods: {}
}
</script>
