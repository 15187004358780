<template>
  <div>
    <div ref="modal" style="display: none">
      <spinner-block v-model="dataProgress">
        <div v-if="data">
          <div class="mb-3">
            <b>{{ data.id ? 'Изменение организации' : 'Добавление организации' }}</b>
          </div>

          <gui-organization-select v-model="data.organization_id" label="Организация"
                                   :error="dataError.organization_id"/>

          <div class="mt-5">
            <button-save v-if="!data.id" @click="add"/>
            <button-save v-else @click="save"/>
            <button-cancel @click="fancyboxClose"/>
          </div>
        </div>
      </spinner-block>
    </div>
  </div>
</template>

<script>

import GuiOrganizationSelect from "@/components/Widget/GuiOrganizationSelect";

export default {
  components: {GuiOrganizationSelect},
  data: function () {
    return {
      data: null,
      dataProgress: false,
      dataDefault: {
        organization_id: null,
      },
      dataError: {
        organization_id: '',
      },
    }
  },
  props: {
    user: Object
  },
  mounted() {
  },
  methods: {
    loadData(id) {
      axiosGetValue('/api/admin/user-organization/get', {id, user_id: this.user.id}, e => {
        this.data = e
      }, e => this.dataProgress = e)
    },
    openModal(data_id) {
      if (data_id)
        this.loadData(data_id)
      else
        this.data = _.clone(this.dataDefault)

      this.fancybox(this.$refs.modal, () => {
        this.data = null
        clearErrorList(this.dataError)
      });
    },
    save() {
      axiosFast({
        url: route('/api/admin/user-organization/update'),
        data: {data: this.data, id: this.data.id, user_id: this.user.id},
        error: this.dataError,
        progress: e => this.dataProgress = e,
        result: () => {
          toastSuccess('Данные сохранены');
          this.$emit('update')
          this.fancyboxClose();
        },
      })
    },
    add() {
      axiosFast({
        url: route('/api/admin/user-organization/create'),
        data: {data: this.data, user_id: this.user.id},
        error: this.dataError,
        progress: e => this.dataProgress = e,
        result: () => {
          toastSuccess('Данные добавлены');
          this.$emit('update')
          this.fancyboxClose();
        },
      })
    },
  }
}
</script>