<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-6">
        <card-field :modelValue="data.description" title="Описание"/>
        <card-field :modelValue="data.payment_id" title="Номер платежа в системе банка"/>
        <card-field :modelValue="data.tinkoff_payment_type ? data.tinkoff_payment_type.name : '—'" title="Тип платежа"/>
      </div>
      <div class="col-12 col-md-6">
        <card-field :modelValue="toTime(data.created_at)" title="Дата создания"/>
        <card-field :modelValue="toTime(data.updated_at)" title="Дата изменения"/>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  data() {
    return {}
  },
  props: {
    data: Object
  },
  mounted() {
  },
  methods: {},
}
</script>