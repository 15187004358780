<template>

  <div class="row" v-if="data" v-show="data.total>0 && !(data.total<=10 && data.perPage==10)">


    <div class="col-12 col-md-2 pt-2 pb-2 pb-md-0">
      Показано <span>{{ data.from }}</span>-<span>{{ data.to }}</span> из <span>{{ data.total }}</span>

    </div>
    <div class="col-12 col-md-4">
      <template v-if="!hidePerPage">
        <gui-per-page :data="data" @changePerPage="changePerPage" is_inline class="pl-0 pl-md-2"/>
      </template>
    </div>
    <div class="col-12 col-md-6">
      <template-pagination
          v-if="data.last_page > 1"
          v-model="currentPage"
          :page-count="data.last_page"
          :labels="labels"/>
    </div>
    <div class="cb"></div>
  </div>

</template>

<script>
//import vPagination from 'vue-plain-pagination'
import TemplatePagination from "./TemplatePagination";

export default {
  components: {
    TemplatePagination,
    //vPagination
  },
  beforeMount() {
    this.perPage = storageLoad('perPage_' + this.$route.name, 10)
  },
  data: function () {
    return {
      currentPage: 0,
      perPage: 10,
      labels: {
        first: "Первая",
        prev: "Назад",
        next: "Вперёд",
        las: "Последняя"
      },
      classes: {
        ul: 'dataTables_paginate paging_simple_numbers',
        li: 'paginate_button',
        liActive: 'pagination-item--active',
        liDisable: 'pagination-item--disable',
        button: 'pagination-link',
        buttonActive: 'current',
        buttonDisable: 'pagination-link--disable'
      },
      watchCurrentPage: false
    }
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {
          list_from: 0,
          list_to: 0,
          list_total: 0,
          last_page: 0,

          perPage: 0,
          currentPage: 0,
        }
      }
    },
    hidePerPage: Boolean
  },
  computed: {},
  mounted() {

    this.currentPage = this.$route.params.curPagPage ? this.$route.params.curPagPage : 1;
    this.watchCurrentPage = true;
  },
  methods: {
    changePerPage(e) {
      let oldCurrent = this.currentPage;
      let oldPerPage = this.perPage;

      this.perPage = e;
      this.currentPage = 1;

      if (oldCurrent === this.currentPage || oldPerPage !== this.perPage) this.$emit('change')

    }
  },
  watch: {
    'currentPage': function () {
      if (this.watchCurrentPage && this.data && this.data.currentPage !== this.currentPage)
        this.$emit('change')
    },
  }

}
</script>
