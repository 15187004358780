<template>
  <div class="app-chat">
    <div class="card card-bordered" v-if="chat">
      <div class="card-header d-block pl-0 pr-0" v-if="!readonly">
        <div class="row">
          <div class="col-8">
            <h4 class="card-title">
              <strong>
                <a :href="`/${chat.initiator.provider==='users' ? 'user' : (chat.initiator.provider==='clients' ? 'client' : 'squad')}/${chat.initiator.user_id}/detail`"
                   v-if="chat.initiator.provider !== 'guest'">
                  {{ chat.name }}
                </a>
                <span v-else>
              {{ chat.name }}
            </span>

              </strong>
            </h4>
          </div>
          <div class="col-4 text-right">
            <button class="btn btn-outline-secondary" @click.prevent="markArchive"
                    v-if="chat.is_active && currentMemberId">
              В архив
            </button>
          </div>
        </div>
      </div>

      <template v-if="joinedMessages.length===0">
        <div class="alert alert-info">Сообщений нет</div>
      </template>
      <div
          v-else
          class="ps-container ps-theme-default ps-active-y"
          id="chat-content"
          ref="chatContent"
          style="overflow-y: scroll !important; height: calc(100vh - 400px) !important"
      >
        <div v-if="messageFirstId" class="text-center pt-2 pb-2">
          <button class="btn btn-sm" @click.prevent="loadPreviewMessages">
            Загрузить ранние сообщения
          </button>
        </div>

        <template v-for="(block, index) in joinedMessages" :key="index">
          <div v-if="block.type === 'block'"
               class="media media-chat"
               :class="{ 'media-chat-reverse': block.isMyMessage }"
          >
            <chat-member-badge :member="getMemberById(block.messages[0].sender_uuid)"/>

            <div class="media-body">
              <template v-for="message in block.messages" :key="message.uuid">
                <div class="p"
                     v-if="message.text"
                     :title="`Отправлено `+toTime(message.sent_at)"
                     style="white-space: pre-line;">
                  {{ message.text }}
                  <time>
                    {{ moment(message.sent_at).format('HH:mm') }}
                  </time>
                </div>
                <div></div>
                <div v-if="message.attachments.length" class="attachments">
                  <div v-for="attach in message.attachments" :key="attach.uuid" class="attachment mb-3">

                    <div v-if="attach.size <= 5*1024*1024 && attach.mime.indexOf('image/')===0"
                         class="attachment-image">
                      <a :href="attach.url" target="_blank"
                         :title='`Отправлено `+toTime(message.sent_at)+` `+attach.name'>
                        <img :src="attach.url" :alt="attach.name" class="img-thumbnail"/>
                      </a>
                    </div>
                    <i class="fas fa-paperclip mr-2"></i>
                    <a :href="attach.url" target="_blank"
                       :title='`Отправлено `+toTime(message.sent_at)+` `+attach.name'>{{ attach.name.substr(0, 50) }}
                    </a>
                    <div class="attachment-meta">
                      <small>
                        {{ Math.round(attach.size / 1024 / 1024 * 100) / 100 }} МБ
                        <time class="ml-2">
                          {{ moment(message.sent_at).format('HH:mm') }}
                        </time>
                      </small>
                    </div>
                  </div>
                </div>
                <div></div>
              </template>


            </div>
          </div>

          <div v-else-if="block.type === 'date'" class="media media-meta-day">
            <span>{{ block.value }}</span>
          </div>
        </template>

        <div class="ps-scrollbar-x-rail" style="left: 0px; bottom: 0px">
          <div class="ps-scrollbar-x" tabindex="0" style="left: 0px; width: 0px"></div>
        </div>

        <div class="ps-scrollbar-y-rail" style="top: 0px; height: 0px; right: 2px">
          <div class="ps-scrollbar-y" tabindex="0" style="top: 0px; height: 2px"></div>
        </div>
      </div>

      <div v-if="readonly" class="publisher bt-1 border-light">
        Чат открыт в режиме просмотра
      </div>
      <div class="publisher bt-1 border-light" v-else-if="currentMemberId">
        <chat-member-badge :member="getMemberById(currentMemberId)"/>
        <textarea
            class="publisher-input"
            placeholder="Введите текст"
            v-model="messageText"
            @keyup.ctrl.enter="sendMessage"
        >
        </textarea>

        <span class="publisher-btn file-group cursor-pointer" @click.prevent="openAttachModal">
          <i class="fa fa-paperclip file-browser"></i>
        </span>

        <button class="publisher-btn text-info" @click="sendMessage">
          <i class="fa fa-paper-plane"></i>
        </button>
      </div>
      <div v-else class="publisher bt-1 border-light d-block p-0 py-3">
        <div class="row">
          <div class="col-6">
            Вы не подключены к чату
          </div>
          <div class="col-6 text-right">
            <button class="btn btn-primary" @click.prevent="attachMember">
              Подключиться
            </button>
          </div>
        </div>
      </div>
    </div>
    <div ref="attachModal" style="display: none">
      <div class="mb-3">
        <b>Отправить файл</b>
      </div>
      <spinner-block v-model="fileProgress">
        <gui-file v-model="selectFile"/>
        <div class="mt-3">
          <button class="btn btn-primary mr-2" :disabled="!selectFile" @click.prevent="sendFile">Отправить файл</button>
          <button class="btn btn-secondary" @click.prevent="fancyboxClose">Отмена</button>
        </div>
      </spinner-block>
    </div>
  </div>
</template>

<script>
import {v4 as uuidv4} from 'uuid';
import ChatMemberBadge from "@/components/Badge/ChatMemberBadge";

export default {
  components: {ChatMemberBadge},

  data() {
    return {
      messageFirstId: null,
      messageLastId: null,
      messageText: '',
      messages: [],
      chat: null,
      currentMemberId: null,
      perPage: 30,
      selectFile: null,
      fileProgress: false,
    };
  },

  props: {
    uuid: String,
    readonly: Boolean,
  },

  computed: {
    joinedMessages() {
      if (!this.messages || !this.messages.length) return [];

      const result = [];
      const groupMessageIfTimeLess = 60

      let lastDate = this.moment(this.messages[0].sent_at).format('DD.MM.YYYY');

      result.push({
        type: 'date',
        value: lastDate,
      })

      this.messages.forEach(message => {

        let currDate = this.moment(message.sent_at).format('DD.MM.YYYY')

        if (currDate !== lastDate) {
          lastDate = currDate
          result.push({
            type: 'date',
            value: lastDate,
          })
        }


        let prevMessage = result.length && result[result.length - 1].type === 'block' && result[result.length - 1].messages.length
            ? result[result.length - 1].messages[result[result.length - 1].messages.length - 1]
            : null

        if (prevMessage && prevMessage.sender_uuid === message.sender_uuid && (this.moment(prevMessage.sent_at).format('X') - this.moment(message.sent_at).format('X')) > -groupMessageIfTimeLess) {
          result[result.length - 1].messages.push(message)
        } else {
          result.push({
            type: 'block',
            isMyMessage: message.sender_uuid === this.currentMemberId,
            messages: [message],
          })
        }

      })

      return result;
    },
  },

  async created() {

    await this.$chat.initIfNeed()

    if (this.uuid) {
      await this.$chat.joinToChat(this.uuid);
    }

    await this.fetchChat();
    await this.fetchMessages();

    this.$chat.onMessageSent(this.onMessageSent);

    setTimeout(this.scrollBottom, 0)
  },

  async unmounted() {
    if (this.uuid) {
      await this.$chat.leaveFromChat(this.uuid);
    }
    this.$chat.offMessageSent(this.onMessageSent)
  },

  methods: {
    async markArchive() {
      $.alertConfirm({
        content: 'Переместить чат в архив?'
      }, {
        ok: async () => {
          await this.$chat.markArchive(this.uuid);
          await this.fetchChat()
          this.$emit('update', {})
        }
      });
    },
    async attachMember() {
      await this.$chat.attachForChat(this.uuid);
      await this.fetchChat()
      await this.fetchMessages();
    },
    async fetchChat() {
      const result = await this.$chat.getChat(this.uuid);
      this.chat = result.chat
      this.currentMemberId = result.currentMemberId
    },

    async fetchMessages() {
      this.messages = []
      const result = await this.$chat.getMessages(this.uuid, {
        limit: this.perPage,
        order: 'desc'
      });

      if (result.messages.length < this.perPage) {
        this.messageFirstId = null
      } else {
        this.messageFirstId = +result.cursor.first_cursor;
      }
      this.messageLastId = +result.cursor.last_cursor;
      this.addMessagesToBeginning(result.messages);
      this.makeSeen()
    },

    async loadPreviewMessages() {
      const result = await this.$chat.getMessages(this.uuid, {
        lt: this.messageFirstId,
        limit: this.perPage,
        order: 'desc'
      });

      if (result.messages.length < this.perPage) {
        this.messageFirstId = null
      } else if (this.messageFirstId > +result.cursor.first_cursor || result.cursor.first_cursor === null) {
        this.messageFirstId = +result.cursor.first_cursor
      }


      this.addMessagesToBeginning(result.messages);
    },

    async loadNextMessages() {
      const result = await this.$chat.getMessages(this.uuid, {
        gt: this.messageLastId
      });

      if (this.messageLastId < +result.cursor.last_cursor || result.cursor.last_cursor === null) {
        this.messageLastId = +result.cursor.last_cursor;
      }

      this.addMessagesToEnd(result.messages);
    },

    async sendMessage() {

      if (!this.messageText.trim()) {
        return;
      }

      await this.$chat.sendMessage(this.uuid, {
        uuid: uuidv4(),
        text: this.messageText,
      }).finally(() => {
        this.messageText = ""
      });
    },

    onMessageSent(message) {

      if (message.chat_uuid !== this.uuid) {
        return
      }

      if (+message.order) {
        this.messageLastId = +message.order;
      }

      let isBottom = this.isScrollBottom();
      this.addMessagesToEnd([message]);
      if (isBottom) {
        setTimeout(this.scrollBottom, 0);
      }
      this.makeSeen()
    },

    addMessagesToBeginning(payload) {
      this.messages.splice(0, 0, ...payload);
    },

    addMessagesToEnd(payload) {
      const len = this.messages.length;
      this.messages.splice(len, 0, ...payload);
    },

    isScrollBottom() {
      if (!this.$refs.chatContent) return false;
      let delta = (this.$refs.chatContent.scrollHeight - this.$refs.chatContent.scrollTop - this.$refs.chatContent.clientHeight)
      return delta === 0
    },

    scrollBottom() {
      if (!this.$refs.chatContent) {
        return
      }
      this.$refs.chatContent.scroll(0, this.$refs.chatContent.scrollHeight)
    },

    getMemberById(memberId) {
      if (this.chat && this.chat.members) {
        return this.chat.members.find(e => e.uuid === memberId)
      }
      return null
    },

    openAttachModal() {
      this.fancybox(this.$refs.attachModal, () => {
        this.selectFile = null
      })
    },

    sendFile() {
      axiosFast({
        url: '/api/admin/chat/file',
        formData: {
          file: this.selectFile,
        },
        progress: e => this.fileProgress = e,
        result: async (data) => {

          this.fileProgress = true
          await this.$chat.sendMessage(this.uuid, {
            uuid: uuidv4(),
            text: "",
            attachments: [{
              uuid: data.uuid,
              name: data.name,
              size: data.size + "",
              mime: data.mime,
              url: data.url,
            }]
          }).finally(() => {
            this.fileProgress = false
            this.fancyboxClose()
          });


        },
      })
    },

    async makeSeen() {
      if (!this.currentMemberId && !this.readonly) {
        return
      }
      await this.$chat.makeSeen(this.uuid)
      this.$emit('update', {})
    }
  },
};
</script>
