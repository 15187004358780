// https://niksmr.github.io/vue-masked-input/
import VueClipboard from "vue-clipboard2";
import CacheVariables from "./cache";
import {TokenService} from "@/services/token.service";


let Vue = window.Vue
let $bus = new require('vue');
window.$bus = $bus;


Vue.config.globalProperties.Cache = require('./cache').default;
Vue.config.globalProperties.$url = document.location.origin;
Vue.config.globalProperties.$app = window.app;
Vue.config.globalProperties.$CallPrint = window.CallPrint;
Vue.config.globalProperties.$storageLoad = window.storageLoad;
Vue.config.globalProperties.$storageSet = window.storageSet;
Vue.config.globalProperties.$bus = $bus;
Vue.config.globalProperties.trans = window.trans;
Vue.config.globalProperties.morphTo = window.morphTo;
Vue.config.globalProperties.$set = (a, b, c) => a[b] = c;
Vue.config.globalProperties.$nextTick = (c) => setTimeout(c, 0);


let moment = require('moment')
moment.locale('ru', require('../moment_lang').default);
moment.defaultFormat = 'DD.MM.YYYY';
Vue.config.globalProperties.moment = moment

/**
 * Открыть диалоговое окно fancybox
 * @param element тег для вызова
 * @param afterClose действие при закрытии
 */
Vue.config.globalProperties.fancybox = function (element, afterClose) {

    element.onkeyup = (e => {
        if (e.keyCode === 27) {
            window.$.fancybox.close();
        }
    });

    window.$.fancybox.open(element, {
        baseClass: 'fb-modal-vue fb-modal-html',
        afterClose: afterClose,

        trapFocus: true,
        // Remove buttons
        infobar: 0,
        toolbar: 0,

        //smallBtn: 0,

        // Disable keyboard navigation
        keyboard: 0,

        // Disable some modules
        slideShow: 0,
        fullScreen: 0,
        thumbs: 0,
        touch: 0,

        // Disable click event handlers
        clickContent: false,
        clickSlide: 'close',
        clickOutside: 'close',
        dblclickContent: false,
        dblclickSlide: false,
        dblclickOutside: false
    });
};

/**
 * Открыть картинку на просмотр
 * @param element
 * @param afterClose
 */
Vue.config.globalProperties.fancybox_image = function (element, afterClose) {

    element.onkeyup = (e => {
        if (e.keyCode === 27) {
            window.$.fancybox.close();
        }
    });

    window.$.fancybox.open(element, {
        afterClose: afterClose,
    });
};
Vue.config.globalProperties.fancyboxClose = () => window.$.fancybox.close();

/**
 * Убрать лишние символы из url cnhjrb
 * @param url
 * @returns {*}
 */
Vue.config.globalProperties.readableUrl = url => url.replace(/^https?:\/\/(www\.)?/, '');


function toMoment(time) {
    return (isNaN(time) ? moment(time) : moment(time * 1000))
}

/**
 * Форматирование вывода даты-времени
 * @param time
 * @returns {*}
 */
Vue.config.globalProperties.toTimes = time => !time ? '—' : toMoment(time).format('DD.MM.YYYY HH:mm:ss');
Vue.config.globalProperties.toTime = time => !time ? '—' : toMoment(time).format('DD.MM.YYYY HH:mm');
Vue.config.globalProperties.toTimeSmall = time => !time ? '—' : toMoment(time).format('HH:mm');
Vue.config.globalProperties.toChatTime = time => {
    if (!time) return '—'
    let timeMoment = toMoment(time)
    let today = moment()

    if (timeMoment.isSame(today, 'day')) {
        return timeMoment.format('HH:mm')
    }
    if (timeMoment.isSame(today, 'week')) {
        return timeMoment.format('dddd HH:mm')
    }
    return timeMoment.format('DD.MM.YYYY HH:mm')
};

/**
 * Форматирование вывода даты
 * @param time
 * @returns {*}
 */
Vue.config.globalProperties.toDate = (time, def) => !time ? (def ? def : '—') : toMoment(time).format('DD.MM.YYYY');

/**
 * Форматирование геокоординат
 * @param value
 * @param def
 * @returns {string|*|string}
 */
Vue.config.globalProperties.toGeo = (value, def) => value && value.lat && value.lon ? (value.lat + ', ' + value.lon) : (def ? def : '—');

/**
 * Получить возраст
 * @param time от даты
 * @param to до даты (если пусто - до сегодня)
 * @returns {*}
 */
Vue.config.globalProperties.getAge = (time, to) => !time ? 0 : moment(to ? (isNaN(to) ? to : to * 1000) : new Date()).diff(isNaN(time) ? time : time * 1000, 'years');

/**
 * Форматирование вывода дня рождения
 * @param time
 * @returns {string}
 */
Vue.config.globalProperties.toDateWithYear = time => {
    let y = moment().diff(isNaN(time) ? time : time * 1000, 'years');
    return !time ? '—' : moment(isNaN(time) ? time : time * 1000).format('DD.MM.YYYY') + ' (' + y + ' ' + window.morphTo(y, ['год', 'года', 'лет']) + ')'
};

/**
 * Форматирование вывода времени час\минута
 * @param time
 * @returns {string}
 */
Vue.config.globalProperties.minFormat = time => {
    return (~~(time / 60) > 0 ? (~~(time / 60)) + ' ч. ' : '') + (time % 60) + ' мин.'
};

/**
 * Форматирование вывода времени день/час
 * @param hours
 * @returns {string}
 */
Vue.config.globalProperties.hourFormat = hours => {
    return (~~(hours / 24) > 0 ? (~~(hours / 24)) + ' д. ' : '') + ~~(hours % 24) + ' ч.'
};

/**
 * Форматирование вывода цены
 * @param data
 * @returns {string}
 */
Vue.config.globalProperties.priceFormat = data => {
    let price = Number.prototype.toFixed.call(parseFloat(data) || 0, parseFloat(data) % 1 ? 2 : 0);
    let price_sep = price.replace(/(\.)/g, ".");
    return price_sep.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
};

Vue.config.globalProperties.copyTextModal = text => {
    this.$copyText(text).then(() => {
        console.log('Текст скопирован ' + htmlspecialchars(text), 1000)
    }, () => {
        console.log('Не удалось скопировать текст', 1000);
    })
}

/**
 * Перейти по истории назад
 */
Vue.config.globalProperties.history_back = () => window.history.back();
Vue.config.globalProperties.strip_tags = window.strip_tags;
Vue.config.globalProperties.sid_images = window.sid_images;
Vue.config.globalProperties.nl2br = window.nl2br;
Vue.config.globalProperties.rnd = window.rnd;
Vue.config.globalProperties.Cache = new CacheVariables($bus);
Vue.config.globalProperties.toastSuccess = toastSuccess;
Vue.config.globalProperties.toastError = toastError;
Vue.config.globalProperties.toastInfo = toastInfo;
Vue.config.globalProperties.toastWarning = toastWarning;
Vue.config.globalProperties.can = (permission) => {
    const permissions = TokenService.getPermissions();
    if (!permissions || !permissions.length) return false;
    return TokenService.getPermissions().indexOf(permission) !== -1
};

Vue.use(require('../plugins/acl.js'));
Vue.use(VueClipboard);
//Vue.use(require('vuedraggable'));

require('./vue-component');
