<template>
  <div class="mb-2">
    <div class="small text-secondary" v-if="title" v-html="title"></div>
    <template v-if="$slots.default">
      <slot></slot>
    </template>
    <template v-else>{{ modelValue ? modelValue : '—' }}</template>
  </div>
</template>

<script>
export default {
  data: function () {
    return {}
  },
  props: {
    title: String,
    modelValue: [String, Number]
  },
  computed: {},
  mounted() {

  },
  methods: {}
}
</script>