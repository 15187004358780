<template>
  <card-block>
    <spinner-block v-model="dataProgress">
      <div v-if="list">
        <card-header>
          Страницы документа
          <template v-slot:right>
            <router-link :to="{name: 'document-type.create'}" v-if="can('module.client_document_type.create')">
              Добавить страницу
            </router-link>
          </template>
        </card-header>
        <card-body>

          <div v-if="list && list.length">
            <table class="table table-hover">
              <thead>
              <tr>
                <th>Название</th>
                <th class="d-none d-md-table-cell">Обязательность</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="row in list" :key="row.id">
                <td class="limit-third_length">
                  <router-link :to="{name: 'document-type.edit', params: {id: row.id}}" class="main-link">
                    {{ row.name }}
                  </router-link>
                </td>
                <td class="d-none d-md-table-cell limit-third_length">
                  {{ row.is_required ? 'Да' : 'Нет' }}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div v-else class="alert alert-info">Список пуст</div>
        </card-body>
      </div>
    </spinner-block>
  </card-block>
</template>

<script>

export default {
  data() {
    return {
      list: [],
      dataProgress: false,
    }
  },
  mounted() {
    this.loadDocumentTypesList()
  },
  methods: {
    loadDocumentTypesList() {
      axiosFast({
        url: '/api/admin/client-document-type/list',
        progress: e => this.dataProgress = e,
        result: (data) => this.list = data,
      });
    },
  },
}
</script>
