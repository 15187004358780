<template>
  <div>
    <spinner-block v-model="dataProgress">
      <div v-if="data">
        <card-field :modelValue="toTime(data.created_at)" title="Дата заполнения"/>
        <card-field :modelValue="answer.values.map(e=>e.value).join(', ')" :title="answer.name"
                    v-for="(answer, i) in data.answers" :key="i"/>
      </div>
    </spinner-block>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      data: null,
      dataProgress: true,
    }
  },
  props: {
    id: Number,
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      if (this.id) {
        axiosGetValue('/api/admin/quiz-answer/get', {id: this.id}, e => this.data = e, e => this.dataProgress = e)
      }
    },
  }
}
</script>
