<template>
  <div>
    <div class="row">
      <div class="col-12">
        <card-field title="Активен">
          {{ data.is_active ? 'Да' : 'Нет' }}
        </card-field>
        <card-field title="Название" :modelValue="data.name"/>
        <card-field title="Цвет">
          <i class="fas fa-circle mr-2" v-if="data.color && data.color.length == 6"
             :style="{color: '#'+data.color}"></i>
          {{ data.color }}
        </card-field>
        <card-field title="Контрастный цвет">
          <i class="fas fa-circle mr-2" v-if="data.contrast_color && data.contrast_color.length == 6"
             :style="{color: '#'+data.contrast_color}"></i>
          {{ data.contrast_color }}
        </card-field>
        <card-field title="Сортировка" :modelValue="data.sort"/>
      </div>
    </div>

    <div class="mt-3">

      <div class="float-right">
        <button-remove @click="remove" v-if="can('module.dictionary.color-delete')"/>
      </div>


      <button-edit @click="$router.push({name: 'dictionary-color.edit', params: {id: data.id}})"
                   v-if="can('module.dictionary.color-update')"/>
    </div>
  </div>
</template>

<script>


export default {
  data() {
    return {}
  },
  props: {
    data: Object
  },
  mounted() {
  },
  methods: {

    remove() {
      $.alertConfirm({
        type: 'red',
      }, {
        ok: () => {
          axiosGetValue('/api/admin/dictionary-color/delete', {id: this.data.id}, () => {
            toastSuccess('Удаление завершено');
            this.$router.push({name: 'dictionary-color.list'})
          })
        }
      });
    },
  },
}
</script>