<template>
  <gui-input-row :data="$props">
    <div class="input-group input-date-range" :class="{'input-group-sm':sm}">
      <div style="-webkit-box-flex: 1;-ms-flex: 1 1 auto;flex: 1 1 auto;width: 1%;">
        <gui-date
            :modelValue="modelValue[fromKey]"
            @update:modelValue="e=>setValue(fromKey,e)"
            :sm="sm"
            :minDate="minDate"
            :maxDate="maxDate"
            :disabled="disabled"
            :hilightOn="hilightOn"
            placeholder="От"
            class="mb-0 input-1"
            :clearable="clearable"/>
      </div>
      <div style="-webkit-box-flex: 1;-ms-flex: 1 1 auto;flex: 1 1 auto;width: 1%;">
        <gui-date
            :modelValue="modelValue[toKey]"
            @update:modelValue="e=>setValue(toKey, e)"
            :sm="sm"
            :minDate="minDate"
            :maxDate="maxDate"
            :disabled="disabled"
            :hilightOn="hilightOn"
            placeholder="До"
            class="mb-0"
            :clearable="clearable"/>
      </div>
      <div class="input-group-append">
        <div role="group" class="btn-group btn-group-sm">
          <button type="button"
                  :disabled="disabled"
                  class="btn btn-secondary group-dropdown"><i class="fas fa-bars"></i></button>
          <div class="dropdown-menu dropdown-menu-right">

            <div class="dropdown-item text-justify px-1"
                 v-for="(row, i) in variables" :key="i">
              <a class="mr-2" href="#" :title="`Сместить на ${row.shift} назад`"
                 @click.prevent="shiftFilterDate(row.code, -1)">
                <i class="fas fa-angle-double-left"></i>
              </a>
              <!--<a href="#" :title="`Прошлый ${row.shift}`"
                :class="{'text-success': dateRange_local==='prev_'+row.code}"
                @click.prevent="setFilterDate(row.code, -1)">
              <i class="fas fa-angle-left"></i>
              </a>
              -->
              <a href="#" :title="`Текущий ${row.shift}`"
                 :class="{'text-success': dateRange_local===row.code}"
                 @click.prevent="setFilterDate(row.code)" v-text="row.label">
              </a>
              <!--
              <a href="#" :title="`Следующий ${row.shift}`"
                 :class="{'text-success': dateRange_local==='next_'+row.code}"
                 @click.prevent="setFilterDate(row.code, 1)">
                  <i class="fas fa-angle-right"></i>
              </a> -->
              <a href="#" class="ml-2" :title="`Сместить на ${row.shift} вперёд`"
                 @click.prevent="shiftFilterDate(row.code, 1)">
                <i class="fas fa-angle-double-right"></i>
              </a>
              <div style="display: inline-block;width: 100%"></div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </gui-input-row>
</template>

<script>

export default {
  data: function () {
    return {
      dateRange_local: 'month',
      variables: [
        {label: 'Сегодня', code: 'day', shift: 'день'},
        {label: 'Неделя', code: 'week', shift: 'неделю'},
        {label: 'Месяц', code: 'month', shift: 'месяц'},
        {label: 'Квартал', code: 'quarter', shift: 'квартал'},
        {label: 'Год', code: 'year', shift: 'год'},
      ]
    }
  },
  props: {
    modelValue: Object,
    fromKey: {
      type: String,
      default: 'date_from'
    },
    toKey: {
      type: String,
      default: 'date_to'
    },
    dateRange: String,

    f: Boolean,
    sm: Boolean,
    w: Boolean,
    error: [Array, Object, String],
    label: String,
    minDate: String,
    maxDate: String,
    clearable: Boolean,
    disabled: Boolean,
    hilightOn: [Array, String, Number],
  },
  computed: {},
  beforeMount() {

  },
  mounted() {
    if (this.dateRange) {
      this.dateRange_local = this.dateRange;
      this.setFilterDate(this.dateRange_local);
    } else {
      this.checkDayRange()
    }

  },
  methods: {
    setValue(param, e) {
      this.$emit('update:modelValue', Object.assign(this.modelValue, {[param]: e}));
      this.getList();
    },
    setFilterDate(range, shift) {
      let date = this.moment();
      let r = this.getDateRange(range, shift, date);
      this.$emit('update:modelValue', Object.assign(this.modelValue, {[this.fromKey]: r[0], [this.toKey]: r[1]}));
      this.getList();

    },
    shiftFilterDate(range, shift) {
      let params = {};
      if (this.modelValue[this.fromKey]) {
        let d = this.moment(this.modelValue[this.fromKey])
        if (shift) d.add(shift, range)
        params[this.fromKey] = d.format('YYYY-MM-DD')
      }
      if (this.modelValue[this.toKey]) {
        let d = this.moment(this.modelValue[this.toKey])
        if (shift) d.add(shift, range)
        params[this.toKey] = d.format('YYYY-MM-DD')
      }
      this.$emit('update:modelValue', Object.assign(this.modelValue, params));
      this.getList();
    },

    getDateRange(range, shift, date) {
      let day = this.moment(date);

      if (shift) day.add(shift, range)

      return [day.startOf(range).format('YYYY-MM-DD'), day.endOf(range).format('YYYY-MM-DD')];
    },
    checkDayRange() {
      let result = false;

      this.variables.forEach(z => {
        let e = z.code
        let d = null

        d = this.getDateRange(e);
        if (this.modelValue[this.fromKey] === d[0] && this.modelValue[this.toKey] === d[1]) result = e;

        d = this.getDateRange(e, -1);
        if (this.modelValue[this.fromKey] === d[0] && this.modelValue[this.toKey] === d[1]) result = 'prev_' + e;

        d = this.getDateRange(e, 1);
        if (this.modelValue[this.fromKey] === d[0] && this.modelValue[this.toKey] === d[1]) result = 'next_' + e;

      })

      this.dateRange_local = result
    },
    getList() {
      this.$emit('update')
    },
  },
  watch: {
    modelValue: {
      handler() {
        this.checkDayRange();
      },
      deep: true
    }
  }
}
</script>
