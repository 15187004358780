<template>
  <div>
    <spinner-block v-model="dataProgress">
      <div v-if="data">
        <gui-quiz-question-type-select v-model="data.quiz_question_type_id" label="Тип вопроса"
                                       :error="dataError.quiz_question_type_id" :edit="!data.is_lock"/>

        <gui-input v-model="data.name" :error="dataError.name" label="Название"/>
        <gui-input v-model="data.code" :error="dataError.code" label="Код" :edit="!data.is_lock"/>
        <gui-input v-model="data.sort" :error="dataError.sort" label="Сортировка"/>

        <gui-checkbox v-model="data.is_active" :error="dataError.is_active" label="Активный" :edit="!data.is_lock"/>
        <gui-checkbox v-model="data.is_required" :error="dataError.is_required" label="Обязательный"
                      :edit="!data.is_lock"/>
        <gui-checkbox v-model="data.can_has_custom_answer" :error="dataError.can_has_custom_answer"
                      label="Может иметь своё значение" :edit="!data.is_lock"/>


        <div class="mt-5">
          <button-save v-if="!data.id" @click="add()"/>
          <button-save v-else @click="save"/>
          <button-cancel @click="$emit('cancel')"/>
        </div>
      </div>
    </spinner-block>
  </div>
</template>

<script>


import GuiQuizQuestionTypeSelect from "@/components/Widget/GuiQuizQuestionTypeSelect";

export default {
  components: {GuiQuizQuestionTypeSelect},
  data() {
    return {
      data: null,
      dataDefault: {
        name: '',
        quiz_id: '',
        quiz_question_type_id: '',
        is_active: '',
        is_required: '',
        can_has_custom_answer: '',
        sort: 100,
        code: '',
      },
      dataError: {
        name: '',
        quiz_id: '',
        quiz_question_type_id: '',
        is_active: '',
        is_required: '',
        can_has_custom_answer: '',
        sort: '',
        code: '',
      },
      dataProgress: false,
    }
  },
  props: {
    id: [String, Number],
    defaultParams: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
  },
  methods: {

    init() {
      if (this.id) {
        this.data = null
        this.loadData()
      } else {
        this.data = Object.assign({}, this.dataDefault, this.defaultParams)
      }
      clearErrorList(this.dataError)
    },
    loadData() {
      axiosGetValue('/api/admin/quiz-question/get', {id: this.id}, e => this.data = e, e => this.dataProgress = e)
    },

    save() {
      axiosFast({
        url: route('/api/admin/quiz-question/update'),
        data: {data: this.data, id: this.data.id},
        error: this.dataError,
        progress: e => this.dataProgress = e,
        result: () => {
          toastSuccess('Данные сохранены');
          this.$emit('save', this.data.id)
        },
      })
    },
    add() {
      axiosFast({
        url: route('/api/admin/quiz-question/create'),
        data: {data: this.data},
        error: this.dataError,
        progress: e => this.dataProgress = e,
        result: (data) => {
          toastSuccess('Данные добавлены');
          this.$emit('add', data.id)
        },
      })
    },
  },
}
</script>