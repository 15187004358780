import EmptyWrap from "@/components/Gui/EmptyWrap";

export default [
    {
        path: 'submit-form',
        component: EmptyWrap,
        children: [
            {
                path: '',
                name: 'submit-form.list',
                component: require('@/views/SubmitForm/SubmitFormList').default,
            },
            {
                path: 'create',
                name: 'submit-form.create',
                component: require('@/views/SubmitForm/SubmitFormForm').default,
            },
            {
                path: ':id',
                component: require('@/views/SubmitForm/Card/WrapBlock').default,
                children: [
                    {
                        path: 'detail',
                        name: 'submit-form.detail',
                        component: require('@/views/SubmitForm/Card/SubmitFormDetail').default,
                    },
                ]
            },
            {
                path: ':id/edit',
                name: 'submit-form.edit',
                component: require('@/views/SubmitForm/SubmitFormForm').default,
            },
        ]
    },
]