<template>
  <div>
    <div class="row">
      <div class="col-12">
        <card-field title="Тип подписки" :modelValue="data.type ? data.type.name : '—'"/>
        <card-field title="Название">
          <i class="fas fa-circle small"
             :title="data.is_active ? 'Активен' : 'Неактивен'"
             :class="data.is_active ? 'text-success' : 'text-danger'"></i>
          {{ data.name }}
        </card-field>
        <card-field title="Длительность, месяцев" :modelValue="data.time"/>
        <card-field title="Кол-во бесплатных вызовов" :modelValue="data.free_calls_count"/>
        <card-field title="Максимальное количество человек" :modelValue="data.max_peoples"/>
        <card-field title="Является публичным" :modelValue="data.is_public ? 'Да' : 'Нет'"/>
        <card-field title="Дата деактивации" :modelValue="toTime(data.deactivated_at)"/>
        <card-field title="Следующий тариф для продления в случае деактивации">
          <template v-if="data.next_tariff_id">
            <router-link :to="{name: 'subscription-tariff.detail', params: {id: data.next_tariff_id}}" target="_blank">
              #{{ data.next_tariff_id }}
            </router-link>
          </template>
          <template v-else>—</template>
        </card-field>

        <div v-if="data.details && data.details.length">
          <table class="table table-sm">
            <thead>
            <tr>
              <th>Количество пользователей в подписке</th>
              <th>Стоимость, руб</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(i, k) in data.details" :key="i.people_count">
              <td>{{ i.people_count }}</td>
              <td>
                {{ data.details[k].price }}
              </td>
            </tr>
            </tbody>
          </table>

        </div>
        <div v-else class="alert alert-info">Выберите "Максимальное количество человек в подписке"</div>


        <card-field title="Максимальная стоимость, руб" :modelValue="data.cost"/>


        <card-field title="Описание">
          <div v-if="data.description" v-html="strip_tags(data.description)"></div>
        </card-field>

      </div>
    </div>

    <div class="mt-3">

      <div class="float-right">
        <button-remove @click="remove" v-if="can('module.subscription_tariff.delete')"/>
      </div>


      <button-edit @click="$router.push({name: 'subscription-tariff.edit', params: {id: data.id}})"
                   v-if="can('module.subscription_tariff.update')"/>
    </div>
  </div>
</template>

<script>


export default {
  data() {
    return {}
  },
  props: {
    data: Object
  },
  mounted() {
  },
  methods: {

    remove() {
      $.alertConfirm({
        type: 'red',
      }, {
        ok: () => {
          axiosGetValue('/api/admin/subscription-tariff/delete', {id: this.data.id}, () => {
            toastSuccess('Удаление завершено');
            this.$router.push({name: 'subscription-tariff.list'})
          })
        }
      });
    },
  },
}
</script>