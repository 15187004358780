<template>

  <div :class="{'small-tab-list':is_small}">
    <div class="float-right px-3 tab-list-right">
      <slot name="right"></slot>
    </div>
    <div class="pb-0" id="nav-tab" role="tablist" :class="{'card-header':!no_card_header}">
      <nav class="nav nav-tabs">
        <slot></slot>
      </nav>
    </div>
  </div>

</template>

<script>
export default {
  data: function () {
    return {}
  },
  props: {
    is_small: Boolean,
    no_card_header: Boolean
  },
  computed: {},
  mounted() {

  },
  methods: {}
}
</script>
