const state = {
    chats: [],

    chatPaging: {
        total: 0,
        current_page: 1,
        per_page: 10,
        last_page: 0,
        from: 0,
        to: 0
    },

    oldChats: null
}

const getters = {
    oldChats(state) {
        return state.oldChats;
    }
};

const mutations = {
    updateChatList(state, payload) {
        const len = state.chats.length;
        state.chats.splice(0, len, ...payload.chats);
        state.chatPaging = payload.pagination;
    },
    oldChats(state, payload) {
        state.oldChats = payload
    }
};

const actions = {
    async updateOldChats(context) {
        axiosGetValue('/api/admin/old-chats', {}, e => context.commit("oldChats", e))
    },
};

export const chat = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}