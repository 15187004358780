<template>
  <div class="mt-5">
    <div class="mb-3 row">
      <div class="col-3">
        <select :value="selectValue" @change="selectCity($event.target.value)" class="form-control">
          <option value="" disabled>Выберите город</option>
          <option v-for="city in cities" :key="city.id" :value="city.id">{{ city.name }}</option>
        </select>
      </div>
      <div class="col-6"></div>
      <div class="col-3 text-right">
        <button class="btn btn-primary mb-2" @click.prevent="loadData">
          Обновить данные
        </button>
      </div>
    </div>

    <div ref="map" style="width: 100%; height: 500px; max-height: 100% ">
      <div ref="popup" id="popup"></div>
    </div>
    <div style="display: none" ref="popupContent">
      <div style="min-width: 200px">
        <template v-if="selectFeatureProp">
          <template v-if="selectFeatureProp.type==='squad'">
            <div><b>Отряд:</b></div>
            <div>
              <router-link :to="{name: 'squad.detail', params: {id: selectFeatureProp.id}}" target="_blank">
                #{{ selectFeatureProp.id }}&nbsp;{{ selectFeatureProp.name ? selectFeatureProp.name : '—' }}
              </router-link>
            </div>
            <div>Геопозиция обновлена: {{ toTime(selectFeatureProp.updated_at) }}</div>
          </template>
          <template v-else-if="selectFeatureProp.type==='district'">
            <div>{{ selectFeatureProp.name }}</div>
          </template>
          <template v-else>
            Нет информации
          </template>
        </template>
      </div>
    </div>
    <div class="mt-3 row justify-content-between">
      <div class="col-2">
        <div v-if="mapParams.center">

          <div class="small text-secondary">
            Данные обновлены
          </div>
          {{ updatedAt }}

          <div class="text-secondary small">
            центр экрана
          </div>
          {{ mapParams.center[1].toFixed(5) }},
          {{ mapParams.center[0].toFixed(5) }}

        </div>
      </div>
      <div class="col-2 text-right">
        <div v-if="cursor">
          <div class="text-secondary small">
            курсор
          </div>
          {{ cursor[1].toFixed(5) }}, {{ cursor[0].toFixed(5) }}
        </div>
      </div>
    </div>

    <p v-if="!can('module.organization.view_all-view')" class="text-secondary mt-3">
      Результат показан с учётом ограничения по организации
    </p>

  </div>
</template>

<script>

import {Feature, Map, Overlay, View} from 'ol/index.js';
import {OSM, Vector as VectorSource} from 'ol/source.js';
import {Point, Polygon} from 'ol/geom.js';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer.js';
import {useGeographic} from 'ol/proj.js';
import Popover from 'bootstrap/js/src/popover';
import {Circle as CircleStyle, Fill, Stroke, Style} from 'ol/style';

useGeographic();

const circleStyles = {
  squad_active: new Style({
    image: new CircleStyle({
      radius: 8,
      stroke: new Stroke({
        color: '#000',
        width: 1
      }),
      fill: new Fill({
        color: '#28a745',
      }),
    }),
  }),
  squad_work: new Style({
    image: new CircleStyle({
      radius: 10,
      stroke: new Stroke({
        color: '#000',
        width: 1
      }),
      fill: new Fill({
        color: '#ffc107',
      }),
    }),
  }),
  squad_not_work: new Style({
    image: new CircleStyle({
      radius: 10,
      stroke: new Stroke({
        color: '#000',
        width: 1
      }),
      fill: new Fill({
        color: '#6c757d',
      }),
    }),
  }),
  squad_pause: new Style({
    image: new CircleStyle({
      radius: 8,
      stroke: new Stroke({
        color: '#000',
        width: 1
      }),
      fill: new Fill({
        color: '#dc3545',
      }),
    }),
  }),
  client_call: new Style({
    image: new CircleStyle({
      radius: 10,
      stroke: new Stroke({
        color: '#000',
        width: 1
      }),
      fill: new Fill({
        color: '#007bff',
      }),
    }),
  }),
  client: new Style({
    image: new CircleStyle({
      radius: 5,
      stroke: new Stroke({
        color: '#000',
        width: 1
      }),
      fill: new Fill({
        color: '#6c757d',
      }),
    }),
  }),
  line: new Style({
    stroke: new Stroke({
      color: '#ff0',
      width: 2,
      lineDash: [3, 10],
    }),
  }),
  line2: new Style({
    stroke: new Stroke({
      color: '#000',
      width: 4,
      lineDash: [3, 10],
    }),
  }),
  city: new Style({
    stroke: new Stroke({
      color: '#000',
      width: 1,
    }),
    fill: new Fill({
      color: '#ffffff20',
    }),
  }),
  district_active: new Style({
    stroke: new Stroke({
      color: '#000000f0',
      width: 1,
    }),
    fill: new Fill({
      color: '#28a7450f',
    }),
  }),
  district_deactive: new Style({
    stroke: new Stroke({
      color: '#000000f0',
      width: 1,
    }),
    fill: new Fill({
      color: '#dc35450f',
    }),
  }),
  track_point: new Style({
    image: new CircleStyle({
      radius: 3,
      stroke: new Stroke({
        color: '#000',
        width: 1
      }),
      fill: new Fill({
        color: '#6c757d',
      }),
    }),
  }),
  track_line: new Style({
    stroke: new Stroke({
      color: '#000000B0',
      width: 1,
    }),
  }),
}


export default {
  data: function () {
    return {
      map: null,
      mapParams: {
        zoom: null,
        center: null,
      },
      mapParamsDefault: {
        zoom: 11,
        center: [59.9386, 30.3141],
      },
      dateTrackRange: {
        startDate: null,
        endDate: null,
      },
      dateTrackRangeLimit: {
        startDate: null,
        endDate: null,
      },
      trackList: [],
      trackDriver: null,
      trackId: null,
      updatedAt: null,
      mapView: null,
      cursor: null, //Текущая позиция
      popover: null, //Объект подсказки
      popup: null, //Оверлей подсказки
      selectFeatureProp: null, //Свойства выбранной метки
      selectFeature: null, //Выбранная метка
      circleStyles: circleStyles, //Стили отображения
      layers: {
        map: new TileLayer({source: new OSM()}),
        city: new VectorLayer({}),
        district: new VectorLayer({}),
        squad: new VectorLayer({}),
        client: new VectorLayer({}),
        clientCall: new VectorLayer({}),
        track: new VectorLayer({}),
      },
      cities: [],
      selectValue: '',
      timeIntervalUpdate: null,
    }
  },
  props: {
    data: Object
  },
  computed: {},
  mounted() {
    this.mapParams = storageLoad(this.$route.name + '_map', this.mapParamsDefault)
    this.initMap()
    this.loadData()
    this.timeIntervalUpdate = setInterval(this.loadData, 30 * 1000)

  },
  unmounted() {
    if (this.timeIntervalUpdate) {
      clearTimeout(this.timeIntervalUpdate)
    }
  },
  methods: {
    initMap() {

      this.popup = new Overlay({
        element: this.$refs.popup,
        stopEvent: false,
      });

      this.mapView = new View({
        center: this.mapParams.center,
        zoom: this.mapParams.zoom,
      });

      this.map = new Map({
        target: this.$refs.map,
        view: this.mapView,
        overlays: [
          this.popup
        ],
        layers: Object.values(this.layers),
      });

      this.map.on('moveend', this.mapMoveEnd);

      this.map.on('click', this.mapClick);

      this.map.on('pointermove', this.mapPointerMove);

      window.mapBlock = this.map
    },

    setLayerValues(layer, values) {
      this.layers[layer].setSource(new VectorSource({features: values}))
    },

    mapPointerMove(event) {
      this.cursor = event.coordinate

      let feature = this.map.getFeaturesAtPixel(event.pixel)[0];

      if (!feature) {
        this.map.getViewport().style.cursor = 'inherit'
        return
      }

      let props = feature.getProperties()

      this.map.getViewport().style.cursor = ['squad', 'client', 'client_call', 'track_point', 'district'].indexOf(props.type) !== -1 ? 'pointer' : 'inherit';
    },

    mapMoveEnd() {
      this.mapParams.center = this.map.getView().getCenter();
      this.mapParams.zoom = this.map.getView().getZoom();
      storageSet(this.$route.name + '_map', this.mapParams)
    },

    mapClick(event) {

      if (event.originalEvent && event.originalEvent.target && event.originalEvent.target.tagName === 'A') {
        if (!event.originalEvent.target.classList.contains('nomapclick')) {
          event.originalEvent.target.click()
        }
        return;
      }

      //Получаем элемент по которому кликнули
      const feature = this.map.getFeaturesAtPixel(event.pixel)[0];

      //Если кликнули по уже открытому элементу - выходим
      if (feature && this.selectFeature && feature.ol_uid === this.selectFeature.ol_uid) {
        return
      }

      //Закрываем старое окно
      if (this.popover) {
        this.popover.dispose();
        this.popover = undefined;
        this.selectFeature = null;
        this.selectFeatureProp = null;
      }

      if (!feature) {
        return;
      }

      //Проверяем по какому элементу мы кликнули
      let props = feature.getProperties()
      if (['squad', 'client', 'client_call', 'track_point', 'district'].indexOf(props.type) === -1) {
        return
      }

      this.selectFeatureProp = props
      this.selectFeature = feature

      const coordinate = this.map.getCoordinateFromPixel(event.pixel);
      this.popup.setPosition([
        coordinate[0] + Math.round(event.coordinate[0] / 360) * 360,
        coordinate[1],
      ]);

      setTimeout(() => {
        this.popover = new Popover(this.$refs.popup, {
          container: this.$refs.popup.parentElement,
          content: this.$refs.popupContent.innerHTML,
          html: true,
          offset: 100,
          placement: 'top',
          sanitize: false,
        });
        this.popover.show()
      });
    },

    loadData() {
      this.loadDataCity()
      this.loadDataDistrict()
      this.loadDataSquad()
      this.updatedAt = this.moment().format("YYYY-MM-DD HH:mm:ss")
    },

    loadDataSquad() {
      axiosGetValue('/api/admin/client-call-squad/geo-data', { client_call_id: this.$route.params.id }, list => {
        let result = []
        if (list && list.length) {
          list.forEach(e => {
            e.type = 'squad'
            let feature = new Feature(new Point(e.geo))
            feature.setStyle(this.circleStyles.squad_active)
            feature.setProperties(e)
            result.push(feature)
          })
        }

        this.setLayerValues('squad', result)
      })
    },

    loadDataCity() {
      axiosGetValue('/api/admin/map/city', {}, list => {
        this.cities = list
        let result = []
        if (list && list.length) {
          list.forEach(e => {
            e.type = 'city'
            let feature = new Feature(new Polygon(e.area))
            feature.setStyle(this.circleStyles.city)
            feature.setProperties(e)
            result.push(feature)
          })
        }
        this.setLayerValues('city', result)
      })
    },

    loadDataDistrict() {
      axiosGetValue('/api/admin/map/district', {}, list => {
        let result = []
        if (list && list.length) {
          list.forEach(e => {
            e.type = 'district'
            let feature = new Feature(new Polygon(e.area))
            feature.setStyle(this.circleStyles[e.is_active ? 'district_active' : 'district_deactive'])
            feature.setProperties(e)
            result.push(feature)
          })
        }
        this.setLayerValues('district', result)
      })
    },

    selectCity(cityId) {
      let city = this.cities.find(e => e.id == cityId)
      if (city) {
        this.mapView.setZoom(11)
        this.mapView.setCenter(city.area[0][0])
      }
    }
  }
}
</script>