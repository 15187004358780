<template>
  <card-block>
    <card-header>
      Список FAQ: {{ application_type_id == 1 ? 'клиенты' : 'отряды' }}
      <template v-slot:right>
        <button class="btn btn-success" @click.prevent="$refs.createModel.openModal({
        parent_section_id: null,
        application_type_id: application_type_id
        })" v-if="can('module.question.create')">
          Добавить вопрос
        </button>
      </template>
    </card-header>
    <card-body>
      <spinner-block v-model="progress">
        <div v-if="data && data.length">
          <table class="table border-left border-dark">
            <tbody>
            <question-list-block :data="data" :lvl="0"
                                 @addEvent="$refs.createModel.openModal"
                                 @updateEvent="e=>$refs.updateModel.openModal(e.id)"
                                 @removeEvent="remove"/>
            </tbody>
          </table>
        </div>
        <div v-else class="alert alert-info">Список пуст</div>
      </spinner-block>
      <gui-paginate :data="data" ref="paginate" @change="getList"/>
    </card-body>
  </card-block>
  <create-questiont-modal ref="createModel" @add="getList"/>
  <update-questiont-modal ref="updateModel" @update="getList"/>
</template>

<script>

import QuestionListBlock from "@/views/Question/QuestionListBlock.vue";
import CreateQuestiontModal from "@/views/Question/Block/CreateQuestiontModal.vue";
import UpdateQuestiontModal from "@/views/Question/Block/UpdateQuestiontModal.vue";

export default {
  components: {UpdateQuestiontModal, CreateQuestiontModal, QuestionListBlock},
  data: function () {
    return {
      data: null,
      progress: true,
      filter: {}
    }
  },
  props: {},
  computed: {
    application_type_id: function () {
      return this.$route.params.id;
    }
  },
  beforeMount() {
    this.filter = storageLoad('filter_' + this.$route.name, this.filter);
  },
  mounted() {
    this.getList();
  },
  watch: {
    application_type_id: function () {
      this.getList()
    }
  },
  methods: {
    getList() {

      let v = _.clone(this.filter);
      v.search = '';
      storageSet('filter_' + this.$route.name, v);

      axiosGetValue('/api/admin/question/list', {
            application_type_id: this.application_type_id,
          },
          e => this.data = e, e => this.progress = e
      )
    },
    remove(e) {
      $.alertConfirm({
        content: 'Продолжить?'
      }, {
        ok: () => {
          axiosGetValue('/api/admin/question/delete', {id: e.id}, () => {
            toastSuccess('Вопрос удалён')
          }).then(() => {
            this.getList();
          })
        }
      });
    },
  }
}
</script>
