<template>
  <div>
    <div id="map" class="map" style="height: 500px; width: 100%;"></div>

    <div v-if="editable" class="row mt-3">

      <div class="col-8">

        <div v-if="polygons && polygons.length">
          <div v-for="(element, i) in polygons" :key="i">
            Полигон {{ i + 1 }} (точек: {{ element.length }}) <i class="fas fa-remove cursor-pointer text-danger"
                                                                 @click.prevent="deleteElement(i)"></i>
          </div>
        </div>
      </div>


      <div class="col-4 text-right">
        <button @click.prevent="updateFromOriginal" class="btn btn-sm btn-secondary mr-2">Исходное значение</button>
        <button @click.prevent="copy" class="btn btn-sm btn-secondary mr-2" title="Экспорт"><i
            class="fas fa-file-export"></i>
        </button>
        <button @click.prevent="paste" class="btn btn-sm btn-secondary mr-2" title="Импорт"><i
            class="fas fa-file-import"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>

import Map from 'ol/Map.js';
import View from 'ol/View.js';
import {OSM, Vector as VectorSource} from 'ol/source.js';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer.js';
import {transform, useGeographic} from 'ol/proj.js';
import {Circle as CircleStyle, Fill, Stroke, Style} from 'ol/style.js';
import {Draw, Modify, Snap} from 'ol/interaction.js';
import {circular} from 'ol/geom/Polygon.js';
import {getDistance} from 'ol/sphere.js';
import {Feature} from "ol";
import {Polygon} from "ol/geom";


export default {
  data: function () {
    return {
      map: null,
      source: new VectorSource({}),
      oldValue: [],
    }
  },
  props: {
    polygons: {
      type: Array,
      default: () => []
    },
    editable: Boolean,
  },
  computed: {},
  mounted() {
    this.oldValue = JSON.parse(JSON.stringify(this.polygons))
    this.init()
  },
  methods: {
    updateNewValue() {
      let result = [];

      this.source.getFeatures().forEach(feature => {
        feature.getGeometry().getCoordinates().forEach(polygon => {
          result.push(polygon)
        })
      })

      this.$emit('change', result)
    },
    deleteElement(i) {
      this.source.removeFeature(this.source.getFeatures()[i])
      this.updateNewValue()
    },
    copy() {
      prompt('Скопируйте текст', JSON.stringify(this.polygons))
    },
    paste() {
      const text = prompt('Вставьте текст');
      if (!text) {
        return;
      }
      const data = JSON.parse(text)
      this.pastAsArray(data)
    },
    updateFromOriginal() {
      this.pastAsArray(this.oldValue)
    },
    pastAsArray(data) {
      let features = []
      if (data) {
        data.forEach(polygon => {
          features.push(new Feature(new Polygon([polygon])))
        })
      }

      //Выставляем центр
      if (data && data[0] && data[0][0]) {
        this.map.getView().setCenter(data[0][0])
        this.map.getView().setZoom(11)
      }

      //Удаляем имеющиеся объекты
      this.source.getFeatures().forEach(e => this.source.removeFeature(e))

      //Добавляем новые
      features.forEach(e => this.source.addFeature(e))

      this.updateNewValue()
    },
    init() {

      // example https://openlayers.org/en/latest/examples/draw-and-modify-geodesic.html

      useGeographic();

      const raster = new TileLayer({
        source: new OSM(),
      });


      const style = new Style({
        fill: new Fill({
          color: 'rgba(0, 255, 0, 0.3)',
        }),
        stroke: new Stroke({
          color: 'rgba(255, 0, 0, 1)',
          width: 2,
        }),
      });

      const geodesicStyle = new Style({
        geometry: function (feature) {
          return feature.get('modifyGeometry') || feature.getGeometry();
        },
        fill: new Fill({
          color: 'rgba(255, 255, 255, 0.2)',
        }),
        stroke: new Stroke({
          color: '#ff3333',
          width: 2,
        }),
        image: new CircleStyle({
          radius: 7,
          fill: new Fill({
            color: 'rgba(0, 0, 0, 0)',
          }),
        }),
      });


      const vector = new VectorLayer({
        source: this.source,
        style: function (feature) {
          const geometry = feature.getGeometry();
          return geometry.getType() === 'GeometryCollection' ? geodesicStyle : style;
        },
      });

      this.map = new Map({
        layers: [raster, vector],
        target: 'map',
        view: new View({
          center: [0, 0],
          zoom: 3,
        }),
      });

      this.updateFromOriginal()

      if (this.editable) {
        this.change()
      }

    },
    change() {

      const source = this.source
      const map = this.map
      const type = 'Polygon'

      const defaultStyle = new Modify({source: source})
          .getOverlay()
          .getStyleFunction();

      const modify = new Modify({
        source: source,
        style: function (feature) {
          feature.get('features').forEach(function (modifyFeature) {
            const modifyGeometry = modifyFeature.get('modifyGeometry');
            if (modifyGeometry) {
              const modifyPoint = feature.getGeometry().getCoordinates();
              const geometries = modifyFeature.getGeometry().getGeometries();
              const polygon = geometries[0].getCoordinates()[0];
              const center = geometries[1].getCoordinates();
              const projection = map.getView().getProjection();
              let first, last, radius;
              if (modifyPoint[0] === center[0] && modifyPoint[1] === center[1]) {
                // center is being modified
                // get unchanged radius from diameter between polygon vertices
                first = transform(polygon[0], projection, 'EPSG:4326');
                last = transform(
                    polygon[(polygon.length - 1) / 2],
                    projection,
                    'EPSG:4326'
                );
                radius = getDistance(first, last) / 2;
              } else {
                // radius is being modified
                first = transform(center, projection, 'EPSG:4326');
                last = transform(modifyPoint, projection, 'EPSG:4326');
                radius = getDistance(first, last);
              }
              // update the polygon using new center or radius
              const circle = circular(
                  transform(center, projection, 'EPSG:4326'),
                  radius,
                  128
              );
              circle.transform('EPSG:4326', projection);
              geometries[0].setCoordinates(circle.getCoordinates());
              // save changes to be applied at the end of the interaction
              modifyGeometry.setGeometries(geometries);
            }
          });
          return defaultStyle(feature);
        },
      });

      modify.on('modifystart', function (event) {
        event.features.forEach(function (feature) {
          const geometry = feature.getGeometry();
          if (geometry.getType() === 'GeometryCollection') {
            feature.set('modifyGeometry', geometry.clone(), true);
          }
        });
      });

      modify.on('modifyend', (event) => {
        event.features.forEach(function (feature) {
          const modifyGeometry = feature.get('modifyGeometry');
          if (modifyGeometry) {
            feature.setGeometry(modifyGeometry);
            feature.unset('modifyGeometry', true);
          }
        });
        setTimeout(this.updateNewValue, 1)
      });

      map.addInteraction(modify);

      const draw = new Draw({
        source: source,
        type: type,
      });

      draw.on('drawend', () => {
        setTimeout(this.updateNewValue, 1)
      })

      map.addInteraction(draw);
      const snap = new Snap({source: source});
      map.addInteraction(snap);
    }
  }
}
</script>