<template>
  <card-block>
    <card-header>
      Чаты с поддержкой
      <template v-slot:right>
        <button class="btn btn-sm btn-outline-secondary" @click.prevent="registerNotify" v-if="!isNotifyInstall">
          Подключить уведомления
        </button>
        <button class="btn btn-sm btn-outline-secondary" @click.prevent="deleteNotify" v-else>
          Отключить уведомления
        </button>
      </template>
    </card-header>

    <spinner-block v-model="progress">
      <card-body>
        <div class="">
          <div class="row">
            <div class="col-4">
              <chat-list-block v-if="!progress" ref="chatList"/>
            </div>

            <div class="col-8">
              <chat-messages-block v-if="uuid && !progress" :key="uuid" :uuid="uuid"
                                   @update="e=>$refs.chatList.update(e)"/>
            </div>
          </div>
        </div>
      </card-body>
    </spinner-block>
  </card-block>
</template>

<script>
import ChatListBlock from "@/views/Chat/Block/ChatListBlock";
import ChatMessagesBlock from "@/views/Chat/Block/ChatMessagesBlock";
import {fetchToken, getCurrentToken, removeToken} from "@/firebase";

export default {
  components: {
    ChatMessagesBlock,
    ChatListBlock
  },

  data: function () {
    return {
      progress: true,
      isNotifyInstall: !!getCurrentToken(),
    };
  },

  computed: {
    uuid() {
      return this.$route.params.uuid;
    }
  },

  methods: {
    registerNotify() {
      fetchToken(() => {
        this.isNotifyInstall = !!getCurrentToken()
      });
    },
    deleteNotify() {
      removeToken().finally(() => {
        this.isNotifyInstall = !!getCurrentToken()
      })
    }
  },

  async mounted() {
    await this.$chat.init();
    this.progress = false;
  },

  unmounted() {
    this.$chat.disconnect();
  }
}
</script>