<template>
  <div>
    <div class="row">
      <div class="col-12">
        <card-field title="Имя">
          <i class="fas fa-circle small"
             :title="data.is_active ? 'Активен' : 'Неактивен'"
             :class="data.is_active ? 'text-success' : 'text-danger'"></i>
          {{ data.name }}
        </card-field>

        <card-field :modelValue="data.email" title="Email"/>
        <card-field :modelValue="data.roles && data.roles.length ? data.roles.map(e=>e.display_name).join(', ') : '—'"
                    title="Роли"/>
        <card-field :modelValue="toTime(data.email_verified_at)" title="Дата подтверждения почты"/>
      </div>
    </div>

    <div class="mt-3">

      <div class="float-right">
        <button-remove @click="remove" v-if="can('module.user.delete')"/>
      </div>


      <button-edit @click="$router.push({name: 'user.edit', params: {id: data.id}})" v-if="can('module.user.update')"/>
    </div>
  </div>
</template>

<script>


export default {
  data() {
    return {}
  },
  props: {
    data: Object
  },
  mounted() {
  },
  methods: {

    remove() {
      $.alertConfirm({
        type: 'red',
      }, {
        ok: () => {
          axiosGetValue('/api/admin/user/delete', {id: this.data.id}, () => {
            toastSuccess('Удаление завершено');
            this.$router.push({name: 'user.list'})
          })
        }
      });
    },
  },
}
</script>