import EmptyWrap from "@/components/Gui/EmptyWrap";

export default [
    {
        path: 'quiz-answer',
        component: EmptyWrap,
        children: [
            {
                path: '',
                name: 'quiz-answer.list',
                component: require('@/views/QuizAnswer/QuizAnswerList').default,
            },
            {
                path: ':id',
                component: require('@/views/QuizAnswer/Card/WrapBlock').default,
                children: [
                    {
                        path: 'detail',
                        name: 'quiz-answer.detail',
                        component: require('@/views/QuizAnswer/Card/QuizAnswerTab').default,
                    },
                ]
            },
        ]
    },
]