<template>
  <div>
    <map-block v-if="can('module.map.view')"/></div>
</template>

<script>
  import MapBlock from "@/components/Map/SquadMapBlock";

  export default {
  components: {MapBlock},
  data: function () {
  return {}
},
  props: {},
  computed: {},
  mounted() {

},
  methods: {}
}
</script>