import EmptyWrap from "@/components/Gui/EmptyWrap";

export default [
    {
        path: 'dictionary-color',
        component: EmptyWrap,
        children: [
            {
                path: '',
                name: 'dictionary-color.list',
                component: require('@/views/DictionaryColor/DictionaryColorList').default,
            },
            {
                path: 'create',
                name: 'dictionary-color.create',
                component: require('@/views/DictionaryColor/DictionaryColorForm').default,
            },
            {
                path: ':id',
                component: require('@/views/DictionaryColor/Card/WrapBlock').default,
                children: [
                    {
                        path: 'detail',
                        name: 'dictionary-color.detail',
                        component: require('@/views/DictionaryColor/Card/DictionaryColorDetail').default,
                    },
                ]
            },
            {
                path: ':id/edit',
                name: 'dictionary-color.edit',
                component: require('@/views/DictionaryColor/DictionaryColorForm').default,
            },
        ]
    },
]