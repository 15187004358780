<template>
  <router-link
      v-if="show"
      :to="{ name: to, params: parameters }"
      class="nav-item nav-link tab-link"
      :class="{'px-2': small}"
      activeClass="active">
    <slot></slot>
  </router-link>
</template>

<script>
export default {
  props: {
    if: String,
    to: String,
    small: Boolean,
    params: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    show() {
      return this.if ? this.$can(this.if) : true
    },
    parameters() {
      return Object.assign({}, this.$route.params, this.params)
    }
  }
}
</script>
