<template>
  <div>
    <spinner-block v-model="progress">
      <div v-if="listData && listData.data && listData.data.length">
        <table class="table table-hover">
          <thead>
          <tr>
            <th>Дата</th>
            <th>Ссылка</th>
            <th class="text-right"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="row in listData.data" :key="row.id">
            <td>
              {{ toTimes(row.receipt_datetime) }}
            </td>
            <td>
              <a :href="row.receipt_url" target="_blank" v-if="row.receipt_url">Ссылка</a>
              <span v-else>—</span>
            </td>
            <td class="text-right">
              <button class="btn btn-sm btn-secondary" @click.prevent="openDetail(row)">
                <i class="fas fa-bug"></i>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div v-else class="alert alert-info">Список пуст</div>
    </spinner-block>
    <gui-paginate :data="listData" ref="paginate" @change="getList"/>
    <div ref="modal" style="display: none">

      <template v-if="selectRow">
        <div class="mb-3">
          <b>Содержимое запроса</b>
        </div>

        <pre>{{ JSON.stringify(selectRow, null, 2) }}</pre>
      </template>

    </div>
  </div>
</template>

<script>

export default {
  components: {},

  data() {
    return {
      listData: null,
      progress: null,
      filter: {
        region_id: null,
      },
      selectRow: null,
    }
  },
  props: {
    data: Object,
  },
  mounted() {
    this.filter.tinkoff_payment_id = this.data.id
    this.getList()
  },
  methods: {
    getList() {
      axiosGetValue('/api/admin/payment-receipt/list', {
            filter: this.filter,
            page: this.$refs.paginate.currentPage,
            per_page: this.$refs.paginate.perPage,
          },
          e => this.listData = e, e => this.progress = e
      )
    },
    openDetail(row) {
      this.selectRow = row
      this.fancybox(this.$refs.modal)
    },
  }
}
</script>