<template>
  <div>
    <spinner-block v-model="progress">
      <client-call-list-block :data="listData"/>
    </spinner-block>
    <gui-paginate :data="listData" ref="paginate" @change="getList"/>
  </div>
</template>

<script>

import ClientCallListBlock from "@/views/ClientCall/ClientCallListBlock";

export default {
  components: {ClientCallListBlock},
  data() {
    return {
      listData: null,
      progress: null,
      filter: {
        squad_duty_id: null,
      }
    }
  },
  props: {
    data: Object,
  },
  mounted() {
    this.filter.squad_duty_id = this.data.id
    this.getList()
  },
  methods: {
    getList() {
      axiosGetValue('/api/admin/client-call/list', {
            filter: this.filter,
            page: this.$refs.paginate.currentPage,
            per_page: this.$refs.paginate.perPage,
          },
          e => this.listData = e, e => this.progress = e
      )
    },
  }
}
</script>