<template>
  <div>
    <div class="row">
      <div class="col-12">
        <card-field-row :modelValue="data.time_duration_duty" title="Длительность смены, сек"/>
        <card-field-row :modelValue="data.time_duration_break" title="Длительность перерывов в смене, сек"/>
        <card-field-row :modelValue="data.time_duration_work_time" title="Длительность рабочего времени смены, сек"/>
        <card-field-row :modelValue="data.count_calls" title="Количество вызовов"/>
        <card-field-row :modelValue="data.count_canceled_calls" title="Количество отменённых вызовов"/>
        <card-field-row :modelValue="data.count_accept_calls" title="Количество подтверждённых вызовов"/>
        <card-field-row :modelValue="data.count_cancel_calls_after_accept"
                        title="Количество отменённых вызовов после подтверждения"/>
        <card-field-row :modelValue="data.count_finish_calls" title="Количество завершённых вызовов"/>
      </div>

    </div>


  </div>
</template>

<script>


export default {
  data() {
    return {}
  },
  props: {
    data: Object
  },
  mounted() {
  },
  methods: {},
}
</script>