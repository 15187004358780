<template>
  <template v-for="row in data" :key="row.id">
    <tr>
      <td>
        <i class="fas fa-circle small"
           :title="row.active ? 'Активен' : 'Неактивен'"
           :class="row.active ? 'text-success' : 'text-danger'"></i>
        {{ row.name }}
        <span v-if="row.type_question_id==2" class="badge badge-secondary">ответ</span>
        <span v-if="row.type_question_id==3" class="badge badge-secondary">ссылка</span>
        <span v-if="row.type_question_id==4" class="badge badge-secondary">диплинк</span>
      </td>
      <td class="limit-half_length"></td>
      <td class="d-none d-md-table-cell limit-third_length">

      </td>
      <td class="text-right">
        <button class="btn btn-sm ml-2 btn-success" v-if="row.type_question_id == 1 && can('module.question.create')" @click.prevent="$emit('addEvent', {
          parent_section_id: row.id,
application_type_id: this.$route.params.id,
        })">
          <i class="fas fa-plus"></i>
        </button>
        <button v-if="can('module.question.update')" class="btn btn-sm ml-2 btn-primary" @click.prevent="$emit('updateEvent', { id: row.id, })">
          <i class="fas fa-edit"></i>
        </button>
        <button v-if="can('module.question.delete')" class="btn btn-sm ml-2 btn-danger" @click.prevent="$emit('removeEvent', { id: row.id, })">
          <i class="fas fa-trash"></i>
        </button>
      </td>
    </tr>
    <template v-if="row.children && row.children.length">
      <tr>
        <td colspan="4" class="p-0 pl-3">
          <table class="table mb-0 border-left border-dark">
            <question-list-block :data="row.children" :lvl="lvl+1"
                                 @addEvent="e=> $emit('addEvent', e)"
                                 @updateEvent="e=> $emit('updateEvent', e)"
                                 @removeEvent="e=> $emit('removeEvent', e)"/>
          </table>
        </td>
      </tr>
    </template>
  </template>
</template>

<script>

export default {
  emits: ['addEvent', 'updateEvent', 'removeEvent'],

  data: function () {
    return {
    }
  },
  props: {
    data: Object,
    lvl: Number,
  },
  computed: {},
  beforeMount() {
  },
  mounted() {
  },
}
</script>
