<template>
  <card-block>
    <spinner-block v-model="dataProgress">
      <template v-if="data">
        <card-header>
          <router-link :to="{name: 'role.list'}">Роли</router-link><!--
                    -->:
          <span class="text-secondary">#{{ data.name }}</span>
          {{ data.display_name }}
        </card-header>

        <div class="m-3">

          <card-field :modelValue="data.display_name" title="Название"/>
          <card-field :modelValue="data.name" title="Код"/>
          <card-field :modelValue="data.is_lock ? 'Да' : 'Нет'" title="Системная роль"/>
          <card-field :modelValue="data.is_custom ? 'Да' : 'Нет'" title="Пользовательская роль"/>

          <div class="mt-3">

            <div class="float-right">
              <button-remove @click="remove" v-if="can('module.role.delete')" :disabled="data.is_lock"/>
            </div>


            <button-edit @click="$router.push({name: 'role.edit', params: {id: data.id}})" :disabled="data.is_lock"
                         v-if="can('module.role.update')"/>
          </div>
        </div>

        <tab-list>
          <tab-link to="role.detail">Права у роли</tab-link>
        </tab-list>
        <card-body>
          <router-view :key="id" :data="data" @change="getData"></router-view>
        </card-body>
      </template>
    </spinner-block>
  </card-block>
</template>

<script>
export default {
  data: function () {
    return {
      data: null,
      dataProgress: true,
    }
  },
  props: {},
  computed: {
    id() {
      return this.$route.params.id;
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      if (this.id) {
        axiosGetValue('/api/admin/roles/get', {id: this.id}, e => this.data = e, e => this.dataProgress = e)
      }
    },
    remove() {
      $.alertConfirm({
        type: 'red',
      }, {
        ok: () => {
          axiosGetValue('/api/admin/roles/delete', {id: this.data.id}, () => {
            toastSuccess('Удаление завершено');
            this.$router.push({name: 'role.list'})
          })
        }
      });
    },
  },
}
</script>