<template>
  <card-block>
    <card-header>
      Список вызовов
    </card-header>
    <card-body>
      <spinner-block v-model="progress">
        <div class="mb-3 row">
          <div class="col-12 col-md-2">
            <div class="small">Статус</div>
            <gui-select sm v-model="filter.active" @change="getList" :hilightOn="['all']">
              <option value="all">Все</option>
              <option value="active">Активные</option>
              <option value="deactive">Не активные</option>
            </gui-select>
          </div>
          <div class="col-12 col-md-2">
            <div class="small">Есть задолженность</div>
            <gui-select sm v-model="filter.is_debit" @change="getList" :hilightOn="['all']">
              <option value="all">Все</option>
              <option value="yes">Да</option>
              <option value="no">Нет</option>
            </gui-select>
          </div>
          <div class="col-12 col-md-2">
            <div class="small">Есть ошибки</div>
            <gui-select sm v-model="filter.is_warning" @change="getList" :hilightOn="['all']">
              <option value="all">Все</option>
              <option value="yes">Да</option>
              <option value="no">Нет</option>
            </gui-select>
          </div>
          <div class="col-12 col-md-2">
            <div class="small">Health check</div>
            <gui-select sm v-model="filter.is_health_check" @change="getList" :hilightOn="['all']">
              <option value="all">Все</option>
              <option value="yes">Да</option>
              <option value="no">Нет</option>
            </gui-select>
          </div>
        </div>
        <client-call-list-block :data="data"/>
      </spinner-block>
      <gui-paginate :data="data" ref="paginate" @change="getList"/>
      <p v-if="!can('module.organization.view_all-view')" class="text-secondary mt-3">
        Результат показан с учётом ограничения по организации
      </p>
    </card-body>
  </card-block>
</template>

<script>

import ClientCallListBlock from "@/views/ClientCall/ClientCallListBlock";
import {onMessageListener} from "@/firebase";

export default {
  components: {ClientCallListBlock},
  data: function () {
    return {
      data: null,
      progress: true,
      filter: {
        active: 'all',
        is_debit: 'all',
        is_warning: 'all',
        is_health_check: 'no',
        search: '',
      }
    }
  },
  props: {},
  computed: {},
  beforeMount() {
    this.filter = storageLoad('filter_' + this.$route.name, this.filter);
  },
  mounted() {
    this.getList();
  },
  created() {

    onMessageListener((payload) => {
      // Обработка пуш уведомления внутри приложения
      if (document.location.href !== payload.data) {
        if (payload.data.toggle) {
          switch (payload.data.toggle) {
            case 'call':
              this.getList();
              break;
            default:
              break;
          }
        }
      }
    });

  },
  methods: {
    getList() {

      let v = _.clone(this.filter);
      v.search = '';
      storageSet('filter_' + this.$route.name, v);

      axiosGetValue('/api/admin/client-call/list', {
            page: this.$refs.paginate.currentPage,
            per_page: this.$refs.paginate.perPage,
            filter: this.filter,
          },
          e => this.data = e, e => this.progress = e
      )
    }
  }
}
</script>
