<template>
  <card-block>
    <spinner-block v-model="dataProgress">
      <div v-if="data">
        <card-header>
          <template v-if="id">
            <router-link :to="{name: 'content.detail', params: {id: id}}">Контент</router-link><!-- -->:
            редактирование

          </template>
          <template v-else>
            <router-link :to="{name: 'content.list'}">Контент</router-link><!-- -->:
            создание
          </template>


        </card-header>
        <card-body>
          <gui-input v-model="data.name" :error="dataError.name" label="Название"/>
          <gui-input v-model="data.code" :error="dataError.code" label="Код"/>
          <gui-content-type-select v-model="data.content_type_id" label="Тип контента"
                                   :error="dataError.content_type_id"/>

          <gui-input v-model="data.sort" :error="dataError.sort" label="Сортировка"/>
          <gui-checkbox v-model="data.is_html" :error="dataError.is_html" label="html"/>
          <div :key="data.is_html? 'Y':'N'">
            <template v-if="data.is_html">
              <gui-html v-model="data.text" :error="dataError.text" label="Текст"/>
            </template>
            <template v-else>
              <gui-textarea v-model="data.text" :error="dataError.text" label="Текст" autosize/>
            </template>
          </div>

          <div class="mt-5">
            <button-save v-if="!data.id" @click="add()"/>
            <button-save v-else @click="save"/>
            <button-back/>
          </div>
        </card-body>
      </div>
    </spinner-block>
  </card-block>
</template>

<script>


import GuiContentTypeSelect from "@/components/Widget/GuiContentTypeSelect";

export default {
  components: {GuiContentTypeSelect},
  data() {
    return {
      data: null,
      dataDefault: {
        name: '',
        code: '',
        text: '',
        content_type_id: '',
        sort: 100,
        is_html: '',
      },
      dataError: {
        name: '',
        code: '',
        text: '',
        content_type_id: '',
        sort: '',
        is_html: '',
      },
      dataProgress: false,
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    }
  },
  mounted() {
    this.init()
  },
  methods: {

    init() {
      if (this.id) {
        this.data = null
        this.loadData()
      } else {
        this.data = _.clone(this.dataDefault)
      }
    },
    loadData() {
      axiosGetValue('/api/admin/content/get', {id: this.id}, e => this.data = e, e => this.dataProgress = e)
    },

    save() {
      axiosFast({
        url: route('/api/admin/content/update'),
        data: {data: this.data, id: this.data.id},
        error: this.dataError,
        progress: e => this.dataProgress = e,
        result: () => {
          toastSuccess('Данные сохранены');
          this.$router.push({name: 'content.detail', params: {id: this.data.id}})
        },
      })
    },
    add() {
      axiosFast({
        url: route('/api/admin/content/create'),
        data: {data: this.data},
        error: this.dataError,
        progress: e => this.dataProgress = e,
        result: (data) => {
          toastSuccess('Данные добавлены');
          this.$router.push({name: 'content.detail', params: {id: data.id}})
        },
      })
    },
  },
}
</script>