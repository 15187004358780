import EmptyWrap from "@/components/Gui/EmptyWrap";

export default [{
    path: 'squad-search-terms',
    component: EmptyWrap,
    children: [
        {
            path: '',
            name: 'squad-search-terms.list',
            component: require('@/views/SearchTerms/SearchTermsList').default,
        },
        {
            path: 'create',
            name: 'squad-search-terms.create',
            component: require('@/views/SearchTerms/SearchTermsForm').default,
        },
        {
            path: ':id',
            component: require('@/views/SearchTerms/Card/WrapBlock').default,
            children: [
                {
                    path: 'detail',
                    name: 'squad-search-terms.detail',
                    component: require('@/views/SearchTerms/Card/SearchTermsDetail').default,
                },
            ]
        },
        {
            path: ':id/edit',
            name: 'squad-search-terms.edit',
            component: require('@/views/SearchTerms/SearchTermsForm').default,
        },
    ]
}]