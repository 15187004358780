<template>
  <div class="card mt-5">
    <div class="card-header">
      Действия
    </div>
    <div v-if="can('module.actions.view')" class="card-body">
      <div class="mb-3">
        <button class="btn btn-secondary" @click.prevent="cacheClear">
          Сбросить кеш
        </button>
      </div>
      <div class="mt-3">
        <button class="btn btn-secondary" @click.prevent="resumeSubscription">
          Продлить подписку
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    cacheClear(){
      axiosGetValue('/api/admin/actions/cache-clear', {}, () => {
        toastSuccess('Действие выполнено');
      })
    },
    resumeSubscription(){
      axiosGetValue('/api/admin/actions/resume-subscription', {}, () => {
        toastSuccess('Действие выполнено');
      })
    },
  }
}
</script>