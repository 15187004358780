<template>
  <div class="mb-2">
    <div class="row">
      <div class="col-4">
        <div class="small text-secondary" v-if="title" v-html="title"></div>
      </div>
      <div class="col-6">
        <template v-if="$slots.default">
          <slot></slot>
        </template>
        <template v-else>{{ modelValue ? modelValue : '—' }}</template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {}
  },
  props: {
    title: String,
    modelValue: [String, Number]
  },
  computed: {},
  mounted() {

  },
  methods: {}
}
</script>