<template>
  <div>

    <spinner-block v-model="progress">

      <div v-if="listData && listData.data && listData.data.length">
        <table class="table table-hover">
          <thead>
          <tr>
            <th>Является главной</th>
            <th>Номер карты</th>
            <th>Срок действия</th>
            <th>Тип карты</th>
            <th>Дата добавления</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="row in listData.data" :key="row.id">
            <td>{{ row.is_main ? 'Да' : '—' }}</td>
            <td>{{ row.pan }}</td>
            <td>{{ row.exp_date }}</td>
            <td>{{ row.payment_type }}</td>
            <td>{{ toTime(row.created_at) }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div v-else class="alert alert-info">Список пуст</div>
    </spinner-block>
    <gui-paginate :data="listData" ref="paginate" @change="getList"/>
  </div>
</template>

<script>


export default {
  components: {},
  data() {
    return {
      listData: null,
      progress: null,
      filter: {
        client_id: null,
      }
    }
  },
  props: {
    data: Object,
  },
  mounted() {
    this.filter.client_id = this.data.id
    this.getList()
  },
  methods: {
    getList() {
      axiosGetValue('/api/admin/client-cards/list', {
            filter: this.filter,
            page: this.$refs.paginate.currentPage,
            per_page: this.$refs.paginate.perPage,
          },
          e => this.listData = e, e => this.progress = e
      )
    },

  }
}
</script>