import VueCtkDateTimePicker from "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.umd.min";

// https://chronotruck.github.io/vue-ctk-date-time-picker/
Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);

Vue.component('Select2Component', require('v-select2-component').default);

Vue.component('card-block', require('../components/Gui/CardBlock').default);
Vue.component('card-header', require('../components/Gui/CardHeader').default);
Vue.component('card-body', require('../components/Gui/CardBody').default);
Vue.component('tab-list', require('../components/Gui/TabList').default);
Vue.component('tab-link', require('../components/Gui/TablListLink').default);
Vue.component('paginate-title', require('../components/Gui/PaginateTitle').default);
Vue.component('error-403', require('../components/Error403Block').default);
Vue.component('gui-input', require('../components/Gui/Input/InputString').default);
Vue.component('gui-geo', require('../components/Gui/Input/InputGeo').default);
Vue.component('gui-geo-span', require('../components/Gui/Input/InputGeoSpan').default);
Vue.component('gui-phone-span', require('../components/Gui/Input/PhoneFormat').default);
Vue.component('gui-checkbox', require('../components/Gui/Input/InputCheckbox').default);
Vue.component('gui-search', require('../components/Gui/Input/InputSearch').default);
Vue.component('gui-date', require('../components/Gui/Input/InputDate').default);
Vue.component('gui-date-time', require('../components/Gui/Input/InputDateTime').default);
Vue.component('gui-time', require('../components/Gui/Input/InputTime').default);
Vue.component('gui-textarea', require('../components/Gui/Input/InputTextarea').default);
Vue.component('gui-html', require('../components/Gui/Input/InputHtml').default);
Vue.component('gui-input-row', require('../components/Gui/Input/InputRow').default);
Vue.component('gui-select', require('../components/Gui/Input/InputSelect').default);
Vue.component('gui-multi-select', require('../components/Gui/Input/MultiSelect').default);
Vue.component('gui-file', require('../components/Gui/Input/FileInput').default);
Vue.component('gui-file-picker', require('../components/Gui/Input/FilePicker').default);
Vue.component('gui-per-page', require('../components/Gui/PerPage').default);
Vue.component('gui-paginate', require('../components/Gui/PaginateBlock').default);
Vue.component('gui-date-range', require('../components/Gui/Input/DateRangePicker').default);
Vue.component('gui-input-date-range', require('../components/Gui/Input/InputDateRangePicker').default);
Vue.component('spinner-block', require('../components/Gui/SpinnerBlock').default);
Vue.component('card-field', require('../components/Gui/CardField').default);
Vue.component('card-field-row', require('../components/Gui/CardFieldRow').default);
Vue.component('button-create', require('../components/Gui/Button/ButtonCreate').default);
Vue.component('button-edit', require('../components/Gui/Button/ButtonEdit').default);
Vue.component('button-remove', require('../components/Gui/Button/ButtonRemove').default);
Vue.component('button-save', require('../components/Gui/Button/ButtonSave').default);
Vue.component('button-cancel', require('../components/Gui/Button/ButtonCancel').default);
Vue.component('button-back', require('../components/Gui/Button/ButtonBack').default);
