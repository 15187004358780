<template>
  <card-block>
    <spinner-block v-model="dataProgress">
      <div v-if="data">
        <card-header>
          <template v-if="id">
            <router-link :to="{name: 'client.detail', params: {id: id}}">Клиент</router-link><!-- -->:
            редактирование

          </template>
          <template v-else>
            <router-link :to="{name: 'client.list'}">Клиенты</router-link><!-- -->:
            создание
          </template>


        </card-header>
        <card-body>
          <gui-checkbox v-model="data.is_active" :error="dataError.is_active" label="Активный"/>
          <gui-checkbox v-model="data.is_beta" :error="dataError.is_beta" label="Пользователь бета тестирования"/>

          <gui-input v-model="data.last_name" :error="dataError.last_name" label="Фамилия"/>
          <gui-input v-model="data.first_name" :error="dataError.first_name" label="Имя"/>
          <gui-input v-model="data.middle_name" :error="dataError.middle_name" label="Отчество"/>
          <gui-input v-model="data.email" :error="dataError.email" label="Email"/>
          <gui-input v-model="data.user_vk_id" :error="dataError.user_vk_id" label="VK user id"/>
          <gui-date v-model="data.birth" :error="dataError.birth" label="Дата рождения"/>
          <gui-select v-model="data.gender" :error="dataError.gender" label="Пол" :list="[
            {id:'', name: 'Не указан'},
            {id:'male', name: 'Мужской'},
            {id:'female', name: 'Женский'}
          ]"/>
          <gui-input v-model="data.phone" :error="dataError.phone" label="Телефон"/>
          <gui-date-time v-model="data.phone_verified_at" :error="dataError.phone_verified_at"
                         label="Дата подтверждения телефона"/>
          <gui-input v-model="data.password" :error="dataError.password" label="Новый пароль"/>

          <gui-file-picker v-model="data.photo_new" :error="dataError.photo_new" label="Фото"
                           :file="data.photo ? {name: data.photo, url: data.photo_url} : null"
                           @delete="data.photo=null; data.photo_delete=true"/>
          <gui-client-status-select v-model="data.client_status_id" :error="dataError.client_status_id" label="Статус"/>
          <gui-input v-model="data.sort" :error="dataError.sort" label="Сортировка"/>
          <gui-checkbox v-model="data.is_validated" :error="dataError.is_validated"
                        label="Все обязательные документы подтверждены"/>

          <gui-textarea autosize v-model="data.special_signs" :error="dataError.special_signs" label="Особые признаки"/>
          <gui-textarea autosize v-model="data.reason_for_blocking" :error="dataError.reason_for_blocking"
                        label="Причина блокировки"/>

          <div class="mt-5">
            <button-save v-if="!data.id" @click="add()"/>
            <button-save v-else @click="save"/>
            <button-back/>
          </div>
        </card-body>
      </div>
    </spinner-block>
  </card-block>
</template>

<script>


import GuiClientStatusSelect from "@/components/Widget/GuiClientStatusSelect";

export default {
  components: {GuiClientStatusSelect},
  data() {
    return {
      data: null,
      dataDefault: {
        is_active: '',
        is_beta: '',
        is_validated: '',
        last_name: '',
        first_name: '',
        middle_name: '',
        email: '',
        user_vk_id: '',
        birth: '',
        phone: '',
        phone_verified_at: '',
        password: '',
        photo: '',
        photo_new: '',
        photo_delete: '',
        special_signs: '',
        reason_for_blocking: '',
        client_status_id: '',
        sort: '',
      },
      dataError: {
        is_beta: '',
        first_name: '',
        last_name: '',
        middle_name: '',
        email: '',
        user_vk_id: '',
        phone: '',
        password: '',
        photo_new: '',
        special_signs: '',
        is_active: '',
        is_validated: '',
        phone_verified_at: '',
        birth: '',
        reason_for_blocking: '',
        sort: '',
        client_status_id: '',
      },
      dataProgress: false,
      statuses: [],
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    }
  },
  mounted() {
    this.init()
  },
  methods: {

    init() {
      if (this.id) {
        this.data = null
        this.loadData()
      } else {
        this.data = _.clone(this.dataDefault)
      }
    },
    loadData() {
      axiosGetValue('/api/admin/client/get', {id: this.id}, e => this.data = e, e => this.dataProgress = e)
    },
    save() {
      axiosFast({
        url: route('/api/admin/client/update'),
        formData: {data: this.data, id: this.data.id},
        error: this.dataError,
        progress: e => this.dataProgress = e,
        result: () => {
          toastSuccess('Данные сохранены');
          this.$router.push({name: 'client.detail', params: {id: this.data.id}})
        },
      })
    },
    add() {
      axiosFast({
        url: route('/api/admin/client/create'),
        formData: {data: this.data},
        error: this.dataError,
        progress: e => this.dataProgress = e,
        result: (data) => {
          toastSuccess('Данные добавлены');
          this.$router.push({name: 'client.detail', params: {id: data.id}})
        },
      })
    },
  },
}
</script>
