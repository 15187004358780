<template>
  <div>

    <card-block>
      <card-header>
        Главная страница
      </card-header>
      <card-body>

        <div class="row">
          <div class="col-4 mb-3" v-if="can('module.client.view')">
            <router-link :to="{name: 'client.list'}">
              <div class="card">
                <div class="card-body">
                  Клиенты
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-4 mb-3" v-if="can('module.squad.view')">
            <router-link :to="{name: 'squad.list'}">
              <div class="card">
                <div class="card-body">
                  Отряды
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-4 mb-3" v-if="can('module.user.view')">
            <router-link :to="{name: 'user.list'}">
              <div class="card">
                <div class="card-body">
                  Сотрудники
                </div>
              </div>
            </router-link>
          </div>


          <div class="col-4 mb-3" v-if="can('module.client_call.view')">
            <router-link :to="{name: 'client-call.list'}">
              <div class="card">
                <div class="card-body">
                  Вызовы
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-4 mb-3" v-if="can('module.organization.view')">
            <router-link :to="{name: 'organization.list'}">
              <div class="card">
                <div class="card-body">
                  Организации
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-4 mb-3" v-if="can('module.payment.view')">
            <router-link :to="{name: 'payment.list'}">
              <div class="card">
                <div class="card-body">
                  Платежи
                </div>
              </div>
            </router-link>
          </div>
        </div>

      </card-body>
    </card-block>


  </div>
</template>

<script>

export default {
  components: {},
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
    }
  }
}
</script>
