<template>
  <card-block>
    <spinner-block v-model="dataProgress">
      <div v-if="data">
        <card-header>
          <template v-if="id">
            <router-link :to="{name: 'squad.detail', params: {id: id}}">Отряд</router-link><!-- -->:
            редактирование

          </template>
          <template v-else>
            <router-link :to="{name: 'squad.list'}">Отряды</router-link><!-- -->:
            создание
          </template>


        </card-header>
        <card-body>
          <gui-checkbox v-model="data.is_active" :error="dataError.is_active" label="Активный"/>

          <gui-input v-model="data.name" :error="dataError.name" label="Название"/>
          <gui-input v-model="data.squad_number" :error="dataError.squad_number" label="Номер отряда"/>
          <gui-input v-model="data.phone" :error="dataError.phone" label="Телефон"/>

          <gui-date-time v-model="data.phone_verified_at" :error="dataError.phone_verified_at"
                         label="Дата подтверждения телефона"/>
          <gui-input v-model="data.password" :error="dataError.password" label="Новый пароль"/>

          <gui-file-picker v-model="data.photo_crew_chief_new" :error="dataError.photo_crew_chief_new"
                           label="Фото начальника экипажа"
                           :file="data.photo_crew_chief ? {name: data.photo_crew_chief, url: data.photo_crew_chief_url} : null"
                           @delete="data.photo_crew_chief=null; data.photo_crew_chief_delete=true"/>

          <gui-organization-select v-model="data.organization_id" :error="dataError.organization_id"
                                   label="Организация"/>

          <gui-input v-model="data.sort" :error="dataError.sort" label="Сортировка"/>

          <div class="mt-5">
            <button-save v-if="!data.id" @click="add()"/>
            <button-save v-else @click="save"/>
            <button-back/>
          </div>
        </card-body>
      </div>
    </spinner-block>
  </card-block>
</template>

<script>


import GuiOrganizationSelect from "@/components/Widget/GuiOrganizationSelect";

export default {
  components: {GuiOrganizationSelect},
  data() {
    return {
      data: null,
      dataDefault: {
        name: '',
        phone: '',
        is_active: '',
        password: '',
        organization_id: '',
        phone_verified_at: '',
        photo_crew_chief: '',
        photo_crew_chief_new: '',
        photo_crew_chief_delete: '',
        squad_number: '',
        sort: '',
      },
      dataError: {
        name: '',
        phone: '',
        is_active: '',
        password: '',
        organization_id: '',
        phone_verified_at: '',
        photo_crew_chief_new: '',
        squad_number: '',
        sort: '',
      },
      dataProgress: false,
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    }
  },
  mounted() {
    this.init()
  },
  methods: {

    init() {
      if (this.id) {
        this.data = null
        this.loadData()
      } else {
        this.data = _.clone(this.dataDefault)
      }
    },
    loadData() {
      axiosGetValue('/api/admin/squad/get', {id: this.id}, e => this.data = e, e => this.dataProgress = e)
    },

    save() {
      axiosFast({
        url: route('/api/admin/squad/update'),
        formData: {data: this.data, id: this.data.id},
        error: this.dataError,
        progress: e => this.dataProgress = e,
        result: () => {
          toastSuccess('Данные сохранены');
          this.$router.push({name: 'squad.detail', params: {id: this.data.id}})
        },
      })
    },
    add() {
      axiosFast({
        url: route('/api/admin/squad/create'),
        formData: {data: this.data},
        error: this.dataError,
        progress: e => this.dataProgress = e,
        result: (data) => {
          toastSuccess('Данные добавлены');
          this.$router.push({name: 'squad.detail', params: {id: data.id}})
        },
      })
    },
  },
}
</script>