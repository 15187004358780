import { createStore } from 'vuex';
import { auth } from './auth.store';
import { chat } from './chat.store';

export const store = createStore({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        auth,
        chat,
    },
})