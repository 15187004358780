<template>
  <card-block>
    <card-header>
      Платежи
    </card-header>
    <card-body>
      <spinner-block v-model="progress">

        <div class="mb-3 row">
          <div class="col-12 col-md-4">
            <div class="small">Дата платежа</div>
            <gui-input-date-range clearable v-model="filter" :hilightOn="['']" sm @update="getList"/>
          </div>
          <div class="col-12 col-md-2">
            <div class="small">Тип</div>
            <gui-select sm v-model="filter.type" @change="getList" :hilightOn="['all']">
              <option value="all">Все</option>
              <option value="active">В процессе оплаты</option>
              <option value="payed">Оплаченные</option>
              <option value="refunded">Возвращённые</option>
            </gui-select>
          </div>
          <div class="col-12 col-md-2">
            <div class="small">Статус платежа</div>
            <gui-tinkoff-payment-status-select clearable sm v-model="filter.tinkoff_payment_status_id" @change="getList"
                                               :hilightOn="['']"/>
          </div>

        </div>

        <payment-list-block :data="data"/>
      </spinner-block>
      <gui-paginate :data="data" ref="paginate" @change="getList"/>
    </card-body>
  </card-block>
</template>

<script>

import GuiTinkoffPaymentStatusSelect from "@/components/Widget/GuiTinkoffPaymentStatusSelect";
import PaymentListBlock from "@/views/Payment/PaymentListBlock";

export default {
  components: {PaymentListBlock, GuiTinkoffPaymentStatusSelect},
  data: function () {
    return {
      data: null,
      progress: true,
      filter: {
        search: '',
        tinkoff_payment_status_id: '',
        date_from: '',
        date_to: '',
        type: 'all',
      }
    }
  },
  props: {},
  computed: {},
  beforeMount() {
    this.filter = storageLoad('filter_' + this.$route.name, this.filter);
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {

      let v = _.clone(this.filter);
      v.search = '';
      storageSet('filter_' + this.$route.name, v);

      axiosGetValue('/api/admin/payment/list', {
            page: this.$refs.paginate.currentPage,
            per_page: this.$refs.paginate.perPage,
            filter: this.filter,
          },
          e => this.data = e, e => this.progress = e
      )
    }
  }
}
</script>