<template>
  <div>
    <gui-select
        :modelValue="modelValue"
        @update:modelValue="e=>$emit('update:modelValue', e)"
        :label="label"
        :error="error"
        :hilightOn="hilightOn"
        :w="w"
        :sm="sm"
        :list="list">
      <option value="" disabled>Выберите экран для перехода</option>
    </gui-select>
  </div>
</template>

<script>
export default {
  name: 'gui-deeplink-select',
  data() {
    return {
      list: [],
    }
  },
  props: {
    application: String,
    label: String,
    error: [String, Object, Array],
    hilightOn: [Array, String, Number],
    modelValue: {
      type: [String, Number],
    },
    w: Boolean,
    sm: Boolean,
  },
  mounted() {
    this.loadList();
  },
  methods: {
    loadList() {
      this.list = this.application === 'client' ? [
        {id: 'AgreementPoliticScreen', name: 'Экран Пользовательское соглашение'},
        {id: 'PersonalDataPoliticScreen', name: 'Экран Политики Персональных Данных'},
        {id: 'ConfPoliticScreen', name: 'Экран Политики Конфиденциальности'},
        {id: 'MainScreen', name: 'Главный Экран'},
        {id: 'OnBoardingScreens', name: 'Экраны Обучения'},
        {id: 'MenuScreen', name: 'Экран Меню Профиля'},
        {id: 'DocumentsScreen', name: 'Экран Документов'},
        {id: 'SubscriptionMainScreen', name: 'Экран Подписок'},
        {id: 'ChangeProfileDataScreen', name: 'Экран Данные Профиля'},
        {id: 'SubscriptionsScreen', name: 'Экран Подписка'},
        {id: 'PaymentCardCatalogScreen', name: 'Экран Карты Оплаты'},
        {id: 'SettingsScreen', name: 'Экран Настройки'},
        {id: 'SupportScreen', name: 'Экран FAQ'},
        {id: 'StudyingSubscriptionScreen', name: 'Экран Что входит в подписку'},
        {id: 'EmergencyFaqScreen', name: 'Экран Тревожная обстановки'},
        {id: 'SubscriptionFamilyMainScreen', name: 'Экран Промо семейная подписка'},
      ] : [
        {id: 'AgreementPoliticScreen', name: 'Экран Пользовательское соглашение'},
        {id: 'ConfPoliticScreen', name: 'Экран Политики Конфиденциальности'},
        {id: 'MainScreen', name: 'Главный Экран'},
        {id: 'MenuScreen', name: 'Экран Меню Профиля'},
        {id: 'SupportPermissionsOptionsScreen', name: 'Экран Настройки'},
        {id: 'NotificationsScreen', name: 'Экран Уведомления'},
        {id: 'SupportScreen', name: 'Экран FAQ'},
      ]
    },
  }
}
</script>
