<template>
  <div>

    <div class="mb-3 text-right">
      <button-create @click="$refs.modal.openModal()" v-if="can('module.quiz.question-create')"
                     :disabled="data.is_lock"/>
    </div>

    <spinner-block v-model="progress">

      <div v-if="listData && listData.data && listData.data.length">
        <table class="table table-hover">
          <thead>
          <tr>
            <th>Вопрос</th>
            <th>Код</th>
            <th>Тип вопроса</th>
            <th class="text-right"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="row in listData.data" :key="row.id">
            <td>
              <is-active-badge v-model="row.is_active"/>&nbsp;
              <is-lock-badge v-model="row.is_lock"/>&nbsp;
              {{ row.name ? row.name : '—' }}
            </td>
            <td>
              {{ row.code ? row.code : '—' }}
            </td>
            <td>
              {{ row.quiz_question_type ? row.quiz_question_type.name : '—' }}
            </td>
            <td class="text-right">

              <button
                  class="btn btn-sm btn-light mr-2"
                  v-if="row.can_has_options"
                  title="Варианты ответов"
                  @click.prevent="$refs.optionList.openModal(row.id)"
              >
                {{ row.quiz_question_options_count }}
                <i class="fas fa-bars"></i>
              </button>

              <button-edit icon sm @click="$refs.modal.openModal(row.id)" v-if="can('module.quiz.question-update')"/>
              <button-remove icon sm @click="remove(row)" v-if="can('module.quiz.question-delete')"
                             :disabled="row.is_lock"/>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div v-else class="alert alert-info">Список пуст</div>
    </spinner-block>
    <gui-paginate :data="listData" ref="paginate" @change="getList"/>
    <create-or-update-quiz-question-modal ref="modal" :user="data" @update="getList"
                                          :defaultParams="{quiz_id: this.data.id}"/>
    <question-options-list-modal ref="optionList" @update="getList" :defaultParams="{quiz_id: this.data.id}"/>
  </div>
</template>

<script>
import CreateOrUpdateQuizQuestionModal from "@/views/Quiz/Block/CreateOrUpdateQuizQuestionModal";
import IsActiveBadge from "@/components/Badge/IsActiveBadge";
import IsLockBadge from "@/components/Badge/IsLockBadge";
import QuestionOptionsListModal from "@/views/Quiz/Block/QuestionOptionsListModal";

export default {
  components: {QuestionOptionsListModal, IsLockBadge, IsActiveBadge, CreateOrUpdateQuizQuestionModal},
  data() {
    return {
      listData: null,
      progress: null,
      filter: {
        quiz_id: null,
      }
    }
  },
  props: {
    data: Object,
  },
  mounted() {
    this.filter.quiz_id = this.data.id
    this.getList()
  },
  methods: {
    getList() {
      axiosGetValue('/api/admin/quiz-question/list', {
            filter: this.filter,
            page: this.$refs.paginate.currentPage,
            per_page: this.$refs.paginate.perPage,
          },
          e => this.listData = e, e => this.progress = e
      )
    },
    remove(row) {
      $.alertConfirm({}, {
        ok: () => {
          axiosGetValue('/api/admin/quiz-question/delete', {id: row.id, region_id: row.region_id}, () => {
            toastSuccess('Удаление завершено');
            this.getList();
          })
        }
      });
    },
  }
}
</script>