<template>
  <div class="navbar navbar-expand-lg p-0 mb-3">
    <div class="navbar-brand">
      <div>
        <slot></slot>
        <span
            v-if="data"
            class="badge badge-pill badge-primary"
            title="Всего записей в разделе">
                    {{ data.total }}
                </span>
      </div>
    </div>
    <slot name="right"></slot>
  </div>
</template>

<script>
export default {
  props: {
    data: Object
  }
}
</script>
