<template>
  <div>
    <map-block v-if="can('module.map.view')"/>
  </div>
</template>

<script>
import MapBlock from "@/components/Map/MapBlock";

export default {
  components: {MapBlock},
  data: function () {
    return {}
  },
  props: {},
  computed: {},
  mounted() {

  },
  methods: {}
}
</script>