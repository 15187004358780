<template>
  <div>
    <div class="row">
      <div class="d-block d-md-none col-md-3 mb-3">
        <img :src="data.flag ? data.flag : '/images/no-photo.jpg'" class="img-thumbnail avatar-middle"/>
      </div>
      <div class="col-12 col-md-9">
        <card-field title="Название">
          <i class="fas fa-circle small"
             :title="data.is_active ? 'Активен' : 'Неактивен'"
             :class="data.is_active ? 'text-success' : 'text-danger'"></i>
          {{ data.name }}
        </card-field>

        <card-field :modelValue="data.code" title="Код"/>
        <card-field :modelValue="data.flag_code" title="Код флага"/>
        <card-field :modelValue="data.phone_code" title="Код телефона"/>
      </div>
      <div class="d-none d-md-block col-md-3 text-right">
        <img :src="data.flag ? data.flag : '/images/no-photo.jpg'" class="img-thumbnail avatar-middle"/>
      </div>
    </div>

    <div class="mt-3">

      <div class="float-right">
        <button-remove @click="remove" v-if="can('module.country.delete')"/>
      </div>


      <button-edit @click="$router.push({name: 'country.edit', params: {id: data.id}})"
                   v-if="can('module.country.update')"/>
    </div>
  </div>
</template>

<script>


export default {
  data() {
    return {}
  },
  props: {
    data: Object
  },
  mounted() {
  },
  methods: {

    remove() {
      $.alertConfirm({
        type: 'red',
      }, {
        ok: () => {
          axiosGetValue('/api/admin/country/delete', {id: this.data.id}, () => {
            toastSuccess('Удаление завершено');
            this.$router.push({name: 'country.list'})
          })
        }
      });
    },
  },
}
</script>