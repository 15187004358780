<template>
  <div>
    <div ref="modal" style="display: none">

      <div class="mb-3">
        <b>Список вариантов ответов</b>
      </div>

      <question-options-list
          ref="list"
          :id="id"
          :defaultParams="defaultParams"
          @update="update"
      />

    </div>
  </div>

</template>

<script>

import QuestionOptionsList from "@/views/Quiz/Block/QuestionOptionsList";

export default {
  components: {QuestionOptionsList},
  data() {
    return {
      id: null,
    }
  },
  props: {
    defaultParams: {
      type: Object,
      default: () => ({})
    },
  },
  methods: {
    openModal(data_id) {
      this.id = data_id
      setTimeout(() => {
        this.$refs.list.init();
        this.fancybox(this.$refs.modal)
      }, 0)
    },
    update() {
      this.$emit('update')
      this.fancyboxClose()
    },
  },
}
</script>