import EmptyWrap from "@/components/Gui/EmptyWrap";

export default [
    {
        path: 'client-call-squad',
        component: EmptyWrap,
        children: [
            {
                path: '',
                name: 'client-call-squad.list',
                component: require('@/views/ClientCallSquad/ClientCallSquadList').default,
            },
            {
                path: ':id',
                component: require('@/views/ClientCallSquad/Card/WrapBlock').default,
                children: [
                    {
                        path: 'detail',
                        name: 'client-call-squad.detail',
                        component: require('@/views/ClientCallSquad/Card/ClientCallSquadDetail').default,
                    },
                ]
            },
        ]
    },
]