<template>
  <ul :class="paginationClasses.ul" v-if="pageCount>1">
    <li :class="paginationClasses.li" v-if="false && paginationLabels.first">
      <a @click.prevent="first" href="#"
         :disabled="hasFirst"
         :class="`${paginationClasses.button} ${hasFirst ? paginationClasses.buttonDisable : ''}`"
         class="previous"
         v-html="paginationLabels.first"></a>
    </li>
    <li :class="paginationClasses.li" v-if="!hasFirst && paginationLabels.prev">
      <a @click.prevent="prev" href="#"
         :disabled="hasFirst"
         :class="`${paginationClasses.button} ${hasFirst ? paginationClasses.buttonDisable : ''}`"
         class="previous"
         v-html="paginationLabels.prev"></a>
    </li>

    <li :class="paginationClasses.li" v-if="rangeFirstPage !== 1">
      <a @click.prevent="goto(1)" href="#"
         :class="paginationClasses.button">1
      </a>
    </li>
    <li :class="paginationClasses.li" v-if="rangeFirstPage === 3">
      <a @click.prevent="goto(2)" href="#"
         :class="paginationClasses.button">2
      </a>
    </li>
    <li :class="[paginationClasses.li, paginationClasses.liDisable]"
        v-if="rangeFirstPage !== 1 && rangeFirstPage !== 2 && rangeFirstPage !== 3">
      <a @click.prevent=""
         :class="paginationClasses.button">
        <span>...</span>
      </a>
    </li>
    <!-- range start -->
    <li :class="[paginationClasses.li, hasActive(page) ? paginationClasses.buttonActive : '']" v-for="page in range"
        :key="page">
      <a href="#"

         @click.prevent="goto(page)"
         :class="`${paginationClasses.button}`">
        {{
          page
        }}
      </a>
    </li>
    <!-- range end -->
    <li :class="[paginationClasses.li, paginationClasses.liDisable]"
        v-if="rangeLastPage !== pageCount && rangeLastPage !== (pageCount - 1) && rangeLastPage !== (pageCount - 2)">


      <a @click.prevent="" :class="paginationClasses.button">
        <span>...</span>
      </a>

    </li>
    <li :class="paginationClasses.li" v-if="rangeLastPage === (pageCount - 2)">
      <a @click.prevent="goto(pageCount - 1)" href="#"
         :class="paginationClasses.button">{{ (pageCount - 1) }}
      </a>
    </li>
    <li :class="paginationClasses.li" v-if="rangeLastPage !== pageCount">
      <a @click.prevent="goto(pageCount)" href="#"
         :class="paginationClasses.button">{{ pageCount }}
      </a>
    </li>
    <li :class="paginationClasses.li" v-if="!hasLast && paginationLabels.next">
      <a @click.prevent="next" href="#"
         :disabled="hasLast"
         :class="`${paginationClasses.button} ${hasLast ? paginationClasses.buttonDisable : ''}`"
         class="next"
         v-html="paginationLabels.next"></a>
    </li>
    <li :class="paginationClasses.li" v-if="false && paginationLabels.last">
      <a @click.prevent="last" href="#"
         :disabled="hasLast"
         :class="`${paginationClasses.button} ${hasLast ? paginationClasses.buttonDisable : ''}`"
         class="next"
         v-html="paginationLabels.last"></a>
    </li>
  </ul>
</template>

<script>
const rangeMax = 4;
const defaultClasses = {
  ul: 'pagination justify-content-start justify-content-md-end mb-0 pt-2 pt-md-0',
  li: 'page-item',
  button: 'page-link',
  buttonActive: 'active',
  buttonDisable: 'disabled',
  liDisable: 'disabled',
};
const defaultLabels = {
  first: 'Первая',
  prev: '«',
  next: '»',
  last: 'Последняя'
};

export default {
  props: {
    modelValue: {  // current page
      required: true
    },
    pageCount: { // page numbers
      required: true
    },
    pageHref: {
      type: String
    },
    classes: {
      type: Object,
      required: false,
      default: () => ({})
    },
    labels: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },

  data() {
    return {
      paginationClasses: {
        ...defaultClasses,
        ...this.classes
      },
      paginationLabels: {
        ...defaultLabels,
        ...this.labels
      }
    }
  },

  mounted() {
    if (this.modelValue > this.pageCount) {
      this.setPage(this.pageCount);
    }
  },

  computed: {
    rangeFirstPage() {
      if (this.modelValue === 1) {
        return 1;
      }

      if (this.modelValue === this.pageCount) {
        if ((this.pageCount - rangeMax) < 0) {
          return 1;
        } else {
          return this.pageCount - rangeMax + 1;
        }
      }

      return (this.modelValue - 1);
    },

    rangeLastPage() {
      return Math.min(this.rangeFirstPage + rangeMax - 1, this.pageCount);
    },

    range() {
      let rangeList = [];
      for (let page = this.rangeFirstPage; page <= this.rangeLastPage; page += 1) {
        rangeList.push(page);
      }
      return rangeList;
    },

    hasFirst() {
      return (this.modelValue === 1);
    },

    hasLast() {
      return (this.modelValue === this.pageCount);
    },
  },

  methods: {
    first() {
      if (!this.hasFirst) {
        this.setPage(1);
      }
    },

    prev() {
      if (!this.hasFirst) {
        this.setPage((this.modelValue - 1));
      }
    },

    goto(page) {
      this.setPage(page);
    },

    next() {
      if (!this.hasLast) {
        this.setPage((this.modelValue + 1));
      }
    },

    last() {
      if (!this.hasLast) {
        this.setPage(this.pageCount);
      }
    },

    setPage(p) {
      this.$emit('update:modelValue', p);
      if (this.pageHref) {
        document.location.href = this.pageHref + (this.pageHref.indexOf('?') !== -1 ? '&' : '?') + 'page=' + p
      }
    },

    hasActive(page) {
      return (page === this.modelValue);
    },
  }
}
</script>
