<template>
  <gui-input-row
      :data="$props"
      :col="{
            label: 12,
            input: 12,
        }"
  >
        <textarea
            ref="textarea"
            :disabled="!edit"
            :name="name"
            :id="name"
            class="form-control"
            :class="error && error.length > 0 ? 'is-invalid' : ''"
            :placeholder="placeholder ? placeholder : label"
            :value="modelValue"
            @input="$emit('update:modelValue', $event.target.value)"
        ></textarea>
  </gui-input-row>

</template>

<script>
import autosize from "autosize/dist/autosize.min";

export default {
  data: function () {
    return {}
  },
  props: {
    name: String,
    label: String,
    placeholder: String,
    subInfo: String,
    autosize: Boolean,
    w: Boolean,
    error: [String, Object, Array],
    edit: {
      type: Boolean,
      default: true
    },
    modelValue: {
      type: String,
      default: ''
    }
  },
  computed: {},
  mounted() {

    if (this.autosize && this.$refs.textarea) {
      autosize(this.$refs.textarea)
    }
  },
  methods: {},
  watch: {
    modelValue() {
      if (this.autosize && this.$refs.textarea)
        this.$nextTick(() => this.$refs.textarea.dispatchEvent(new Event('autosize:update')))

    }
  }

}
</script>
