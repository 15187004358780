<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-9">
        <card-field :modelValue="data.name" title="Название"/>
        <card-field :modelValue="data.radius_presence_city" title="Радиус города, м"/>
        <card-field :modelValue="data.radius_search_max" title="Максимальный радиус поиска, м"/>
        <card-field :modelValue="data.radius_search_min" title="Минимальный радиус поиска, м"/>
        <card-field :modelValue="data.step_search_radius" title="Шаг радиуса поиска, м"/>
        <card-field :modelValue="data.squad_searching_time_interval" title="Временной интервал поиска отрядов, с"/>
        <card-field :modelValue="data.sort" title="Сортировка"/>
        <card-field :modelValue="data.squad_search_term_search_type ? data.squad_search_term_search_type.name : '—'"
                    title="Способ поиска ближайшего отряда"/>
      </div>

    </div>

    <div class="mt-3">

      <div class="float-right">
        <button-remove @click="remove" v-if="can('module.squad_search_terms.delete')"/>
      </div>


      <button-edit @click="$router.push({name: 'squad-search-terms.edit', params: {id: data.id}})"
                   v-if="can('module.squad_search_terms.update')"/>
    </div>
  </div>
</template>

<script>


export default {
  data() {
    return {}
  },
  props: {
    data: Object
  },
  mounted() {
  },
  methods: {

    remove() {
      $.alertConfirm({
        type: 'red',
      }, {
        ok: () => {
          axiosGetValue('/api/admin/squad-search-terms/delete', {id: this.data.id}, () => {
            toastSuccess('Удаление завершено');
            this.$router.push({name: 'squad-search-terms.list'})
          })
        }
      });
    },
  },
}
</script>