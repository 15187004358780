import {inject} from "vue";
import {TokenService} from "../token.service";
import {createSocketConnection} from "./create-socket";
import {v4 as uuid} from 'uuid';
import {AuthService} from "@/services/auth.service";

export class ChatServiceConnectionError extends Error {
    constructor() {
        super('Отсутствует подключение к сокету. Попробуйте вызвать метод ChatService.connect() при инициализации компонента');
    }
}

export class ChatService {

    async initIfNeed() {
        if (!this.socket || !this.socket.connected) return this.init();
    }

    async init() {
        return new Promise((resolve, reject) => {
            this.connect()
                .then(() => {
                    resolve(this)
                })
                .catch((err) => {
                    if (err === '401') {
                        AuthService
                            .refreshToken()
                            .then(() => {
                                this.connect().then(() => {
                                    resolve(this)
                                })
                            })
                            .catch(() => {
                                AuthService.signOut()
                                reject('chat reauthorize fail')
                            })

                    }
                })
        })
    }

    connect() {
        return new Promise((resolve, reject) => {
            if (this.socket && this.socket.connected) {
                console.warn('Переподключение к сервису чата');
            }

            const token = TokenService.getToken();

            this.socket = createSocketConnection({
                auth: {
                    token,
                    deviceUid: uuid(),
                },

                namespace: 'manager-chat',
            });

            this.socket.on('connect', () => {
                resolve(this);
            });

            this.socket.on('connect_error', (err) => {
                if (err.toString() === 'Error: Request failed with status code 401') {
                    reject('401')
                } else {
                    reject(err);
                }
            });
        });
    }

    disconnect() {
        if (!this.socket) return;

        this.socket.disconnect();
        this.socket = undefined;
    }

    _rpc(name, props) {
        if (!this.socket || !this.socket.connected) throw new ChatServiceConnectionError();

        return new Promise((res, rej) => {
            this.socket.emit(name, props, (response) => {
                if (!response.result) return rej(new Error(response.message));

                return res(response.data);
            });
        });
    }

    _subscribe(name, callback) {
        if (!this.socket || !this.socket.connected) throw new ChatServiceConnectionError();

        this.socket.on(name, callback);
    }

    _unsubscribe(name, callback) {
        if (!this.socket) throw new ChatServiceConnectionError();

        this.socket.off(name, callback);
    }

    getChatList(props = {}) {
        return this._rpc('listChats', props);
    }

    getChat(uuid) {
        return this._rpc('getChat', {uuid});
    }

    attachForChat(uuid) {
        return this._rpc('attachForChat', {uuid});
    }

    markArchive(uuid) {
        return this._rpc('markArchive', {uuid});
    }

    getMessages(chat_uuid, page = {}) {
        return this._rpc('listMessages', {chat_uuid, ...page});
    }

    joinToChat(chat_uuid) {
        return this._rpc('joinToChat', {uuid: chat_uuid});
    }

    leaveFromChat(chat_uuid) {
        return this._rpc('leaveFromChat', {uuid: chat_uuid});
    }

    sendMessage(chat_uuid, message) {
        return this._rpc('sendMessage', {chat_uuid, ...message});
    }

    makeSeen(uuid) {
        return this._rpc('makeSeen', {uuid});
    }

    onMessageSent(callback) {
        this._subscribe('messageSent', callback);
    }

    offMessageSent(callback) {
        this._unsubscribe('messageSent', callback);
    }
}

export const chatKey = Symbol('ChatService');

let instance = new ChatService();

export function installChat(app) {
    app.config.globalProperties.$chat = instance;
    app.provide(chatKey, instance);
}

export function useChat() {
    return inject(chatKey, instance);
}