<template>
  <div class="spinner-block">
    <div :class="{'spinner-wrap': !hideContent}" v-if="modelValue" :style="{'z-index': zIndex}">
      <div class="spinner-square text-center mg-b-20" :class="{'spinner-square': !hideContent}">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
    <div v-if="!modelValue || !hideContent">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: [Boolean, Number, String],
    hideContent: Boolean,
    zIndex: [String, Number, Boolean],
  },
  mounted() {

  },
}
</script>
