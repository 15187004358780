<template>
  <div>
    <div class="row">

      <div class="col-12 col-md-9">
        <card-field title="Название">
          <i class="fas fa-circle small"
             :title="data.is_active ? 'Активен' : 'Неактивен'"
             :class="data.is_active ? 'text-success' : 'text-danger'"></i>
          {{ data.code }}
        </card-field>

        <card-field title="Скидка">
          <router-link :to="{name: 'discount.detail', params: {id: data.discount_id}}">
            {{ data.discount ? data.discount.name : '—' }}
          </router-link>
        </card-field>


        <card-field :modelValue="data.start_at" title="Дата начала действия промокода"/>
        <card-field :modelValue="data.end_at" title="Дата окончания действия промокода"/>
        <card-field :modelValue="data.for_first_subscription ? 'Да' : 'Нет'"
                    title="Применять только для тех у кого не была оформлена подписка"/>
        <card-field :modelValue="data.count_total_usage" title="Сколько раз все пользователи могут применить промокод"/>
        <card-field :modelValue="data.count_client_usage"
                    title="Сколько раз один пользователь может применить промокод"/>
        <card-field :modelValue="nl2br(data.comment)" title="Примечание"/>
      </div>
    </div>

    <div class="mt-3">

      <div class="float-right">
        <button-remove @click="remove" v-if="can('module.promocode.delete')"/>
      </div>


      <button-edit @click="$router.push({name: 'promocode.edit', params: {id: data.id}})"
                   v-if="can('module.promocode.update')"/>
    </div>
  </div>
</template>

<script>


export default {
  data() {
    return {}
  },
  props: {
    data: Object
  },
  mounted() {
  },
  methods: {

    remove() {
      $.alertConfirm({
        type: 'red',
      }, {
        ok: () => {
          axiosGetValue('/api/admin/promocode/delete', {id: this.data.id}, () => {
            toastSuccess('Удаление завершено');
            this.$router.push({name: 'promocode.list'})
          })
        }
      });
    },
  },
}
</script>
