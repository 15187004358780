<template>
  <div>
    <spinner-block v-model="dataProgress">
      <div v-if="data">
        <gui-input v-model="data.name" :error="dataError.name" label="Имя"/>
        <gui-input v-model="data.phone" :error="dataError.phone" label="Телефон"/>
        <div class="mt-5">
          <button-save v-if="!data.id" @click="add()"/>
          <button-save v-else @click="save"/>
          <button-cancel @click="$emit('cancel')"/>
        </div>
      </div>
    </spinner-block>
  </div>
</template>

<script>


export default {
  data() {
    return {
      data: null,
      dataDefault: {
        name: '',
        phone: '',
        client_id: '',
      },
      dataError: {
        name: '',
        phone: '',
        client_id: '',
      },
      dataProgress: false,
      regions: [],
      squad_search_terms: []
    }
  },
  props: {
    id: [String, Number],
    defaultParams: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {

    init() {
      if (this.id) {
        this.data = null
        this.loadData()
      } else {
        this.data = Object.assign({}, this.dataDefault, this.defaultParams)
      }
      clearErrorList(this.dataError)
    },
    loadData() {
      axiosGetValue('/api/admin/client-relatives/get', {id: this.id}, e => this.data = e, e => this.dataProgress = e)
    },

    save() {
      axiosFast({
        url: route('/api/admin/client-relatives/update'),
        data: {data: this.data, id: this.data.id},
        error: this.dataError,
        progress: e => this.dataProgress = e,
        result: () => {
          toastSuccess('Данные сохранены');
          this.$emit('save', this.data.id)
        },
      })
    },
    add() {
      axiosFast({
        url: route('/api/admin/client-relatives/create'),
        data: {data: this.data},
        error: this.dataError,
        progress: e => this.dataProgress = e,
        result: (data) => {
          toastSuccess('Данные добавлены');
          this.$emit('add', data.id)
        },
      })
    },
  },
}
</script>