import axios from "axios";

const ApiService = {
    _requestInterceptor: 0,
    _401interceptor: 0,

    init(baseURL) {
        axios.defaults.baseURL = baseURL;
    },

    setHeader() {
        
    },

    removeHeader() {

    },

    get(resource) {
        return axios.get(resource);
    },

    post(resource, data) {
        return axios.post(resource, data);
    },

    put(resource, data) {
        return axios.put(resource, data);
    },

    delete(resource) {
        return axios.delete(resource);
    },

    customRequest(data) {
        return axios(data);
    },
}

export default ApiService;