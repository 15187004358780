import EmptyWrap from "@/components/Gui/EmptyWrap";

export default [
    {
        path: 'promocode',
        component: EmptyWrap,
        children: [
            {
                path: '',
                name: 'promocode.list',
                component: require('@/views/Promocode/PromocodeList').default,
            },
            {
                path: 'create',
                name: 'promocode.create',
                component: require('@/views/Promocode/PromocodeForm').default,
            },
            {
                path: ':id',
                component: require('@/views/Promocode/Card/WrapBlock').default,
                children: [
                    {
                        path: 'detail',
                        name: 'promocode.detail',
                        component: require('@/views/Promocode/Card/PromocodeDetail').default,
                    },
                    {
                        path: 'promocode-use',
                        name: 'promocode.promocode-use',
                        component: require('@/views/Promocode/Card/PromocodeUseTab.vue').default,
                    },
                ]
            },
            {
                path: ':id/edit',
                name: 'promocode.edit',
                component: require('@/views/Promocode/PromocodeForm').default,
            },
        ]
    },
]
