<template>
  <i class="fas fa-circle small"
     :title="modelValue ? 'Активен' : 'Неактивен'"
     :class="modelValue ? 'text-success' : 'text-danger'"></i>
</template>

<script>
export default {

  props: {
    modelValue: {
      type: [String, Boolean],
      default: ''
    }
  },
}
</script>