import {initializeApp} from 'firebase/app';
import {deleteToken, getMessaging, getToken, onMessage} from "firebase/messaging";

var firebaseConfig = {
    apiKey: "AIzaSyDMea7t76UXtKhZuSRIqcuYR4c6wbZ2Xk4",
    authDomain: "guardee-app.firebaseapp.com",
    projectId: "guardee-app",
    storageBucket: "guardee-app.appspot.com",
    messagingSenderId: "958138593666",
    appId: "1:958138593666:web:04f5a8b9f6db00016fdf9b",
    measurementId: "G-YDCWTWNYCJ"
};

try {
    var firebaseApp = initializeApp(firebaseConfig);
    var messaging = getMessaging(firebaseApp);
} catch (e) {
    console.log('firebase error', e)
}

export const fetchToken = (setTokenFound) => {
    return getToken(messaging, {vapidKey: 'BGJedY5NAbtqdz-oU9Xh5m5_affT7K6NQitx8ksHXhUKDKfBt2ddR2tXhF3wOQ-wIocnqzKKuzK6RROqW04ygg0'}).then((currentToken) => {
        if (currentToken) {
            storageSet('push_token', currentToken)
            axiosGetValue('/api/admin/profile/set-push-token', {token: currentToken});
            setTokenFound(true);
            // Track the token -> client mapping, by sending to backend server
            // show on the UI that permission is secured
        } else {
            console.log('No registration token available. Request permission to generate one.');
            setTokenFound(false);
            // shows on the UI that permission is required
        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // catch error while creating client token
    });
}

export function removeToken() {
    storageSet('push_token', '')
    return deleteToken(messaging)
}

export function getCurrentToken() {
    return storageLoad('push_token', '')
}

export const onMessageListener = (callback) =>
    onMessage(messaging, (payload) => {
        console.log('onMessageListener', payload)
        callback(payload);
    });