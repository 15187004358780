<template>
  <div>
    <spinner-block v-model="progress">

      <div class="mb-3 text-right">
        <button class="btn btn-success" @click.prevent="$refs.createOrUpdateModal.openModal()"
                v-if="can('module.tariff.detail-create')">
          Добавить
        </button>
      </div>

      <div v-if="tariffErrors && tariffErrors.length" class="alert alert-danger mb-3">
        <b>Обратите внимание на эти ошибки:</b>
        <div v-for="(row, i) in tariffErrors" :key="i">
          {{ row }}
        </div>
      </div>
      <div v-if="listData && listData.data && listData.data.length">
        <table class="table table-hover">
          <thead>
          <tr>
            <th>Номер</th>
            <th>Для всех городов</th>
            <th>Города</th>
            <th>Дата изменения</th>
            <th>Условия тарифа</th>
            <th class="text-right"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="row in listData.data" :key="row.id">
            <td>
              #{{ row.id }}
            </td>
            <td>
              {{ row.is_all_cities ? 'Да' : 'Нет' }}
            </td>
            <td class="small">
              <div v-for="city in row.cities" :key="city.id">
                {{ city.name }}
              </div>
            </td>
            <td>
              {{ toTime(row.updated_at) }}
            </td>
            <td>
              <a href="#"
                 v-if="row.tariff_detail_term_id"
                 @click.prevent="openTerm(row.tariff_detail_term_id)">
                Условия v{{ row.tariff_detail_term ? row.tariff_detail_term.version : '—' }}
              </a>
              <span v-else>—</span>
            </td>
            <td class="text-right">
              <button class="btn btn-primary btn-sm ml-2" @click.prevent="$refs.createOrUpdateModal.openModal(row.id)"
                      v-if="can('module.tariff.detail-update')">
                <i class="fas fa-edit"></i>
              </button>
              <button class="btn btn-danger btn-sm ml-2" @click.prevent="remove(row.id)"
                      v-if="can('module.tariff.detail-delete')">
                <i class="fas fa-times"></i>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div v-else class="alert alert-info">Список пуст</div>
    </spinner-block>
    <gui-paginate :data="listData" ref="paginate" @change="getList"/>


    <view-tariff-detail-term-modal ref="termModalView"/>
    <create-or-update-tariff-detail-modal ref="createOrUpdateModal" :defaultParams="{tariff_id: data.id}"
                                          @update="getList"/>
  </div>
</template>

<script>

import ViewTariffDetailTermModal from "@/views/Tariff/Block/ViewTariffDetailTermModal";
import CreateOrUpdateTariffDetailModal from "@/views/Tariff/Block/CreateOrUpdateTariffDetailModal";

export default {
  components: {CreateOrUpdateTariffDetailModal, ViewTariffDetailTermModal},

  data() {
    return {
      listData: null,
      progress: null,
      filter: {},
      selectRow: null,
      tariffErrors: null,
    }
  },
  props: {
    data: Object,
  },
  mounted() {
    this.filter.tariff_id = this.data.id
    this.getList()
  },
  methods: {
    getList() {
      axiosGetValue('/api/admin/tariff-detail/list', {
            filter: this.filter,
            page: this.$refs.paginate.currentPage,
            per_page: this.$refs.paginate.perPage,
          },
          e => this.listData = e, e => this.progress = e
      )
      axiosGetValue('/api/admin/tariff-detail/errors', {
            tariff_id: this.data.id,
          },
          e => this.tariffErrors = e
      )
    },
    openTerm(id) {
      this.$refs.termModalView.openModal(id)
    },
    remove(id) {
      $.alertConfirm({
        type: 'red',
      }, {
        ok: () => {
          axiosGetValue('/api/admin/tariff-detail/delete', {id: id}, () => {
            toastSuccess('Удаление завершено');
            this.getList()
          })
        }
      });
    },
  }
}
</script>