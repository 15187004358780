<template>
  <span
      class="border border-dark table-light text-b rounded-circle text-body font-monospace d-inline-block avatar"
      :class="`avatar-${color}`">
    {{ initials }}
  </span>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: 'XX'
    },
    color: {
      type: String,
    },
  },
  computed: {
    initials() {
      return this.value?.split(' ')
          .map((el) => el.charAt(0).toUpperCase())
          .join('') || 'XX'
    }
  }
}
</script>