<template>
  <card-block>
    <spinner-block v-model="dataProgress">
      <div v-if="data">
        <card-header>
          <template v-if="id">
            <router-link :to="{name: 'subscription.detail', params: {id: id}}">Подписка</router-link><!-- -->:
            редактирование

          </template>
          <template v-else>
            <router-link :to="{name: 'subscription.list'}">Подписки</router-link><!-- -->:
            создание
          </template>


        </card-header>
        <card-body>
          <gui-client-select v-if="!id" v-model="data.client_id" :error="dataError.client_id"
                             label="Клиент"/>
          <gui-tariff-detail-select v-if="!id" v-model="data.tariff_detail_id" :error="dataError.tariff_detail_id"
                             label="Тариф полный"/>
          <gui-tariff-select v-if="id" v-model="data.tariff_id" :error="dataError.tariff_id"
                             label="Тариф"/>
          <gui-date v-model="data.activated_at" :error="dataError.activated_at"
                         label="Дата активации"/>
          <gui-date v-model="data.deactivated_at" :error="dataError.deactivated_at"
                         label="Дата деактивации"/>

          <gui-input v-if="id" v-model="data.free_calls_count" :error="dataError.free_calls_count" label="Бесплатное кол-во звонков"/>
          <gui-input v-if="id" v-model="data.need_paid" :error="dataError.need_paid" label="Сколько нужно оплатить"/>
          <gui-input v-if="id" v-model="data.max_peoples" :error="dataError.max_peoples" label="Максимальное кол-во человек"/>
          
          <div class="mt-5">
            <button-save v-if="!data.id" @click="add()"/>
            <button-save v-else @click="save"/>
            <button-back/>
          </div>
        </card-body>
      </div>
    </spinner-block>
  </card-block>
</template>

<script>
import GuiTariffDetailSelect from "@/components/Widget/GuiTariffDetailSelect";
import GuiTariffSelect from "@/components/Widget/GuiTariffSelect";
import GuiClientSelect from "@/components/Widget/GuiClientSelect";

export default {
  components: {GuiTariffDetailSelect, GuiTariffSelect, GuiClientSelect},
  data() {
    return {
      data: null,
      dataDefault: {
        tariff_detail_id: '',
        tariff_id: '',
        client_id: '',
        activated_at: '',
        deactivated_at: '',
        free_calls_count: '',
        need_paid: '',
        max_peoples: '',
      },
      dataError: {
        tariff_detail_id: '',
        tariff_id: '',
        client_id: '',
        activated_at: '',
        deactivated_at: '',
        free_calls_count: '',
        need_paid: '',
        max_peoples: '',
      },
      dataProgress: false,
      statuses: [],
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    }
  },
  mounted() {
    this.init()
  },
  methods: {

    init() {
      if (this.id) {
        this.data = null
        this.loadData()
      } else {
        this.data = _.clone(this.dataDefault)
      }
    },
    loadData() {
      axiosGetValue('/api/admin/subscription/get', {id: this.id}, e => this.data = e, e => this.dataProgress = e)
    },
    save() {
      axiosFast({
        url: route('/api/admin/subscription/update'),
        formData: {data: this.data, id: this.data.id},
        error: this.dataError,
        progress: e => this.dataProgress = e,
        result: () => {
          toastSuccess('Данные сохранены');
          this.$router.push({name: 'subscription.detail', params: {id: this.data.id}})
        },
      })
    },
    add() {
      axiosFast({
        url: route('/api/admin/subscription/create'),
        formData: {data: this.data},
        error: this.dataError,
        progress: e => this.dataProgress = e,
        result: (data) => {
          toastSuccess('Данные добавлены');
          this.$router.push({name: 'subscription.detail', params: {id: data.id}})
        },
      })
    },
  },
}
</script>