import EmptyWrap from "@/components/Gui/EmptyWrap";

export default [
    {
        path: 'district',
        component: EmptyWrap,
        children: [
            {
                path: '',
                name: 'district.list',
                component: require('@/views/District/DistrictList').default,
            },
            {
                path: 'create',
                name: 'district.create',
                component: require('@/views/District/DistrictForm').default,
            },
            {
                path: ':id',
                component: require('@/views/District/Card/WrapBlock').default,
                children: [
                    {
                        path: 'detail',
                        name: 'district.detail',
                        component: require('@/views/District/Card/DistrictDetail').default,
                    },
                ]
            },
            {
                path: ':id/edit',
                name: 'district.edit',
                component: require('@/views/District/DistrictForm').default,
            },
        ]
    },
]