<template>
  <card-block>
    <spinner-block v-model="dataProgress">
      <div v-if="data">
        <card-header>
          <template v-if="id">
            <router-link :to="{name: 'document-type.list'}">Страницы документа</router-link><!-- -->:
            редактирование

          </template>
          <template v-else>
            <router-link :to="{name: 'document-type.list'}">Страницы документа</router-link><!-- -->:
            создание
          </template>

        </card-header>
        <card-body>

          <gui-input v-model="data.name" label="Наименование"/>
          <gui-input v-model="data.description" label="Описание"/>
          <gui-input v-model="data.code" :error="dataError.code" label="Код"/>
          <gui-checkbox v-model="data.is_required" label="Обязательный"/>

          <gui-client-document-group-select v-model="data.group_id" label="Группа"
                                            :error="dataError.group_id"/>
          <div class="mt-5">
            <template v-if="!data.id">
              <button-create v-if="can('module.client_document_type.create')" @click="add()"/>
            </template>
            <template v-else>
              <button-save v-if="can('module.client_document_type.update')" @click="save"/>
            </template>
            <button-back/>

            <div class="float-right">
              <button-remove @click="remove" v-if="can('module.client_document_type.delete')"/>
            </div>
          </div>
        </card-body>
      </div>
    </spinner-block>
  </card-block>
</template>

<script>

import GuiClientDocumentGroupSelect from "@/components/Widget/GuiClientDocumentGroupSelect.vue";

export default {
  components: {GuiClientDocumentGroupSelect},
  data() {
    return {
      data: null,
      dataProgress: false,
      dataDefault: {
        name: '',
        description: '',
        code: '',
        is_required: false,
        sort: '',
        group_id: '',
      },
      dataError: {code: '', group_id: ''},
      statuses: [],
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      if (this.id) {
        this.data = null
        this.loadData()
      } else {
        this.data = _.clone(this.dataDefault)
      }
    },

    loadData() {
      axiosGetValue('/api/admin/client-document-type/get',
          {id: this.id},
          e => this.data = e,
          e => this.dataProgress = e
      )
    },

    save() {
      axiosFast({
        url: route('/api/admin/client-document-type/update'),
        data: {data: this.data, id: this.data.id},
        error: this.dataError,

        progress: e => this.dataProgress = e,
        result: () => {
          toastSuccess('Данные сохранены');
          this.$router.push({name: 'document-type.list'});
        },
      })
    },

    add() {
      axiosFast({
        url: route('/api/admin/client-document-type/create'),
        data: {data: this.data},
        error: this.dataError,
        progress: e => this.dataProgress = e,
        result: () => {
          toastSuccess('Данные добавлены');
          this.$router.push({name: 'document-type.list'})
        },
      })
    },

    remove() {
      axiosFast({
        url: route('/api/admin/client-document-type/delete'),
        data: {id: this.id},
        error: this.dataError,
        progress: e => this.dataProgress = e,
        result: () => {
          toastSuccess('Данные удалены');
          this.$router.push({name: 'document-type.list'})
        },
      })
    },

  },
}
</script>
