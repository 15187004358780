<template>
  <div class="row" v-if="data" v-show="data.total>0 && !(data.total<=10 && data.perPage==10)">
    <div class="col-12 col-md-12">
      <template-pagination
          v-if="data.last_page > 1"
          v-model="currentPage"
          :page-count="data.last_page"
          :labels="labels"/>
    </div>
    <div class="cb"></div>
  </div>

</template>

<script>
//import vPagination from 'vue-plain-pagination'
import TemplatePagination from "./TemplatePagination";

export default {
  components: {
    TemplatePagination,
    //vPagination
  },

  beforeMount() {
    this.perPage = storageLoad('perPage_' + this.$route.name, 10)
  },

  data: function () {
    return {
      currentPage: 0,
      perPage: 10,

      labels: {
        first: "Первая",
        prev: '«',
        next: '»',
        las: "Последняя"
      },

      classes: {
        ul: 'dataTables_paginate paging_simple_numbers',
        li: 'paginate_button',
        liActive: 'pagination-item--active',
        liDisable: 'pagination-item--disable',
        button: 'pagination-link',
        buttonActive: 'current',
        buttonDisable: 'pagination-link--disable'
      },

      watchCurrentPage: false
    }
  },

  props: {
    data: {
      type: Object,
      default: function () {
        return {
          list_from: 0,
          list_to: 0,
          list_total: 0,
          last_page: 0,

          perPage: 0,
          currentPage: 0,
        }
      }
    },
    hidePerPage: Boolean
  },

  computed: {},

  mounted() {
    this.currentPage = this.$route.params.curPagPage ? this.$route.params.curPagPage : 1;
    this.watchCurrentPage = true;
  },

  methods: {
    changePerPage(e) {
      let oldCurrent = this.currentPage;
      let oldPerPage = this.perPage;

      this.perPage = e;
      this.currentPage = 1;

      if (
          oldCurrent === this.currentPage ||
          oldPerPage !== this.perPage
      ) {
        this.$emit('change', this.currentPage);
      }

    }
  },

  watch: {
    'currentPage': function () {
      if (this.watchCurrentPage && this.data && this.data.currentPage !== this.currentPage)
        this.$emit('change', this.currentPage);
    },
  }
}
</script>
