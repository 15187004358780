<template>
  <card-block>
    <spinner-block v-model="dataProgress">
      <template v-if="data">
        <card-header>
          <router-link :to="{name: 'squad.list'}">Отряды</router-link><!--
                    -->:
          <span class="text-secondary">#{{ data.id }}</span>
          {{ data.phone ? data.phone : '—' }} {{ data.name ? data.name : '—' }}
        </card-header>
        <tab-list>
          <tab-link to="squad.detail">Личные данные</tab-link>
          <tab-link to="squad.duty" v-if="can('module.squad.duty-view')">Смены</tab-link>
          <tab-link to="squad.client-calls" v-if="can('module.client_call.view')">Вызовы</tab-link>
          <tab-link to="squad.client-call-squad" v-if="can('module.client_call_squad.view')">Поиск отряда</tab-link>
          <tab-link to="squad.phone-call" v-if="can('module.phone_call.view')">Телефонные звонки</tab-link>
        </tab-list>
        <card-body>
          <router-view :key="id" :data="data" @change="getData"></router-view>
        </card-body>
      </template>
    </spinner-block>
  </card-block>
</template>

<script>
export default {
  data: function () {
    return {
      data: null,
      dataProgress: true,
    }
  },
  props: {},
  computed: {
    id() {
      return this.$route.params.id;
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      if (this.id) {
        axiosGetValue('/api/admin/squad/get', {id: this.id}, e => this.data = e, e => this.dataProgress = e)
      }
    },
  },
}
</script>