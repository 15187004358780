import EmptyWrap from "@/components/Gui/EmptyWrap";

export default [
    {
        path: 'client',
        component: EmptyWrap,
        children: [
            {
                path: '',
                name: 'client.list',
                component: require('@/views/Client/ClientList').default,
            },
            {
                path: 'create',
                name: 'client.create',
                component: require('@/views/Client/ClientForm').default,
            },
            {
                path: ':id',
                component: require('@/views/Client/Card/WrapBlock').default,
                children: [
                    {
                        path: 'detail',
                        name: 'client.detail',
                        component: require('@/views/Client/Card/ClientDetail').default,
                    },
                    {
                        path: 'relatives',
                        name: 'client.relatives',
                        component: require('@/views/Client/Card/RelativesTab').default,
                    },
                    {
                        path: 'payments',
                        name: 'client.payments',
                        component: require('@/views/Client/Card/PaymentTab').default,
                    },
                    {
                        path: 'phone-calls',
                        name: 'client.phone-calls',
                        component: require('@/views/Client/Card/PhoneCallTab').default,
                    },
                    {
                        path: 'calls',
                        name: 'client.client-calls',
                        component: require('@/views/Client/Card/ClientCallTab').default,
                    },
                    {
                        path: 'subscribe',
                        name: 'client.subscribe',
                        component: require('@/views/Client/Card/SubscribeTab').default,
                    },
                    {
                        path: 'subscribe-chain',
                        name: 'client.subscribe-chain',
                        component: require('@/views/Client/Card/SubscribeChainTab').default,
                    },
                    {
                        path: 'cards',
                        name: 'client.cards',
                        component: require('@/views/Client/Card/CardsTab').default,
                    },
                    {
                        path: 'documents',
                        name: 'client.documents',
                        component: require('@/views/Client/Card/DocumentsTab').default,
                    },
                    {
                        path: 'promocode-use',
                        name: 'client.promocode-use',
                        component: require('@/views/Client/Card/PromocodeUseTab').default,
                    },
                ]
            },
            {
                path: ':id/edit',
                name: 'client.edit',
                component: require('@/views/Client/ClientForm').default,
            },
        ]
    },
]
