<template>
  <card-block>
    <card-header>
      Скрининг ошибок
    </card-header>
    <card-body>
      <spinner-block v-model="progress">

        <div v-if="data">

          <error-client-call-block
              :data="data.new_client_call"
              title="Вызовы в режиме поиска отряда старше 5 минут"/>

          <error-client-call-block
              :data="data.active_client_call"
              title="Активные вызовы старше 1 часа"/>

          <error-client-call-block
              :data="data.warning_client_call"
              title="Вызовы на которые нужно обратить внимание"/>

          <error-client-call-block
              :data="data.hold_client_call"
              title="Вызовы по которым не вернулся холд средств старше 1 часа"/>

          <error-squad-duty-block
              :data="data.old_squad_duty"
              title="Незакрытые смены отрядов старше 1 дня"/>

          <error-client-call-block
              :data="data.debit_client_call"
              title="Вызовы с дебиторской задолженностью"/>

          <error-subscription-block
              :data="data.debit_subscription"
              title="Подписки с ошибками оплаты"/>

          <error-chat-block
              :data="data.old_chat"
              title="Зависшие чаты"/>


        </div>

      </spinner-block>
    </card-body>
  </card-block>
</template>

<script>

import ErrorClientCallBlock from "@/components/Error/ErrorClientCallBlock";
import ErrorSquadDutyBlock from "@/components/Error/ErrorSquadDutyBlock";
import ErrorSubscriptionBlock from "@/components/Error/ErrorSubscriptionBlock";
import ErrorChatBlock from "@/components/Error/ErrorChatBlock";

export default {
  components: {ErrorChatBlock, ErrorSubscriptionBlock, ErrorSquadDutyBlock, ErrorClientCallBlock},
  data: function () {
    return {
      data: null,
      progress: true,
    }
  },
  props: {},
  computed: {},
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      axiosGetValue('/api/admin/errors/all', {},
          e => this.data = e, e => this.progress = e
      )
    }
  }
}
</script>