<template>

  <gui-input-row :data="$props">
    <input type="text"
           :disabled="!edit"
           :name="name"
           :id="name"
           :placeholder="placeholder ? placeholder : label"
           :class="{
                    'form-control': true, 'is-invalid': error && error.length,
                    'no_spin': no_spin,
                    'form-control-sm': sm,
                    'hilight-field': typeof hilightOn !== 'undefined' && !( typeof hilightOn =='object' ? hilightOn.indexOf(modelValue)!==-1 : hilightOn==modelValue )
               }"
           :value="val"
           @change="e=>val=e.target.value"
           ref="date"
    />
    <slot v-if="clearable && modelValue && edit" name="after">
      <div class="input-group-append">
        <button class="btn btn-sm btn-danger" @click.prevent="val=''">
          <i class="fas fa-times"></i>
        </button>
      </div>
    </slot>

  </gui-input-row>

</template>

<script>

export default {
  components: {},
  data: function () {
    return {
      pikada: null
    }
  },
  props: {
    w: Boolean,
    sm: Boolean,
    no_spin: Boolean,
    time: Boolean,
    hide: Boolean,
    name: String,
    label: String,
    placeholder: String,
    error: [String, Object, Array],
    hilightOn: [Array, String, Number],
    type: String,
    append: String,
    prepend: String,
    minDate: String,
    maxDate: String,
    edit: {
      type: Boolean,
      default: true
    },
    modelValue: {
      type: String,
      default: ''
    },
    clearable: {
      type: Boolean,
      default: false
    },

    format: {
      type: String,
      default: 'DD.MM.YYYY'
    }
  },
  computed: {
    val: {
      get() {
        return this.modelValue ? this.moment(this.modelValue, this.getDateFormat(this.modelValue)).format(this.format) : ''
      },
      set(v) {
        let r = v ? this.moment(v, this.getDateFormat(v)).format('YYYY-MM-DD') : '';
        this.$emit('update:modelValue', r)
      }
    }
  },
  mounted() {
    let option = {format: this.format};
    if (this.minDate) option.minDate = this.moment(this.minDate).toDate();
    if (this.maxDate) option.maxDate = this.moment(this.maxDate).toDate();
    this.pikaday = makePikaday(this.$refs.date, option)
  },
  unmounted() {
    if (this.pikaday) this.pikaday.destroy();
  },
  methods: {
    getDateFormat(modelValue) {
      return modelValue.match(/\d\d\d\d-\d\d-\d\d/) ? 'YYYY-MM-DD' : 'DD.MM.YYYY'
    }
  }
}
</script>
