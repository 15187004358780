<template>
  <card-block>
    <card-header>

      <template v-if="id">
        <router-link :to="{name: 'district.detail', params: {id: id}}">Район</router-link><!-- -->:
        редактирование
      </template>

      <template v-else>
        <router-link :to="{name: 'district.list'}">Районы</router-link><!-- -->:
        создание
      </template>

    </card-header>
    <card-body>

      <district-form-block
          ref="form"
          :id="id"
          @save="(id)=>$router.push({name: 'district.detail', params: {id: id}})"
          @add="(id)=>$router.push({name: 'district.detail', params: {id: id}})"
          @cancel="history_back"
      />

    </card-body>
  </card-block>
</template>

<script>
import DistrictFormBlock from "@/views/District/DistrictFormBlock";

export default {
  components: {DistrictFormBlock},
  computed: {
    id() {
      return this.$route.params.id
    }
  },
  mounted() {
    this.$refs.form.init()
  },
  methods: {},
}
</script>