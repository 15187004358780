<template>
  <div :class="{'d-inline': is_inline, 'my-3':!is_inline}">
    <select v-model="perPage" @change="e=>$emit('changePerPage', e.target.value)"
            class="form-control mr-2"
            style="display: inline-block; width: 80px; vertical-align: inherit;">
      <option value=2>2</option>
      <option value=10>10</option>
      <option value=25>25</option>
      <option value=50>50</option>
      <option value=100>100</option>
      <option value=200>200</option>
      <option value=500>500</option>
    </select>
    на страницу
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      perPage: 10,
    }
  },
  beforeMount() {
    this.perPage = storageLoad('perPage_' + this.$route.name, 10)
  },
  props: {

    data: {
      type: Object,
      default: function () {
        return {
          perPage: 0,
        }
      }
    },
    is_inline: Boolean
  },
  computed: {},
  mounted() {

  },
  methods: {},
  watch: {
    'perPage': function (v) {
      storageSet('perPage_' + this.$route.name, v)
    }
  }
}
</script>
