<template>
  <div :id="id" v-if="show" class="card mt-5">
    <slot></slot>
  </div>
  <error-403 v-else/>
</template>

<script>
export default {
  props: {
    id: String,
    if: String
  },
  computed: {
    show() {
      return this.if ? this.$can(this.if) : true
    },
  }
}
</script>
