<template>
  <card-block>
    <spinner-block v-model="dataProgress">
      <div v-if="data">
        <card-header>
          <template v-if="id">
            <router-link :to="{name: 'promocode.detail', params: {id: id}}">Промокод</router-link><!-- -->:
            редактирование

          </template>
          <template v-else>
            <router-link :to="{name: 'promocode.list'}">Промокоды</router-link><!-- -->:
            создание
          </template>


        </card-header>
        <card-body>
          <gui-checkbox v-model="data.is_active" :error="dataError.is_active" label="Активный"/>
          <gui-input v-model="data.code" :error="dataError.code" label="Промокод"/>
          <gui-discount-select v-model="data.discount_id" :error="dataError.discount_id"
                               label="Скидка"/>
          <gui-date sm clearable v-model="data.start_at" :error="dataError.start_at"
                    label="Дата начала действия промокода"/>
          <gui-date sm clearable v-model="data.end_at" :error="dataError.end_at"
                    label="Дата окончания действия промокода"/>
          <gui-input sm type="number" v-model="data.count_total_usage" :error="dataError.count_total_usage"
                     label="Сколько раз все пользователи могут применить промокод"/>
          <gui-input sm type="number" v-model="data.count_client_usage" :error="dataError.count_client_usage"
                     label="Сколько раз один пользователь может применить промокод"/>
          <gui-checkbox v-model="data.for_first_subscription" :error="dataError.for_first_subscription"
                        label="Применять только для тех у кого не была оформлена подписка"/>
          <gui-textarea v-model="data.comment" :error="dataError.comment"
                        label="Примечание"/>

          <div class="mt-5">
            <button-save v-if="!data.id" @click="add()"/>
            <button-save v-else @click="save"/>
            <button-back/>
          </div>
        </card-body>
      </div>
    </spinner-block>
  </card-block>
</template>

<script>


import GuiDiscountSelect from "@/components/Widget/GuiDiscountSelect.vue";

export default {
  components: {GuiDiscountSelect},
  data() {
    return {
      data: null,
      dataDefault: {
        code: '',
        is_active: '',
        start_at: '',
        end_at: '',
        for_first_subscription: '',
        count_total_usage: '',
        count_client_usage: '',
        discount_id: '',
        comment: '',
      },
      dataError: {
        code: '',
        is_active: '',
        start_at: '',
        end_at: '',
        for_first_subscription: '',
        count_total_usage: '',
        count_client_usage: '',
        discount_id: '',
        comment: '',
      },
      dataProgress: false,
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    }
  },
  mounted() {
    this.init()
  },
  methods: {

    init() {
      if (this.id) {
        this.data = null
        this.loadData()
      } else {
        this.data = _.clone(this.dataDefault)
      }
    },
    loadData() {
      axiosGetValue('/api/admin/promocode/get', {id: this.id}, e => this.data = e, e => this.dataProgress = e)
    },

    save() {
      axiosFast({
        url: route('/api/admin/promocode/update'),
        data: {data: this.data, id: this.data.id},
        error: this.dataError,
        progress: e => this.dataProgress = e,
        result: () => {
          toastSuccess('Данные сохранены');
          this.$router.push({name: 'promocode.detail', params: {id: this.data.id}})
        },
      })
    },
    add() {
      axiosFast({
        url: route('/api/admin/promocode/create'),
        data: {data: this.data},
        error: this.dataError,
        progress: e => this.dataProgress = e,
        result: (data) => {
          toastSuccess('Данные добавлены');
          this.$router.push({name: 'promocode.detail', params: {id: data.id}})
        },
      })
    },
  },
}
</script>
