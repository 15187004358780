<template>
  <div>
    <div v-if="data && data.data && data.data.length">
      <table class="table table-hover">
        <thead>
        <tr>
          <th>Платёж клиента</th>
          <th class="text-right">Сумма</th>
          <th class="d-none d-md-table-cell">Статус</th>
          <th class="d-none d-md-table-cell">Тип</th>
          <th class="d-none d-md-table-cell">Дата</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="row in data.data" :key="row.id">
          <td>
            <router-link :to="{name: 'payment.detail', params: {id: row.id}}" class="main-link">
              #{{ row.id }}
              {{ row.client ? row.client.initials : '—' }}
            </router-link>
          </td>
          <td class="d-none d-md-table-cell text-right">
            {{ row.amount / 100 }} руб.
          </td>
          <td class="d-none d-md-table-cell">
            {{ row.tinkoff_payment_status ? row.tinkoff_payment_status.name : '—' }}
          </td>
          <td class="d-none d-md-table-cell">
            {{ row.tinkoff_payment_type ? row.tinkoff_payment_type.name : '—' }}
          </td>
          <td class="d-none d-md-table-cell">
            {{ toTime(row.updated_at) }}
          </td>
          <td>
            <a href="#" @click.prevent="updatePayment(row)" v-if="can_refresh">
              <i class="fas fa-refresh"></i>
            </a>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div v-else class="alert alert-info">Список пуст</div>
  </div>
</template>

<script>

export default {
  components: {},
  data: function () {
    return {}
  },
  props: {
    data: Object,
    can_refresh: Boolean
  },
  methods: {
    updatePayment(row) {
      if (row.id) {
        axiosGetValue('/api/admin/payment/update', {id: row.id}, () => {
          this.$emit('update')
        })
      }
    },
  },
}
</script>