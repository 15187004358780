<template>
  <div>
    <spinner-block v-model="progress">
      <div class="card">
        <div class="card-header"><b>Добавьте список номеров в поле ниже или выберите файл для загрузки</b></div>
        <div class="card-body">
          <gui-textarea label="Список номеров" :placeholder="`+79112223344\n+79123334455`" v-model="row.phones"/>

          <gui-file class="mb-0" v-model="row.file" label="Файл для импорта csv или xlsx" clearable/>
          <div class="small text-secondary mb-3">
            Номера телефонов должны быть в первой колонке файла. Для csv разделитель ячеек - запятая.
          </div>

          <gui-select v-model="row.method"
                      label="Способ импорта"
                      :list="[{id:'append', name: 'Только добавить новые'}, {id:'sync', name: 'Полная синхронизация, клиенты не из списка удалятся'}]"/>

          <div class="mt-3">
            <button class="btn btn-primary" @click.prevent="send">Импортировать</button>
          </div>
        </div>
      </div>

      <div v-if="result" class="mt-5">
        <div class="mb-3"><b>Предварительный план импорта</b></div>


        <table class="table">
          <thead>
          <tr>
            <th class="pr-0" style="width: 1rem;"></th>
            <th>Телефон</th>
            <th class="text-right">
              <button class="btn btn-primary btn-sm" @click.prevent="executeAll">
                Выполнить все
              </button>
            </th>
          </tr>
          </thead>
          <tbody>
          <template v-for="(info, key) in result" :key="key">
            <template v-if="info.data && info.data.length">
              <tr>
                <td :class="`table-${info.type}`"></td>
                <td colspan="2" class="text-center">
                  {{ info.name }}
                  <div class="small">{{ info.description }}</div>
                </td>
              </tr>
              <tr v-for="row in info.data" :key="row.phone">
                <td :class="`table-${info.type}`"></td>
                <td>
                  <a v-if="row.client_id" :href="`/client/${row.client_id}`" target="_blank">{{ row.phone }}</a>
                  <div v-else>{{ row.phone }}</div>
                </td>
                <td class="text-right">
                  <template v-if="info.has_action">
                  <button v-if="row.is_done" class="btn btn-secondary btn-sm" disabled>Выполнено</button>
                  <button v-else class="btn btn-primary btn-sm" :disabled="row.in_progress"  @click.prevent="executeOne(info.code, row)">
                    Выполнить
                  </button>
                  </template>
                </td>
              </tr>
            </template>
          </template>
          </tbody>

        </table>

      </div>


    </spinner-block>
  </div>
</template>

<script>

export default {
  data: function () {
    return {
      progress: false,
      rowDefault: {
        phones: ``,
        file: null,
        method: 'append',
      },
      row: null,
      result: null,
    }
  },
  props: {
    data: Object
  },
  computed: {},
  beforeMount() {
    this.row = Object.assign({}, this.rowDefault)
  },
  mounted() {
  },
  methods: {
    send() {
      axiosFast({
        url: '/api/admin/business-company-clients/import',
        formData: Object.assign({}, this.row, {business_company_id: this.data.id}),
        progress: e => this.progress = e,
        result: async (result) => {
          this.result = result
        },
      })
    },
    executeAll(){
      let data = []
      for(let info of Object.values(this.result)){
        if(info.has_action){
          for(let row of info.data){
            data.push({code: info.code, phone: row.phone})
          }
        }
      }

      axiosFast({
        url: '/api/admin/business-company-clients/execute',
        data: {business_company_id: this.data.id, method: this.row.method, data: data},
        progress: e => this.progress = e,
        result: async () => {
          toastSuccess('Данные сохранены');
          this.row = Object.assign({}, this.rowDefault)
          this.result = null;
        },
      })

      console.log('data', data)
    },
    executeOne(code, row){
      let data = [{code, phone: row.phone}]
      axiosFast({
        url: '/api/admin/business-company-clients/execute',
        data: {business_company_id: this.data.id, method: this.row.method, data: data},
        progress: e => row.in_progress = e,
        result: async () => {
          row.is_done = true;
        },
      })
    }
  }
}
</script>
