<template>
  <gui-input-row :data="$props">
    <Multiselect3
        v-model="selected"
        :options="list"
        :placeholder="placeholder ? placeholder : label"
        :class="{'is-invalid': error && error.length > 0}"
        :valueProp="keyField"
        :label="textField"
        mode="tags"
        :searchable="true"
        :close-on-select="false"
        noResultsText="Список пуст"
    />
  </gui-input-row>
</template>


<script>

import Multiselect3 from '@vueform/multiselect'

export default {
  components: {Multiselect3},
  data: function () {
    return {
      search_text: '',
    }
  },
  props: {
    name: String,
    label: String,
    subInfo: String,
    placeholder: String,
    error: [String, Object, Array],
    append: String,
    prepend: String,
    edit: {
      type: Boolean,
      default: true
    },
    modelValue: Array,
    w: Boolean,
    list: Array,
    keyField: {
      type: String,
      default: 'id'
    },
    textField: {
      type: [String, Function],
      default: 'name'
    },
  },
  computed: {

    localList() {
      return this.list.map((k, i) => {
        let text = typeof this.textField === 'function' ? this.textField(k, i, this.list) : k[this.textField];
        return {id: k[this.keyField], text: text}
      });
    },

    selected: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val)
      }

    },

    filterList() {
      return this.localList.filter(i => {
        return i.text.toLowerCase().indexOf(this.search_text.toLowerCase()) !== -1;
      });
    },
  },
  mounted() {

  },
  methods: {}
}
</script>
