<template>
  <initials-badge v-if="!member" value="A A" title="Неизвестный отправитель"/>
  <a v-else-if="member.provider !== 'guest'"
     :href="`/${member.provider==='users' ? 'user' : (member.provider==='clients' ? 'client' : 'squad')}/${member.user_id}/detail`">
    <initials-badge :value="member ? member.name : null" :color="member.provider" :title="member.name"/>
  </a>
  <initials-badge v-else :value="member ? member.name : null" :color="member.provider" :title="member.name"/>
</template>

<script>
import InitialsBadge from "@/components/Badge/InitialsBadge";

export default {
  components: {InitialsBadge},
  props: {
    member: Object
  },
}
</script>