import EmptyWrap from "@/components/Gui/EmptyWrap";

export default [
    {
        path: 'payment',
        component: EmptyWrap,
        children: [
            {
                path: '',
                name: 'payment.list',
                component: require('@/views/Payment/PaymentList').default,
            },
            {
                path: ':id',
                component: require('@/views/Payment/Card/WrapBlock').default,
                children: [
                    {
                        path: 'detail',
                        name: 'payment.detail',
                        component: require('@/views/Payment/Card/PaymentDetail').default,
                    },
                    {
                        path: 'history',
                        name: 'payment.history',
                        component: require('@/views/Payment/Card/HistoryTab').default,
                    },
                    {
                        path: 'receipt',
                        name: 'payment.receipt',
                        component: require('@/views/Payment/Card/ReceiptTab').default,
                    },
                ]
            },
        ]
    },
]