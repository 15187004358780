<template>
  <div>
    <spinner-block v-model="dataProgress">
      <div v-if="data">
        <gui-checkbox v-model="data.is_active" :error="dataError.is_active" label="Активный"/>
        <gui-input v-model="data.name" :error="dataError.name" label="Название"/>
        <gui-city-select v-model="data.city_id" label="Город" :error="dataError.city_id"/>

        <label class="col-form-label semi-small">Область</label>
        <map-polygon :polygons="data.area_array" editable @change="e=>data.area_array=e"/>

        <div class="mt-5">
          <button-save v-if="!data.id" @click="add()"/>
          <button-save v-else @click="save"/>
          <button-cancel @click="$emit('cancel')"/>
        </div>
      </div>
    </spinner-block>
  </div>
</template>

<script>


import GuiCitySelect from "@/components/Widget/GuiCitySelect";
import MapPolygon from "@/views/Map/MapPolygon";

export default {
  components: {MapPolygon, GuiCitySelect},
  data() {
    return {
      data: null,
      dataDefault: {
        name: '',
        area_array: '',
        is_active: false,
        city_id: '',
      },
      dataError: {
        name: '',
        area_array: '',
        is_active: '',
        city_id: '',
      },
      dataProgress: false,
    }
  },
  props: {
    id: [String, Number],
    defaultParams: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
  },
  methods: {

    init() {
      if (this.id) {
        this.data = null
        this.loadData()
      } else {
        this.data = Object.assign({}, this.dataDefault, this.defaultParams)
      }
      clearErrorList(this.dataError)
    },
    loadData() {
      axiosGetValue('/api/admin/district/get', {id: this.id}, e => this.data = e, e => this.dataProgress = e)
    },

    save() {
      axiosFast({
        url: route('/api/admin/district/update'),
        data: {data: this.data, id: this.data.id},
        error: this.dataError,
        progress: e => this.dataProgress = e,
        result: () => {
          toastSuccess('Данные сохранены');
          this.$emit('save', this.data.id)
        },
      })
    },
    add() {
      axiosFast({
        url: route('/api/admin/district/create'),
        data: {data: this.data},
        error: this.dataError,
        progress: e => this.dataProgress = e,
        result: (data) => {
          toastSuccess('Данные добавлены');
          this.$emit('add', data.id)
        },
      })
    },
  },
}
</script>