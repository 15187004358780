<template>
  <div>
    <div class="row">

      <div class="col-12 col-md-9">
        <card-field title="Название">
          <i class="fas fa-circle small"
             :title="data.is_active ? 'Активен' : 'Неактивен'"
             :class="data.is_active ? 'text-success' : 'text-danger'"></i>
          {{ data.code }}
        </card-field>

        <card-field :modelValue="data.application && data.application.name" title="Тип приложения"/>
        <card-field :modelValue="data.version" title="Версия"/>
        <card-field title="ID раздела FAQ">
          ID: {{data.question_section_id || '—'}}<br/>
          {{data.question_section ? data.question_section.name : '—'}}
        </card-field>
        <card-field title="Примечание">
          <div v-html="nl2br(data.note)"></div>
        </card-field>
      </div>
    </div>

    <div class="mt-3">

      <div class="float-right">
        <button-remove @click="remove" v-if="can('module.release_note.delete')"/>
      </div>


      <button-edit @click="$router.push({name: 'release_note.edit', params: {id: data.id}})"
                   v-if="can('module.release_note.update')"/>
    </div>
  </div>
</template>

<script>


export default {
  data() {
    return {}
  },
  props: {
    data: Object
  },
  mounted() {
  },
  methods: {

    remove() {
      $.alertConfirm({
        type: 'red',
      }, {
        ok: () => {
          axiosGetValue('/api/admin/release-note/delete', {id: this.data.id}, () => {
            toastSuccess('Удаление завершено');
            this.$router.push({name: 'release_note.list'})
          })
        }
      });
    },
  },
}
</script>
