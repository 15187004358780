<template>
  <div>

    <div class="mb-3 text-right">
      <button-create @click="$refs.modal.openModal()" v-if="can('module.district.create')"/>
    </div>

    <spinner-block v-model="progress">

      <div v-if="listData && listData.data && listData.data.length">
        <table class="table table-hover">
          <thead>
          <tr>
            <th>Районы</th>
            <th class="text-right"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="row in listData.data" :key="row.id">
            <td>
              <i class="fas fa-circle small"
                 :title="row.is_active ? 'Активен' : 'Неактивен'"
                 :class="row.is_active ? 'text-success' : 'text-danger'"></i>&nbsp;
              <router-link :to="{name: 'district.detail', params: {id: row.id}}" class="main-link">
                {{ row.name ? row.name : '—' }}
              </router-link>
            </td>
            <td class="text-right">
              <button-edit icon sm @click="$refs.modal.openModal(row.id)" v-if="can('module.district.update')"/>
              <button-remove icon sm @click="remove(row)" v-if="can('module.district.delete')"/>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div v-else class="alert alert-info">Список пуст</div>
    </spinner-block>
    <gui-paginate :data="listData" ref="paginate" @change="getList"/>
    <create-or-update-district-modal ref="modal" :user="data" @update="getList"
                                     :defaultParams="{city_id: this.data.id}"/>
  </div>
</template>

<script>
import CreateOrUpdateDistrictModal from "@/views/City/Block/CreateOrUpdateDistrictModal";

export default {
  components: {CreateOrUpdateDistrictModal},
  data() {
    return {
      listData: null,
      progress: null,
      filter: {
        city_id: null,
      }
    }
  },
  props: {
    data: Object,
  },
  mounted() {
    this.filter.city_id = this.data.id
    this.getList()
  },
  methods: {
    getList() {
      axiosGetValue('/api/admin/district/list', {
            filter: this.filter,
            page: this.$refs.paginate.currentPage,
            per_page: this.$refs.paginate.perPage,
          },
          e => this.listData = e, e => this.progress = e
      )
    },
    remove(row) {
      $.alertConfirm({}, {
        ok: () => {
          axiosGetValue('/api/admin/district/delete', {id: row.id, city_id: row.city_id}, () => {
            toastSuccess('Удаление завершено');
            this.getList();
          })
        }
      });
    },
  }
}
</script>