import EmptyWrap from "@/components/Gui/EmptyWrap";

export default [
    {
        path: 'discount',
        component: EmptyWrap,
        children: [
            {
                path: '',
                name: 'discount.list',
                component: require('@/views/Discount/DiscountList').default,
            },
            {
                path: 'create',
                name: 'discount.create',
                component: require('@/views/Discount/DiscountForm').default,
            },
            {
                path: ':id',
                component: require('@/views/Discount/Card/WrapBlock').default,
                children: [
                    {
                        path: 'detail',
                        name: 'discount.detail',
                        component: require('@/views/Discount/Card/DiscountDetail').default,
                    },
                    {
                        path: 'promocode',
                        name: 'discount.promocode',
                        component: require('@/views/Discount/Card/PromocodeTab.vue').default,
                    },
                    {
                        path: 'promocode-use',
                        name: 'discount.promocode-use',
                        component: require('@/views/Discount/Card/PromocodeUseTab.vue').default,
                    },
                ]
            },
            {
                path: ':id/edit',
                name: 'discount.edit',
                component: require('@/views/Discount/DiscountForm').default,
            },
        ]
    },
]
