<template>
  <card-block>
    <card-header>
      Список смен отрядов
      <template v-slot:right>

      </template>
    </card-header>
    <card-body>
      <spinner-block v-model="progress">
        <div class="mb-3 row">
          <div class="col-12 col-md-2">
            <div class="small">Статус</div>
            <gui-select sm v-model="filter.status" @change="getList" :hilightOn="['all']">
              <option value="all">Все</option>
              <option value="started">В работе</option>
              <option value="breaks">На перерыве</option>
              <option value="ended">Завершил</option>
            </gui-select>
          </div>
          <div class="col-12 col-md-5">
          </div>
        </div>
        <squad-duty-list-block :data="data"/>
      </spinner-block>
      <gui-paginate :data="data" ref="paginate" @change="getList"/>
      <p v-if="!can('module.organization.view_all-view')" class="text-secondary mt-3">
        Результат показан с учётом ограничения по организации
      </p>
    </card-body>
  </card-block>
</template>

<script>

import SquadDutyListBlock from "@/views/SquadDuty/SquadDutyListBlock";

export default {
  components: {SquadDutyListBlock},
  data: function () {
    return {
      data: null,
      progress: true,
      filter: {
        status: 'all',
      }
    }
  },
  props: {},
  computed: {},
  beforeMount() {
    this.filter = storageLoad('filter_' + this.$route.name, this.filter);
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {

      let v = _.clone(this.filter);
      v.search = '';
      storageSet('filter_' + this.$route.name, v);

      axiosGetValue('/api/admin/squad-duty/list', {
            page: this.$refs.paginate.currentPage,
            per_page: this.$refs.paginate.perPage,
            filter: this.filter,
          },
          e => this.data = e, e => this.progress = e
      )
    }
  }
}
</script>