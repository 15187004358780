import EmptyWrap from "@/components/Gui/EmptyWrap";

export default [
    {
        path: 'mobile-app',
        component: EmptyWrap,
        children: [
            {
                path: '',
                name: 'mobile-app.list',
                component: require('@/views/MobileApp/MobileAppList').default,
            },
        ]
    },
]