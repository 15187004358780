<template>

  <gui-input-row :data="$props">
    <input
        :disabled="!edit"
        ref="input"
        type="file"
        :name="name"
        :id="name"
        class="form-control form-control-file"
        :class="error && error.length > 0 ? 'is-invalid' : ''"
        :placeholder="label"
        @change="fileGetSet = $event"
    />

    <slot v-if="clearable && modelValue" name="after">
      <div class="input-group-append">
        <button class="btn btn-sm btn-danger" @click.prevent="fileGetSet=''">
          <i class="fas fa-times"></i>
        </button>
      </div>
    </slot>

  </gui-input-row>

</template>

<script>
export default {
  components: {},
  data: function () {
    return {}
  },
  props: {
    name: String,
    label: String,
    error: [String, Object, Array],
    type: String,
    append: String,
    prepend: String,
    edit: {
      type: Boolean,
      default: true
    },
    modelValue: {
      type: [String, File, Boolean],
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    }
  },
  computed: {

    fileGetSet: {
      get: function () {
        return this.modelValue;
      },
      set: function (newVal) {
        if (!newVal) this.$refs.input.value = [];
        this.$emit('update:modelValue', newVal && newVal.target.files ? newVal.target.files[0] : null);
      }
    }


  },
  mounted() {
  },
  methods: {},
  watch: {
    modelValue: function () {
      if (!this.modelValue) this.$refs.input.value = [];
    }
  }
}
</script>
