<template>
  <div>
    <div ref="modal" style="display: none">
      <spinner-block v-model="dataProgress">
        <div v-if="client_call && cancel_info && payments">
          <div class="mb-3">
            <div>
              <b>Частичный возврат стоимости вызова</b>
            </div>

          </div>

          <div class="alert alert-secondary">
            Если требуется выполнить полный возврат платежа, лучше воспользоваться
            соответствующим функционалом в карточке платежа!
          </div>

          <div class="mb-3">
            Будет возвращено на карту клиента <b>{{ payments.all.subAmount }}р</b>, из них:<br/>
            <template v-if="payments.squad.subAmount > 0">
              <template v-if="payments.squad.payment">
                - {{ payments.squad.subAmount }}р из платежа отряда
                <router-link :to="{name: 'payment.detail', params:{id: payments.squad.payment.id}}">
                  #{{ payments.squad.payment.id }}
                </router-link>
                <br/>
              </template>
              <template v-else>
                <b class="text-danger">
                  - {{ payments.squad.subAmount }}р из платежа отряда. Нет подходящих платежей откуда можно сделать
                  возврат
                </b>
              </template>
            </template>


            <template v-if="payments.service.subAmount > 0">
              <template v-if="payments.service.payment">
                - {{ payments.service.subAmount }}р из платежа сервиса
                <router-link :to="{name: 'payment.detail', params:{id: payments.service.payment.id}}">
                  #{{ payments.service.payment.id }}
                </router-link>
                <br/>
              </template>
              <template v-else>
                <b class="text-danger">
                  - {{ payments.service.subAmount }}р из платежа сервиса. Нет подходящих платежей откуда можно сделать
                  возврат
                </b>
              </template>
            </template>
          </div>

          <div class="mt-5">
            <button-save @click="save" text="Сделать возврат" :disabled="!canProcess"/>
            <button-cancel @click="fancyboxClose"/>
          </div>
        </div>
      </spinner-block>
    </div>
  </div>
</template>

<script>

export default {
  components: {},
  data: function () {
    return {
      client_call: null,
      cancel_info: null,
      payments: null,
      dataProgress: false,
      dataError: {},
    }
  },
  props: {},
  computed: {
    canSquadProcess() {
      return this.payments.squad.subAmount > 0 && this.payments.squad.payment;
    },
    canServiceProcess() {
      return this.payments.service.subAmount > 0 && this.payments.service.payment;
    },
    canProcess() {
      if (!this.payments) return false

      return (this.canSquadProcess || this.payments.squad.subAmount == 0) &&
          (this.canServiceProcess || this.payments.service.subAmount == 0)
    }
  },
  mounted() {
  },
  methods: {
    loadData(id) {
      axiosGetValue('/api/admin/client-call/cancel-info', {id}, e => {
        this.client_call = e.client_call
        this.cancel_info = e.cancel_info
        this.payments = e.payments

      }, e => this.dataProgress = e)
    },
    openModal(data_id) {
      if (data_id)
        this.loadData(data_id)

      this.fancybox(this.$refs.modal, () => {
        this.debit_info = null
        this.client_call = null
        this.client_cards = null
        this.data = {
          id: null,
          client_card_id: null,
        }
        clearErrorList(this.dataError)
      });
    },
    save() {

      if (!this.canProcess) {
        return
      }

      let data = {
        id: this.client_call.id
      }

      if (this.canSquadProcess) {
        data.squad = {
          subAmount: this.payments.squad.subAmount,
          paymentId: this.payments.squad.payment.id,
        }
      }

      if (this.canServiceProcess) {
        data.service = {
          subAmount: this.payments.service.subAmount,
          paymentId: this.payments.service.payment.id,
        }
      }

      $.alertConfirmForce({}, {
        ok: () => {
          axiosFast({
            url: route('/api/admin/client-call/cancel-pay'),
            data: data,
            error: this.dataError,
            progress: e => this.dataProgress = e,
            result: () => {
              toastSuccess('Оплата прошла');
              this.$emit('update')
              this.fancyboxClose();
            },
          })
        }
      });
    }
  }
}
</script>