<template>
  <div>
    <div class="alert alert-warning" v-if="data.is_test_user">
      Тестовый пользователь
    </div>
    <div class="alert alert-warning" v-if="data.is_health_check">
      Health check
    </div>
    <div class="row">
      <div class="d-block d-md-none col-md-3 mb-3">
        <img :src="data.photo_url ? data.photo_url : '/images/no-photo.jpg'" class="img-thumbnail avatar-middle"/>
      </div>
      <div class="col-12 col-md-5">
        <card-field title="Имя">
          <i class="fas fa-circle small"
             :title="data.is_active ? 'Активен' : 'Неактивен'"
             :class="data.is_active ? 'text-success' : 'text-danger'"></i>
          {{ data.fullName ? data.fullName : '—' }}
        </card-field>
        <card-field :modelValue="data.is_beta ? 'Да' : 'Нет'" title="Пользователь бета тестирования"/>
        <card-field :modelValue="data.phone" title="Телефон"/>
        <card-field :modelValue="data.email" title="Email"/>
        <card-field :modelValue="data.user_vk_id" title="User vk id"/>
        <card-field :modelValue="toTime(data.phone_verified_at)" title="Дата подтверждения телефона"/>
        <card-field :modelValue="toDate(data.birth)" title="Дата рождения"/>
        <card-field :modelValue="data.gender_name" title="Пол"/>
      </div>
      <div class="col-12 col-md-4">
        <card-field :modelValue="data.emergency_started_at ? data.emergency_started_at : '—'"
                    title="Тревожная обстановка"/>
        <card-field :modelValue="data.client_status ? data.client_status.name : '—'" title="Статус"/>
        <card-field :modelValue="data.count_canceled_calls" title="Количество отменённых звонков"/>
        <card-field :modelValue="data.amount_receivables" title="Дебиторская задолженность"/>

        <card-field title="Компания">
          <template v-if="data.business_company">
            <router-link :to="{name: 'business_company.detail', params: {id: data.business_company.id}}">
              {{ data.business_company.name || '—' }}
            </router-link>
            <div class="small">
              {{ data.client_b_c_status && data.client_b_c_status.name ? data.client_b_c_status.name : '—' }}
            </div>
          </template>
          <div v-else>
            Пользователь не привязан к компании
          </div>
        </card-field>

        <card-field :modelValue="data.show_geo ? 'Да' : 'Нет'" title="Показывать мою геопозицию в семейной подписке"/>
        <template v-if="data.app_metrica && data.app_metrica.length !== 0">
          <div>Данные по метрике:</div>
          <table>
            <tr v-for="item in data.app_metrica" :key="item.id">
              <td>
                {{ item.metrica_id }} - {{ toTime(item.updated_at) }}
              </td>
            </tr>
          </table>
        </template>
        <template v-else>
          <div>Данные по метрике отсутствуют</div>
        </template>
      </div>

      <div class="d-none d-md-block col-md-3 text-right">
        <img :src="data.photo_url ? data.photo_url : '/images/no-photo.jpg'" class="img-thumbnail avatar-middle"/>
      </div>
      <div class="col-12">

        <card-field :modelValue="data.is_validated ? 'Да' : 'Нет'" title="Все обязательные документы подтверждены"/>
        <card-field :modelValue="data.special_signs" title="Особые признаки"/>
        <card-field :modelValue="data.reason_for_blocking" title="Причина блокировки"/>
      </div>
    </div>

    <div class="mt-3">

      <div class="float-right">
        <button-remove @click="remove" v-if="can('module.client.delete')"/>
      </div>


      <button-edit @click="$router.push({name: 'client.edit', params: {id: data.id}})"
                   v-if="can('module.client.update')"/>

      <button class="btn btn-secondary" @click.prevent="clearCache" v-if="can('module.client.update')">
        Очистить кеш
      </button>
    </div>
  </div>
</template>

<script>


export default {
  data() {
    return {}
  },
  props: {
    data: Object
  },
  mounted() {
  },
  methods: {

    remove() {
      $.alertConfirm({
        type: 'red',
      }, {
        ok: () => {
          axiosGetValue('/api/admin/client/delete', {id: this.data.id}, () => {
            toastSuccess('Удаление завершено');
            this.$router.push({name: 'client.list'})
          })
        }
      });
    },

    clearCache() {
      $.alertConfirm({
        content: 'Использовать в случае когда информация в мобильном приложении отличается от данных в административной панеле. Продолжить?'
      }, {
        ok: () => {
          axiosGetValue('/api/admin/client/clear-cache', {id: this.data.id}, () => {
            toastSuccess('Кеш очищен');
          })
        }
      });
    }

  },
}
</script>
