<template>
  <div>
    <spinner-block v-model="dataProgress">
      <div v-if="data">
        <gui-checkbox v-model="data.active" :error="dataError.active" label="Активный"/>
        <gui-question-parent-select v-model="data.parent_section_id" :error="dataError.parent_section_id" label="Раздел"
                                    :application_type_id="data.application_type_id"/>
        <gui-input v-model="data.name" :error="dataError.name" label="Название"/>

        <gui-textarea v-if="data.type_question_id==2" v-model="data.text" :error="dataError.text" label="Ответ"/>
        <gui-html v-if="data.type_question_id==5" v-model="data.text" :error="dataError.text" label="Ответ"/>
        <gui-input v-if="data.type_question_id==3" v-model="data.link" :error="dataError.link" label="Ссылка"/>
        <gui-deeplink-select v-if="data.type_question_id==4" v-model="data.link" :error="dataError.link" label="Ссылка" :application="data.application_type_id == 1 ? 'client' : 'squad'"/>
        <gui-input type="number" v-model="data.sort" :error="dataError.sort" label="Сортировка"/>

        <div class="mt-5">
          <button-save @click="save"/>
          <button-cancel @click="$emit('cancel')"/>
        </div>
      </div>
    </spinner-block>
  </div>
</template>

<script>


import GuiQuestionParentSelect from "@/components/Widget/GuiQuestionParentSelect.vue";
import GuiDeeplinkSelect from "@/components/Widget/GuiDeeplinkSelect.vue";

export default {
  components: {GuiDeeplinkSelect, GuiQuestionParentSelect},
  data() {
    return {
      data: null,
      dataDefault: {
        name: null,
        link: null,
        text: null,
        active: true,
        sort: 100,
        application_type_id: 1,
        parent_section_id: null
      },
      dataError: {
        name: '',
        link: '',
        text: '',
        active: '',
        sort: '',
        parent_section_id: '',
      },
      dataProgress: false,
    }
  },
  props: {
    id: [String, Number],
    defaultParams: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
  },
  methods: {

    init() {
      if (this.id) {
        this.data = null
        this.loadData()
      } else {
        this.data = Object.assign({}, this.dataDefault, this.defaultParams)
      }
      clearErrorList(this.dataError)
    },
    loadData() {
      axiosGetValue('/api/admin/question/get', {id: this.id}, e => {
        if (e.parent_section_id === null) {
          e.parent_section_id = ''
        }

        this.data = e
      }, e => this.dataProgress = e)
    },

    save() {
      if (this.data.type_question_id != 2 && this.data.type_question_id != 5) {
        this.data.text = null
      }
      if (this.data.type_question_id != 3 && this.data.type_question_id != 4) {
        this.data.link = null
      }

      axiosFast({
        url: route('/api/admin/question/update'),
        data: {data: this.data, id: this.data.id},
        error: this.dataError,
        progress: e => this.dataProgress = e,
        result: () => {
          toastSuccess('Данные сохранены');
          this.$emit('save', this.data.id)
        },
      })
    },
  },
}
</script>
