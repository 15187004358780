<template>
  <div ref="modal" style="display: none">
    <div ref="map" style="width: 100%; height: 500px; max-height: 100% ">
    </div>
    <div class="mt-3 row">
      <div class="col-12 text-right">
        <div v-if="mapParams.center">
          <div class="text-secondary small">
            центр экрана
          </div>
          {{ mapParams.center[1].toFixed(5) }},
          {{ mapParams.center[0].toFixed(5) }}

        </div>
        <div v-if="cursor">
          <div class="text-secondary small">
            курсор
          </div>
          {{ cursor[1].toFixed(5) }}, {{ cursor[0].toFixed(5) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {Feature, Map, View} from 'ol/index.js';
import {Tile as TileLayer, Vector as VectorLayer} from "ol/layer";
import {OSM, Vector as VectorSource} from "ol/source";
import {useGeographic} from 'ol/proj.js';
import {Point} from "ol/geom";
import {Circle as CircleStyle, Fill, Stroke, Style} from "ol/style";

useGeographic();

export default {
  data: function () {
    return {
      geo: null,
      zoom: 11,
      pointLayers: new VectorLayer({}),
      map: null,
      mapParams: {
        zoom: null,
        center: null,
      },
      cursor: null, //Текущая позиция
    }
  },
  props: {},
  computed: {},
  mounted() {

  },
  methods: {
    openModal(geo) {
      this.geo = [geo.lon, geo.lat]
      this.mapParams = {
        zoom: 11,
        center: [geo.lon, geo.lat]
      }
      this.fancybox(this.$refs.modal, () => {
        if (this.map) {
          this.map.setTarget(undefined)
          this.map = null
        }
      })
      setTimeout(() => {
        this.initMap()
      }, 0)
    },
    initMap() {

      this.map = new Map({
        target: this.$refs.map,
        view: new View(this.mapParams),
        layers: [
          new TileLayer({source: new OSM()}),
          this.pointLayers,
        ]
      });

      this.map.on('moveend', this.mapMoveEnd);

      this.map.on('pointermove', this.mapPointerMove);

      this.setPoint(this.geo)
    },
    setPoint(geo) {
      let feature = new Feature(new Point(geo))
      feature.setStyle(new Style({
        image: new CircleStyle({
          radius: 8,
          stroke: new Stroke({
            color: '#000',
            width: 1
          }),
          fill: new Fill({
            color: '#28a745',
          }),
        }),
      }))
      this.pointLayers.setSource(new VectorSource({features: [feature]}))
    },
    mapPointerMove(event) {
      this.cursor = event.coordinate
    },
    mapMoveEnd() {
      this.mapParams.center = this.map.getView().getCenter();
      this.mapParams.zoom = this.map.getView().getZoom();
    },
  }
}
</script>