<template>
  <div>
    <spinner-block v-model="dataProgress">
      <div v-if="data">
        <gui-checkbox v-model="data.is_active" :error="dataError.is_active" label="Активный"/>
        <gui-input v-model="data.name" :error="dataError.name" label="Название"/>
        <gui-input v-model="data.code" :error="dataError.code" label="Код"/>
        <gui-input v-model="data.sort" :error="dataError.sort" label="Сортировка"/>

        <gui-region-select v-model="data.region_id" label="Регион" :error="dataError.region_id"/>
        <gui-squad-search-therms-select v-model="data.squad_search_term_id" label="Условия поиска"
                                        :error="dataError.squad_search_term_id"/>

        <gui-checkbox v-model="data.use_districts" :error="dataError.use_districts" label="Использовать районы"/>


        <map-polygon :polygons="data.area_array" editable @change="e=>data.area_array=e"/>

        <div class="mt-5">
          <button-save v-if="!data.id" @click="add()"/>
          <button-save v-else @click="save"/>
          <button-cancel @click="$emit('cancel')"/>
        </div>
      </div>
    </spinner-block>
  </div>
</template>

<script>


import GuiSquadSearchThermsSelect from "@/components/Widget/GuiSquadSearchTermsSelect";
import GuiRegionSelect from "@/components/Widget/GuiRegionSelect";
import MapPolygon from "@/views/Map/MapPolygon";

export default {
  components: {MapPolygon, GuiRegionSelect, GuiSquadSearchThermsSelect},
  data() {
    return {
      data: null,
      dataDefault: {
        name: '',
        code: '',
        sort: 100,
        is_active: '',
        use_districts: false,
        region_id: '',
        area_array: '',
        squad_search_term_id: '',
      },
      dataError: {
        name: '',
        code: '',
        is_active: '',
        use_districts: '',
        sort: '',
        area_array: '',
        region_id: '',
        squad_search_term_id: '',
      },
      dataProgress: false,
    }
  },
  props: {
    id: [String, Number],
    defaultParams: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
  },
  methods: {

    init() {
      if (this.id) {
        this.data = null
        this.loadData()
      } else {
        this.data = Object.assign({}, this.dataDefault, this.defaultParams)
      }
      clearErrorList(this.dataError)
    },
    loadData() {
      axiosGetValue('/api/admin/city/get', {id: this.id}, e => this.data = e, e => this.dataProgress = e)
    },

    save() {
      axiosFast({
        url: route('/api/admin/city/update'),
        data: {data: this.data, id: this.data.id},
        error: this.dataError,
        progress: e => this.dataProgress = e,
        result: () => {
          toastSuccess('Данные сохранены');
          this.$emit('save', this.data.id)
        },
      })
    },
    add() {
      axiosFast({
        url: route('/api/admin/city/create'),
        data: {data: this.data},
        error: this.dataError,
        progress: e => this.dataProgress = e,
        result: (data) => {
          toastSuccess('Данные добавлены');
          this.$emit('add', data.id)
        },
      })
    },
  },
}
</script>