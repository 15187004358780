<template>

  <gui-input-row :data="$props">
    <div class="bd p-2 cursor-pointer" style="position: relative; flex: 1 1 auto;"
         @click.prevent="$refs.input.click()">
      <span v-if="modelValue">{{ modelValue.name || 'Файл выбран' }}</span>
      <span v-else-if="file">{{ file.name }}</span>
      <span v-else>Выберите файл</span>
    </div>
    <input
        style="display: none;"
        :disabled="!edit"
        ref="input"
        type="file"
        :name="name"
        :id="name"
        class=""
        :class="error && error.length > 0 ? 'is-invalid' : ''"
        :placeholder="label"
        @change="fileGetSet = $event"
    />

    <slot name="after">
      <div class="input-group-append">

        <a v-if="!modelValue && file && (file.url || file.url_see)"
           class="btn btn-sm btn-secondary px-2 py-2 line-height-260"
           :href="(file.url || '#')" :data-fancybox="!!file.url">
          <i class="fas fa-link"></i>
        </a>
        <button v-if="modelValue || file" class="btn btn-sm btn-danger" @click.prevent="closeClick">
          <i class="fas fa-times"></i>
        </button>
      </div>
    </slot>

  </gui-input-row>

</template>

<script>
export default {
  components: {},
  data: function () {
    return {}
  },
  props: {
    file: [Object, String], // {name: filename, url: file_src, url_see: file_src_prf},
    picked_file_name: String,
    name: String,
    label: String,
    error: [String, Object, Array],
    type: String,
    append: String,
    prepend: String,
    edit: {
      type: Boolean,
      default: true
    },
    modelValue: {
      type: [String, File, Boolean],
      default: false
    }
  },
  computed: {

    fileGetSet: {
      get: function () {
        return this.modelValue;
      },
      set: function (newVal) {
        if (!newVal) this.$refs.input.value = [];
        this.$emit('update:modelValue', newVal && newVal.target.files ? newVal.target.files[0] : null);
      }
    }


  },
  mounted() {
  },
  methods: {
    closeClick() {

      if (this.modelValue) {
        this.fileGetSet = '';
      } else if (this.file) {
        this.$emit('delete')
      }

    }
  },
  watch: {
    modelValue: function () {
      if (!this.modelValue) this.$refs.input.value = [];
    }
  }
}
</script>
