<template>
  <div>
    <div ref="modal" style="display: none">
      <spinner-block v-model="dataProgress">
        <div v-if="amount && subscription && client_cards">
          <div class="mb-3">
            <div>
              <b>Оплата подпписки</b>
            </div>
            Сумма задолженности будет списана с карты клиента. Обратите внимание что списание происходит без
            подтверждения клиентом.<br/>
            Убедитесь что списываете деньги с его счёта с его согласия.
          </div>

          <div class="mb-3">
            Будет списано <b>{{ +amount }}р</b>
          </div>

          <gui-select v-model="data.client_card_id" label="Карта клиента" name_prop="pan" :list="client_cards">
            <option value="" disabled>Выберите карту</option>
          </gui-select>


          <div class="mt-5">
            <button-save @click="save" text="Оплатить" :disabled="!(amount >= 0)"/>
            <button-cancel @click="fancyboxClose"/>
          </div>
        </div>
      </spinner-block>
    </div>
  </div>
</template>

<script>

export default {
  components: {},
  data: function () {
    return {
      amount: null,
      subscription: null,
      client_cards: null,
      data: {
        id: null,
        client_card_id: null,
      },
      dataProgress: false,
      dataError: {},
    }
  },
  props: {},
  mounted() {
  },
  methods: {
    loadData(id) {
      axiosGetValue('/api/admin/subscription/debit-info', {id}, e => {
        this.amount = e.amount
        this.subscription = e.subscription
        this.client_cards = e.client_cards
        this.data.id = this.subscription.id

        this.client_cards.forEach(e => {
          if (e.is_main) {
            this.data.client_card_id = e.id
          }
        })

      }, e => this.dataProgress = e)
    },
    openModal(data_id) {
      if (data_id)
        this.loadData(data_id)

      this.fancybox(this.$refs.modal, () => {
        this.debit_info = null
        this.subscription = null
        this.client_cards = null
        this.data = {
          id: null,
          client_card_id: null,
        }
        clearErrorList(this.dataError)
      });
    },
    save() {
      $.alertConfirmForce({}, {
        ok: () => {
          axiosFast({
            url: route('/api/admin/subscription/pay-subscription'),
            data: this.data,
            error: this.dataError,
            progress: e => this.dataProgress = e,
            result: () => {
              toastSuccess('Оплата прошла');
              this.$emit('update')
              this.fancyboxClose();
            },
          })
        }
      });
    }
  }
}
</script>