<template>
  <div>
    <spinner-block v-model="dataProgress">
      <div v-if="data">


        <gui-client-document-type v-model="data.type" :error="dataError.type" label="Тип документа"/>

        <gui-file-picker v-model="data.photo_new" :error="dataError.photo_new" label="Фото документа"
                         :file="data.photo ? {name: data.photo, url: data.photo_url} : null"
                         @delete="data.photo=null; data.photo_delete=true"/>

        <div class="mt-5">
          <button-save v-if="!data.id" @click="add()"/>
          <button-save v-else @click="save"/>
          <button-cancel @click="$emit('cancel')"/>
        </div>
      </div>
    </spinner-block>
  </div>
</template>

<script>


import GuiClientDocumentType from "@/components/Widget/GuiClientDocumentTypeSelect.vue";

export default {
  components: {GuiClientDocumentType},
  data() {
    return {
      data: null,
      dataDefault: {
        photo: '',
        photo_new: '',
        photo_delete: false,
        type: '',
        client_id: '',
      },
      dataError: {
        photo_new: '',
        type: '',
        client_id: '',
      },
      dataProgress: false,
    }
  },
  props: {
    id: [String, Number],
    defaultParams: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {

    init() {
      if (this.id) {
        this.data = null
        this.loadData()
      } else {
        this.data = Object.assign({}, this.dataDefault, this.defaultParams)
      }
      clearErrorList(this.dataError)
    },
    loadData() {
      console.error('Нельзя редактировать')
    },
    save() {
      console.error('Нельзя редактировать')
    },
    add() {
      axiosFast({
        url: route('/api/admin/client-document/create'),
        formData: {data: this.data},
        error: this.dataError,
        progress: e => this.dataProgress = e,
        result: () => {
          toastSuccess('Данные добавлены');
          this.$emit('add', null)
        },
      })
    },
  },
}
</script>
