<template>
  <div style="position: relative;">
    <div class="form-group row">
      <div class="col-12 col-md-3">
        <label class="col-form-label">Поиск клиента</label>
      </div>
      <div class="col-12 col-md-9">
        <input
            type="text"
            id="searchInput"
            v-model="search"
            @input="handleSearchInput"
            class="input-search-custom form-control"
        />
      </div>
    </div>
    <gui-select
        ref="clientSelect"
        :modelValue="modelValue"
        @update:modelValue="e => $emit('update:modelValue', e)"
        :label="label"
        :error="error"
        :hilightOn="hilightOn"
        :w="w"
        :sm="sm"
        :list="list"
        class="select-search-custom"
    >
      <option value="" disabled>Выберите клиента</option>
    </gui-select>
  </div>
</template>
<script>
export default {
  name: 'gui-client-select',
  data() {
    return {
      list: [],
      search: '',
    }
  },
  props: {
    label: String,
    error: [String, Object, Array],
    hilightOn: [Array, String, Number],
    modelValue: {
      type: [String, Number],
    },
    w: Boolean,
    sm: Boolean,
  },
  mounted() {
    this.loadList();
  },
  methods: {
    async loadList() {
      await axiosGetValue('/api/admin/client/list', {
        per_page: 15,
        filter: {
          search: this.search,
        }
      }, e => {
        this.list = e.data.map(item => ({
          id: item.id,
          name: item.phone,
        }));
      }, e => this.dataProgress = e);
    },
    async handleSearchInput() {
      await this.loadList();
      this.$refs.clientSelect.$el

      const select = this.$refs.clientSelect.$el.querySelector('select')
      let toggleState = true;
      toggleState ? (select.size = select.length) : (select.size = 1)
    },
  }
}
</script>