<template>
  <div>
    <div class="alert alert-warning" v-if="data.is_health_check">
      Health check
    </div>
    <div class="row">
      <div class="col-12">
        <card-field title="Название">
          <i class="fas fa-circle small"
             :title="data.is_active ? 'Активен' : 'Неактивен'"
             :class="data.is_active ? 'text-success' : 'text-danger'"></i>
          {{ data.name }}
        </card-field>


        <card-field :modelValue="data.city ? data.city.name : '—'" title="Город">
          <template v-if="data.city">
            <router-link :to="{name: 'city.detail', params: {id: data.city_id}}">
              {{ data.city.name }}
            </router-link>
          </template>
          <template v-else>—</template>
        </card-field>
      </div>

      <div class="col-12">
        <label class="col-form-label semi-small">Область</label>
        <map-polygon :polygons="data.area_array"/>
      </div>
    </div>

    <div class="mt-3">

      <div class="float-right">
        <button-remove @click="remove" v-if="can('module.district.delete')"/>
      </div>


      <button-edit @click="$router.push({name: 'district.edit', params: {id: data.id}})"
                   v-if="can('module.district.update')"/>
    </div>
  </div>
</template>

<script>


import MapPolygon from "@/views/Map/MapPolygon";

export default {
  components: {MapPolygon},
  data() {
    return {}
  },
  props: {
    data: Object
  },
  mounted() {
  },
  methods: {

    remove() {
      $.alertConfirm({
        type: 'red',
      }, {
        ok: () => {
          axiosGetValue('/api/admin/district/delete', {id: this.data.id}, () => {
            toastSuccess('Удаление завершено');
            this.$router.push({name: 'district.list'})
          })
        }
      });
    },
  },
}
</script>