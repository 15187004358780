<template>
  <div>
    <div class="row">
      <div class="col-12">
        <card-field title="Активен">
          {{ data.is_active ? 'Да' : 'Нет' }}
        </card-field>
        <card-field title="Название" :modelValue="data.name"/>
        <card-field title="Сортировка" :modelValue="data.sort"/>
      </div>
    </div>

    <div class="mt-3">

      <div class="float-right">
        <button-remove @click="remove" v-if="can('module.dictionary.place-delete')"/>
      </div>


      <button-edit @click="$router.push({name: 'dictionary-place.edit', params: {id: data.id}})"
                   v-if="can('module.dictionary.place-update')"/>
    </div>
  </div>
</template>

<script>


export default {
  data() {
    return {}
  },
  props: {
    data: Object
  },
  mounted() {
  },
  methods: {

    remove() {
      $.alertConfirm({
        type: 'red',
      }, {
        ok: () => {
          axiosGetValue('/api/admin/dictionary-place/delete', {id: this.data.id}, () => {
            toastSuccess('Удаление завершено');
            this.$router.push({name: 'dictionary-place.list'})
          })
        }
      });
    },
  },
}
</script>