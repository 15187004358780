<template>
  <div class="mt-5">
    <div class="mb-3 row">
      <div class="col-3">
        <select
          :value="selectValue"
          @change="selectCity($event.target.value)"
          class="form-control"
        >
          <option value="" disabled>Выберите город</option>
          <option v-for="city in cities" :key="city.id" :value="city.id">
            {{ city.name }}
          </option>
        </select>
      </div>
      <div class="col-6"></div>
      <div class="col-3 text-right">
        <button class="btn btn-primary mb-2" @click.prevent="loadData">
          Обновить данные
        </button>
      </div>
    </div>

    <div ref="map" style="width: 100%; height: 500px; max-height: 100%">
      <div ref="popup" id="popup"></div>
    </div>
    <div style="display: none" ref="popupContent">
      <div style="min-width: 200px">
        <template v-if="selectFeatureProp">
          <template v-if="selectFeatureProp.type === 'track_point'">
            <div>Местоположение в {{ toTime(selectFeatureProp.time) }}</div>
          </template>
          <template v-else-if="selectFeatureProp.type === 'district'">
            <div>{{ selectFeatureProp.name }}</div>
          </template>
          <template v-else> Нет информации </template>
        </template>
      </div>
    </div>
    <div class="mt-3 row">
      <div class="col-2">
        <span class="badge badge-success">Отряд на смене</span><br />
        <span class="badge badge-warning" style="background: #c177ff">Отряд на вызове</span><br />
        <span class="badge badge-danger">Отряд на перерыве</span>
        <span class="badge badge-secondary">Отряд не на смене</span>
        <span class="badge badge-warning" style="background: #ffe55e"
          >Отряд на месте</span
        >
      </div>
      <div class="col-2">
        <span class="badge badge-primary">Активный вызов</span><br />
        <span class="badge badge-secondary">Пользователь</span><br />
        <span class="border-warning" style="border-bottom: dashed 2px"
          >Отряд едет на вызов</span
        >
      </div>
      <div class="col-2">
        <span class="badge badge-light">Зона города</span><br />
      </div>
      <div class="col-4"></div>
      <div class="col-2 text-right">
        <div v-if="mapParams.center">
          <div class="small text-secondary">Данные обновлены</div>
          {{ updatedAt }}

          <div class="text-secondary small">центр экрана</div>
          {{ mapParams.center[1].toFixed(5) }},
          {{ mapParams.center[0].toFixed(5) }}
        </div>
        <div v-if="cursor">
          <div class="text-secondary small">курсор</div>
          {{ cursor[1].toFixed(5) }}, {{ cursor[0].toFixed(5) }}
        </div>
      </div>
    </div>

    <div class="mt-3 card" v-if="isShowTrack">
      <div class="card-header">
        <b>Фильтр трека</b>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-3">
            <gui-date-time
              w
              sm
              v-model="dateTrackRange.startDate"
              class="mb-0"
              @update:modelValue="filterTrack"
              label="От"
            />
            <div
              class="small text-secondary text-right cursor-pointer"
              @click="
                dateTrackRange.startDate = dateTrackRangeLimit.startDate;
                filterTrack();
              "
            >
              первая точка в {{ toTime(dateTrackRangeLimit.startDate) }}
            </div>
          </div>
          <div class="col-3">
            <gui-date-time
              sm
              w
              v-model="dateTrackRange.endDate"
              class="mb-0"
              @update:modelValue="filterTrack"
              label="До"
            />
            <div
              class="small text-secondary text-right cursor-pointer"
              @click="
                dateTrackRange.endDate = dateTrackRangeLimit.endDate;
                filterTrack();
              "
            >
              последняя точка в {{ toTime(dateTrackRangeLimit.endDate) }}
            </div>
          </div>
          <div class="col-3"></div>
          <div class="col-3 text-right">
            <label class="col-form-label semi-small col-form-label-sm"
              >&nbsp;</label
            >
            <button
              class="btn btn-secondary"
              href="#"
              @click.prevent="clearTrack"
            >
              Выключить трек
            </button>
          </div>
        </div>
      </div>
    </div>

    <p
      v-if="!can('module.organization.view_all-view')"
      class="text-secondary mt-3"
    >
      Результат показан с учётом ограничения по организации
    </p>
  </div>
</template>

<script>

import { onMessageListener } from '@/firebase'
import Popover from 'bootstrap/js/src/popover'
import { LineString, Point, Polygon } from 'ol/geom.js'
import { Feature, Map, Overlay, View } from 'ol/index.js'
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer.js'
import { useGeographic } from 'ol/proj.js'
import { OSM, Vector as VectorSource } from 'ol/source.js'
import { Circle as CircleStyle, Fill, Icon, Stroke, Style, Text } from 'ol/style'
import { ref } from 'vue'

useGeographic()

export default {
  data: function () {
    return {
      map: null,
      mapParams: {
        zoom: null,
        center: null,
      },
      mapParamsDefault: {
        zoom: 11,
        center: [59.9386, 30.3141],
      },
      dateTrackRange: {
        startDate: null,
        endDate: null,
      },
      dateTrackRangeLimit: {
        startDate: null,
        endDate: null,
      },
      trackList: [],
      trackDriver: null,
      trackId: null,
      isTrackShown: false,
      updatedAt: null,
      mapView: null,
      cursor: null, //Текущая позиция
      popover: null, //Объект подсказки
      popup: null, //Оверлей подсказки
      selectFeatureProp: null, //Свойства выбранной метки
      selectFeature: null, //Выбранная метка
      layers: {
        map: new TileLayer({ source: new OSM() }),
        city: new VectorLayer({}),
        district: new VectorLayer({}),
        squad: new VectorLayer({}),
        client: new VectorLayer({}),
        clientCall: new VectorLayer({}),
        track: new VectorLayer({}),
        linkSquad: new VectorLayer({}),
        linkClientCall: new VectorLayer({}),
        linkClient: new VectorLayer({}),
      },
      cities: [],
      selectValue: '',
      isShowTrack: false,
      MapBlockHandlerName: null,
      timeIntervalUpdate: null,
    }
  },
  props: {},
  computed: {},

  created() {
    onMessageListener((payload) => {
      // Обработка пуш уведомления внутри приложения
      if (payload.data.toggle) {
        switch (payload.data.toggle) {
          case 'call':
            setTimeout(() => this.loadData(), 200)
            break
          default:
            break
        }
      }
    })
  },

  setup() {
    const selectedPin = ref(null)
    const isActivePin = ref(false)
    const pinBreakpoint = ref(11) // Ol style resolution при котором пины изменяются на мини версии

    const circleStyles = {
      squad_active: function (feature, resolution) {
        console.log(resolution)
        let transparency
        let src
        let srcGeo
        let iconFill
        let result = []
        if (!(feature.getProperties().id === selectedPin.value) && isActivePin.value) {
          transparency = 0.5
        } else {
          transparency = 1
        }
        if (resolution <= 12) {
          iconFill = 0
          src = '/images/custom-pin-geo.svg'
          srcGeo = '/images/geo.svg'
        } else {
          iconFill = 1
          src = '/images/location-filled.svg'
          srcGeo = '/images/empty.svg'
        }
        result.push(new Style({
          image: new Icon({
            src: src,//'/images/custom-pin.svg',
            color: '#28a745',
            anchor: [0.6, 0.79],
            opacity: transparency
          }),
        }),
        new Style({
          image: new Icon({
            src: srcGeo,
            color: '#ffffff',
            anchor: [2.8, 1.9],
            opacity: transparency,
        })}),
        new Style({
          text: new Text({
            text: !iconFill ? `${feature.get('name')?.toString()}` : '',
            offsetY: -22,
            fill: new Fill({
              color: '#ffffff',
            })
          })
        }))
        return result
      },
      squad_work: function (feature, resolution) {
        let transparency
        let src
        let srcGeo
        let iconFill
        let result = []
        if (!(feature.getProperties().id === selectedPin.value) && isActivePin.value) {
          transparency = 0.5
        } else {
          transparency = 1
        }
        if (resolution <= pinBreakpoint.value) {
          iconFill = 0
          src = '/images/custom-pin-no-geo.svg'
          srcGeo = '/images/geo.svg'
        } else {
          iconFill = 1
          src = '/images/location-filled.svg'
          srcGeo = '/images/empty.svg'
        }
        result.push(new Style({
          image: new Icon({
            src: src, //'/images/custom-pin.svg',
            color: '#c177ff',
            anchor: [0.6, 0.79],
            opacity: transparency
          }),
        }),
        new Style({
          image: new Icon({
            src: srcGeo,
            color: '#ffffff',
            anchor: [2.8, 1.9],
            opacity: transparency,
        })}),
        new Style({
          text: new Text({
            text: !iconFill ? `${feature.get('name')?.toString()}` : '',
            offsetY: -22,
            fill: new Fill({
              color: '#ffffff',
            })
          })
        }))
        return result
      },
      squad_not_work: function (feature, resolution) {
        let transparency
        let src
        let srcGeo
        let iconFill
        let result = []
        if (!(feature.getProperties().id === selectedPin.value) && isActivePin.value) {
          transparency = 0.5
        } else {
          transparency = 1
        }
        if (resolution <= pinBreakpoint.value) {
          iconFill = 0
          src = '/images/custom-pin-geo.svg'
          srcGeo = '/images/geo.svg'
        } else {
          iconFill = 1
          src = '/images/location-filled.svg'
          srcGeo = '/images/empty.svg'
        }
        result.push(new Style({
          image: new Icon({
            src: src,//'/images/custom-pin.svg',
            color: '#6C757D',
            anchor: [0.6, 0.79],
            opacity: transparency
          }),
        }),
        new Style({
          image: new Icon({
            src: srcGeo,
            color: '#ffffff',
            anchor: [2.8, 1.9],
            opacity: transparency,
        })}),
        new Style({
          text: new Text({
            text: !iconFill ? `${feature.get('name')?.toString()}` : '',
            offsetY: -22,
            fill: new Fill({
              color: '#ffffff',
            })
          })
        }))
        return result
      },
      squad_pause: function (feature, resolution) {
        let transparency
        let src
        let srcGeo
        let iconFill
        let result = []
        if (!(feature.getProperties().id === selectedPin.value) && isActivePin.value) {
          transparency = 0.5
        } else {
          transparency = 1
        }
        if (resolution <= pinBreakpoint.value) {
          iconFill = 0
          src = '/images/custom-pin-geo.svg'
          srcGeo = '/images/geo.svg'
        } else {
          iconFill = 1
          src = '/images/location-filled.svg'
          srcGeo = '/images/empty.svg'
        }
        result.push(new Style({
          image: new Icon({
            src: src,//'/images/custom-pin.svg',
            color: '#dc3545',
            anchor: [0.6, 0.79],
            opacity: transparency
          }),
        }),
        new Style({
          image: new Icon({
            src: srcGeo,
            color: '#ffffff',
            anchor: [2.8, 1.9],
            opacity: transparency,
        })}),
        new Style({
          text: new Text({
            text: !iconFill ? `${feature.get('name')?.toString()}` : '',
            offsetY: -22,
            fill: new Fill({
              color: '#ffffff',
            })
          })
        }))
        if (resolution <= pinBreakpoint.value) {
          result.push(trackIcon)
        } 
        return result
      },
      squad_incident: function (feature, resolution) {
        console.log(resolution)
        let transparency
        let src
        let srcGeo
        let iconFill
        let result = []
        if (!(feature.getProperties().id === selectedPin.value) && isActivePin.value) {
          transparency = 0.5
        } else {
          transparency = 1
        }
        if (resolution <= pinBreakpoint.value) {
          iconFill = 0
          src = '/images/custom-pin-geo.svg'
          srcGeo = '/images/geo.svg'
        } else {
          iconFill = 1
          src = '/images/location-filled.svg'
          srcGeo = '/images/empty.svg'
        }
        result.push(new Style({
          image: new Icon({
            src: src, //'/images/custom-pin.svg',
            color: '#FFE55E',
            anchor: [0.6, 0.79],
            fill: iconFill ? new Fill({
              color: '#1D1D1D',
            }) : null,
            opacity: transparency
          }),
        }),
        new Style({
          image: new Icon({
            src: srcGeo,
            color: '#ffffff',
            anchor: [2.8, 1.9],
            opacity: transparency,
        })}),
        new Style({
          text: new Text({
            text: !iconFill ? `${feature.get('name')?.toString()}` : '',
            offsetY: -22,
            fill: new Fill({
              color: '#1D1D1D',
            })
          })
        }))
        return result
      },
      client_call: function (feature, resolution) {
        console.log(resolution)
        let transparency
        let src
        let iconFill
        if (!(feature.getProperties().id === selectedPin.value) && isActivePin.value) {
          transparency = 0.5
        } else {
          transparency = 1
        }
        if (resolution <= pinBreakpoint.value) {
          iconFill = 0
          src = '/images/custom-pin-no-geo.svg'
        } else {
          iconFill = 1
          src = '/images/location-filled.svg'
        }
        return new Style({
          image: new Icon({
            src: src,
            color: '#007bff',
            anchor: [0.5, 0.89],
            opacity: transparency,
          }),
          text: new Text({
            text: !iconFill ? `№${feature.get('id')?.toString()?.split(' ')?.[0]}` : '',
            offsetY: -21,
            fill: new Fill({
              color: '#ffffff',
            })
          })
        })
      },
      client: function (feature, resolution) {
        let transparency
        let src
        let iconFill
        if (!(feature.getProperties().id === selectedPin.value) && isActivePin.value) {
          transparency = 0.5
        } else {
          transparency = 1
        }
        if (resolution <= pinBreakpoint.value) {
          iconFill = 0
          src = '/images/custom-pin-no-geo.svg'
        } else {
          iconFill = 1
          src = '/images/location-filled.svg'
        }
        return new Style({
          image: new Icon({
            src: src,//'/images/custom-pin-no-geo.svg',
            color: '#6c757d',
            anchor: [0.5, 0.89],
            opacity: transparency,
          }),
          text: new Text({
            text: !iconFill ? `№${feature.get('id')?.toString()}` : '',
            offsetY: -21,
            fill: new Fill({
              color: '#ffffff',
            })
          })
        })
      },
      active: function (feature, resolution) {
        let transparency
        if (resolution <= pinBreakpoint.value) {
          transparency = 1
        } else {
          transparency = 0
        }
        return new Style({
          image: new Icon({
            src: 'images/underline.svg',
            anchor: [0.47, 1.8],
            color: '#fff',
            opacity: transparency,
          })
        })
      },
      activeClient: function (feature, resolution) {
        let transparency
        if (resolution <= pinBreakpoint.value) {
          transparency = 1
        } else {
          transparency = 0
        }
        return new Style({
          image: new Icon({
            src: 'images/underline.svg',
            anchor: [0.47, 1.8],
            color: '#fff',
            opacity: transparency,
          })
        })
      },
      line: new Style({
        stroke: new Stroke({
          color: '#ff0',
          width: 2,
          lineDash: [3, 10],
        }),
      }),
      line2: new Style({
        stroke: new Stroke({
          color: '#000',
          width: 4,
          lineDash: [3, 10],
        }),
      }),
      city: new Style({
        stroke: new Stroke({
          color: '#000',
          width: 1,
        }),
        fill: new Fill({
          color: '#ffffff20',
        }),
      }),
      district_active: new Style({
        stroke: new Stroke({
          color: '#000000f0',
          width: 1,
        }),
        fill: new Fill({
          color: '#28a7450f',
        }),
      }),
      district_deactive: new Style({
        stroke: new Stroke({
          color: '#000000f0',
          width: 1,
        }),
        fill: new Fill({
          color: '#dc35450f',
        }),
      }),
      track_point: new Style({
        image: new CircleStyle({
          radius: 3,
          stroke: new Stroke({
            color: '#000',
            width: 1
          }),
          fill: new Fill({
            color: '#6c757d',
          }),
        }),
      }),
      track_line: new Style({
        stroke: new Stroke({
          color: '#000000B0',
          width: 1,
        }),
      }),
    }

    return {
      circleStyles, //стили отображения
      selectedPin,  //id выделеного пина
      isActivePin   //есть ли выделение пина на карте
    }
  },
  mounted() {
    this.mapParams = storageLoad(this.$route.name + '_map', this.mapParamsDefault)
    this.initMap()
    this.loadData()

    this.timeIntervalUpdate = setInterval(this.loadData, 30 * 1000)

    this.MapBlockHandlerName = 'MapBlockHandler' + Math.ceil((Math.random() & 10000))
    window[this.MapBlockHandlerName] = (event, ...args) => {
      if (event === 'loadTrack') {
        this.loadTrack(...args)
      }
    }
  },
  unmounted() {
    window[this.MapBlockHandlerName] = null
    if (this.timeIntervalUpdate) {
      clearTimeout(this.timeIntervalUpdate)
    }
  },
  methods: {
    initMap() {

      this.popup = new Overlay({
        element: this.$refs.popup,
        stopEvent: false,
      })

      this.mapView = new View({
        center: this.mapParams.center,
        zoom: this.mapParams.zoom,
      })

      this.map = new Map({
        target: this.$refs.map,
        view: this.mapView,
        overlays: [
          this.popup
        ],
        layers: Object.values(this.layers),
      })

      this.map.on('moveend', this.mapMoveEnd)

      this.map.on('click', this.mapClick)

      this.map.on('pointermove', this.mapPointerMove)

      window.mapBlock = this.map
    },

    setLayerValues(layer, values) {
      this.layers[layer].setSource(new VectorSource({ features: values }))
    },

    filterTrack() {
      console.log('filterTrack')
      this.dateTrackRange.startDate = this.moment
        .utc(this.dateTrackRange.startDate)
        .format("YYYY-MM-DD HH:mm:ss ZZ")
      this.showTrack()
    },

    mapPointerMove(event) {
      this.cursor = event.coordinate

      let feature = this.map.getFeaturesAtPixel(event.pixel)[0]

      if (!feature) {
        this.map.getViewport().style.cursor = 'inherit'
        return
      }

      let props = feature.getProperties()

      this.map.getViewport().style.cursor = ['linkSquad', 'linkClient', 'linkClientCall', 'track_point'].indexOf(props.type) !== -1 ? 'pointer' : 'inherit'
    },

    mapMoveEnd() {
      this.mapParams.center = this.map.getView().getCenter()
      this.mapParams.zoom = this.map.getView().getZoom()
      storageSet(this.$route.name + '_map', this.mapParams)
    },

    mapClick(event) {

      if (event.originalEvent && event.originalEvent.target && event.originalEvent.target.tagName === 'A') {
        if (!event.originalEvent.target.classList.contains('nomapclick')) {
          event.originalEvent.target.click()
        }
        return
      }

      //Получаем элемент по которому кликнули
      const feature = this.map.getFeaturesAtPixel(event.pixel)[0]
      console.log('feature', feature)

      //Закрываем старое окно
      if (this.popover) {
        this.popover.dispose()
        this.popover = undefined
        this.selectFeature = null
        this.selectFeatureProp = null
      }

      if (!feature) {
        return
      }

      //Проверяем по какому элементу мы кликнули
      let props = feature.getProperties()

      //Проверяем был ли клик по пину
      if (['linkSquad', 'linkClient', 'linkClientCall', 'squad', 'client', 'client_call'].indexOf(props.type) !== -1) {

        //если был клик по активной области в уже активном пине то делаем переход на детальную страницу
        if ((['linkSquad', 'linkClient', 'linkClientCall'].indexOf(props.type) !== -1))// &&
        // this.isActivePin &&
        // this.selectedPin === props.id) убрал проверку на активность пина
        {
          const typesMap = {
            linkSquad: 'squad.detail',
            linkClient: 'client.detail',
            linkClientCall: 'client-call.detail',
          }
          this.$router.push({ name: `${typesMap[props.type]}`, params: { id: props.id } })
          return
        }

        this.selectedPin = feature.getProperties().id
        this.isActivePin = !this.isActivePin

        //обновляем стили для слоев с пинами
        this.layers.clientCall.changed()
        this.layers.squad.changed()
        this.layers.client.changed()

        if (this.isTrackShown) {
          this.clearTrack()
          this.isTrackShown = false
        } else {
          //загружаем трек для клиента|отряда
          if (['client', 'squad', 'linkClient', 'linkSquad'].indexOf(props.type) !== -1) {
            console.log(props.type)
            const typesMap = {
              client: "clients",
              linkClient: "clients",
              squad: "squads",
              linkSquad: "squads",
            }

            this.loadTrack(`${typesMap[props.type]}`, props.id)
            this.isTrackShown = true
          }
        }
        return
      }
      if (this.isTrackShown) {
        this.clearTrack()
        this.isTrackShown = false
      }

      this.isActivePin = false
      this.layers.clientCall.changed()
      this.layers.squad.changed()
      this.layers.client.changed()

      //Если кликнули по уже открытому элементу - выходим
      if (feature && this.selectFeature && feature.ol_uid === this.selectFeature.ol_uid) {
        this.clearTrack()
        this.isTrackShown = false
        return
      }

      if (['track_point', 'district', 'active'].indexOf(props.type) === -1) {
        return
      }

      this.selectFeatureProp = props
      this.selectFeature = feature

      const coordinate = this.map.getCoordinateFromPixel(event.pixel)
      this.popup.setPosition([
        coordinate[0] + Math.round(event.coordinate[0] / 360) * 360,
        coordinate[1],
      ])

      setTimeout(() => {
        this.popover = new Popover(this.$refs.popup, {
          container: this.$refs.popup.parentElement,
          content: this.$refs.popupContent.innerHTML,
          html: true,
          offset: 100,
          placement: 'top',
          sanitize: false,
        })
        this.popover.show()
      })
    },

    loadData() {
      this.loadDataCity()
      this.loadDataDistrict()
      this.loadDataClient()
      this.loadDataSquad()
      this.loadDataClientCall()
      this.updatedAt = this.moment().format("YYYY-MM-DD HH:mm:ss")
    },

    loadDataClient() {
      axiosGetValue('/api/admin/map/client', {}, list => {
        let result = []
        if (list && list.length) {
          list.forEach(e => {
            e.type = 'client'
            let feature = new Feature(new Point(e.geo))
            feature.setStyle(this.circleStyles.client)
            feature.setProperties(e)
            result.push(feature)
          })
        }
        const actives = []
        if (list && list.length) {
          list.forEach(e => {
            e.type = 'linkClient'
            let feature = new Feature(new Point(e.geo))
            feature.setStyle(this.circleStyles.activeClient)
            feature.setProperties(e)
            actives.push(feature)
          })
        }
        this.setLayerValues('client', result)
        this.setLayerValues('linkClient', actives)
      })
    },

    loadDataSquad() {
      axiosGetValue('/api/admin/map/squad', {}, list => {
        let result = []
        if (list && list.length) {
          list.forEach(e => {
            e.type = 'squad'
            let feature = new Feature(new Point(e.geo))
            feature.setStyle(this.circleStyles[e.status])
            feature.setProperties(e)
            result.push(feature)

            if (e.client_call) {
              feature = new Feature(new LineString([e.geo, e.client_call.geo]))
              feature.setStyle(this.circleStyles.line2)
              result.push(feature)
              feature = new Feature(new LineString([e.geo, e.client_call.geo]))
              feature.setStyle(this.circleStyles.line)
              result.push(feature)
            }

          })
        }
        const actives = []
        if (list && list.length) {
          list.forEach(e => {
            e.type = 'linkSquad'
            let feature = new Feature(new Point(e.geo))
            feature.setStyle(this.circleStyles.active)
            feature.setProperties(e)
            actives.push(feature)
          })
        }
        this.setLayerValues('squad', result)
        this.setLayerValues('linkSquad', actives)
      })
    },

    loadDataCity() {
      axiosGetValue('/api/admin/map/city', {}, list => {
        this.cities = list
        let result = []
        if (list && list.length) {
          list.forEach(e => {
            e.type = 'city'
            let feature = new Feature(new Polygon(e.area))
            feature.setStyle(this.circleStyles.city)
            feature.setProperties(e)
            result.push(feature)
          })
        }
        this.setLayerValues('city', result)
      })
    },

    loadDataDistrict() {
      axiosGetValue('/api/admin/map/district', {}, list => {
        let result = []
        if (list && list.length) {
          list.forEach(e => {
            e.type = 'district'
            let feature = new Feature(new Polygon(e.area))
            feature.setStyle(this.circleStyles[e.is_active ? 'district_active' : 'district_deactive'])
            feature.setProperties(e)
            result.push(feature)
          })
        }
        this.setLayerValues('district', result)
      })
    },

    loadDataClientCall() {
      axiosGetValue('/api/admin/map/client-call', {}, list => {
        let result = []
        if (list && list.length) {
          list.forEach(e => {
            e.type = 'client_call'
            let feature = new Feature(new Point(e.geo))
            feature.setStyle(this.circleStyles.client_call)
            feature.setProperties(e)
            result.push(feature)
          })
        }
        const actives = []
        if (list && list.length) {
          list.forEach(e => {
            e.type = 'linkClientCall'
            let feature = new Feature(new Point(e.geo))
            feature.setStyle(this.circleStyles.activeClient)
            feature.setProperties(e)
            actives.push(feature)
          })
        }
        this.setLayerValues('clientCall', result)
        this.setLayerValues('linkClientCall', actives)
      })
    },

    loadTrack(driver, id) {
      axiosGetValue('/api/admin/map/track', {
        driver,
        id
      }, list => {

        if (list && list.length) {
          let isUpdateStartDate = this.dateTrackRange.startDate === this.dateTrackRangeLimit.startDate
          let isUpdateEndDate = this.dateTrackRange.endDate === this.dateTrackRangeLimit.endDate

          this.dateTrackRangeLimit.startDate = this.moment()
            .utc()
            .subtract(2, "hours")
            .format("YYYY-MM-DD HH:mm:ss ZZ")
          this.dateTrackRangeLimit.endDate = this.moment.utc(list[list.length - 1].time).format('YYYY-MM-DD HH:mm:ss ZZ')

          if (!this.dateTrackRange.startDate || isUpdateStartDate) {
            this.dateTrackRange.startDate = this.dateTrackRangeLimit.startDate
          }

          if (!this.dateTrackRange.endDate || isUpdateEndDate) {
            this.dateTrackRange.endDate = this.dateTrackRangeLimit.endDate
          }

          this.trackList = list
          this.trackDriver = driver
          this.trackId = id
          this.filterTrack()
          this.showTrack()
        }
      })
    },

    showTrack() {


      let result = []

      let list = this.trackList

      if (list && list.length) {
        let lastPoint = null
        list
          .filter(e => {
            return (!this.dateTrackRange.startDate || e.time >= this.dateTrackRange.startDate) &&
              (!this.dateTrackRange.endDate || e.time <= this.dateTrackRange.endDate)
          })
          .forEach(e => {
            e.type = 'track_point'
            e.driver = this.trackDriver
            e.id = this.trackId
            let feature = new Feature(new Point(e.geo))
            feature.setStyle(this.circleStyles.track_point)
            feature.setProperties(e)
            result.push(feature)

            if (lastPoint) {
              feature = new Feature(new LineString([lastPoint.geo, e.geo]))
              feature.setStyle(this.circleStyles.track_line)
              result.push(feature)
            }

            lastPoint = e

          })
      }

      this.isShowTrack = true
      this.setLayerValues('track', result)
    },

    clearTrack() {
      this.isShowTrack = false
      this.setLayerValues('track', [])
    },

    selectCity(cityId) {
      let city = this.cities.find(e => e.id == cityId)
      if (city) {
        this.mapView.setZoom(11)
        this.mapView.setCenter(city.area[0][0])
      }
    }
  }
}
</script>
