import { io } from 'socket.io-client';

export function createSocketConnection(options) {
    if (!options.auth) {
        throw new Error('Необходимо указать параметры авторизации при подключении к сокет-серверу');
    }

    if (!options.auth.token) {
        throw new Error('Необходимо указать токен пользователя при подключении к сокет-серверу');
    }

    if (!options.auth.deviceUid) {
        throw new Error('Необходимо указать уникальный идентификатор устройства при подключении к сокет-серверу');
    }

    const host = options.host || process.env.VUE_APP_SOCKET_HOST;
    const namespace = options.namespace || '';
    const serverPath = options.path || process.env.VUE_APP_SOCKET_PATH;
    const authToken = options.auth.token;
    const deviceUid = options.auth.deviceUid;
    const forceNew = options.forceNew || false;

    const socket = io(`${host}/${namespace}`, {
        transports: ['websocket'],
        path: serverPath,
        forceNew,

        auth: {
            token: authToken,
            device_uid: deviceUid
        },
    });

    return socket;
}