<template>
  <div v-if="data && data.length">
    <div class="card mb-4">
      <div class="card-header">
        {{ title }}
      </div>
      <div class="card-block">
        <table class="table">
          <thead>
          <tr>
            <td>Чат</td>
            <td>Дата последнего сообщения</td>
          </tr>
          </thead>
          <tbody>
          <tr v-for="row in data" :key="row.id">
            <td>
              <router-link :to="{name: 'chat.detail', params: {uuid: row.uuid}}" target="_blank">
                {{ row.uuid }}
              </router-link>
            </td>
            <td>
              {{ toTime(row.last_message_date) }}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div v-else class="alert alert-secondary">
    {{ title }} - ошибок не обнаружено
  </div>
</template>

<script>
export default {
  data: function () {
    return {}
  },
  props: {
    data: Array,
    title: String
  },
  computed: {},
  mounted() {

  },
  methods: {}
}
</script>