import EmptyWrap from "@/components/Gui/EmptyWrap";

export default [
    {
        path: 'client-document-group',
        component: EmptyWrap,
        children: [
            {
                path: 'list',
                name: 'document-group.list',
                component: require('@/views/Document/GroupList.vue').default,
            },
            {
                path: 'create',
                name: 'document-group.create',
                component: require('@/views/Document/GroupForm.vue').default,
            },
            {
                path: ':id/edit',
                name: 'document-group.edit',
                component: require('@/views/Document/GroupForm.vue').default,
            },
        ]
    },
]