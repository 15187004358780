import EmptyWrap from "@/components/Gui/EmptyWrap";

export default [
    {
        path: 'business-company',
        component: EmptyWrap,
        children: [
            {
                path: '',
                name: 'business_company.list',
                component: require('@/views/BusinessCompany/BusinessCompanyList').default,
            },
            {
                path: 'create',
                name: 'business_company.create',
                component: require('@/views/BusinessCompany/BusinessCompanyForm').default,
            },
            {
                path: ':id',
                component: require('@/views/BusinessCompany/Card/WrapBlock').default,
                children: [
                    {
                        path: 'detail',
                        name: 'business_company.detail',
                        component: require('@/views/BusinessCompany/Card/BusinessCompanyDetail').default,
                    },
                    {
                        path: 'clients',
                        name: 'business_company.clients',
                        component: require('@/views/BusinessCompany/Card/BusinessCompanyClients').default,
                    },
                    {
                        path: 'clients-import',
                        name: 'business_company.clients-import',
                        component: require('@/views/BusinessCompany/Card/BusinessCompanyClientsImport').default,
                    },
                ]
            },
            {
                path: ':id/edit',
                name: 'business_company.edit',
                component: require('@/views/BusinessCompany/BusinessCompanyForm').default,
            },
        ]
    },
]
