<template>
  <div>
    <div v-if="data && data.data && data.data.length">
      <table class="table table-hover">
        <thead>
        <tr>
          <th>Вызов отряда</th>
          <th class="d-none d-md-table-cell">Статус</th>
          <th class="d-none d-md-table-cell">Начат</th>
          <th class="d-none d-md-table-cell">Отменён</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="row in data.data" :key="row.id">
          <td>
            <router-link :to="{name: 'client-call-squad.detail', params: {id: row.id}}" class="main-link">
              #{{ row.id }}
              {{ row.squad ? row.squad.name : '—' }}
            </router-link>
          </td>
          <td class="d-none d-md-table-cell">
            {{ row.client_call_squad_status ? row.client_call_squad_status.name : '—' }}
          </td>
          <td class="d-none d-md-table-cell">
            {{ toTimes(row.date_start) }}
          </td>
          <td class="d-none d-md-table-cell">
            {{ toTimes(row.date_cancel) }}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div v-else class="alert alert-info">Список пуст</div>
  </div>
</template>

<script>

export default {
  data: function () {
    return {}
  },
  props: {
    data: Object
  },
  computed: {},
  methods: {}
}
</script>