<template>
  <div>
    <div v-if="data.client_call_reviews && data.client_call_reviews.length">
      <div v-for="(review, i) in data.client_call_reviews" :key="i">
        <quiz-answer-block :id="review.quiz_answer_id"/>
      </div>
    </div>
    <div v-else class="alert alert-info">Отзыв не заполнен</div>
  </div>
</template>

<script>


import QuizAnswerBlock from "@/views/QuizAnswer/Block/QuizAnswerBlock";

export default {
  components: {QuizAnswerBlock},
  data() {
    return {}
  },
  props: {
    data: Object
  },
  mounted() {
  },
  methods: {},
}
</script>