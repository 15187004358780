import EmptyWrap from "@/components/Gui/EmptyWrap";

export default [
    {
        path: 'country',
        component: EmptyWrap,
        children: [
            {
                path: '',
                name: 'country.list',
                component: require('@/views/Country/CountryList').default,
            },
            {
                path: 'create',
                name: 'country.create',
                component: require('@/views/Country/CountryForm').default,
            },
            {
                path: ':id',
                component: require('@/views/Country/Card/WrapBlock').default,
                children: [
                    {
                        path: 'detail',
                        name: 'country.detail',
                        component: require('@/views/Country/Card/CountryDetail').default,
                    },
                ]
            },
            {
                path: ':id/edit',
                name: 'country.edit',
                component: require('@/views/Country/CountryForm').default,
            },
        ]
    },
]