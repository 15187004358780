<template>
  <gui-input-row :data="$props">
    <div class="input-group input-date-range" :class="{'input-group-sm':sm}">
      <div style="-webkit-box-flex: 1;-ms-flex: 1 1 auto;flex: 1 1 auto;width: 1%;">
        <input
            :disabled="disabled"
            class="form-control"
            :class="{'is-invalid': error && error.length > 0, 'form-control-sm': sm}"
            placeholder="Широта"
            :value="modelValue[fromKey]"
            @input="e=>setValue(fromKey,e.target.value)"
        />
      </div>
      <div style="-webkit-box-flex: 1;-ms-flex: 1 1 auto;flex: 1 1 auto;width: 1%;">

        <input
            :disabled="disabled"
            class="form-control"
            :class="{'is-invalid': error && error.length > 0, 'form-control-sm': sm}"
            placeholder="Долгота"
            :value="modelValue[toKey]"
            @input="e=>setValue(toKey,e.target.value)"
        />
      </div>
      <div class="input-group-append">
        <button class="btn btn-sm btn-danger" @click.prevent="clear">
          <i class="fas fa-times"></i>
        </button>
      </div>
    </div>
  </gui-input-row>
</template>

<script>

export default {
  data: function () {
    return {}
  },
  props: {
    modelValue: [Array, Object, String],
    fromKey: {
      type: String,
      default: 'lat'
    },
    toKey: {
      type: String,
      default: 'lon'
    },

    f: Boolean,
    sm: Boolean,
    w: Boolean,
    error: [Array, Object, String],
    label: String,
    clearable: Boolean,
    disabled: Boolean,
  },
  computed: {},
  beforeMount() {

  },
  mounted() {
    if (this.modelValue === '' || this.modelValue === null || this.modelValue === false) {
      this.clear()
    }
  },
  methods: {
    clear() {
      this.$emit('update:modelValue', {[this.fromKey]: '', [this.toKey]: ''});
    },
    setValue(param, e) {
      this.$emit('update:modelValue', Object.assign({}, this.modelValue, {[param]: e}));
    },
  },
  watch: {}
}
</script>
