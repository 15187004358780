<template>
  <card-block>
    <spinner-block v-model="dataProgress">
      <template v-if="data">
        <card-header>
          <router-link :to="{name: 'country.list'}">Страны</router-link><!--
                    -->:
          <span class="text-secondary">#{{ data.id }}</span>
          {{ data.name }}
        </card-header>
        <tab-list>
          <tab-link to="country.detail">Основные данные</tab-link>
        </tab-list>
        <card-body>
          <router-view :key="id" :data="data" @change="getData"></router-view>
        </card-body>
      </template>
    </spinner-block>
  </card-block>
</template>

<script>
export default {
  data: function () {
    return {
      data: null,
      dataProgress: true,
    }
  },
  props: {},
  computed: {
    id() {
      return this.$route.params.id;
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      if (this.id) {
        axiosGetValue('/api/admin/country/get', {id: this.id}, e => this.data = e, e => this.dataProgress = e)
      }
    },
  },
}
</script>