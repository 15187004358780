<template>
  <div>

    <div class="mb-3 text-right">
      <button-create @click="$refs.modal.openModal()" v-if="can('module.quiz.question_option-create')"/>
    </div>

    <spinner-block v-model="progress">

      <div v-if="listData && listData.data && listData.data.length">
        <table class="table table-hover">
          <thead>
          <tr>
            <th>Ответ</th>
            <th>Сортировка</th>
            <th>По умолчанию</th>
            <th class="text-right"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="row in listData.data" :key="row.id">
            <td>
              <is-active-badge v-model="row.is_active"/>
              {{ row.name ? row.name : '—' }}
            </td>
            <td>{{ row.sort ? row.sort : '—' }}</td>
            <td>{{ row.is_default ? 'Да' : '-' }}</td>
            <td class="text-right">

              <button-edit icon sm @click="$refs.modal.openModal(row.id)"
                           v-if="can('module.quiz.question_option-update')"/>
              <button-remove icon sm @click="remove(row)" v-if="can('module.quiz.question_option-delete')"/>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div v-else class="alert alert-info">Список пуст</div>
    </spinner-block>
    <gui-paginate :data="listData" ref="paginate" @change="getList"/>
    <create-or-update-quiz-question-option-modal ref="modal" :user="data" @update="getList"
                                                 :defaultParams="{quiz_question_id: this.id}"/>
  </div>
</template>

<script>
import CreateOrUpdateQuizQuestionOptionModal from "@/views/Quiz/Block/CreateOrUpdateQuizQuestionOptionModal";
import IsActiveBadge from "@/components/Badge/IsActiveBadge";

export default {
  components: {IsActiveBadge, CreateOrUpdateQuizQuestionOptionModal},
  data() {
    return {
      listData: null,
      progress: null,
      filter: {
        quiz_question_id: null,
      }
    }
  },
  props: {
    id: Number,
  },
  mounted() {
  },
  methods: {
    init() {
      this.filter.quiz_question_id = this.id
      this.getList()
    },
    getList() {
      axiosGetValue('/api/admin/quiz-question-option/list', {
            filter: this.filter,
            page: this.$refs.paginate.currentPage,
            per_page: this.$refs.paginate.perPage,
          },
          e => this.listData = e, e => this.progress = e
      )
    },
    remove(row) {
      $.alertConfirm({}, {
        ok: () => {
          axiosGetValue('/api/admin/quiz-question-option/delete', {id: row.id}, () => {
            toastSuccess('Удаление завершено');
            this.getList();
          })
        }
      });
    },
  }
}
</script>