<template>
  <div>
    <div v-if="data.subscription_clients && data.subscription_clients.length">
      <table class="table">
        <thead>
        <tr>
          <th>Клиент</th>
          <th>Статус</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="subscriptionClient in data.subscription_clients" :key="subscriptionClient.id">
          <td>
            <template v-if="data.client_id">
              <router-link :to="{name: 'client.detail', params: {id: subscriptionClient.client_id}}" target="_blank">
                #{{ subscriptionClient.client_id }} {{
                  subscriptionClient.client ? subscriptionClient.client.phone : '—'
                }}
              </router-link>
            </template>
            <template v-else>—</template>
          </td>
          <td>
            {{
              subscriptionClient.subscription_client_status ? subscriptionClient.subscription_client_status.name : '—'
            }}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div v-else class="alert alert-info">Список пуст</div>
  </div>
</template>

<script>

export default {
  components: {},
  data() {
    return {
      progress: false,
    }
  },
  props: {
    data: Object
  },
  mounted() {
  },
  methods: {},
}
</script>