<template>
  <div>

    <div class="row">
      <div class="col-3">

        <div class="mb-3">
          <b>Рассчитанная стоимость</b>
        </div>
        <card-field
            :modelValue="(data.amount_call ? +data.amount_call : 0) + (data.amount_extra_paid ? +data.amount_extra_paid : 0)"
            title="Стоимость вызова, р"/>
        <card-field :modelValue="data.amount_call" title="-- базовая стоимость, р" class="pl-3"/>
        <card-field :modelValue="data.amount_extra_paid" title=" -- стоимость доп. времени, р" class="pl-3"/>


      </div>
      <div class="col-3">
        <div class="mb-3">
          <b>Фактичеси оплаченная сумма</b>
        </div>
        <card-field :modelValue="data.amount_payed_by_client" title="Сумма, заплаченная клиентом, р"/>
        <card-field :modelValue="data.amount_payed_by_client_service" class="pl-3"
                    title="-- за услуги сервиса, р"/>
        <card-field :modelValue="data.amount_payed_by_client_squad" class="pl-3"
                    title="-- за услуги отряда, р"/>
        <card-field :modelValue="data.amount_hold_by_client" title="Сумма, находящаяся на удержании, р"/>
      </div>
      <div class="col-6">
        <div class="text-right">
          <div>
            <button class="btn btn-secondary mb-2" @click.prevent="updatePayment"
                    v-if="can('module.client_call.payment-update')">
              Пересчитать фактически оплаченную стоимость
            </button>
          </div>
          <div>
            <button class="btn btn-secondary mb-2" @click.prevent="holdCancel"
                    :disabled="+data.amount_hold_by_client <= 0"
                    v-if="can('module.client_call.payment-update')">
              Вернуть захолдированные средства
            </button>
          </div>
          <div>
            <button class="btn btn-secondary mb-2" @click.prevent="makeSuccess"
                    :disabled="data.is_active || (!data.is_debit && !data.is_warning)"
                    v-if="can('module.client_call.payment-update')">
              Пометить оплату вызова успешной
            </button>
          </div>
          <div>
            <button class="btn btn-secondary mb-2" @click.prevent="$refs.changeAmountModal.openModal(data.id)"
                    :disabled="data.is_active"
                    v-if="can('module.client_call.payment-update')">
              Изменить стоимость вызова
            </button>
            <client-call-change-amount-modal ref="changeAmountModal" @update="getData"/>
          </div>
          <div>
            <button class="btn btn-secondary mb-2" @click.prevent="$refs.debitPayModal.openModal(data.id)"
                    :disabled="data.is_active || (+data.amount_call +(+data.amount_extra_paid)) <= +data.amount_payed_by_client"
                    v-if="can('module.client_call.payment-update')">
              Оплатить дебиторскую задолженность
            </button>
            <client-call-pay-debit-modal ref="debitPayModal" @update="getData"/>
          </div>
          <div>
            <button class="btn btn-secondary mb-2" @click.prevent="$refs.partialCancelModal.openModal(data.id)"
                    :disabled="data.is_active || (+data.amount_call +(+data.amount_extra_paid)) >= +data.amount_payed_by_client"
                    v-if="can('module.client_call.payment-update')">
              Сделать частичный возврат
            </button>
            <client-call-partial-cancel-modal ref="partialCancelModal" @update="getData"/>
          </div>
        </div>
      </div>
    </div>


    <spinner-block v-model="progress">
      <payment-list-block :data="listData" can_refresh @update="getList"/>
    </spinner-block>
    <gui-paginate :data="listData" ref="paginate" @change="getList"/>
  </div>
</template>

<script>

import PaymentListBlock from "@/views/Payment/PaymentListBlock";
import ClientCallChangeAmountModal from "@/views/ClientCall/Block/ClientCallChangeAmountModal";
import ClientCallPayDebitModal from "@/views/ClientCall/Block/ClientCallPayDebitModal";
import ClientCallPartialCancelModal from "@/views/ClientCall/Block/ClientCallPartialCancelModal";

export default {
  components: {ClientCallPartialCancelModal, ClientCallPayDebitModal, ClientCallChangeAmountModal, PaymentListBlock},
  data() {
    return {
      listData: null,
      progress: null,
      filter: {
        client_call_id: null,
      }
    }
  },
  props: {
    data: Object,
  },
  mounted() {
    this.filter.client_call_id = this.data.id
    this.getList()
  },
  methods: {
    getData() {
      this.$emit('change')
      this.getList()
    },
    getList() {
      axiosGetValue('/api/admin/payment/list', {
            filter: this.filter,
            page: this.$refs.paginate.currentPage,
            per_page: this.$refs.paginate.perPage,
          },
          e => this.listData = e, e => this.progress = e
      )
    },

    updatePayment() {
      if (this.data.id) {
        axiosGetValue('/api/admin/client-call/update-payment', {id: this.data.id}, () => {
          this.getData()
        }, e => this.dataProgress = e)
      }
    },
    holdCancel() {
      $.alertConfirm({
        content: 'Будут возвращены захолдированные оплаты с типом "Холдирование за вызов". Продолжить?'
      }, {
        ok: () => {
          if (this.data.id) {
            axiosGetValue('/api/admin/client-call/hold-cancel', {id: this.data.id}, () => {
              this.getData()
            }, e => this.dataProgress = e)
          }

        }
      });
    },
    makeSuccess() {
      $.alertConfirm({
        content: 'Будут сброшены пометки ошибки оплаты и наличия дебиторской задолженности. Вернуть их можно будет обновлением фактической оплаты. Продолжить?'
      }, {
        ok: () => {
          if (this.data.id) {
            axiosGetValue('/api/admin/client-call/make-success', {id: this.data.id}, () => {
              this.getData()
            }, e => this.dataProgress = e)
          }

        }
      });
    },
  }
}
</script>