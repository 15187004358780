<template>
  <div>
    <div v-if="data && data.data && data.data.length">
      <table class="table table-hover">
        <thead>
        <tr>
          <th class="pr-0" style="width: 1rem;"></th>
          <th>Название</th>
          <th class="d-none d-md-table-cell">Статус</th>
          <th class="d-none d-md-table-cell">Организация</th>
          <th class="d-none d-md-table-cell">Телефон</th>
          <th class="text-right"></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="row in data.data" :key="row.id">
          <th class="pr-0">
            <i class="fas fa-circle small"
               :title="row.is_active ? 'Активен' : 'Неактивен'"
               :class="row.is_active ? 'text-success' : 'text-danger'"></i>
          </th>
          <td class="limit-half_length">
            <router-link :to="{name: 'squad.detail', params: {id: row.id}}" class="main-link">
              {{ row.name ? row.name : '—' }}
            </router-link>
          </td>
          <td class="d-none d-md-table-cell limit-half_length">
            <div>
              {{ row.squad_status ? row.squad_status.name : '—' }}
            </div>
          </td>
          <td class="d-none d-md-table-cell limit-half_length">
            <div>
              {{ row.organization ? row.organization.name : '—' }}
            </div>
          </td>
          <td class="d-none d-md-table-cell limit-half_length">
            <div>
              {{ row.phone }}
            </div>
            <div class="small text-secondary" title="Дата подтверждения">
              {{ toTime(row.phone_verified_at) }}
            </div>
          </td>
          <td class="text-right">

          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div v-else class="alert alert-info">Список пуст</div>
  </div>
</template>

<script>

export default {
  data: function () {
    return {}
  },
  props: {
    data: Object
  },

}
</script>