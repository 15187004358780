<template>
  <div class="alert alert-danger">
    Недостаточно прав.
  </div>
</template>

<script>

</script>

