<template>
  <div>

    <div class="mb-3 text-right">
      <button-create @click="$refs.modal.openModal()" v-if="can('module.client.relative-create')"/>
    </div>

    <spinner-block v-model="progress">

      <div v-if="listData && listData.data && listData.data.length">
        <table class="table table-hover">
          <thead>
          <tr>
            <th>Имя</th>
            <th class="d-none d-md-table-cell">Телефон</th>
            <th class="text-right"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="row in listData.data" :key="row.id">
            <td>
              {{ row.name ? row.name : '—' }}
            </td>
            <td class="d-none d-md-table-cell">
              {{ row.phone ? row.phone : '—' }}
            </td>
            <td class="text-right">
              <button-edit icon sm @click="$refs.modal.openModal(row.id)" v-if="can('module.client.relative-update')"/>
              <button-remove icon sm @click="remove(row)" v-if="can('module.client.relative-delete')"/>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div v-else class="alert alert-info">Список пуст</div>
    </spinner-block>
    <gui-paginate :data="listData" ref="paginate" @change="getList"/>
    <create-or-update-client-relatives-modal ref="modal"
                                             @update="getList"
                                             :defaultParams="{client_id: this.data.id}"/>
  </div>
</template>

<script>
import CreateOrUpdateClientRelativesModal from "@/views/Client/Block/CreateOrUpdateClientRelativesModal";

export default {
  components: {CreateOrUpdateClientRelativesModal},
  data() {
    return {
      listData: null,
      progress: null,
      filter: {
        client_id: null,
      }
    }
  },
  props: {
    data: Object,
  },
  mounted() {
    this.filter.client_id = this.data.id
    this.getList()
  },
  methods: {
    getList() {
      axiosGetValue('/api/admin/client-relatives/list', {
            filter: this.filter,
            page: this.$refs.paginate.currentPage,
            per_page: this.$refs.paginate.perPage,
          },
          e => this.listData = e, e => this.progress = e
      )
    },
    remove(row) {
      $.alertConfirm({}, {
        ok: () => {
          axiosGetValue('/api/admin/client-relatives/delete', {id: row.id, client_id: row.client_id}, () => {
            toastSuccess('Удаление завершено');
            this.getList();
          })
        }
      });
    },
  }
}
</script>