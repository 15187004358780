<template>
  <card-block>
    <spinner-block v-model="dataProgress">
      <div v-if="data">
        <card-header>
          <template v-if="id">
            <router-link :to="{name: 'document-group.list'}">Группы документов</router-link><!-- -->:
            редактирование

          </template>
          <template v-else>
            <router-link :to="{name: 'document-group.list'}">Группы документов</router-link><!-- -->:
            создание
          </template>

        </card-header>
        <card-body>

          <gui-input v-model="data.name" label="Наименование"/>
          <gui-input v-model="data.description" label="Описание"/>
          <gui-input v-model="data.code" :error="dataError.code" label="Код"/>

          <div class="mt-5">
            <template v-if="!data.id">
              <button-create v-if="can('module.client_document_group.create')" @click="add()"/>
            </template>
            <template v-else>
              <button-save v-if="can('module.client_document_group.update')" @click="save"/>
            </template>
            <button-back/>

            <div class="float-right" v-if="data.id">
              <button-remove @click="remove" v-if="can('module.client_document_group.delete')"/>
            </div>
          </div>
        </card-body>
      </div>
    </spinner-block>
  </card-block>
</template>

<script>


export default {
  components: {},
  data() {
    return {
      data: null,
      dataProgress: false,
      dataDefault: {
        name: '',
        description: '',
        code: ''
      },
      dataError: {code: '', group_id: ''},
      statuses: [],
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      if (this.id) {
        this.data = null
        this.loadData()
      } else {
        this.data = _.clone(this.dataDefault)
      }
    },

    loadData() {
      axiosGetValue('/api/admin/client-document-group/get',
          {id: this.id},
          e => this.data = e,
          e => this.dataProgress = e
      )
    },

    save() {
      axiosFast({
        url: route('/api/admin/client-document-group/update'),
        data: {data: this.data, id: this.data.uuid},
        error: this.dataError,

        progress: e => this.dataProgress = e,
        result: () => {
          toastSuccess('Данные сохранены');
          this.$router.push({name: 'document-group.list'});
        },
      })
    },

    add() {
      axiosFast({
        url: route('/api/admin/client-document-group/create'),
        data: {data: this.data},
        error: this.dataError,
        progress: e => this.dataProgress = e,
        result: () => {
          toastSuccess('Данные добавлены');
          this.$router.push({name: 'document-group.list'})
        },
      })
    },

    remove() {
      axiosFast({
        url: route('/api/admin/client-document-group/delete'),
        data: {id: this.id},
        error: this.dataError,
        progress: e => this.dataProgress = e,
        result: () => {
          toastSuccess('Данные удалены');
          this.$router.push({name: 'document-group.list'})
        },
      })
    },

  },
}
</script>
