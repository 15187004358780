<template>
  <div>
    <div class="mb-3">
      <b>Перерывы в смене</b>
    </div>
    <spinner-block v-model="progress">
      <div v-if="dataTable && dataTable.data && dataTable.data.length">
        <table class="table table-hover">
          <thead>
          <tr>
            <th class="pr-0" style="width: 1rem;"></th>
            <th>Начало</th>
            <th>Окончание</th>
            <th>Длительность, сек</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="row in dataTable.data" :key="row.id">
            <th class="pr-0">
              <template v-if="!row.ended_at">
                <i class="fas fa-circle small text-success" title="Активен"></i>
              </template>
            </th>
            <td>
              {{ toTime(row.started_at) }}
            </td>
            <td>
              {{ toTime(row.ended_at) }}
            </td>
            <td>
              {{ row.time_duration }}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div v-else class="alert alert-info">Список пуст</div>
    </spinner-block>
    <gui-paginate :data="dataTable" ref="paginate" @change="getList"/>
  </div>
</template>

<script>

export default {
  data: function () {
    return {
      dataTable: null,
      progress: true,
      filter: {},
    }
  },
  props: {
    data: Object,
  },
  computed: {},
  beforeMount() {
    this.filter = storageLoad('filter_' + this.$route.name, this.filter);
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {

      let v = _.clone(this.filter);
      v.search = '';
      storageSet('filter_' + this.$route.name, v);

      axiosGetValue('/api/admin/squad-duty-break/list', {
            page: this.$refs.paginate.currentPage,
            per_page: this.$refs.paginate.perPage,
            filter: Object.assign({}, this.filter, {
              squad_duty_id: this.data.id
            }),
          },
          e => this.dataTable = e, e => this.progress = e
      )
    }
  }
}
</script>