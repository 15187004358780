import EmptyWrap from "@/components/Gui/EmptyWrap";

export default [
    {
        path: 'region',
        component: EmptyWrap,
        children: [
            {
                path: '',
                name: 'region.list',
                component: require('@/views/Region/RegionList').default,
            },
            {
                path: 'create',
                name: 'region.create',
                component: require('@/views/Region/RegionForm').default,
            },
            {
                path: ':id',
                component: require('@/views/Region/Card/WrapBlock').default,
                children: [
                    {
                        path: 'detail',
                        name: 'region.detail',
                        component: require('@/views/Region/Card/RegionDetail').default,
                    },
                    {
                        path: 'city',
                        name: 'region.city',
                        component: require('@/views/Region/Card/CityTab').default,
                    },
                ]
            },
            {
                path: ':id/edit',
                name: 'region.edit',
                component: require('@/views/Region/RegionForm').default,
            },
        ]
    },
]