<template>
  <card-block>
    <spinner-block v-model="dataProgress">
      <div v-if="data">
        <card-header>
          <template v-if="id">
            <router-link :to="{name: 'quiz.detail', params: {id: id}}">Опросник</router-link><!-- -->:
            редактирование

          </template>
          <template v-else>
            <router-link :to="{name: 'quiz.list'}">Опросники</router-link><!-- -->:
            создание
          </template>


        </card-header>
        <card-body>
          <gui-input v-model="data.name" :error="dataError.name" label="Название"/>
          <gui-input v-model="data.code" :error="dataError.code" label="Код"/>
          <gui-input v-model="data.group_code" :error="dataError.group_code" label="Код группы"/>

          <div class="mt-5">
            <button-save v-if="!data.id" @click="add()"/>
            <button-save v-else @click="save"/>
            <button-back/>
          </div>
        </card-body>
      </div>
    </spinner-block>
  </card-block>
</template>

<script>


export default {
  data() {
    return {
      data: null,
      dataDefault: {
        name: '',
        code: '',
        group_code: '',
      },
      dataError: {
        name: '',
        code: '',
        group_code: '',
      },
      dataProgress: false,
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    }
  },
  mounted() {
    this.init()
  },
  methods: {

    init() {
      if (this.id) {
        this.data = null
        this.loadData()
      } else {
        this.data = _.clone(this.dataDefault)
      }
    },
    loadData() {
      axiosGetValue('/api/admin/quiz/get', {id: this.id}, e => this.data = e, e => this.dataProgress = e)
    },

    save() {
      axiosFast({
        url: route('/api/admin/quiz/update'),
        data: {data: this.data, id: this.data.id},
        error: this.dataError,
        progress: e => this.dataProgress = e,
        result: () => {
          toastSuccess('Данные сохранены');
          this.$router.push({name: 'quiz.detail', params: {id: this.data.id}})
        },
      })
    },
    add() {
      axiosFast({
        url: route('/api/admin/quiz/create'),
        data: {data: this.data},
        error: this.dataError,
        progress: e => this.dataProgress = e,
        result: (data) => {
          toastSuccess('Данные добавлены');
          this.$router.push({name: 'quiz.detail', params: {id: data.id}})
        },
      })
    },
  },
}
</script>