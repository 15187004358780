<template>
  <div class="card-body">
    <slot></slot>
  </div>
</template>

<script>
export default {
  data: function () {
    return {}
  },
  props: {},
  computed: {},
  mounted() {

  },
  methods: {}
}
</script>
