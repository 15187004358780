<template>
  <div>
    <div class="row">
      <div class="col-12">
        <card-field :modelValue="data.name" title="Название"/>
        <card-field :modelValue="data.organization" title="Организация"/>
        <card-field :modelValue="data.phone" title="Телефон"/>
        <card-field :modelValue="data.email" title="Email"/>
        <card-field :modelValue="data.is_send ? 'Отправлено' : 'Не отправлено'" title="Статус"/>
        <card-field :modelValue="toTime(data.send_at)" title="Дата отправления"/>
      </div>
    </div>

    <div class="mt-3">

      <div class="float-right">
        <button-remove @click="remove" v-if="can('module.submit_form_1.delete')"/>
      </div>


      <button-edit @click="$router.push({name: 'submit-form.edit', params: {id: data.id}})"
                   v-if="can('module.submit_form_1.update')"/>
    </div>
  </div>
</template>

<script>


export default {
  data() {
    return {}
  },
  props: {
    data: Object
  },
  mounted() {
  },
  methods: {

    remove() {
      $.alertConfirm({
        type: 'red',
      }, {
        ok: () => {
          axiosGetValue('/api/admin/submit-form/delete', {id: this.data.id}, () => {
            toastSuccess('Удаление завершено');
            this.$router.push({name: 'submit-form.list'})
          })
        }
      });
    },
  },
}
</script>