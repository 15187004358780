<template>
  <div class="invalid-feedback" v-if="error && error.length > 0">
    <small v-html="html"></small>
  </div>
</template>

<script>
export default {
  data: function () {
    return {}
  },
  props: {
    error: [String, Object, Array]
  },
  computed: {
    html() {
      let html = [];
      for (let i in this.error) {
        let t = this.error[i];
        html.push(t)
      }

      return html.join('; ')
    }
  },
  mounted() {

  },
  methods: {}
}
</script>
