import EmptyWrap from "@/components/Gui/EmptyWrap";

export default [
    {
        path: 'role',
        component: EmptyWrap,
        children: [
            {
                path: '',
                name: 'role.list',
                component: require('@/views/Role/RoleList').default,
            },
            {
                path: 'create',
                name: 'role.create',
                component: require('@/views/Role/RoleForm').default,
            },
            {
                path: ':id',
                component: require('@/views/Role/Card/WrapBlock').default,
                children: [
                    {
                        path: 'detail',
                        name: 'role.detail',
                        component: require('@/views/Role/Card/PermissionTab').default,
                    },
                ]
            },
            {
                path: ':id/edit',
                name: 'role.edit',
                component: require('@/views/Role/RoleForm').default,
            },
        ]
    },
]