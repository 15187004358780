<template>

  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8 pt-5 mt-5">


        <div class="card mt-5">
          <div class="card-header">
            Вход в систему
          </div>
          <div class="card-body">
            <form @submit.prevent="handleLogin">

              <div class="form-group row">
                <label for="username" class="col-sm-4 col-form-label text-md-right">Логин</label>
                <div class="col-md-6">
                  <input v-model="form.username" id="username" required class="form-control"/>
                </div>
              </div>

              <div class="form-group row">
                <label for="password" class="col-sm-4 col-form-label text-md-right">Пароль</label>
                <div class="col-md-6">
                  <input type="password" v-model="form.password" id="password" required class="form-control"/>
                </div>
              </div>

              <div class="form-group row mb-0">
                <div class="col-md-8 offset-md-4">
                  <button type="submit" class="btn btn-primary">
                    Войти
                  </button>
                </div>
              </div>

            </form>

          </div>

        </div>


      </div>
    </div>
  </div>


</template>

<script>
import {mapActions, mapGetters} from "vuex"
import {useRouter} from 'vue-router';

export default {
  mounted() {
  },
  setup() {
    const router = useRouter();
    return {
      router,
    };
  },
  data() {
    return {
      form: {
        username: "",
        password: ""
      }
    };
  },
  computed: {
    ...mapGetters("auth", [
      "authenticating",
      "authenticationError",
      "authenticationErrorCode"
    ])
  },
  methods: {
    ...mapActions("auth", ["signIn"]),
    async handleLogin() {
      this.signIn(this.form).then(() => {
        this.form.username = ""
        this.form.password = ""
        this.router.push("/")
      }).catch(async (err) => {
        console.log('err', err)
        alert('Failed')
      })
    }
  }
}
</script>