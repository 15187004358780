import EmptyWrap from "@/components/Gui/EmptyWrap";

export default [
    {
        path: 'dictionary-place',
        component: EmptyWrap,
        children: [
            {
                path: '',
                name: 'dictionary-place.list',
                component: require('@/views/DictionaryPlace/DictionaryPlaceList').default,
            },
            {
                path: 'create',
                name: 'dictionary-place.create',
                component: require('@/views/DictionaryPlace/DictionaryPlaceForm').default,
            },
            {
                path: ':id',
                component: require('@/views/DictionaryPlace/Card/WrapBlock').default,
                children: [
                    {
                        path: 'detail',
                        name: 'dictionary-place.detail',
                        component: require('@/views/DictionaryPlace/Card/DictionaryPlaceDetail').default,
                    },
                ]
            },
            {
                path: ':id/edit',
                name: 'dictionary-place.edit',
                component: require('@/views/DictionaryPlace/DictionaryPlaceForm').default,
            },
        ]
    },
]