import EmptyWrap from "@/components/Gui/EmptyWrap";

export default [
    {
        path: 'content',
        component: EmptyWrap,
        children: [
            {
                path: '',
                name: 'content.list',
                component: require('@/views/Content/ContentList').default,
            },
            {
                path: 'create',
                name: 'content.create',
                component: require('@/views/Content/ContentForm').default,
            },
            {
                path: ':id',
                component: require('@/views/Content/Card/WrapBlock').default,
                children: [
                    {
                        path: 'detail',
                        name: 'content.detail',
                        component: require('@/views/Content/Card/ContentDetail').default,
                    },
                ]
            },
            {
                path: ':id/edit',
                name: 'content.edit',
                component: require('@/views/Content/ContentForm').default,
            },
        ]
    },
]