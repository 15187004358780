<template>
  <div>
    <gui-select
        :modelValue="modelValue"
        @update:modelValue="e=>$emit('update:modelValue', e)"
        :label="label"
        :error="error"
        :hilightOn="hilightOn"
        :w="w"
        :sm="sm"
        :list="list">
      <option value="" disabled>Выберите тип контента</option>
    </gui-select>
  </div>
</template>

<script>
export default {
  name: 'content-type-select',
  data() {
    return {
      list: [],
    }
  },
  props: {
    label: String,
    error: [String, Object, Array],
    hilightOn: [Array, String, Number],
    modelValue: {
      type: [String, Number],
    },
    w: Boolean,
    sm: Boolean,
  },
  mounted() {
    this.loadList();
  },
  methods: {
    loadList() {
      axiosGetValue('/api/admin/content-type/widget', {}, e => {
        this.list = e
      })
    },
  }
}
</script>