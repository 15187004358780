import EmptyWrap from "@/components/Gui/EmptyWrap";

export default [
    {
        path: 'tariff',
        component: EmptyWrap,
        children: [
            {
                path: '',
                name: 'tariff.list',
                component: require('@/views/Tariff/TariffList').default,
            },
            {
                path: ':id',
                component: require('@/views/Tariff/Card/WrapBlock').default,
                children: [
                    {
                        path: 'detail',
                        name: 'tariff.detail',
                        component: require('@/views/Tariff/Card/TariffDetailTab').default,
                    },
                ]
            },
        ]
    },
]