<template>
  <card-block>
    <card-header>
      Список стран
      <template v-slot:right>
        <router-link :to="{name: 'country.create'}" v-if="can('module.country.create')">
          Добавить страну
        </router-link>
      </template>
    </card-header>
    <card-body>
      <spinner-block v-model="progress">
        <div class="mb-3 row">
          <div class="col-12 col-md-2">
            <div class="small">Статус</div>
            <gui-select sm v-model="filter.active" @change="getList" :hilightOn="['all']">
              <option value="active">Активные</option>
              <option value="deactive">Не активные</option>
              <option value="all">Все</option>
            </gui-select>
          </div>
          <div class="col-12 col-md-5">
          </div>
          <div class="col-12 col-md-5">
            <div class="small">Поиск</div>
            <gui-search sm v-model="filter.search" @change="getList" :hilightOn="['', undefined]"/>
          </div>
        </div>
        <div v-if="data && data.data && data.data.length">
          <table class="table table-hover">
            <thead>
            <tr>
              <th class="pr-0" style="width: 1rem;"></th>
              <th>Название</th>
              <th class="d-none d-md-table-cell">Код</th>
              <th class="text-right"></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="row in data.data" :key="row.id">
              <th class="pr-0">
                <i class="fas fa-circle small"
                   :title="row.is_active ? 'Активен' : 'Неактивен'"
                   :class="row.is_active ? 'text-success' : 'text-danger'"></i>
              </th>
              <td class="limit-half_length">
                <router-link :to="{name: 'country.detail', params: {id: row.id}}" class="main-link">
                  {{ row.name }}
                </router-link>
              </td>
              <td class="d-none d-md-table-cell limit-third_length">
                <div>
                  {{ row.code }}
                </div>
              </td>
              <td class="text-right">

              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div v-else class="alert alert-info">Список пуст</div>
      </spinner-block>
      <gui-paginate :data="data" ref="paginate" @change="getList"/>
    </card-body>
  </card-block>
</template>

<script>

export default {
  data: function () {
    return {
      data: null,
      progress: true,
      filter: {
        active: 'active',
        search: '',
      }
    }
  },
  props: {},
  computed: {},
  beforeMount() {
    this.filter = storageLoad('filter_' + this.$route.name, this.filter);
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {

      let v = _.clone(this.filter);
      v.search = '';
      storageSet('filter_' + this.$route.name, v);

      axiosGetValue('/api/admin/country/list', {
            page: this.$refs.paginate.currentPage,
            per_page: this.$refs.paginate.perPage,
            filter: this.filter,
          },
          e => this.data = e, e => this.progress = e
      )
    }
  }
}
</script>