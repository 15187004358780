<template>
  <div>

    <div class="mb-3 text-right">
      <button-create @click="$refs.modal.openModal()" v-if="can('module.user.organization-create')"/>
    </div>

    <spinner-block v-model="progress">

      <div v-if="listData && listData.length">
        <table class="table table-hover">
          <thead>
          <tr>
            <th>Организация</th>
            <th class="text-right"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="row in listData" :key="row.id">
            <td>
              <router-link :to="{name: 'organization.detail', params: {id: row.organization_id}}" class="main-link">
                {{ row.organization ? row.organization.name : '—' }}
              </router-link>
            </td>
            <td class="text-right">
              <button-edit icon sm @click="$refs.modal.openModal(row.id)"
                           v-if="can('module.user.organization-update')"/>
              <button-remove icon sm @click="remove(row)" v-if="can('module.user.organization-delete')"/>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div v-else class="alert alert-info">Список пуст</div>
    </spinner-block>

    <create-or-update-user-organization-modal ref="modal" :user="data" @update="getList"/>
  </div>
</template>

<script>
import CreateOrUpdateUserOrganizationModal from "@/views/User/Block/CreateOrUpdateUserOrganizationModal";

export default {
  components: {CreateOrUpdateUserOrganizationModal},
  data() {
    return {
      listData: null,
      progress: null,
    }
  },
  props: {
    data: Object,
  },
  mounted() {
    this.getList()
  },
  methods: {

    getList() {
      axiosGetValue('/api/admin/user-organization/list', {
            user_id: this.data.id,
          },
          e => this.listData = e, e => this.progress = e
      )
    },

    remove(row) {
      $.alertConfirm({}, {
        ok: () => {
          axiosGetValue('/api/admin/user-organization/delete', {id: row.id, user_id: row.user_id}, () => {
            toastSuccess('Удаление завершено');
            this.getList();
          })
        }
      });
    },
  }
}
</script>