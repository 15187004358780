<template>
  <div>
    <div v-if="data && data.data && data.data.length">
      <table class="table table-hover">
        <thead>
        <tr>
          <th class="pr-0" style="width: 1rem;"></th>
          <th>Клиент</th>
          <th class="d-none d-md-table-cell">Отряд</th>
          <th class="d-none d-md-table-cell">Статус</th>
          <th class="d-none d-md-table-cell">Создан</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="row in data.data" :key="row.id">
          <th class="pr-0">
            <i class="fas fa-circle small"
               :title="row.is_active ? 'Активен' : 'Неактивен'"
               :class="row.is_active ? 'text-success' : 'text-danger'"></i>
          </th>
          <td>


            <router-link :to="{name: 'client-call.detail', params: {id: row.id}}" class="main-link">

              <i class="fas fa-warning text-danger mr-2" v-if="row.is_warning"
                 title="Менеджеру нужно проверить оплаты"></i>
              <i class="fas fa-warning text-warning mr-2" v-if="row.is_debit"
                 title="Есть дебиторская задолженность"></i>

              #{{ row.id }} {{ row.client ? row.client.initials : '—' }}
            </router-link>
          </td>
          <td class="d-none d-md-table-cell">
            {{ row.client_call_squad && row.client_call_squad.squad ? row.client_call_squad.squad.name : '—' }}
          </td>
          <td class="d-none d-md-table-cell">
            {{ row.client_call_status ? row.client_call_status.name : '—' }}
          </td>
          <td class="d-none d-md-table-cell">
            {{ toTime(row.created_at) }}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div v-else class="alert alert-info">Список пуст</div>
  </div>
</template>

<script>

export default {
  data: function () {
    return {}
  },
  props: {
    data: Object
  },
}
</script>