<template>
  <div>
    <spinner-block v-model="progress">
      <subscription-list-block :data="listData" :ownerId="data.id"/>
    </spinner-block>
    <gui-paginate :data="listData" ref="paginate" @change="getList"/>
  </div>
</template>

<script>

import SubscriptionListBlock from "@/views/Subscription/SubscriptionListBlock";

export default {
  components: {SubscriptionListBlock},
  data() {
    return {
      listData: null,
      progress: null,
      filter: {
        client_id: null,
      }
    }
  },
  props: {
    data: Object,
  },
  mounted() {
    this.filter.client_id = this.data.id
    this.getList()
  },
  methods: {
    getList() {
      axiosGetValue('/api/admin/subscription/list', {
            filter: this.filter,
            page: this.$refs.paginate.currentPage,
            per_page: this.$refs.paginate.perPage,
          },
          e => this.listData = e, e => this.progress = e
      )
    },
  }
}
</script>