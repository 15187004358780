<template>
  <div>
    <header>
      <main-menu/>
    </header>

    <main role="main" class="container">
      <router-view></router-view>
    </main>
  </div>
</template>

<script>


import MainMenu from "@/components/MainMenu";

export default {
  components: {MainMenu}
}
</script>
