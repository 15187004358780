import EmptyWrap from "@/components/Gui/EmptyWrap";

export default [
    {
        path: 'release-note',
        component: EmptyWrap,
        children: [
            {
                path: '',
                name: 'release_note.list',
                component: require('@/views/ReleaseNote/ReleaseNoteList.vue').default,
            },
            {
                path: 'create',
                name: 'release_note.create',
                component: require('@/views/ReleaseNote/ReleaseNoteForm.vue').default,
            },
            {
                path: ':id',
                component: require('@/views/ReleaseNote/Card/WrapBlock').default,
                children: [
                    {
                        path: 'detail',
                        name: 'release_note.detail',
                        component: require('@/views/ReleaseNote/Card/ReleaseNoteDetail.vue').default,
                    },
                ]
            },
            {
                path: ':id/edit',
                name: 'release_note.edit',
                component: require('@/views/ReleaseNote/ReleaseNoteForm.vue').default,
            },
        ]
    },
]
