<template>
  <div>
    <div class="row">
      <div class="col-12">
        <card-field title="Название">
          <i class="fas fa-circle small"
             :title="data.is_active ? 'Активен' : 'Неактивен'"
             :class="data.is_active ? 'text-success' : 'text-danger'"></i>
          {{ data.name }}
        </card-field>

        <card-field :modelValue="data.code" title="Код"/>
        <card-field :modelValue="data.region ? data.region.name : '—'" title="Регион">
          <template v-if="data.region">
            <router-link :to="{name: 'region.detail', params: {id: data.region_id}}">
              {{ data.region.name }}
            </router-link>
          </template>
          <template v-else>—</template>
        </card-field>
        <card-field :modelValue="data.squad_search_term ? data.squad_search_term.name : '—'" title="Условия поиска"/>
        <card-field :modelValue="data.use_districts ? 'Да' : 'Нет'" title="Диспользовать районы"/>
      </div>

      <div class="col-12">
        <label class="col-form-label semi-small">Область</label>
        <map-polygon :polygons="data.area_array"/>
      </div>
    </div>

    <div class="mt-3">

      <div class="float-right">
        <button-remove @click="remove" v-if="can('module.city.delete')"/>
      </div>


      <button-edit @click="$router.push({name: 'city.edit', params: {id: data.id}})" v-if="can('module.city.update')"/>
    </div>
  </div>
</template>

<script>


import MapPolygon from "@/views/Map/MapPolygon";

export default {
  components: {MapPolygon},
  data() {
    return {}
  },
  props: {
    data: Object
  },
  mounted() {
  },
  methods: {

    remove() {
      $.alertConfirm({
        type: 'red',
      }, {
        ok: () => {
          axiosGetValue('/api/admin/city/delete', {id: this.data.id}, () => {
            toastSuccess('Удаление завершено');
            this.$router.push({name: 'city.list'})
          })
        }
      });
    },
  },
}
</script>