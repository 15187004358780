<template>
  <div>
    <div ref="modal" style="display: none">

      <div class="mb-3">
        <b>Добавление вопроса</b>
      </div>

      <div v-if="data">
        <gui-checkbox v-model="data.active" :error="dataError.active" label="Активный"/>
        <gui-input v-model="data.name" :error="dataError.name" label="Название"/>
        <gui-question-type-select v-model="data.type_question_id" :error="dataError.type_question_id"
                                  label="Тип пункта"/>

        <gui-textarea v-if="data.type_question_id==2" v-model="data.text" :error="dataError.text" label="Ответ"/>
        <gui-html v-if="data.type_question_id==5" v-model="data.text" :error="dataError.text" label="Ответ"/>
        <gui-input v-if="data.type_question_id==3" v-model="data.link" :error="dataError.link" label="Ссылка"/>
        <gui-deeplink-select v-if="data.type_question_id==4" v-model="data.link" :error="dataError.link" label="Ссылка" :application="data.application_type_id == 1 ? 'client' : 'squad'"/>
        <gui-input type="number" v-model="data.sort" :error="dataError.sort" label="Сортировка"/>


        <div class="mt-5">
          <button-save @click="add()"/>
          <button-cancel @click="fancyboxClose"/>
        </div>
      </div>

    </div>
  </div>

</template>

<script>

import GuiQuestionTypeSelect from "@/components/Widget/GuiQuestionTypeSelect.vue";
import GuiDeeplinkSelect from "@/components/Widget/GuiDeeplinkSelect.vue";

export default {
  components: {GuiDeeplinkSelect, GuiQuestionTypeSelect},
  data() {
    return {
      data: null,
      dataDefault: {
        name: null,
        link: null,
        text: null,
        active: true,
        sort: 100,
        application_type_id: 1,
        type_question_id: 1,
        parent_section_id: null
      },
      dataError: {
        name: '',
        link: '',
        text: '',
        active: '',
        sort: '',
        type_question_id: '',
      },
      dataProgress: false,
    }
  },
  props: {},
  methods: {
    openModal(props) {
      this.data = Object.assign({}, this.dataDefault, {
        parent_section_id: props.parent_section_id,
        application_type_id: props.application_type_id,
      })
      setTimeout(() => {
        this.fancybox(this.$refs.modal)
      }, 0)
    },
    update() {
      this.$emit('update')
      this.fancyboxClose()
    },
    add() {
      if (this.data.type_question_id != 2 && this.data.type_question_id != 5) {
        this.data.text = null
      }
      if (this.data.type_question_id != 3 && this.data.type_question_id != 4) {
        this.data.link = null
      }

      axiosFast({
        url: route('/api/admin/question/create'),
        data: {data: this.data},
        error: this.dataError,
        progress: e => this.dataProgress = e,
        result: (data) => {
          toastSuccess('Данные добавлены');
          this.$emit('add', data.id)
          this.fancyboxClose()
        },
      })
    }
  },
}
</script>
