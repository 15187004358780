<template>
  <card-block>
    <card-header>
      Список ответов на опросники
    </card-header>
    <card-body>
      <spinner-block v-model="progress">

        <div v-if="data && data.data && data.data.length">
          <table class="table table-hover">
            <thead>
            <tr>
              <th>Опросник</th>
              <th>Клиент</th>
              <th>Дата заполнения</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="row in data.data" :key="row.id">
              <td>
                <router-link :to="{name: 'quiz-answer.detail', params: {id: row.id}}" class="main-link">
                  {{ row.quiz_name }}
                </router-link>
              </td>
              <td>
                <span class="small text-secondary">
                  #{{ row.client_id }}
                </span>
                {{ row.client_name }}
              </td>
              <td>
                {{ toTime(row.created_at) }}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div v-else class="alert alert-info">Список пуст</div>
      </spinner-block>
      <gui-paginate :data="data" ref="paginate" @change="getList"/>
    </card-body>
  </card-block>
</template>

<script>

export default {
  components: {},
  data: function () {
    return {
      data: null,
      progress: true,
      filter: {}
    }
  },
  props: {},
  computed: {},
  beforeMount() {
    this.filter = storageLoad('filter_' + this.$route.name, this.filter);
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {

      let v = _.clone(this.filter);
      v.search = '';
      storageSet('filter_' + this.$route.name, v);

      axiosGetValue('/api/admin/quiz-answer/list', {
            page: this.$refs.paginate.currentPage,
            per_page: this.$refs.paginate.perPage,
            filter: this.filter,
          },
          e => this.data = e, e => this.progress = e
      )
    },
  }
}
</script>