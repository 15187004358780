<template>
  <card-block>
    <card-header>
      Список организаций
      <template v-slot:right>
        <router-link :to="{name: 'organization.create'}" v-if="can('module.organization.create')">
          Добавить организацию
        </router-link>
      </template>
    </card-header>
    <card-body>
      <spinner-block v-model="progress">
        <div class="mb-3 row">
          <div class="col-12 col-md-7">
          </div>
          <div class="col-12 col-md-5">
            <div class="small">Поиск</div>
            <gui-search sm v-model="filter.search" @change="getList" :hilightOn="['', undefined]"/>
          </div>
        </div>
        <div v-if="data && data.data && data.data.length">
          <table class="table table-hover">
            <thead>
            <tr>
              <th>Название</th>
              <th class="d-none d-md-table-cell">Номер договора</th>
              <th class="text-right"></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="row in data.data" :key="row.id">
              <td class="limit-half_length">
                <router-link :to="{name: 'organization.detail', params: {id: row.id}}" class="main-link">
                  {{ row.name }}
                </router-link>
              </td>
              <td class="d-none d-md-table-cell limit-half_length">
                <div>
                  {{ row.contract_number ? row.contract_number : '—' }}
                </div>
              </td>
              <td class="text-right">

              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div v-else class="alert alert-info">Список пуст</div>
      </spinner-block>
      <gui-paginate :data="data" ref="paginate" @change="getList"/>
      <p v-if="!can('module.organization.view_all-view')" class="text-secondary mt-3">
        Результат показан с учётом ограничения по организации
      </p>
    </card-body>
  </card-block>
</template>

<script>

export default {
  data: function () {
    return {
      data: null,
      progress: true,
      filter: {
        search: '',
      }
    }
  },
  props: {},
  computed: {},
  beforeMount() {
    this.filter = storageLoad('filter_' + this.$route.name, this.filter);
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {

      let v = _.clone(this.filter);
      v.search = '';
      storageSet('filter_' + this.$route.name, v);

      axiosGetValue('/api/admin/organization/list', {
            page: this.$refs.paginate.currentPage,
            per_page: this.$refs.paginate.perPage,
            filter: this.filter,
          },
          e => this.data = e, e => this.progress = e
      )
    }
  }
}
</script>