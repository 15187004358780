<template>
  <card-block>
    <spinner-block v-model="dataProgress">
      <template v-if="data">

        <card-header>
          <router-link :to="{name: 'quiz.list'}">Ответы на опросники</router-link><!--
                    -->:
          <span class="text-secondary">#{{ data.id }}</span>
          {{ data.quiz_name }}
        </card-header>

        <div class="m-3">
          <div class="row">

            <div class="col-12 col-md-9">
              <card-field :modelValue="data.quiz_name" title="Опросник"/>
              <card-field title="Заполнил опросник">
                <router-link v-if="data.client_type=='client'"
                             :to="{name: 'client.detail', params: {id: data.client_id}}">
                  #{{ data.client_id }} {{ data.client_name }}
                </router-link>
              </card-field>
              <card-field :modelValue="toTime(data.created_at)" title="Дата заполнения"/>
            </div>

          </div>
        </div>

        <tab-list>
          <tab-link to="quiz-answer.detail">Ответы</tab-link>
        </tab-list>
        <card-body>
          <router-view :key="id" :data="data" @change="getData"></router-view>
        </card-body>
      </template>
    </spinner-block>
  </card-block>
</template>

<script>

export default {
  components: {},
  data: function () {
    return {
      data: null,
      dataProgress: true,
    }
  },
  props: {},
  computed: {
    id() {
      return this.$route.params.id;
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      if (this.id) {
        axiosGetValue('/api/admin/quiz-answer/get', {id: this.id}, e => this.data = e, e => this.dataProgress = e)
      }
    },
  },
}
</script>