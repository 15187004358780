<template>
  <button-block
      :btnClass='btnClass'
      :text='text'
      :textIcon="textIcon"
      :sm="sm"
      :disabled="disabled"
  />
</template>

<script>
import ButtonBlock from "./ButtonBlock";

export default {
  components: {ButtonBlock},
  data: function () {
    return {}
  },
  props: {
    btnClass: {
      type: String,
      default: 'btn-secondary',
    },
    text: {
      type: String,
      default: 'Отмена',
    },
    textIcon: {
      type: String,
      default: 'fas fa-back',
    },
    sm: Boolean,
    disabled: Boolean,
  },
  computed: {},
  mounted() {

  },
  methods: {}
}
</script>
