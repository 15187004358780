<template>
  <card-block>
    <spinner-block v-model="dataProgress">
      <template v-if="data">

        <card-header>
          <router-link :to="{name: 'quiz.list'}">Опросники</router-link><!--
                    -->:
          <span class="text-secondary">#{{ data.id }}</span>
          {{ data.name }}
        </card-header>

        <div class="m-3">
          <div class="row">

            <div class="col-12 col-md-9">
              <card-field title="Название">
                <is-lock-badge v-model="data.is_lock"/>
                {{ data.name }}
              </card-field>

              <card-field :modelValue="data.code" title="Код"/>
              <card-field :modelValue="data.group_code" title="Код группы"/>
            </div>

          </div>

          <div class="mt-3">

            <div class="float-right">

              <button-remove @click="remove" v-if="can('module.quiz.delete')" :disabled="data.is_lock"/>

            </div>

            <button-edit @click="$router.push({name: 'quiz.edit', params: {id: data.id}})"
                         v-if="can('module.quiz.update')" :disabled="data.is_lock"/>
          </div>
        </div>

        <tab-list>
          <tab-link to="quiz.detail">Вопросы</tab-link>
        </tab-list>
        <card-body>
          <router-view :key="id" :data="data" @change="getData"></router-view>
        </card-body>
      </template>
    </spinner-block>
  </card-block>
</template>

<script>
import IsLockBadge from "@/components/Badge/IsLockBadge";

export default {
  components: {IsLockBadge},
  data: function () {
    return {
      data: null,
      dataProgress: true,
    }
  },
  props: {},
  computed: {
    id() {
      return this.$route.params.id;
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      if (this.id) {
        axiosGetValue('/api/admin/quiz/get', {id: this.id}, e => this.data = e, e => this.dataProgress = e)
      }
    },
    remove() {
      $.alertConfirm({
        type: 'red',
      }, {
        ok: () => {
          axiosGetValue('/api/admin/quiz/delete', {id: this.data.id}, () => {
            toastSuccess('Удаление завершено');
            this.$router.push({name: 'quiz.list'})
          })
        }
      });
    },
  },
}
</script>