<template>
  <div>
    <div class="row">
      <div class="col-12">
        <card-field title="Имя">
          <i class="fas fa-circle small"
             :title="data.is_active ? 'Активен' : 'Неактивен'"
             :class="data.is_active ? 'text-success' : 'text-danger'"></i>
        </card-field>
        
        <card-field title="Вопрос" v-html="data.question">
        </card-field>
        <card-field title="Ответ" v-html="data.answer">
        </card-field>
        <card-field :modelValue="data.sort" title="Сортировка"/>
        <card-field
            :modelValue="data.applications && data.applications.length ? data.applications.map(e=>e.name).join(', ') : '—'"
            title="Приложения"/>
      </div>
    </div>

    <div class="mt-3">

      <div class="float-right">
        <button-remove @click="remove" v-if="can('module.faq.delete')"/>
      </div>


      <button-edit @click="$router.push({name: 'faq.edit', params: {id: data.id}})" v-if="can('module.faq.update')"/>
    </div>
  </div>
</template>

<script>


export default {
  data() {
    return {}
  },
  props: {
    data: Object
  },
  mounted() {
  },
  methods: {

    remove() {
      $.alertConfirm({
        type: 'red',
      }, {
        ok: () => {
          axiosGetValue('/api/admin/faq/delete', {id: this.data.id}, () => {
            toastSuccess('Удаление завершено');
            this.$router.push({name: 'faq.list'})
          })
        }
      });
    },
  },
}
</script>