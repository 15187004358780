<template>
  <div class="input-group">
    <input type="text" class="form-control" :placeholder="placeholder"
           :class="{
            'form-control-sm':sm,
            'hilight-field': typeof hilightOn !== 'undefined' && !( typeof hilightOn =='object' ? hilightOn.indexOf(modelValue)!==-1 : hilightOn==modelValue )
        }"
           :value="modelValue"
           @input="$emit('update:modelValue', $event.target.value)"
           @keyup.enter="$emit('change')"
    >
    <div class="input-group-append">
      <button class="btn btn-danger" :class="{'btn-sm':sm}" type="button" v-if="modelValue"
              @click.prevent="$emit('update:modelValue',''),$emit('change')">
        <i class="fa fa-times"></i>
      </button>
      <button
          class="btn btn-primary" :class="{'btn-sm':sm}" type="button"
          @click.prevent="$emit('change')">
        <i class="fa fa-search"></i>
      </button>
    </div>
  </div>

</template>

<script>
export default {
  props: {
    modelValue: String,
    hilightOn: [Array, String, Number],
    placeholder: String,
    sm: Boolean
  }
}
</script>
