<template>
  <div>

    <div class="form-group row" v-if="(!edit && modelValue || edit)"
         :class="{'is-invalid': error && error.length > 0, 'mb-0': !label}"
    >

      <div :class="'col-12'" v-if="label">
        <label :for="name" class="col-form-label semi-small">{{ label }}</label>
      </div>

      <div class="col-12">

        <div v-if="edit">
          <editor
              :modelValue="modelValue"
              @update:modelValue="e=>$emit('update:modelValue', e)"
              :disabled="!edit"
              plugins="preview autolink fullscreen image link imagetools"
              :name="name"
              :id="name"
              class="rich-text"
              :class="error && error.length > 0 ? 'is-invalid' : ''"
              :placeholder="label"
              :init="{
                                language: 'ru',
                                convert_urls : false,
                                //gecko_spellcheck:true
                            }"
          />
          <error-block :error="error"/>
        </div>
        <div v-else>
          <div v-if="noBorder">
            <div v-html="strip_tags(modelValue)"></div>
          </div>
          <div v-else class="card bg-gray-100">
            <div v-html="strip_tags(modelValue)" class="card-body"></div>
          </div>
        </div>


      </div>

    </div>

  </div>

</template>

<script>
import Editor from '@tinymce/tinymce-vue';
import ErrorBlock from "../ErrorBlock";

export default {
  components: {
    ErrorBlock,
    'editor': Editor
  },
  data: () => {
    return {}
  },
  props: {
    autosize: Boolean,
    noBorder: Boolean,
    name: String,
    label: String,
    placeholder: String,
    error: [String, Object, Array],
    modelValue: String,
    edit: {
      type: Boolean,
      default: true
    },
  },
  computed: {},
  beforeMount() {


  },
  mounted() {

  },
  methods: {},
  watch: {}
}
</script>
