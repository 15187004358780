<template>
  <card-block>
    <card-header>
      Телефонные звонки
    </card-header>
    <card-body>
      <spinner-block v-model="progress">
        <div class="mb-3 row">
          <div class="col-12 col-md-7">
          </div>
          <div class="col-12 col-md-5">
            <div class="small">Поиск</div>
            <gui-search sm v-model="filter.search" @change="getList" :hilightOn="['', undefined]"/>
          </div>
        </div>
        <phone-call-list-block :data="data"/>
      </spinner-block>
      <gui-paginate :data="data" ref="paginate" @change="getList"/>
    </card-body>
  </card-block>
</template>

<script>

import PhoneCallListBlock from "@/views/PhoneCall/PhoneCallListBlock";

export default {
  components: {PhoneCallListBlock},
  data: function () {
    return {
      data: null,
      progress: true,
      filter: {
        search: '',
      }
    }
  },
  props: {},
  computed: {},
  beforeMount() {
    this.filter = storageLoad('filter_' + this.$route.name, this.filter);
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {

      let v = _.clone(this.filter);
      v.search = '';
      storageSet('filter_' + this.$route.name, v);

      axiosGetValue('/api/admin/phone-call/list', {
            page: this.$refs.paginate.currentPage,
            per_page: this.$refs.paginate.perPage,
            filter: this.filter,
          },
          e => this.data = e, e => this.progress = e
      )
    }
  }
}
</script>