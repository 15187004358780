<template>
  <div>
    <spinner-block v-model="dataProgress">
      <div v-if="data">
        <gui-checkbox v-model="data.is_all_cities" :error="dataError.is_all_cities"
                      label="Использовать для всех городов"/>

        <gui-city-multi-select v-model="data.cities_ids" :error="dataError.cities_ids" label="Города"/>
        <gui-input v-model="data.average_arrival_time_s" :error="dataError.average_arrival_time_s"
                   label="Среднее время прибытия" append="сек."/>
        <gui-input v-model="data.average_help_time_m" :error="dataError.average_help_time_m"
                   label="Среднее время оказания помощи" append="мин."/>

        <hr/>

        <gui-input v-model="data.tariff_detail_term.service_commission" append="%"
                   :error="dataError['tariff_detail_term.service_commission']" label="Комиссия сервиса"/>
        <gui-input v-model="data.tariff_detail_term.duration_base_time" append="мин."
                   :error="dataError['tariff_detail_term.duration_base_time']" label="Длительность базового времени"/>
        <gui-input v-model="data.tariff_detail_term.duration_ext_time" append="мин."
                   :error="dataError['tariff_detail_term.duration_ext_time']" label="Шаг доп. времени"/>

        <div class="row">
          <div class="col-12 col-md-6">
            <gui-input w sm v-model="data.tariff_detail_term.cost_delivery" append="&#8381;"
                       :error="dataError['tariff_detail_term.cost_delivery']" label="Стоимость подачи экипажа"/>
          </div>
          <div class="col-12 col-md-6">
            <gui-input w sm v-model="data.tariff_detail_term.old_cost_delivery" append="&#8381;"
                       :error="dataError['tariff_detail_term.old_cost_delivery']"
                       label="Старая стоимость подачи экипажа"/>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <gui-input w sm v-model="data.tariff_detail_term.cost_base_time" append="&#8381;"
                       :error="dataError['tariff_detail_term.cost_base_time']" label="Стоимость базового времени"/>
          </div>
          <div class="col-12 col-md-6">
            <gui-input w sm v-model="data.tariff_detail_term.old_cost_base_time" append="&#8381;"
                       :error="dataError['tariff_detail_term.old_cost_base_time']"
                       label="Старая стоимость базового времени"/>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <gui-input w sm v-model="data.tariff_detail_term.cost_ext_time" append="&#8381;"
                       :error="dataError['tariff_detail_term.cost_ext_time']" label="Стоимость шага доп. времени"/>
          </div>
          <div class="col-12 col-md-6">
            <gui-input w sm v-model="data.tariff_detail_term.old_cost_ext_time" append="&#8381;"
                       :error="dataError['tariff_detail_term.old_cost_ext_time']"
                       label="Старая стоимость шага доп. времени"/>
          </div>
        </div>


        <gui-squad-search-therm-payment-type-select v-model="data.tariff_detail_term.squad_search_term_payment_type_id"
                                                    :error="dataError['tariff_detail_term.squad_search_term_payment_type_id']"
                                                    label="Способ расчёта стоимости"/>


        <div class="mt-5">
          <button-save v-if="!data.id" @click="add()"/>
          <button-save v-else @click="save"/>
          <button-cancel @click="$emit('cancel')"/>
        </div>
      </div>
    </spinner-block>
  </div>
</template>

<script>
import GuiSquadSearchThermPaymentTypeSelect from "@/components/Widget/GuiSquadSearchTermPaymentTypeSelect";
import GuiCityMultiSelect from "@/components/Widget/GuiCityMultiSelect";

export default {
  components: {GuiCityMultiSelect, GuiSquadSearchThermPaymentTypeSelect},
  data() {
    return {
      data: null,
      dataDefault: {
        tariff_id: '',
        is_all_cities: '',
        average_arrival_time_s: '',
        average_help_time_m: '',
        cities_ids: [],
        tariff_detail_term: {
          service_commission: '',
          cost_delivery: '',
          cost_base_time: '',
          cost_ext_time: '',
          duration_base_time: '',
          duration_ext_time: '',
          old_cost_delivery: '',
          old_cost_base_time: '',
          old_cost_ext_time: '',
          squad_search_term_payment_type_id: 1,
        },
      },
      dataError: {
        is_all_cities: '',
        average_arrival_time_s: '',
        average_help_time_m: '',
        cities_ids: '',
        'tariff_detail_term.service_commission': '',
        'tariff_detail_term.cost_delivery': '',
        'tariff_detail_term.cost_base_time': '',
        'tariff_detail_term.cost_ext_time': '',
        'tariff_detail_term.duration_base_time': '',
        'tariff_detail_term.duration_ext_time': '',
        'tariff_detail_term.old_cost_delivery': '',
        'tariff_detail_term.old_cost_base_time': '',
        'tariff_detail_term.old_cost_ext_time': '',
        'tariff_detail_term.squad_search_term_payment_type_id': '',
      },
      dataProgress: false,
    }
  },
  props: {
    id: [String, Number],
    defaultParams: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
  },
  methods: {

    init() {
      if (this.id) {
        this.data = null
        this.loadData()
      } else {
        this.data = Object.assign({}, this.dataDefault, this.defaultParams)
      }
      clearErrorList(this.dataError)
    },
    loadData() {
      axiosGetValue('/api/admin/tariff-detail/get', {id: this.id}, e => this.data = e, e => this.dataProgress = e)
    },

    save() {
      axiosFast({
        url: route('/api/admin/tariff-detail/update'),
        data: {data: this.data, id: this.data.id},
        error: this.dataError,
        progress: e => this.dataProgress = e,
        result: () => {
          toastSuccess('Данные сохранены');
          this.$emit('save', this.data.id)
        },
      })
    },
    add() {
      axiosFast({
        url: route('/api/admin/tariff-detail/create'),
        data: {data: this.data},
        error: this.dataError,
        progress: e => this.dataProgress = e,
        result: (data) => {
          toastSuccess('Данные добавлены');
          this.$emit('add', data.id)
        },
      })
    },
  },
}
</script>