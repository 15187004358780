<template>
  <card-block>
    <spinner-block v-model="dataProgress">
      <template v-if="data">
        <card-header>
          <router-link :to="{name: 'subscription-chain.list'}">Цепочка подписок</router-link><!--
                    -->:
          <span class="text-secondary">#{{ data.id }}</span>

        </card-header>

        <card-body>
          <div class="row">
            <div class="col-6">


              <card-field title="Статус">
                <i class="fas fa-circle small"
                   :title="data.is_active ? 'Активен' : 'Неактивен'"
                   :class="data.is_active ? 'text-success' : 'text-danger'"></i>
                {{ data.subscription_status ? data.subscription_status.name : '—' }}
              </card-field>

              <card-field title="Клиент">
                <template v-if="data.client_id">
                  <router-link :to="{name: 'client.detail', params: {id: data.client_id}}" target="_blank">
                    #{{ data.client_id }} {{ data.client ? data.client.phone : '—' }}
                  </router-link>
                </template>
                <template v-else>—</template>
              </card-field>

              <card-field title="Тариф">
                <template v-if="data.tariff_id">
                  <router-link :to="{name: 'subscription-tariff.detail', params: {id: data.tariff_id}}" target="_blank">
                    #{{ data.tariff_id }} {{ data.tariff ? data.tariff.name : '—' }}
                  </router-link>
                </template>
                <template v-else>—</template>
              </card-field>

              <card-field title="Количество подписок в цепочке" :modelValue="data.subscription_count"/>


            </div>
            <div class="col-6">
              <card-field title="Дата активации" :modelValue="toTime(data.activated_at)"/>
              <card-field title="Дата деактивации" :modelValue="toTime(data.deactivated_at)"/>
              <card-field title="Стоимость согласно тарифу, руб" :modelValue="data.need_paid"/>
              <card-field title="Суммарная стоимость, руб" :modelValue="data.subscriptions_sum_need_paid"/>
              <card-field title="Суммарная стоимость без скидок, руб" :modelValue="data.subscriptions_sum_without_sale_paid"/>

              <card-field title="Максимальное количество человек в подписке">
                {{ data.max_peoples }}
              </card-field>
            </div>
          </div>
        </card-body>

        <tab-list>
          <tab-link to="subscription-chain.detail" v-if="can('module.subscription.view')">Периоды подписок
          </tab-link>
        </tab-list>
        <card-body>
          <router-view :key="id" :data="data" @change="getData"></router-view>
        </card-body>
      </template>
    </spinner-block>
  </card-block>
</template>

<script>
export default {
  data: function () {
    return {
      data: null,
      dataProgress: true,
    }
  },
  props: {},
  computed: {
    id() {
      return this.$route.params.id;
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      if (this.id) {
        axiosGetValue('/api/admin/subscription-chain/get', {id: this.id}, e => this.data = e, e => this.dataProgress = e)
      }
    },
  },
}
</script>
