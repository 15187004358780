import ApiService from "./api.service";
import {TokenService} from "./token.service";
import qs from "qs";
import {getCurrentToken} from "@/firebase";

class AuthenticationError extends Error {
    errorCode;

    constructor(errorCode, message) {
        super(message);
        this.name = this.constructor.name;
        if (message != null) {
            this.message = message;
        }
        this.errorCode = errorCode;
    }
}

const AuthService = {
    signIn: async function (signInData) {
        const requestData = {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            url: "/oauth/token",
            data: JSON.stringify({
                grant_type: "password",
                client_id: process.env.VUE_APP_CLIENT_ID,
                client_secret: process.env.VUE_APP_CLIENT_SECRET,
                push_token: getCurrentToken(),
                username: signInData.username,
                password: signInData.password
            })
        };

        try {
            const response = await ApiService.customRequest(requestData);
            TokenService.saveToken(response.data.data.access_token);
            TokenService.saveRefreshToken(response.data.data.refresh_token);
            ApiService.setHeader();
            await TokenService.loadUser()
            await TokenService.loadPermissions()

            return response.data.data.access_token;
        } catch (error) {
            this.catchError(error);
        }
    },

    refreshTokenHandle: null,

    refreshToken: function () {
        if (this.refreshTokenHandle) {
            return this.refreshTokenHandle
        } else {
            this.refreshTokenHandle = this.refreshTokenProcess()
            this.refreshTokenHandle.finally(() => {
                this.refreshTokenHandle = null;
            })
            return this.refreshTokenHandle;
        }
    },

    refreshTokenProcess: async function () {
        const refreshToken = TokenService.getRefreshToken();

        const requestData = {
            method: "post",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            url: "/oauth/token",
            data: qs.stringify({
                grant_type: "refresh_token",
                client_id: process.env.VUE_APP_CLIENT_ID,
                client_secret: process.env.VUE_APP_CLIENT_SECRET,
                refresh_token: refreshToken,
                push_token: getCurrentToken(),
                scope: '',
            })
        };

        try {
            const response = await ApiService.customRequest(requestData);

            TokenService.saveToken(response.data.data.access_token);
            TokenService.saveRefreshToken(response.data.data.refresh_token);
            ApiService.setHeader();
            await TokenService.loadUser()
            await TokenService.loadPermissions()

            return response.data.access_token;
        } catch (error) {
            throw new AuthenticationError(
                error.response.status,
                error.response.data.error_description
            );
        }
    },

    signOut() {
        TokenService.removeToken();
        TokenService.removeRefreshToken();
        TokenService.removeUser();
        TokenService.removeRoles();
        TokenService.removePermissions();
        ApiService.removeHeader();
    },

    signup: async function (email, password, name) {
        const signupData = {
            method: "post",
            headers: {"Content-Type": "application/json"},
            url: "/oauth/signup",
            data: {
                email: email,
                password: password,
                name: name
            }
        };

        try {
            return await ApiService.customRequest(signupData);
        } catch (error) {
            this.catchError(error);
        }
    },

    catchError: function (error) {
        let status;
        let description;

        if (error.response === undefined) {
            status = error.message;
            description = error.message;
        } else {
            status = error.response.status;
            description = error.response.data.error_description;
        }

        throw new AuthenticationError(status, description);
    }
}

export {AuthService, AuthenticationError};