import {AuthService} from "@/services/auth.service";
import {TokenService} from "@/services/token.service";

window.Pikaday = require('pikaday');

/**
 * Вывести сообщение
 * @param variant (success|error|warning|info) тип сообщения
 * @param message текст
 * @param delay время до автоскрытия (0 - не скрывать)
 */
window.toast = (variant, message, delay) => {

    window.VueApp.flash(message, variant, {timeout: delay});

};

window.toastSuccess = (message) => {
    $.alert({
        title: '',
        content: message,
        type: 'green',
        scrollToPreviousElement: false,
        scrollToPreviousElementAnimate: false,
        buttons: {
            ok: {
                text: 'Продолжить',
                btnClass: 'btn-green',
                keys: ['enter'],
            }
        }
    })
};

window.toastError = function (text, time, options) {
    $.alertError(Object.assign({}, {
        content: text,
        scrollToPreviousElement: false,
        scrollToPreviousElementAnimate: false,
    }, options))
};

window.toastWarning = function (text, time, options) {
    $.alertError(Object.assign({}, {
        type: 'orange',
        title: 'Сообщение',
        content: text,
        scrollToPreviousElement: false,
        scrollToPreviousElementAnimate: false,
        buttons: {
            ok: {
                text: 'Продолжить',
                keys: ['enter'],
                btnClass: 'btn-orange',
            }
        }
    }, options))
};


window.toastInfo = (text, time, options) => {
    $.alertError(Object.assign({}, {
        type: 'blue',
        title: 'Сообщение',
        content: text,
        scrollToPreviousElement: false,
        scrollToPreviousElementAnimate: false,
        buttons: {
            ok: {
                text: 'Продолжить',
                keys: ['enter'],
                btnClass: 'btn-blue',
            }
        }
    }, options))
};

/**
 * Вывести сообщение со списком ошибок
 * @param errors ассоциативный массив ошибок
 * @param errorList массив уже обработанных ошибок
 * @param errorText сообщение которое нужно показать если все ошибки были обработаны
 */
window.showErrors = (errors, errorList, errorText) => {

    let hasError = Object.keys(errors).length > 0;

    for (let key in errorList) if (Object.prototype.hasOwnProperty.call(errorList, key)) {

        if (Object.prototype.hasOwnProperty.call(errors, key)) {
            errorList[key] = errors[key];
            delete errors[key];
        } else {
            errorList[key] = [];
        }

    }


    let result = '';
    if (Object.keys(errors).length > 0) {

        for (let i in errors) {
            if (isNaN(i)) result += '<strong>' + i + '</strong>';
            result += '<ul>';
            if (typeof errors[i] === 'string')
                result += '<li>' + errors[i] + '</li>';

            else for (let k in errors[i]) {
                result += '<li>' + errors[i][k] + '</li>';
            }

            result += '</ul>';
        }

    }

    if (!result && errorText && hasError) window.toastError(errorText, 3000);
    else if (result) {
        window.toastError(result);
    }


};

/**
 *
 * param = {
 *     method = get\post
 *     params - get params
 *     data Object
 *     formData Object
 *     formDataIgnore Array
 *     error Object
 *     errorCustom boolean
 *     errorText String
 *     url  String
 *     progress
 *     then - callback
 *     result - callback
 *     finally - callback
 * }
 *
 * @returns {Q.Promise<T>}
 */
window.axiosFast = (param) => {

    param = Object.assign({
        error: {},
        errorText: 'Форма заполнена некорректно',
        formData: false,
        formDataIgnore: [],
        tryToRefreshAccess: true,
    }, param);

    let token = TokenService.getToken();

    let headers = {
        Authorization: token ? `Bearer ${token}` : ''
    };

    if (param.data && typeof param.data.append === 'function') {
        headers['Content-Type'] = 'multipart/form-data';
    }

    if (param.error && typeof param.error !== 'function') window.clearErrorList(param.error);
    if (typeof param.progress !== 'undefined') {
        if (typeof param.progress === "function") param.progress(true);
        else param.progress = true;
    }


    if (param.formData) {
        param.data = window.objectToFormData(param.formData, param.formDataIgnore);
    }

    return window.axios({
        method: param.method ? param.method : 'post',
        url: param.url,
        data: param.data,
        params: param.params,
        headers: headers
    })
        .then(response => {

            if (response.data === '') {
                window.toastInfo('Получен пустой ответ от сервера');
                console.error('axiosFast empty result', response)
            } else if (response.data.result) {
                if (param.then) param.then(response);
                if (typeof param.result === "function") param.result(response.data.data);
            } else {
                window.showErrors(response.data.error, param.error, param.errorText);
            }
        })
        .catch(error => {

            if (error.response) {

                let get_error_text = error => error.response.status + ' ' + error.response.statusText
                    + ('<br>' + error.response.config.url.name)
                    + (error.response.data.file ? '<br>' + error.response.data.file : '')
                    + (error.response.data.line ? '<br>Line: ' + error.response.data.line : '')
                    + (error.response.data.message ? '<br>' + error.response.data.message : '')
                    + (error.response.data.id ? '<br>ID ' + error.response.data.id : '');

                let send_error_to_log = error => {
                    console.group('axiosFast error');
                    console.error(error);
                    console.error(error.response);
                    console.groupEnd();
                };

                if (error.response.status == '401') {
                    if (param.tryToRefreshAccess) {
                        AuthService.refreshToken().then(() => {
                            axiosFast(Object.assign({}, param, {tryToRefreshAccess: false}))
                        }).catch(() => {
                            window.$.alert('Ваша сессия истекла. Запомните внесённые изменения и обновите страницу.');
                            AuthService.signOut();
                        })
                    } else {
                        window.$.alert('Ваша сессия истекла. Запомните внесённые изменения и обновите страницу.');
                    }
                } else if (error.response.data.validations)
                    if (typeof param.error === "function")
                        param.error(error.response.data.validations);
                    else
                        window.showErrors(error.response.data.validations, param.error, param.errorText);
                else if (error.response.data.message && error.response.data.file) {
                    send_error_to_log(error);
                    window.$.dialog({
                        type: 'red',
                        title: 'PHP error',
                        content: get_error_text(error)
                    });
                } else if (error.response.data.message) {
                    send_error_to_log(error);
                    window.toastError(get_error_text(error), 10000);
                } else {
                    send_error_to_log(error);
                    alert('Some axios errors, see console')
                }
            }
            if (param.catch) param.catch(error);
        })
        .finally(e => {

            if (param.finally) param.finally(e);
            if (typeof param.progress !== 'undefined')
                if (typeof param.progress === "function") param.progress(false);
                else param.progress = false;

        });

};

/**
 * Выбзвать метод api.module.%route% с параметрами data и поместить результат в variable
 * @param router_suffix
 * @param data
 * @param variable
 * @param progress
 * @returns {Q.Promise<T>}
 */
window.axiosGetValue = (router_suffix, data, variable, progress = null) => {
    return window.axiosFast({
        url: window.route(router_suffix),
        data: data,
        result: variable,
        progress: progress || null
    })
};

/**
 * Выбзвать метод как post форму, для скачивания документов
 * @param router_suffix
 * @param data
 * @returns {Q.Promise<T>}
 */
window.loadAsPostForm = (router_suffix, data) => {
    let form = document.createElement("form");
    form.method = "POST";
    form.target = "_blank";
    form.action = window.route(router_suffix);

    window.addHiddenInputToFormFromObject(form, {
        'api_token': TokenService.getToken(),
    })
    window.addHiddenInputToFormFromObject(form, data)

    document.body.appendChild(form);
    form.submit();
    form.remove()
};

/**
 * Добавить в форму список input hidden на основе массива
 * @param form
 * @param obj
 * @param prefix
 */
window.addHiddenInputToFormFromObject = (form, obj, prefix) => {
    Object.keys(obj).forEach(key => {
        if (({}.toString.call(obj[key])) === "[object Object]" ||
            ({}.toString.call(obj[key])) === "[object Array]") {
            window.addHiddenInputToFormFromObject(form, obj[key], prefix ? prefix + '[' + key + ']' : key)
        } else {
            window.addHiddenInputToForm(form, prefix ? prefix + '[' + key + ']' : key, obj[key])
        }
    })
}

/**
 * Добавить в форму один input hidden
 * @param form
 * @param name
 * @param value
 */
window.addHiddenInputToForm = (form, name, value) => {
    let element = document.createElement("input");
    element.type = 'hidden';
    element.value = value;
    element.name = name;
    form.appendChild(element);
}

/**
 * Очистить список ошибок
 * @param errorList
 */
window.clearErrorList = (errorList) => {
    for (let i in errorList) errorList[i] = [];
};

/**
 * Из списка с ошибками отделяет строки с указанным префиксом
 * В главном списке ошибок отделённые строки удаляются
 * @param error
 * @param prefix
 * @returns {{}}
 */
window.getErrorWithPrefix = (error, prefix) => {
    let sub_error = {};
    Object.keys(error).forEach(key => {
        if (key.indexOf(prefix) === 0) {
            sub_error[key.replace(prefix, '')] = error[key]
            delete error[key]
        }
    })
    console.log('sub_error', sub_error);
    return sub_error
};

/**
 * Преобразует многоуровненвый объект с файлами в объект FormData для ajax отправки
 * ! Числа в значениях преобразуются в строки
 * @param obj
 * @param ignoreFields
 * @param formData
 * @param prefix
 * @param level
 * @returns {*}
 */
window.objectToFormData = (obj, ignoreFields, formData, prefix, level) => {

    if (!level) level = 0;
    if (!prefix) prefix = '';
    if (!formData) formData = new FormData();

    if (level > 6) {
        console.error('too deep query (name, level):', prefix, level)
        return
    }

    if (typeof obj === 'object')
        for (let i in obj) if (Object.prototype.hasOwnProperty.call(obj, i)) {

            if (ignoreFields.indexOf(i) !== -1) continue;

            let name = prefix ? prefix + '[' + i + ']' : i;

            // console.log('property',name, typeof obj[i], obj[i] instanceof File);

            if (obj[i] instanceof File) // Добавляем как файл
                formData.append(name, obj[i]);

            else if ( // Перебираем объект\массив
                ({}.toString.call(obj[i])) === "[object Object]" ||
                ({}.toString.call(obj[i])) === "[object Array]"
            ) {

                let childIgnoreFields = ignoreFields
                    .map(e => e && e.indexOf(i + '.') === 0 ? e.replace(i + '.', '') : false);
                window.objectToFormData(obj[i], childIgnoreFields || [], formData, name, level + 1);

            } else if (obj[i] !== null) // Добавляем как строку
                formData.append(name, obj[i])

        }

    return formData;

};

/**
 * Получает значение по ключу в локальной памяти
 * @param key
 * @param default_callback
 */
window.storageLoad = function (key, default_callback) {

    let dat = localStorage.getItem(key);
    let res = {};

    if (dat) res = JSON.parse(dat);
    else if (typeof default_callback === 'function') res = default_callback();
    else res = default_callback;

    return res;

};

/**
 * Запоминает значение по ключу в локальной памяти
 * value=false, если нужно удалить значение
 * @param key
 * @param value
 */
window.storageSet = function (key, value) {
    if (value)
        localStorage.setItem(key, JSON.stringify(value));
    else
        localStorage.removeItem(key)
};

/**
 * Удаляет лишние теги и аттрибуты из html
 * @param html
 * @param white_tags
 * @param white_attr
 * @returns {*}
 */
window.strip_tags = function (html, white_tags, white_attr) {
    if (!white_tags) white_tags = "p, b, br, a, strong, img, div, sup, sub, code, " +
        "span, em, i, blockquote, pre, h1, h2, h3, h4, h5, " +
        "table, thead, tbody, tr, th, td, tfoot" +
        "ol, ul, li";
    let remove_tags = "style, script";

    if (!white_attr) white_attr = ['style', 'class', 'href', 'src', 'width', 'height'];
    let h = window.$('<div>' + html + '</div>');
    h.find(remove_tags).remove();
    h.find('*').each(function () {
        Object.values(this.attributes).map(a => {
            if (white_attr.indexOf(a.name) === -1) {
                window.$(this).removeAttr(a.name)
            }
        })
    }).not(white_tags).each(function () {
        window.$(this).replaceWith(window.$(this).contents());
    });
    return h[0].innerHTML;
};

/**
 * Подготовить plaintext к выводу в html
 * @param text
 * @returns {*}
 */
window.nl2br = function (text) {
    text = window.strip_tags(text || '', '', '');
    text = text.replace(/\n/g, '<br>');
    return text;
};

/**
 * Округлить число до 2х знаков после запятой
 * @param n
 * @returns {string|number}
 */
window.rnd = function (n) {
    if (typeof n === 'undefined') return '—';
    return parseFloat(n.toFixed(2))
};


/**
 * Выбор числительного окончания
 * @param number
 * @param txt [1 яблоко, 2 яблока, 10 яблок]
 * @returns {*}
 */
window.morphTo = function (number, txt) {
    let cases = [2, 0, 1, 1, 1, 2];
    return txt[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
}

/**
 * Получить значение из языкового пакета
 * @param string
 * @param args
 * @returns {*}
 */
window.trans = (string, args) => {
    let value = window._.get(window.localisations, string);

    window._.eachRight(args, (paramVal, paramKey) => {
        value = window._.replace(value, `:${paramKey}`, paramVal);
    });

    return typeof value === 'undefined' ? string : value;
};

/**
 * Инициировать поле Pikaday
 * @param el
 * @param options
 * @returns {*}
 */
window.makePikaday = function (el, options) {
    return new window.Pikaday(Object.assign({
        field: el,
        format: 'DD.MM.YYYY',
        keyboardInput: false,
        yearRange: [1920, new Date().getFullYear() + 5],
        i18n: {
            previousMonth: 'Предыдущий месяц',
            nextMonth: 'Следующий месяц',
            months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
            weekdays: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
            weekdaysShort: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб']
        },
        firstDay: 1
    }, options))
}


/**
 * Возвращает GET параметр из адресной строки, null если он не указан
 * @param name
 * @returns {*}
 * @constructor
 */
window.GET = function (name) {
    return (new URL(window.location.href)).searchParams.get(name);
}

/**
 * Формирует ссылку на апи
 * @param url
 * @returns {*}
 */
window.route = function (url) {
    return process.env.VUE_APP_API_URL + url;
}
