<template>
  <div>
    <div class="mb-3">
      <b>Смены отряда</b>
    </div>
    <spinner-block v-model="progress">
      <div v-if="dataTable && dataTable.data && dataTable.data.length">
        <table class="table table-hover">
          <thead>
          <tr>
            <th class="pr-0" style="width: 1rem;"></th>
            <th>Смена</th>
            <th>Начало</th>
            <th>Окончание</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="row in dataTable.data" :key="row.id">
            <th class="pr-0">
              <template v-if="row.squad_duty_status">
                <template v-if="row.squad_duty_status.code==='started'">
                  <i class="fas fa-circle small text-success" :title="row.squad_duty_status.name"></i>
                </template>
                <template v-if="row.squad_duty_status.code==='breaks'">
                  <i class="fas fa-circle small text-warning" :title="row.squad_duty_status.name"></i>
                </template>
              </template>
            </th>
            <td>
              <router-link :to="{name: 'squad-duty.detail', params: {id: row.id}}" class="main-link">
                #{{ row.id }}
              </router-link>
            </td>
            <td>
              {{ toTime(row.started_at) }}
            </td>
            <td>
              {{ toTime(row.ended_at) }}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div v-else class="alert alert-info">Список пуст</div>
    </spinner-block>
    <gui-paginate :data="dataTable" ref="paginate" @change="getList"/>
  </div>
</template>

<script>

export default {
  data: function () {
    return {
      dataTable: null,
      progress: true,
      filter: {},
    }
  },
  props: {
    data: Object,
  },
  computed: {},
  beforeMount() {
    this.filter = storageLoad('filter_' + this.$route.name, this.filter);
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {

      let v = _.clone(this.filter);
      v.search = '';
      storageSet('filter_' + this.$route.name, v);

      axiosGetValue('/api/admin/squad-duty/list', {
            page: this.$refs.paginate.currentPage,
            per_page: this.$refs.paginate.perPage,
            filter: Object.assign({}, this.filter, {
              squad_id: this.data.id
            }),
          },
          e => this.dataTable = e, e => this.progress = e
      )
    }
  }
}
</script>