<template>
  <div>
    <div class="row">
      <div class="d-block d-md-none col-md-3 mb-3">
        <img
            :src="data.squad && data.squad.photo_crew_chief_thumb_url ? data.squad.photo_crew_chief_thumb_url : '/images/no-photo.jpg'"
            class="img-thumbnail avatar-middle"/>
      </div>
      <div class="col-12 col-md-9">
        <card-field title="Статус">
          <template v-if="data.squad_duty_status">
            <template v-if="data.squad_duty_status.code==='started'">
              <i class="fas fa-circle small text-success" :title="data.squad_duty_status.name"></i>
            </template>
            <template v-if="data.squad_duty_status.code==='breaks'">
              <i class="fas fa-circle small text-warning" :title="data.squad_duty_status.name"></i>
            </template>
          </template>
          {{ data.squad_duty_status ? data.squad_duty_status.name : '—' }}
        </card-field>

        <card-field title="Отряд">
          <router-link :to="{name: 'squad.detail', params: {id: data.squad_id}}">
            {{ data.squad ? data.squad.name : '—' }}
          </router-link>
        </card-field>
        <card-field title="Период смены">
          {{ toTime(data.started_at) }} - {{ toTime(data.ended_at) }}
        </card-field>
        <card-field title="Машина">
          {{ data.car_number ? data.car_number : '—' }}
          {{ data.car_color ? data.car_color : '—' }}
          {{ data.car_model ? data.car_model : '—' }}
        </card-field>
        <card-field :modelValue="data.squad_composition" title="Состав отряда"/>
        <card-field :modelValue="data.fio" title="ФИО"/>
        <card-field :modelValue="data.phone_format" title="Телефон"/>
        <card-field :modelValue="data.count_people_in_squad" title="Количество человек в отряде"/>
      </div>

      <div class="d-none d-md-block col-md-3 text-right">
        <img
            :src="data.squad && data.squad.photo_crew_chief_thumb_url ? data.squad.photo_crew_chief_thumb_url : '/images/no-photo.jpg'"
            class="img-thumbnail avatar-middle"/>
      </div>

    </div>

    <spinner-block v-model="dataProgress">
      <div>
        <div>
          <button class="btn btn-secondary mb-2" @click.prevent="forceClose"
                  :disabled="data.squad_duty_status.code==='ended'"
                  v-if="can('module.squad.duty-update')">
            Принудительно завершить смену
          </button>

        </div>
        <div>
          <button class="btn btn-secondary" @click.prevent="clearCache" v-if="can('module.squad.update')">
            Очистить кеш
          </button>
        </div>


      </div>
    </spinner-block>


  </div>
</template>

<script>


export default {
  data() {
    return {
      dataProgress: false,
    }
  },
  props: {
    data: Object
  },
  mounted() {
  },
  methods: {
    forceClose() {
      $.alertConfirmForce({
        content: 'Смена будет принудительно завершена. Действие необратимо. Используйте если нет связи с отрядом для штатного завершения смены. Для продолжения введите слово "Подтверждаю" в поле ниже.'
      }, {
        ok: () => {
          if (this.data.id) {
            axiosGetValue('/api/admin/squad-duty/force-close', {id: this.data.id}, () => {
              this.$emit('change')
            }, e => this.dataProgress = e)
          }

        }
      });
    },

    clearCache() {
      $.alertConfirm({
        content: 'Использовать в случае когда информация в мобильном приложении отличается от данных в административной панеле. Продолжить?'
      }, {
        ok: () => {
          axiosGetValue('/api/admin/squad/clear-cache', {id: this.data.squad_id}, () => {
            toastSuccess('Кеш очищен');
          })
        }
      });
    }
  },
}
</script>