<template>
  <div>
    <spinner-block v-model="progress">
      <div class="mb-3 row">
        <div class="col-9 col-md-9">
          <div class="small">Поиск</div>
          <gui-search sm v-model="filter.search" @change="fetchChatList" :hilightOn="['', undefined]"/>
        </div>
        <div class="col-3 col-md-3">
          <div class="popup-wrapper text-right" style="position: relative">
            <div class="small">Фильтр</div>
            <button @click="toggleFilterPopup" class="btn btn-sm btn-outline-secondary" ref="openPopup"><i class="fas fa-filter"></i></button>
            <div v-if="showFilterPopup" class="filter-popup text-left" ref="filterPopup">
              <div class="mb-3 row">
                <div class="col-12 col-md-12 mt-4">
                  <div class="small">Сортировка по дате последнего сообщения</div>
                  <gui-select sm v-model="filter.orderDirection" @change="fetchChatList" :hilightOn="['DESC']">
                    <option value="DESC">По убыванию</option>
                    <option value="ASC">По возрастанию</option>
                  </gui-select>
                </div>
                <div class="col-12 col-md-12 mt-4">
                  <div class="small">Статус</div>
                  <gui-select sm v-model="filter.chatStatus" @change="fetchChatList" :hilightOn="['all']">
                    <option value=all>Все</option>
                    <option value="active">Только активные</option>
                    <option value=inactive>Только архивные</option>
                  </gui-select>
                </div>
                <div class="col-12 col-md-12 mt-4">
                  <gui-checkbox noSpan noMargin sm  v-model="filter.hasManager" label="В работе" @change="fetchChatList"/>
                  <gui-checkbox noSpan noMargin sm  v-model="filter.withMessages" label="Только чаты с сообщениями" @change="fetchChatList"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ul v-if="chatsList && chatsList.length" class="list-group">
        <router-link
            v-for="chat in chatsList"
            :key="chat.uuid"
            :to="{
            name: 'chat.detail',
            params: { uuid: chat.uuid }
          }"
            class="list-group-item list-group-item-action cursor-pointer pl-2 py-3"
            :class="{ 'active': chat.uuid === uuid }"
        >
          <div class="float-right">

              <span class="small" v-if="chat.has_unseen_messages && chat.is_visited"
                    title="Есть непрочитанные сообщения">
                <i class="fas fa-circle text-danger"></i>
              </span>

          </div>
          <div>
            <chat-member-badge :member="chat.initiator" class="mr-2"/>
            {{ chat.name }}
          </div>
          <div class="small mt-2">
            <div class="float-right">
              <span v-if="chat.last_message_date" :title="toTime(chat.last_message_date)">
                {{ toChatTime(chat.last_message_date) }}
              </span>
            </div>
            <template v-if="chat.is_active">
              <span class="badge badge-danger mr-2" v-if="!chat.has_manager"
                    title="К чату ещё не подключился менеджер">
                  Требуется менеджер
              </span>
              <span class="badge badge-success mr-2" v-if="chat.is_visited"
                    title="Вы подключены к чату">
                  В работе
              </span>
            </template>
            <template v-else>
              <span class="badge badge-secondary mr-2" title="Чат помечен неактивным">
                Архив
              </span>
              <span class="badge badge-secondary mr-2" v-if="chat.is_visited"
                    title="Вы подключены к чату">
                Ваш чат
            </span>
            </template>

          </div>
        </router-link>
      </ul>

      <div v-else class="alert alert-info">Список пуст</div>

      <paginate-sm-block :data="paging" ref="paginate" class="mt-4" @change="fetchChatList"/>
    </spinner-block>
  </div>
</template>

<script>
import PaginateSmBlock from "@/components/Gui/PaginateSmBlock";
import {mapState} from "vuex";
import {TokenService} from "@/services/token.service";
import ChatMemberBadge from "@/components/Badge/ChatMemberBadge";

export default {
  components: {
    ChatMemberBadge,
    PaginateSmBlock
  },

  data: function () {
    return {
      progress: true,
      firstLoad: true,
      showFilterPopup: false,
      filter: {
        search: '',
        hasManager: false,
        withMessages: true,
        chatStatus: 'all',
        type: 'support',
        withLastMessageDate: true,
        order: 'last_message_date',
        orderDirection: 'DESC',
      }
    }
  },

  props: {},

  computed: {
    chatsList() {
      const userId = TokenService.getUser().id

      return this.chats && this.chats.length
          ? this.chats.map(e => {
            const currentMember = e.members.find(e => {
              return e.user_id == userId && e.provider === 'users'
            })
            const manager = e.members.find(e => {
              return e.provider === 'users'
            })
            e.is_visited = !!currentMember
            e.has_manager = !!manager
            e.has_unseen_messages = currentMember && currentMember.seen_at < e.last_message_date
            e.last_seen_at = currentMember ? currentMember.seen_at : null
            return e
          })
          : this.chats
    },
    uuid() {
      return this.$route.params.uuid;
    },

    ...mapState({
      chats: (state) => state.chat.chats,

      paging: (state) => {
        const p = state.chat.chatPaging;

        return {
          list_from: p.from,
          list_to: p.to,
          list_total: p.total,
          last_page: p.last_page,

          total: p.total,
          perPage: p.per_page,
          currentPage: p.current_page,
        }
      },
    })
  },

  beforeMount() {
    this.filter = storageLoad('filter_' + this.$route.name, this.filter);
  },

  async mounted() {
    document.addEventListener('click', this.closeFilterPopupOutside);
    await this.fetchChatList();
    this.$chat.onMessageSent(this.onMessageSent)
  },

  unmounted() {
    document.removeEventListener('click', this.closeFilterPopupOutside);
    this.$chat.offMessageSent(this.onMessageSent)
  },
  methods: {
    toggleFilterPopup() {
      this.showFilterPopup = !this.showFilterPopup;
    },
    closeFilterPopupOutside(event) {
      const filterPopup = this.$refs.filterPopup;
      const openFilterButton = this.$refs.openPopup;
      if (this.showFilterPopup && filterPopup && !filterPopup.contains(event.target) && !openFilterButton.contains(event.target)) {
        this.showFilterPopup = false;
      }
    },
    
    update() {
      this.fetchChatList()
    },
    onMessageSent(message) {
      if (!this.chats || this.uuid === message.chat_uuid) return
      this.fetchChatList()
    },
    async fetchChatList() {

      if (this.firstLoad) {
        this.progress = true;
        this.firstLoad = false;
      }

      const result = await this.$chat.getChatList({
        per_page: this.$refs.paginate.perPage,
        page: this.$refs.paginate.currentPage,
        filter: this.filter,
      });

      this.$store.dispatch('chat/updateOldChats');
      this.$store.commit('chat/updateChatList', result);

      this.progress = false;
    }
  }
}
</script>
<style>
  .filter-popup {
    position: absolute;
    top: 55px;
    left: 0;
    background: white;
    padding: 10px;
    border: 1px solid #ccc;
    z-index: 10;
    width: 300px;
  }
</style>