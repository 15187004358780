import {createRouter, createWebHistory} from 'vue-router'
import DashboardLayout from "@/layouts/DashboardLayout";
import FullPageLayout from "@/layouts/FullPageLayout";
import {TokenService} from "@/services/token.service";

/**
 * Пример подключения компонетов асинхронно
 * component: () => import(/ * webpackChunkName: "about" * / '../views/AboutView.vue')
 */

const routes = [
    {
        path: '/',
        component: DashboardLayout,
        children: [
            {
                path: '',
                name: 'main.page',
                component: require('@/views/HomeView').default,
            },
            {
                path: 'demo',
                name: 'test.page',
                component: require('@/views/Test/TestPage').default,
            },
            {
                path: 'map',
                name: 'map.page',
                component: require('@/views/Map/MapPage').default,
            },
            {
                path: 'error',
                name: 'error.page',
                component: require('@/views/Error/ErrorPage').default,
            },
            {
                path: 'actions',
                name: 'actions',
                component: require('@/views/Actions/ActionsPage').default,
            },
            ...require('./user').default,
            ...require('./organization').default,
            ...require('./squad-search-terms').default,
            ...require('./client').default,
            ...require('./client-call').default,
            ...require('./client-call-squad').default,
            ...require('./client-document-type').default,
            ...require('./client-document-group').default,
            ...require('./payment').default,
            ...require('./squad').default,
            ...require('./squad-duty').default,
            ...require('./city').default,
            ...require('./region').default,
            ...require('./country').default,
            ...require('./messenger').default,
            ...require('./submit-form').default,
            ...require('./content').default,
            ...require('./dictionary-color').default,
            ...require('./dictionary-place').default,
            ...require('./faq').default,
            ...require('./tariff').default,
            ...require('./quiz').default,
            ...require('./role').default,
            ...require('./chat').default,
            ...require('./quiz-answer').default,
            ...require('./report').default,
            ...require('./phone-call').default,
            ...require('./subscription-tariff').default,
            ...require('./subscription').default,
            ...require('./subscription-chain').default,
            ...require('./district').default,
            ...require('./mobile-app').default,
            ...require('./discount').default,
            ...require('./promocode').default,
            ...require('./question').default,
            ...require('./business-company').default,
            ...require('./release-note').default,
        ]
    },
    {
        path: '/',
        component: FullPageLayout,
        children: [
            {
                path: '/login',
                name: 'login',
                component: require("@/views/Auth/LoginView").default,
                meta: {
                    public: true,
                    onlyWhenLoggedOut: true
                }
            }
        ]
    },

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    const isPublic = to.matched.some(record => record.meta.public);
    const onlyWhenLoggedOut =
        to.matched.some(record => record.meta.onlyWhenLoggedOut)
    const loggedIn = !!TokenService.getToken();
    if (!isPublic && !loggedIn) {
        return next({
            path: "/login",
            query: {redirect: to.fullPath}
        });
    }
    if (loggedIn && onlyWhenLoggedOut) {
        return next('/')
    }
    next();
});


export default router
