<template>
  <card-block>
    <card-header>

      <template v-if="id">
        <router-link :to="{name: 'city.detail', params: {id: id}}">Город</router-link><!-- -->:
        редактирование
      </template>

      <template v-else>
        <router-link :to="{name: 'city.list'}">Города</router-link><!-- -->:
        создание
      </template>

    </card-header>
    <card-body>

      <city-form-block
          ref="form"
          :id="id"
          @save="(id)=>$router.push({name: 'city.detail', params: {id: id}})"
          @add="(id)=>$router.push({name: 'city.detail', params: {id: id}})"
          @cancel="history_back"
      />

    </card-body>
  </card-block>
</template>

<script>
import CityFormBlock from "@/views/City/CityFormBlock";

export default {
  components: {CityFormBlock},
  computed: {
    id() {
      return this.$route.params.id
    }
  },
  mounted() {
    this.$refs.form.init()
  },
  methods: {},
}
</script>