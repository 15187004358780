<template>
  <div>
    <gui-select
        :modelValue="modelValue"
        @update:modelValue="e=>$emit('update:modelValue', e)"
        :label="label"
        :error="error"
        :hilightOn="hilightOn"
        :w="w"
        :sm="sm"
        :list="list">
    </gui-select>
  </div>
</template>

<script>

const recursiveHandler = (list, prefix = '', excludeQuestionTypeId) => {
  let result = []
  for (let row of list) {


    if (!(excludeQuestionTypeId.indexOf(row.type_question_id) !== -1)) {
      result.push({
        id: row.id,
        name: (prefix ? prefix + ' - ' : '') + row.name + ' id: ' + row.id + '',
      })
    }

    if (row.children && row.children.length) {
      result = result.concat(recursiveHandler(row.children, (prefix ? prefix + ' - ' : '') + row.name, excludeQuestionTypeId))
    }

  }

  return result;
}

export default {
  name: 'gui-question-element-select',
  data() {
    return {
      list: [],
    }
  },
  props: {
    application_type_id: [String, Number],
    label: String,
    error: [String, Object, Array],
    hilightOn: [Array, String, Number],
    modelValue: {
      type: [String, Number],
    },
    w: Boolean,
    sm: Boolean,
    clearable: Boolean,
    excludeQuestionTypeId: {
      type: Array,
      default: () => []
    },
  },
  mounted() {
    this.loadList();
  },
  methods: {
    loadList() {
      axiosGetValue('/api/admin/question/list', {application_type_id: this.application_type_id}, e => {
        this.list = [{
          id: '',
          name: 'Не выбран',
        }].concat(recursiveHandler(e, 'Корень', this.excludeQuestionTypeId))

      })
    },
  }
}
</script>
