export const playNotificationSound = () => {
  let audio = new Audio('/audio/notification.wav');

  audio.play()
    .then(() => {
      audio = null;
    })
    .catch(console.error)
    .finally(() => {
      audio = null;
    });
}
