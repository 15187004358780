import EmptyWrap from "@/components/Gui/EmptyWrap";

export default [
    {
        path: 'quiz',
        component: EmptyWrap,
        children: [
            {
                path: '',
                name: 'quiz.list',
                component: require('@/views/Quiz/QuizList').default,
            },
            {
                path: 'create',
                name: 'quiz.create',
                component: require('@/views/Quiz/QuizForm').default,
            },
            {
                path: ':id',
                component: require('@/views/Quiz/Card/WrapBlock').default,
                children: [
                    {
                        path: 'detail',
                        name: 'quiz.detail',
                        component: require('@/views/Quiz/Card/QuizQuestionTab').default,
                    },
                ]
            },
            {
                path: ':id/edit',
                name: 'quiz.edit',
                component: require('@/views/Quiz/QuizForm').default,
            },
        ]
    },
]