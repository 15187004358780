import EmptyWrap from "@/components/Gui/EmptyWrap";

export default [
    {
        path: 'message',
        component: EmptyWrap,
        children: [
            {
                path: '',
                name: 'chat.list',
                component: require('@/views/Chat/ChatPage').default,
            },
            {
                path: ':uuid',
                name: 'chat.detail',
                component: require('@/views/Chat/ChatPage').default,
            },
        ]
    },
]