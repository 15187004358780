<template>
  <div>
    <div ref="modal" style="display: none">

      <div class="mb-3">
        <b>Редактирование вопроса</b>
      </div>

      <question-form-block
          ref="form"
          :id="id"
          :defaultParams="defaultParams"
          @save="update"
          @add="update"
          @cancel="fancyboxClose"
      />

    </div>
  </div>

</template>

<script>

import QuestionFormBlock from "@/views/Question/QuestionFormBlock.vue";

export default {
  components: {QuestionFormBlock},
  data() {
    return {
      id: null,
    }
  },
  props: {
    defaultParams: {
      type: Object,
      default: () => ({})
    },
  },
  methods: {
    openModal(data_id) {
      this.id = data_id
      setTimeout(() => {
        this.$refs.form.init();
        this.fancybox(this.$refs.modal)
      }, 0)
    },
    update() {
      this.$emit('update')
      this.fancyboxClose()
    },
  },
}
</script>
