<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-6">

        <card-field title="Номер вызова отряда">
          #{{ data.id }}
          <span class="small text-secondary">
            <span class="small">
              [{{ data.uuid }}]
            </span>
          </span>
        </card-field>

        <card-field :modelValue="data.client_call_squad_status.name" title="Статус вызова отряда"/>
        <card-field :modelValue="data.squad_status.name" title="Статус отряда во время вызова"/>

        <card-field :modelValue="data.comment" title="Комментарий"/>
        <card-field :modelValue="data.comment_cancel" title="Комментарий отмены"/>
        <card-field
            :modelValue="data.client_call_squad_cancel_reason ? data.client_call_squad_cancel_reason.name : '—'"
            title="Причина отмены"/>

      </div>
      <div class="col-12 col-md-6">
        <card-field :modelValue="toTime(data.date_start)" title="Дата начала"/>
        <card-field :modelValue="toTime(data.date_cancel)" title="Дата отмены"/>
        <card-field :modelValue="toTime(data.date_accept)" title="Дата принятия"/>
        <card-field :modelValue="toTime(data.date_arrive)" title="Дата прибытия"/>
        <card-field :modelValue="toTime(data.date_finish)" title="Дата окончания"/>
        <card-field :modelValue="toTime(data.date_cancel_squad)" title="Дата отмены отрядом"/>
        <card-field :modelValue="data.time_duration" title="Время работы, с"/>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  data() {
    return {}
  },
  props: {
    data: Object
  },
  mounted() {
  },
  methods: {},
}
</script>