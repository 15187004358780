<template>
  <card-block>
    <spinner-block v-model="dataProgress">
      <div v-if="data">
        <card-header>
          <template v-if="id">
            <router-link :to="{name: 'messenger.detail', params: {id: id}}">Мессенджер</router-link><!-- -->:
            редактирование

          </template>
          <template v-else>
            <router-link :to="{name: 'messenger.list'}">Мессенджеры</router-link><!-- -->:
            создание
          </template>


        </card-header>
        <card-body>

          <gui-checkbox v-model="data.is_active" :error="dataError.is_active" label="Активный"/>
          <gui-select v-model="data.type" label="Тип" :error="dataError.type" :list="[
              {
                'id': 'whatsapp',
                'name': 'whatsapp'
              },
              {
                'id': 'vk',
                'name': 'vk'
              },
              {
                'id': 'instagram',
                'name': 'instagram'
              },
              {
                'id': 'telegram',
                'name': 'telegram'
              },
              {
                'id': 'phone',
                'name': 'phone'
              },
          ]">
            <option value="" disabled>Выберите тип</option>
          </gui-select>
          <gui-input v-model="data.link" :error="dataError.link" label="Ссылка"/>
          <gui-input v-model="data.sort" :error="dataError.sort" label="Сортировка"/>

          <div class="mt-5">
            <button-save v-if="!data.id" @click="add()"/>
            <button-save v-else @click="save"/>
            <button-back/>
          </div>
        </card-body>
      </div>
    </spinner-block>
  </card-block>
</template>

<script>


export default {
  data() {
    return {
      data: null,
      dataDefault: {
        type: '',
        link: '',
        sort: 100,
        is_active: '',
      },
      dataError: {
        type: '',
        link: '',
        sort: '',
        is_active: '',
      },
      dataProgress: false,
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    }
  },
  mounted() {
    this.init()
  },
  methods: {

    init() {
      if (this.id) {
        this.data = null
        this.loadData()
      } else {
        this.data = _.clone(this.dataDefault)
      }
    },
    loadData() {
      axiosGetValue('/api/admin/messenger/get', {id: this.id}, e => this.data = e, e => this.dataProgress = e)
    },

    save() {
      axiosFast({
        url: route('/api/admin/messenger/update'),
        data: {data: this.data, id: this.data.id},
        error: this.dataError,
        progress: e => this.dataProgress = e,
        result: () => {
          toastSuccess('Данные сохранены');
          this.$router.push({name: 'messenger.detail', params: {id: this.data.id}})
        },
      })
    },
    add() {
      axiosFast({
        url: route('/api/admin/messenger/create'),
        data: {data: this.data},
        error: this.dataError,
        progress: e => this.dataProgress = e,
        result: (data) => {
          toastSuccess('Данные добавлены');
          this.$router.push({name: 'messenger.detail', params: {id: data.id}})
        },
      })
    },
  },
}
</script>