<template>
  <button-block
      :btnClass='disabled ? "btn-secondary" : btnClass'
      :text='text'
      :textIcon="textIcon"
      :sm="sm"
      :icon="icon"
      :disabled="disabled"
  />
</template>

<script>
import ButtonBlock from "./ButtonBlock";

export default {
  components: {ButtonBlock},
  data: function () {
    return {}
  },
  props: {
    btnClass: {
      type: String,
      default: 'btn-danger',
    },
    text: {
      type: String,
      default: 'Удалить',
    },
    textIcon: {
      type: String,
      default: 'fas fa-trash',
    },
    sm: Boolean,
    icon: Boolean,
    disabled: Boolean,
  },
  computed: {},
  mounted() {

  },
  methods: {}
}
</script>
