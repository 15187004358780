<template>
  <card-block>
    <spinner-block v-model="dataProgress">
      <template v-if="data">
        <card-header>
          <router-link :to="{name: 'client-call.list'}">Вызовы</router-link><!--
                    -->:
          <span class="text-secondary">#{{ data.id }}</span>
          {{ data.name }}
        </card-header>
        <div class="m-3">

          <div class="row">
            <div class="col-12 col-md-6">

              <card-field title="Статус вызова">
                <i class="fas fa-circle small"
                   :title="data.is_active ? 'Активен' : 'Неактивен'"
                   :class="data.is_active ? 'text-success' : 'text-danger'"></i>
                {{ data.client_call_status ? data.client_call_status.name : '—' }}
              </card-field>

              <card-field title="Подписка пользователя">

                <router-link :to="{name: 'subscription.detail', params: {id: data.subscription.id}}"
                             v-if="data.subscription">
                  #{{ data.subscription.id }}
                </router-link>
                <template v-else>—</template>

              </card-field>


            </div>
            <div class="col-12 col-md-6">


              <card-field :modelValue="toTime(data.created_at)" title="Дата создания"/>

              <card-field title="Тариф" v-if="data.tariff_detail_term">

                <a href="#" @click.prevent="$refs.tariffDetailModal.openModal(data.tariff_detail_term_id)">
                  {{
                    data.tariff_detail_term.tariff_detail && data.tariff_detail_term.tariff_detail.tariff ? data.tariff_detail_term.tariff_detail.tariff.name : '—'
                  }}
                  v{{ data.tariff_detail_term.version }}
                </a>
                <view-tariff-detail-term-modal ref="tariffDetailModal"/>

              </card-field>


            </div>

            <div class="col-12 mt-4"></div>

            <div class="col-12 col-md-6">
              <card-field title="Клиент">
                <router-link :to="{name: 'client.detail', params: {id: data.client_id}}">
                  #{{ data.client_id }} {{ data.client ? data.client.initials : '—' }}
                </router-link>
              </card-field>
              <card-field title="Вызов клиенту">
                <template v-if="data.to_client_id">
                  <router-link :to="{name: 'client.detail', params: {id: data.to_client_id}}">
                    #{{ data.to_client_id }}
                  </router-link>
                </template>
                <template v-else>—</template>
              </card-field>
              <card-field :modelValue="data.client && data.client.phone ? data.client.phone : '—'"
                          title="Номер телефона клиента, указанный при регистрации"/>
              <card-field :modelValue="data.actual_phone" title="Номер телефона клиента, указанный при вызове"/>
              <card-field :modelValue="data.address_home ? data.address_home : '—'" title="Адрес офиса"/>
              <card-field :modelValue="data.address_office ? data.address_office : '—'" title="Адрес дома"/>
              <card-field title="Предположительный адрес вызова">
                <template v-if="data.geo_client_start_object">
                  <a :href="'https://maps.yandex.ru/?text=' + data.geo_client_start_object.lat + ' ' + data.geo_client_start_object.lon" target="_blank">
                    ({{ data.geo_client_start_object.lat }},{{ data.geo_client_start_object.lon }})
                  </a>
                </template>
                <template v-else>—</template>
              </card-field>
              <card-field :modelValue="data.need_ambulance ? 'Да' : 'Нет'" title="Нужна скорая помощь"/>
            </div>
            <div class="col-12 col-md-6">
              <card-field title="Активный вызов отряда">
                <router-link :to="{name: 'client-call-squad.detail', params: {id: data.client_call_squad.id}}"
                             v-if="data.client_call_squad">
                  #{{ data.client_call_squad.id }}
                  {{
                    data.client_call_squad && data.client_call_squad.squad ? data.client_call_squad.squad.name : '—'
                  }}
                </router-link>
                <template v-else>
                  —
                </template>
              </card-field>
              <card-field title="Отряд">
                <router-link :to="{name: 'squad.detail', params: {id: data.client_call_squad.squad_id}}"
                             v-if="data.client_call_squad && data.client_call_squad.squad_id">
                  #{{ data.client_call_squad.squad_id }}
                  {{
                    data.client_call_squad && data.client_call_squad.squad ? data.client_call_squad.squad.name : '—'
                  }}
                </router-link>
                <template v-else>
                  —
                </template>
              </card-field>
              <card-field title="Номер телефона отряда ГБР">
                <div v-if="data.client_call_squad && data.client_call_squad.squad">
                  {{ data.client_call_squad.squad.phone }}
                </div>
                <template v-else>
                  —
                </template>
              </card-field>
            </div>

            <div class="col-12">
              <div class="alert alert-danger" v-if="data.is_warning">
                Есть расхождения в запрошенных и полученных денежных средствах
              </div>
              <div class="alert alert-danger" v-if="data.is_debit">
                Есть дебиторская задолженность
              </div>
              <div class="alert alert-info" v-if="data.is_free">
                Вызов бесплатный
              </div>
              <div class="alert alert-info"
                   v-if="data.client && data.client.is_health_check || data.client_call_squad && data.client_call_squad.squad && data.client_call_squad.squad.is_health_check">
                Health check
              </div>
            </div>

            <div class="col-12 text-right">
              <div>
                <button class="btn btn-secondary mb-2" @click.prevent="coreSync"
                        v-if="can('module.client_call.update')">
                  Обновить данные с сервера
                </button>
              </div>
              <div>
                <button class="btn btn-secondary mb-2" @click.prevent="coreEndUrgently"
                        :disabled="!data.is_active"
                        v-if="can('module.client_call.update')">
                  Принудительно завершить вызов
                </button>
              </div>

            </div>

          </div>


        </div>
        <tab-list>
          <tab-link to="client-call.detail">Основные данные</tab-link>
          <tab-link to="client-call.chat" v-if="can('module.chat.client_call-view')">Чат</tab-link>
          <tab-link to="client-call.payment" v-if="can('module.payment.view')">Оплаты</tab-link>
          <tab-link to="client-call.review" v-if="can('module.quiz_answer.view')">Отзыв</tab-link>
          <tab-link to="client-call.phone-calls" v-if="can('module.phone_call.view')">Телефонные звонки</tab-link>
          <tab-link to="client-call.client-call-squad" v-if="can('module.client_call_squad.view')">Поиск отряда
          </tab-link>
          <tab-link to="client-call.client-call-squad-map" v-if="can('module.client_call_squad_geo_data.view')">Расположение отрядов</tab-link>
        </tab-list>
        <card-body>
          <router-view :key="id" :data="data" @change="getData"></router-view>
        </card-body>
      </template>
    </spinner-block>
  </card-block>
</template>

<script>
import ViewTariffDetailTermModal from "@/views/Tariff/Block/ViewTariffDetailTermModal";

export default {
  components: {
    ViewTariffDetailTermModal
  },
  data: function () {
    return {
      data: null,
      dataProgress: true,
    }
  },
  props: {},
  computed: {
    id() {
      return this.$route.params.id;
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      if (this.id) {
        axiosGetValue('/api/admin/client-call/get', {id: this.id}, e => this.data = e, e => this.dataProgress = e)
      }
    },

    coreSync() {
      axiosGetValue('/api/admin/client-call/core-sync', {id: this.data.id}, () => {
        this.getData()
      }, e => this.dataProgress = e)
    },
    coreEndUrgently() {
      $.alertConfirmForce({
        content: 'Вызов будет принудительно закрыт и переведён в статус Ошибка. Оплата и возврат холда НЕ будет произведён. Для продолжения введите слово "Подтверждаю" в поле ниже.'
      }, {
        ok: () => {
          if (this.data.id) {
            axiosGetValue('/api/admin/client-call/core-end-urgently', {id: this.data.id}, () => {
              this.getData()
            }, e => this.dataProgress = e)
          }

        }
      });
    },
  },
}
</script>