<template>
  <card-block>
    <spinner-block v-model="dataProgress">
      <div v-if="data">
        <card-header>
          <template v-if="id">
            <router-link :to="{name: 'subscription-tariff.detail', params: {id: id}}">Тариф</router-link><!-- -->:
            редактирование

          </template>
          <template v-else>
            <router-link :to="{name: 'subscription-tariff.list'}">Тариф</router-link><!-- -->:
            создание
          </template>


        </card-header>
        <card-body>

          <gui-subscription-type-select v-model="data.subscription_type_id" :error="dataError.subscription_type_id"
                                        label="Тип подписки"/>

          <gui-input v-model="data.name" :error="dataError.name" label="Название"/>
          <gui-input type="number" v-model="data.time" :error="dataError.time" label="Длительность, месяцев"/>
          <gui-input type="number" v-model="data.free_calls_count" :error="dataError.free_calls_count"
                     label="Кол-во бесплатных вызовов"/>
          <gui-input type="number" v-model="data.next_tariff_id" :error="dataError.next_tariff_id"
                     label="Следующий тариф для продления в случае деактивации"/>
          <gui-date-time v-model="data.deactivated_at" :error="dataError.deactivated_at"
                         label="Дата деактивации" clearable/>

          <gui-checkbox v-model="data.is_public" :error="dataError.is_public" label="Является публичным"/>

          <gui-input v-model="data.max_peoples" :error="dataError.max_peoples"
                     @input="remakePrice"
                     label="Максимальное количество человек в подписке"/>

          <div v-if="data.details && data.details.length">
            <table class="table table-sm">
              <thead>
              <tr>
                <th>Количество пользователей в подписке</th>
                <th>Стоимость, руб</th>

              </tr>
              </thead>
              <tbody>
              <tr v-for="(i, k) in data.details" :key="i.people_count">
                <td>{{ i.people_count }}</td>
                <td>
                  <gui-input type="number" v-model="data.details[k].price" @input="remakeMinPrice"/>
                </td>
              </tr>
              </tbody>
            </table>

          </div>
          <div v-else class="alert alert-info">Выберите "Максимальное количество человек в подписке"</div>


          <gui-input type="number" v-model="data.cost" :error="dataError.cost"
                     label="Максимальная стоимость подписки, руб" :edit="false"/>

          <gui-html v-model="data.description" :error="dataError.description" label="Описание"/>

          <div class="mt-5">
            <button-save v-if="!data.id" @click="add()"/>
            <button-save v-else @click="save"/>
            <button-back/>
          </div>
        </card-body>
      </div>
    </spinner-block>
  </card-block>
</template>

<script>


import GuiSubscriptionTypeSelect from "@/components/Widget/GuiSubscriptionTypeSelect.vue";

export default {
  components: {GuiSubscriptionTypeSelect},
  data() {
    return {
      peopleLimit: 100,
      data: null,
      prices: [],
      dataDefault: {
        deactivated_at: '',
        free_calls_count: '',
        description: '',
        next_tariff_id: '',
        is_public: '',
        cost: '',
        time: '',
        name: '',
        subscription_type_id: 1,
        max_peoples: 1,
        details: [],
      },
      dataError: {
        deactivated_at: '',
        free_calls_count: '',
        description: '',
        next_tariff_id: '',
        is_public: '',
        cost: '',
        time: '',
        name: '',
        subscription_type_id: '',
        max_peoples: '',
      },
      dataProgress: false,
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    }
  },
  mounted() {
    this.init()
  },
  methods: {

    init() {
      if (this.id) {
        this.data = null
        this.loadData()
      } else {
        this.data = _.clone(this.dataDefault)
        this.remakePrice()
      }
    },
    remakePrice() {
      let result = []
      if (this.data.max_peoples > this.peopleLimit) {
        this.data.max_peoples = this.peopleLimit
      }
      for (let i = 0; i < this.data.max_peoples; i++) {

        let people_count = i + 1
        let old_value = this.data.details ? this.data.details.find(e => e.people_count == people_count) : null

        result.push({
          people_count: people_count,
          price: old_value ? old_value.price : "",
          id: old_value ? old_value.id : null,
        })
      }

      this.data.details = result

      this.remakeMinPrice()

    },
    remakeMinPrice() {
      this.data.cost = Math.max(...this.data.details.map(e => e.price))
    },
    loadData() {
      axiosGetValue('/api/admin/subscription-tariff/get', {id: this.id}, e => {
        this.data = e
        this.remakePrice()
      }, e => this.dataProgress = e)
    },

    save() {
      axiosFast({
        url: route('/api/admin/subscription-tariff/update'),
        data: {data: this.data, id: this.data.id},
        error: this.dataError,
        progress: e => this.dataProgress = e,
        result: () => {
          toastSuccess('Данные сохранены');
          this.$router.push({name: 'subscription-tariff.detail', params: {id: this.data.id}})
        },
      })
    },
    add() {
      axiosFast({
        url: route('/api/admin/subscription-tariff/create'),
        data: {data: this.data},
        error: this.dataError,
        progress: e => this.dataProgress = e,
        result: (data) => {
          toastSuccess('Данные добавлены');
          this.$router.push({name: 'subscription-tariff.detail', params: {id: data.id}})
        },
      })
    },
  },
}
</script>