import EmptyWrap from "@/components/Gui/EmptyWrap";

export default [
    {
        path: 'client-document-type',
        component: EmptyWrap,
        children: [
            {
                path: 'list',
                name: 'document-type.list',
                component: require('@/views/Document/RequiredList.vue').default,
            },
            {
                path: 'create',
                name: 'document-type.create',
                component: require('@/views/Document/RequiredForm.vue').default,
            },
            {
                path: ':id/edit',
                name: 'document-type.edit',
                component: require('@/views/Document/RequiredForm').default,
            },
        ]
    },
]