<template>
  <div>
    <gui-select
        :modelValue="modelValue"
        @update:modelValue="e=>$emit('update:modelValue', e)"
        :label="label"
        :error="error"
        :hilightOn="hilightOn"
        :w="w"
        :sm="sm"
        :list="list">
      <option value="" disabled>Выберите организацию</option>

      <template v-slot:after>
        <div class="input-group-append">

          <template v-if="can('module.organization.widget')">
            <router-link :to="{name: 'organization.detail', params: {id: modelValue}}" target="_blank"
                         v-if="modelValue" class="btn btn-secondary">
              <i class="fa fa-link"></i>
            </router-link>
            <router-link :to="{name: 'organization.list'}" target="_blank"
                         class="btn btn-success">
              <i class="fa fa-ellipsis-v"></i>
            </router-link>
          </template>
        </div>
      </template>

    </gui-select>
  </div>
</template>

<script>
export default {
  name: 'gui-organization-select',
  data() {
    return {
      list: [],
    }
  },
  props: {
    label: String,
    error: [String, Object, Array],
    hilightOn: [Array, String, Number],
    modelValue: {
      type: [String, Number],
    },
    w: Boolean,
    sm: Boolean,
  },
  mounted() {
    this.loadList();
  },
  methods: {
    loadList() {
      axiosGetValue('/api/admin/organization/widget', {}, e => {
        this.list = e
      })
    },
  }
}
</script>