<template>
  <card-block>
    <card-header>
      Список приложений
      <template v-slot:right>
        <template v-if="can('module.mobile_app.create')">
          <a href="#" @click.prevent="$refs.modal.openModal()">
            Добавить приложение
          </a>
          <create-mobile-app-modal ref="modal" @update="getList"/>
        </template>
      </template>
    </card-header>
    <card-body>

      <div v-if="minVersions && minVersions.length" class="mb-5">
        <div class="mb-3">
          <b>Минимальные версии</b>
        </div>
        <table class="table table-sm">
          <thead>
          <tr>
            <th>OS</th>
            <th>Тип</th>
            <th>Версия</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="row in minVersions" :key="row.id">
            <td>{{ row.os_name }}</td>
            <td>{{ row.type_name }}</td>
            <td>{{ row.version_code }}</td>
          </tr>
          </tbody>
        </table>
      </div>

      <spinner-block v-model="progress">

        <div v-if="data && data.data && data.data.length">

          <div class="alert alert-danger mb-3" v-if="data.total > 12">
            Пожалуйста, удалите старые и неактуальные версии приложений
          </div>

          <table class="table table-hover">
            <thead>
            <tr>
              <th>OS, тип</th>
              <th>Название</th>
              <th>Версия</th>
              <th>Ссылка</th>
              <th>Добавлено</th>
              <th class="text-right"></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="row in data.data" :key="row.id">
              <td>

                <i class="fas fa-circle small"
                   :title="versionIsOverMinimal(row) ? 'Активен' : 'Неактивен'"
                   :class="versionIsOverMinimal(row) ? 'text-success' : 'text-danger'"></i>

                {{ row.mobile_app_os_name }}
                <div class="small text-secondary">
                  {{ row.mobile_app_type_name }}
                </div>

              </td>
              <td>
                {{ row.name }}
                <div class="small text-secondary">
                  {{ row.app_code }}
                </div>
              </td>
              <td>
                {{ row.version_code }} ({{ row.version_name }})
                <div>
                  <span class="badge badge-warning" v-if="row.is_last">Последняя версия</span>
                </div>
              </td>
              <td>
                <a :href="row.file" target="_blank">Скачать</a>
              </td>
              <td>
                {{ toTime(row.created_at) }}
              </td>
              <td class="text-right">
                <button class="btn btn-sm btn-secondary mr-2" @click.prevent="minVersion(row)"
                        v-if="can('module.mobile_app.update')">
                  <i class="fas fa-arrow-down" title="Пометить как минимально поддерживаемую версию"></i>
                </button>
                <button class="btn btn-sm btn-danger" @click.prevent="remove(row)"
                        v-if="can('module.mobile_app.delete')">
                  <i class="fas fa-times"></i>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div v-else class="alert alert-info">Список пуст</div>


      </spinner-block>
      <gui-paginate :data="data" ref="paginate" @change="getList"/>
    </card-body>
  </card-block>
</template>

<script>

import CreateMobileAppModal from "@/views/MobileApp/Block/CreateMobileAppModal";

export default {
  components: {CreateMobileAppModal},
  data: function () {
    return {
      data: null,
      minVersions: null,
      progress: true,
      filter: {}
    }
  },
  props: {},
  computed: {},
  beforeMount() {
  },
  mounted() {
    this.getList();
  },
  methods: {
    versionIsOverMinimal(row) {

      if (this.minVersions && this.minVersions.length) {
        for (const r of this.minVersions) {
          if (r.mobile_app_os_id === row.mobile_app_os_id && r.mobile_app_type_id === row.mobile_app_type_id) {
            return row.version_code >= r.version_code;
          }
        }
      }

      return false;
    },
    getList() {

      axiosGetValue('/api/admin/mobile-app/list', {
            page: this.$refs.paginate.currentPage,
            per_page: this.$refs.paginate.perPage,
          },
          e => this.data = e, e => this.progress = e
      )
      this.getMinVersions();
    },
    getMinVersions() {

      axiosGetValue('/api/admin/mobile-app/get-min-versions', {},
          e => this.minVersions = e, e => this.progress = e
      )
    },

    remove(row) {
      $.alertConfirm({
        type: 'red',
      }, {
        ok: () => {
          axiosGetValue('/api/admin/mobile-app/delete', {id: row.id}, () => {
            toastSuccess('Удаление завершено');
            this.getList()
          })
        }
      });
    },
    minVersion(row) {


      $.alertConfirm({
        content: 'Пометить эту версию как минимально поддерживаемую?',
      }, {
        ok: () => {
          axiosGetValue('/api/admin/mobile-app/mark-min-version', {id: row.id}, () => {
            toastSuccess('Изменение завершено');
            this.getList()
          })
        }
      });
    },
  }
}
</script>