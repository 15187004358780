<template>
  <div>
    <div v-if="listData && listData.data && listData.data.length">
      <table class="table table-hover">
        <thead>
        <tr>
          <th class="pr-0" style="width: 1rem;"></th>
          <th>Промокод</th>
          <th>Скидка</th>
          <th>Клиент</th>
          <th>Подписка</th>
          <th>Дата</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="row in listData.data" :key="row.id">
          <th class="pr-0">
            <i class="fas fa-circle small"
               :title="row.is_active ? 'Активен' : 'Неактивен'"
               :class="row.is_active ? 'text-success' : 'text-danger'"></i>
          </th>
          <td>
            <router-link :to="{name: 'promocode.detail', params: {id: row.promocode_id}}" class="main-link">
              {{ row.promocode ? row.promocode.code : '—' }}
            </router-link>
          </td>
          <td>
            <router-link :to="{name: 'discount.detail', params: {id: row.discount_id}}" class="main-link">
              {{ row.discount ? row.discount.name : '—' }}
            </router-link>
          </td>
          <td>
            <router-link :to="{name: 'client.detail', params: {id: row.client_id}}" class="main-link">
              #{{ row.client_id }}
            </router-link>
          </td>
          <td>
            <router-link :to="{name: 'subscription.detail', params: {id: row.subscription_id}}" class="main-link">
              #{{ row.subscription_id }}
            </router-link>
          </td>
          <td>
            {{ toTime(row.created_at) }}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div v-else class="alert alert-info">Список пуст</div>
  </div>
</template>

<script>


export default {
  components: {},
  data() {
    return {}
  },
  props: {
    listData: Object,
  },
  mounted() {

  },
}
</script>
