<template>
  <div>

    <div class="mb-3 text-right" v-if="can('module.promocode.create')">
      <router-link :to="{name: 'promocode.create'}" class="btn btn-success">
        Добавить промокод
      </router-link>
    </div>

    <spinner-block v-model="progress">

      <div v-if="listData && listData.data && listData.data.length">
        <table class="table table-hover">
          <thead>
          <tr>
            <th class="pr-0" style="width: 1rem;"></th>
            <th>Промокод</th>
            <th class="d-none d-md-table-cell">Скидка</th>
            <th class="text-right"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="row in listData.data" :key="row.id">
            <th class="pr-0">
              <i class="fas fa-circle small"
                 :title="row.is_active ? 'Активен' : 'Неактивен'"
                 :class="row.is_active ? 'text-success' : 'text-danger'"></i>
            </th>
            <td class="limit-half_length">
              <router-link :to="{name: 'promocode.detail', params: {id: row.id}}" class="main-link">
                {{ row.code }}
              </router-link>
            </td>
            <td class="d-none d-md-table-cell limit-third_length">
              <div>
                {{ row.discount ? row.discount.name : '—' }}
              </div>
            </td>
            <td class="text-right">

            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div v-else class="alert alert-info">Список пуст</div>
    </spinner-block>
    <gui-paginate :data="listData" ref="paginate" @change="getList"/>
  </div>
</template>

<script>


export default {
  components: {},
  data() {
    return {
      listData: null,
      progress: null,
      filter: {
        discount_id: null,
      }
    }
  },
  props: {
    data: Object,
  },
  mounted() {
    this.filter.discount_id = this.data.id
    this.getList()
  },
  methods: {
    getList() {
      axiosGetValue('/api/admin/promocode/list', {
            filter: this.filter,
            page: this.$refs.paginate.currentPage,
            per_page: this.$refs.paginate.perPage,
          },
          e => this.listData = e, e => this.progress = e
      )
    },

  }
}
</script>
