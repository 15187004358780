<template>
  <div class="form-group row" v-if="true" :class="{'mb-0':noMargin}">
    <div class="d-none d-md-block col-3" v-if="!noSpan">
      <slot name="left"></slot>
    </div>
    <div :class="'col-12 col-md-'+(noSpan?12:9)">
      <label :for="name" class="col-form-label" :class="{'p-0':inline}">

        <span v-if="append" v-html="append"></span>

        <input
            :disabled="!edit"
            type="checkbox"
            :name="name"
            :id="name"
            class="form-control-checkbox mr-2"
            :class="error && error.length > 0 ? 'is-invalid' : ''"
            :placeholder="label"
            v-model="val"
        />

        <span>{{ label }}</span>
      </label>
      <div class="invalid-feedback" v-if="error && error.length > 0">
        <span v-html="error"></span>
      </div>
    </div>

  </div>


</template>

<script>
export default {
  data: function () {
    return {}
  },
  props: {
    name: String,
    label: String,
    error: [String, Object, Array],
    append: String,
    prepend: String,
    noMargin: Boolean,
    noSpan: Boolean,
    inline: Boolean,
    edit: {
      type: Boolean,
      default: true
    },
    modelValue: {
      type: [Number, String, Boolean],
      required: false,
      default: 0
    }
  },
  computed: {
    val: {
      get() {
        return !!this.modelValue;
      },
      set(v) {
        this.$emit('update:modelValue', v ? 1 : 0)
      }
    }
  },
  mounted() {

  },
  methods: {}
}
</script>
