<template>
  <card-block>
    <card-header>
      Список отрядов
      <template v-slot:right>
        <router-link :to="{name: 'squad.create'}" v-if="can('module.squad.create')">
          Добавить отряд
        </router-link>
      </template>
    </card-header>
    <card-body>
      <spinner-block v-model="progress">
        <div class="mb-3 row">
          <div class="col-12 col-md-2">
            <div class="small">Активность</div>
            <gui-select sm v-model="filter.active" @change="getList" :hilightOn="['all']">
              <option value="active">Активные</option>
              <option value="deactive">Не активные</option>
              <option value="all">Все</option>
            </gui-select>
          </div>
          <div class="col-12 col-md-2">
            <div class="small">Статус</div>
            <gui-squad-status-select sm clearable v-model="filter.squad_status" @change="getList" :hilightOn="['']">
            </gui-squad-status-select>
          </div>
          <div class="col-12 col-md-3">
          </div>
          <div class="col-12 col-md-5">
            <div class="small">Поиск</div>
            <gui-search sm v-model="filter.search" @change="getList" :hilightOn="['', undefined]"/>
          </div>
        </div>
        <squad-list-block :data="data"/>
      </spinner-block>
      <gui-paginate :data="data" ref="paginate" @change="getList"/>
      <p v-if="!can('module.organization.view_all-view')" class="text-secondary mt-3">
        Результат показан с учётом ограничения по организации
      </p>
    </card-body>
  </card-block>
</template>

<script>

import SquadListBlock from "@/views/Squad/SquadListBlock";
import GuiSquadStatusSelect from "@/components/Widget/GuiSquadStatusSelect.vue";

export default {
  components: {GuiSquadStatusSelect, SquadListBlock},
  data: function () {
    return {
      data: null,
      progress: true,
      filter: {
        active: 'active',
        squad_status: '',
        search: '',
      }
    }
  },
  props: {},
  computed: {},
  beforeMount() {
    this.filter = storageLoad('filter_' + this.$route.name, this.filter);
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {

      let v = _.clone(this.filter);
      v.search = '';
      storageSet('filter_' + this.$route.name, v);

      axiosGetValue('/api/admin/squad/list', {
            page: this.$refs.paginate.currentPage,
            per_page: this.$refs.paginate.perPage,
            filter: this.filter,
          },
          e => this.data = e, e => this.progress = e
      )
    }
  }
}
</script>