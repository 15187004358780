<template>
  <card-block>
    <card-header>
      Список вызовов отрядов
    </card-header>
    <card-body>
      <spinner-block v-model="progress">
        <client-call-squad-list-block :data="data"/>
      </spinner-block>
      <gui-paginate :data="data" ref="paginate" @change="getList"/>
    </card-body>
  </card-block>
</template>

<script>

import ClientCallSquadListBlock from "@/views/ClientCallSquad/ClientCallSquadListBlock";

export default {
  components: {ClientCallSquadListBlock},
  data: function () {
    return {
      data: null,
      progress: true,
      filter: {
        search: '',
      }
    }
  },
  props: {},
  computed: {},
  beforeMount() {
    this.filter = storageLoad('filter_' + this.$route.name, this.filter);
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {

      let v = _.clone(this.filter);
      v.search = '';
      storageSet('filter_' + this.$route.name, v);

      axiosGetValue('/api/admin/client-call-squad/list', {
            page: this.$refs.paginate.currentPage,
            per_page: this.$refs.paginate.perPage,
            filter: this.filter,
          },
          e => this.data = e, e => this.progress = e
      )
    }
  }
}
</script>