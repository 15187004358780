<template>
  <div>
    <div class="mb-3 text-right">
      <button-create @click="$refs.modal.openModal()" v-if="can('module.client_document.create')"/>
    </div>

    <spinner-block v-model="progress">
      <template v-if="listData && listData.length">
        <div class="row">
          <div v-for="document in listData" v-bind:key="document.id" class="d-block col-md-6 mb-5 text-center">
            <p>{{ document.type.name }}</p>
            <template v-if="!document.is_loaded">
              <img src="/images/loading-photo.jpg" class="img-thumbnail avatar-middle" title="Файл загружается"
                   alt="Файл загружается"/>
            </template>
            <template v-else-if="document.is_error">
              <img src="/images/no-photo.jpg" class="img-thumbnail avatar-middle" title="no-photo"
                   alt="Возникла ошибка"/>
            </template>
            <template v-else-if="null == document.url">
              <img src="/images/no-photo.jpg" class="img-thumbnail avatar-middle" title="Файл недоступен"
                   alt="Файл недоступен"/>
            </template>
            <template v-else>
              <a :href="document.url" data-fancybox class="fancybox">
                <img :src="document.url" class="img-thumbnail avatar-middle" :alt="document.type.name"/>
              </a>
            </template>

            <div v-if="document.url" class="mt-2">
              <div v-if="document.status === 'checked'" class="d-inline-block">
                <button-save sm text="Подвтерждено" :disabled="true"/>
              </div>
              <div v-else class="d-inline-block">
                <button-save sm text="Подвтердить" @click="confirm(document.type.code)"
                             v-if="can('module.client_document.update')"/>
              </div>
              <button-remove sm @click="remove(document.type.code)" v-if="can('module.client_document.delete')"/>
              <button @click="download(document)" class="btn btn-sm btn-secondary"><i class="fas fa-download"></i>
              </button>
            </div>
          </div>
        </div>
      </template>
      <div v-else class="alert alert-info">Список пуст</div>
    </spinner-block>


    <create-client-document-modal
        v-if="can('module.client_document.create')"
        ref="modal"
        @update="getList"
        :defaultParams="{client_id: data.id}"/>
  </div>
</template>

<script>

import {TokenService} from "@/services/token.service";
import CreateClientDocumentModal from "@/views/Client/Block/CreateClientDocumentModal.vue";

export default {
  components: {CreateClientDocumentModal},
  data() {
    return {
      listData: [],
      progress: null,
      filter: {
        client_id: null,
      },
      loading: true,
    }
  },
  props: {
    data: Object,
  },
  mounted() {
    this.filter.client_id = this.data.id
    this.getList()
  },
  methods: {
    getList() {
      axiosGetValue(
          '/api/admin/client-document/list',
          {id: this.data.id},
          e => this.listData = e,
          e => this.progress = e
      ).then(() => {
        this.listData.forEach(documentData => {
          try {
            fetch(window.route('/api/admin/client-document/get'), {
              method: 'POST',
              headers: {
                'Authorization': 'Bearer ' + TokenService.getToken(),
                'Content-Type': 'application/json;charset=utf-8',
              },
              body: JSON.stringify({id: this.data.id, type: documentData.type.code})
            }).then(response => {
              if (!response.ok) {
                console.error('Возникла ошибка при загрузке изображения', response)
                $.alertError('Возникла ошибка при загрузке изображения. Обновите страницу')
              }

              response.blob().then(blob => {
                documentData.is_loaded = true
                documentData.url = blob.size === 0 ? null : URL.createObjectURL(blob)
              })

            })
          } catch (err) {
            documentData.is_loaded = true
            documentData.is_error = true
            console.error('Error in fetching and downloading file:', err);
            $.alertError('Возникла ошибка при загрузке изображения. Обновите страницу')
          }
        })
      })
    },

    download(documentData) {
      loadAsPostForm('/api/admin/client-document/get', {id: this.data.id, type: documentData.type.code})
    },

    remove(code) {
      $.alertConfirm({
        content: 'Продолжить?'
      }, {
        ok: () => {
          axiosGetValue('/api/admin/client-document/delete', {id: this.data.id, type: code}, () => {
            toastSuccess('Документ удалён!')
          }).then(() => {
            this.getList();
          })
        }
      });
    },

    confirm(code) {
      axiosGetValue('/api/admin/client-document/confirm', {id: this.data.id, type: code}, () => {
        toastSuccess('Документ подтверждён.')
      }).then(() => {
        this.getList();
      })
    }
  }
}
</script>
