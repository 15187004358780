<template>

  <gui-input-row :data="$props">
    <select
        :disabled="!edit"
        :name="name"
        :id="name"
        class="form-control"
        :class="{
                'is-invalid': error && error.length > 0, 'form-control-sm': sm,
                'hilight-field': typeof hilightOn !== 'undefined' && !( typeof hilightOn =='object' ? hilightOn.indexOf(modelValue)!==-1 : hilightOn==value )
                }"
        :placeholder="label"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        @change="$emit('change:modelValue', $event.target.value)"
    >
      <slot></slot>
      <template v-if="list && Object.values(list).length">
        <option v-for="(item, k) in list"
                :value="typeof item === 'string' ? k : item[value_prop]"
                :key="typeof item === 'string' ? k : item[value_prop]"
        >
          {{ typeof item === 'string' ? item : item[name_prop] }}
        </option>
      </template>
    </select>
    <template v-slot:after>
      <slot name="after"/>
    </template>
  </gui-input-row>

</template>

<script>
export default {
  data: function () {
    return {}
  },
  props: {
    name: String,
    label: String,
    error: [String, Object, Array],
    hilightOn: [Array, String, Number],
    append: String,
    prepend: String,
    list: [Array, Object],
    edit: {
      type: Boolean,
      default: true
    },
    modelValue: {
      type: [String, Number],
    },
    w: Boolean,
    sm: Boolean,
    value_prop: {
      type: String,
      default: 'id'
    },
    name_prop: {
      type: String,
      default: 'name'
    },

  },
  computed: {},
  mounted() {

  },
  methods: {}
}
</script>
