<template>
  <div>

    <template v-if="can('module.role.permission-view')">
      <spinner-block v-model="progress">

        <div v-if="permissions && lang">
          <table class="table table-hover permission-table">

            <thead>
            <tr>
              <th>{{ trans('_perm.module') }}</th>
              <th>{{ trans('_perm.zone') }}</th>
              <th v-for="type in perm_sort" :key="type" class="text-center">{{ trans('_perm.' + type) }}</th>
            </tr>
            </thead>
            <tbody>

            <template v-for="(zones, module) in permissions" :key="module">
              <tr v-for="(permis, zone) in sortZonez(zones)" :key="zone">
                <td v-if="zone===''" :rowspan="Object.keys(zones).length" class="p-0 align-top">
                  <div class="py-2 px-3">{{ trans('' + module + '._name') }}</div>
                </td>
                <td class="p-0 align-middle">
                  <div v-if="zone!==''" class="py-2 px-3">{{ trans('' + module + '.' + zone) }}</div>
                </td>
                <td v-for="type in perm_sort" :key="type" class="p-0 align-middle text-center cell">
                  <label v-if="typeof permis[type] !== 'undefined'"
                         class="col-12 p-2 m-0">
                    <input v-model="permis[type].value" :disabled="!edit || !!permis[type].disabled"
                           @change.stop=""
                           type="checkbox">
                  </label>
                  <span v-else> - </span>
                </td>
              </tr>
            </template>

            </tbody>

          </table>


          <div v-if="can('module.role.permission-update')" class="permission-buttons">
            <button v-if="!edit" class="btn btn-primary mr-2" @click="allowEdit" :disabled="data.is_lock">
              Редактировать
            </button>
            <button v-if="edit" class="btn btn-primary mr-2" @click="save">
              Сохранить
            </button>
            <button v-if="edit" class="btn btn-secondary mr-2" @click="cancelEdit">
              Отменить
            </button>
          </div>
        </div>

      </spinner-block>
    </template>
    <div v-else class="alert alert-danger">Недостаточно прав для просмотра</div>
  </div>
</template>

<script>

export default {
  components: {},
  data() {
    return {
      perm_sort: ['view', 'update', 'create', 'delete', 'widget'],
      permissions: null,
      lang: null,
      edit: false,
      oldList: false,
      progress: false,
    }
  },
  props: {
    data: Object,
  },
  mounted() {
    this.getList()
  },
  methods: {
    sortZonez(unordered) {
      let ordered = {};
      Object.keys(unordered).sort().forEach(function (key) {
        ordered[key] = unordered[key];
      });

      return ordered;
    },
    allowEdit() {
      this.oldList = _.cloneDeep(this.permissions);
      this.edit = true;
    },
    cancelEdit() {
      this.permissions = _.cloneDeep(this.oldList);
      this.edit = false;
    },
    getList() {
      if (this.can('module.role.permission-view')) {
        axiosGetValue('/api/admin/role-permissions/get', {id: this.data.id},
            e => {
              this.permissions = e.permissions.module
              this.lang = e.lang
            }, e => this.progress = e
        )
      }
    },
    trans(code) {
      let tab = code.split('.')
      let res = ''
      try {
        if (tab.length === 1) {
          res = this.lang[tab[0]]
        }
        if (tab.length === 2) {
          res = this.lang[tab[0]][tab[1]]
        }
        if (tab.length === 3) {
          res = this.lang[tab[0]][tab[1]][tab[2]]
        }
      } catch (e) {
        return code
      }
      return res ? res : code
    },
    save() {

      axiosGetValue('/api/admin/role-permissions/set', {id: this.data.id, data: this.permissions},
          () => {
            toastSuccess("Данные сохранены")
            this.getList()
            this.edit = false
          }, e => this.progress = e
      );
    },
  }
}
</script>