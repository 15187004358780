<template>
  <router-view/>
</template>

<script>

import {onMessageListener} from '@/firebase';
import {playNotificationSound} from '@/assets/js/utils/audio';

export default {
  components: {},
  created() {

    onMessageListener((payload) => {
      // Обработка пуш уведомления внутри приложения
      if (document.location.href !== payload.data) {
        let message = new Notification(payload.data.title, {
          body: payload.data.body,
        });
        message.onclick = (event) => {
          event.preventDefault();
          window.open(payload.data.click_action, "_blank");
        }

        playNotificationSound();

        if (payload.data.toggle) {
          switch (payload.data.toggle) {
            case 'chat':
              this.$store.dispatch('chat/updateOldChats');
              break;
            case 'call':
              console.log('toggle call')
              break;
          }
        }
      }
    });

  },
}
</script>
