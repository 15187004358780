<template>
  <button class="btn mr-1"
          :class="{'btn-sm': sm, [btnClass]: true}"
          :disabled="disabled"
          :title="disabled ? 'Заблокировано' : ''"
  >
    <template v-if="!$slots.length">
      <template v-if="icon"><i :class="textIcon"></i></template>
      <template v-else>{{ text }}</template>
    </template>
    <slot v-else></slot>
  </button>
</template>

<script>
export default {
  data: function () {
    return {}
  },
  props: {
    btnClass: String,
    text: String,
    textIcon: String,
    icon: Boolean,
    sm: Boolean,
    disabled: Boolean,
  },
  computed: {},
  mounted() {

  },
  methods: {}
}
</script>
