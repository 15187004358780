<template>
  <div>
    <div class="row">

      <div class="col-12 col-md-6">

        <div class="mb-3">
          <b>Описание клиента</b>
        </div>
        <card-field
            :modelValue="(data.last_name ? data.last_name : '—') + ' ' + (data.first_name ? data.first_name : '—') +' ' + (data.middle_name ? data.middle_name : '—')"
            title="ФИО, указанный при вызове"/>
        <card-field
            :modelValue="getAge(data.birth)"
            title="Возраст, указанный при вызове"/>
        <card-field
            :modelValue="data.gender ? (data.gender==='male' ? 'Мужской' : 'Женский') : '—'"
            title="Пол, указанный при вызове"/>
        <card-field :modelValue="data.dictionary_color_top ? data.dictionary_color_top.name : '—'"
                    title="Цвет верха"/>
        <card-field :modelValue="data.dictionary_color_bot ? data.dictionary_color_bot.name : '—'" title="Цвет низа"/>
        <card-field :modelValue="data.dictionary_place ? data.dictionary_place.name : '—'" title="Место"/>
        <card-field :modelValue="data.color_custom" title="Свой цвет"/>
        <card-field :modelValue="data.place_custom" title="Своё место"/>

        <div class="mb-3">
          <b>Время</b>
        </div>
        <card-field :modelValue="toTime(data.date_call)" title="Дата вызова"/>
        <card-field :modelValue="toTime(data.date_arrival)" title="Дата прибытия"/>
        <card-field :modelValue="toTime(data.date_finish)" title="Дата окончания"/>
        <card-field :modelValue="toTime(data.date_cancel)" title="Дата отмены"/>
        <card-field :modelValue="data.time_processing" title="Время обработки, с"/>
        <card-field :modelValue="data.time_arrival" title="Время прибытия, с"/>
        <card-field :modelValue="data.time_arrival_geo" title="Рассчитанное время прибытия, с"/>
        <card-field :modelValue="data.time_resolution_actual" title="Время решения проблемы, с"/>
        <card-field :modelValue="data.time_resolution_squad" title="Время решения проблемы отрядом, с"/>
        <card-field :modelValue="data.time_extra_paid" title="Время сверх нормы, с"/>

      </div>

      <div class="col-12 col-md-6">
        <card-field title="Номер вызова">
          #{{ data.id }}
          <span class="small text-secondary">
            <span class="small">
              [{{ data.uuid }}]
            </span>
          </span>
        </card-field>
        <card-field :modelValue="data.city ? data.city.name : '—'" title="Город"/>
        <card-field :modelValue="data.district ? data.district.name : '—'" title="Район"/>


        <card-field :modelValue="data.client_call_type ? data.client_call_type.name : '—'" title="Тип вызова"/>
        <card-field :modelValue="data.client_call_cancel_reason ? data.client_call_cancel_reason.name : '—'"
                    title="Причина отмены"/>
        <card-field :modelValue="data.client_call_who_close ? data.client_call_who_close_id.name : '—'"
                    title="Кто отменил вызов"/>


        <card-field :modelValue="data.cancel_comment" title="Комментарий отмены"/>
        <card-field title="Куда заказан вызов">
          <gui-geo-span :modelValue="data.geo_object"/>
        </card-field>
        <card-field title="Где был клиент на момент старта вызова">
          <gui-geo-span :modelValue="data.geo_client_start_object"/>
        </card-field>
        <card-field title="Где был клиент на момент прибытия отряда">
          <gui-geo-span :modelValue="data.geo_client_arrive_object"/>
        </card-field>


      </div>

    </div>
  </div>
</template>

<script>


export default {
  data() {
    return {}
  },
  props: {
    data: Object
  },
  mounted() {
  },
  methods: {},
}
</script>