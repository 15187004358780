<template>
  <card-block>
    <spinner-block v-model="dataProgress">
      <div v-if="data">
        <card-header>
          <template v-if="id">
            <router-link :to="{name: 'organization.detail', params: {id: id}}">Организация</router-link><!-- -->:
            редактирование

          </template>
          <template v-else>
            <router-link :to="{name: 'organization.list'}">Организации</router-link><!-- -->:
            создание
          </template>


        </card-header>
        <card-body>

          <gui-input v-model="data.name" :error="dataError.name" label="Имя"/>
          <gui-file-picker v-model="data.logo_new" :error="dataError.logo_new" label="Логотип"
                           :file="data.logo ? {name: data.logo, url: data.logo_url} : ''"
                           @delete="data.logo=null; data.logo_delete=true"/>

          <gui-input v-model="data.contract_number" :error="dataError.contract_number" label="Номер договора"/>
          <gui-input v-model="data.phone" :error="dataError.phone" label="Номер телефона"/>
          <gui-tinkoff-taxation-select v-model="data.tinkoff_taxation_id" :error="dataError.tinkoff_taxation_id"
                                       label="Система налогооблажения"/>
          <gui-tinkoff-vat-select v-model="data.tinkoff_vat_id" :error="dataError.tinkoff_vat_id" label="НДС"/>
          <gui-input v-model="data.inn" :error="dataError.inn" label="ИНН"/>

          <div class="mt-5">
            <button-save v-if="!data.id" @click="add()"/>
            <button-save v-else @click="save"/>
            <button-back/>
          </div>
        </card-body>
      </div>
    </spinner-block>
  </card-block>
</template>

<script>


import GuiTinkoffTaxationSelect from "@/components/Widget/GuiTinkoffTaxationSelect";
import GuiTinkoffVatSelect from "@/components/Widget/GuiTinkoffVatSelect";

export default {
  components: {GuiTinkoffVatSelect, GuiTinkoffTaxationSelect},
  data() {
    return {
      data: null,
      dataDefault: {
        name: '',
        logo: null,
        logo_new: null,
        logo_delete: null,
        contract_number: '',
        phone: '',
        tinkoff_vat_id: '',
        tinkoff_taxation_id: '',
        inn: '',
      },
      dataError: {
        name: '',
        logo_new: '',
        contract_number: '',
        phone: '',
        tinkoff_vat_id: '',
        tinkoff_taxation_id: '',
        inn: '',
      },
      dataProgress: false,
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    }
  },
  mounted() {
    this.init()
  },
  methods: {

    init() {
      if (this.id) {
        this.data = null
        this.loadData()
      } else {
        this.data = _.clone(this.dataDefault)
      }
    },
    loadData() {
      axiosGetValue('/api/admin/organization/get', {id: this.id}, e => this.data = Object.assign({}, this.dataDefault, e), e => this.dataProgress = e)
    },

    save() {
      axiosFast({
        url: route('/api/admin/organization/update'),
        formData: {data: this.data, id: this.data.id},
        error: this.dataError,
        progress: e => this.dataProgress = e,
        result: () => {
          toastSuccess('Данные сохранены');
          this.$router.push({name: 'organization.detail', params: {id: this.data.id}})
        },
      })
    },
    add() {
      axiosFast({
        url: route('/api/admin/organization/create'),
        formData: {data: this.data},
        error: this.dataError,
        progress: e => this.dataProgress = e,
        result: (data) => {
          toastSuccess('Данные добавлены');
          this.$router.push({name: 'organization.detail', params: {id: data.id}})
        },
      })
    },
  },
}
</script>