<template>
  <div>
    <div ref="modal" style="display: none">
      <spinner-block v-model="dataProgress">
        <div v-if="data">
          <div class="mb-3">
            <div>
              <b>Изменение стоимости вызова</b>
            </div>
            Обратите внимание, это изменение заменит рассчитанную стоимость вызова, но не будет фактически
            списывать\возвращать деньги клиенту.<br/>
            Так же в случае повышения стоимости вызова клиенту отправится уведомление о дебиторской задолженности.
          </div>

          <gui-input type="number" v-model="data.amount_call" :error="dataError.amount_call" label="Базовая стоимость"/>
          <gui-input type="number" v-model="data.amount_extra_paid" :error="dataError.amount_extra_paid"
                     label="Дополнительная стоимость"/>


          <div class="mt-5">
            <button-save @click="save"/>
            <button-cancel @click="fancyboxClose"/>
          </div>
        </div>
      </spinner-block>
    </div>
  </div>
</template>

<script>

export default {
  components: {},
  data: function () {
    return {
      data: null,
      dataProgress: false,
      dataError: {
        amount_call: '',
        amount_extra_paid: '',
      },
    }
  },
  props: {},
  mounted() {
  },
  methods: {
    loadData(id) {
      axiosGetValue('/api/admin/client-call/get', {id}, e => {
        this.data = e
      }, e => this.dataProgress = e)
    },
    openModal(data_id) {
      if (data_id)
        this.loadData(data_id)

      this.fancybox(this.$refs.modal, () => {
        this.data = null
        clearErrorList(this.dataError)
      });
    },
    save() {
      axiosFast({
        url: route('/api/admin/client-call/change-amount'),
        data: {id: this.data.id, amount_call: this.data.amount_call, amount_extra_paid: this.data.amount_extra_paid},
        error: this.dataError,
        progress: e => this.dataProgress = e,
        result: () => {
          toastSuccess('Данные сохранены');
          this.$emit('update')
          this.fancyboxClose();
        },
      })
    }
  }
}
</script>