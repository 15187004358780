/**
 * @function $.alertResult
 */

(function ($) {

    /**
     * Вывести фатальную ошибку
     * @param option
     * @param callback
     */
    $.alertFatalError = function (option, callback) {
        if (!option) option = {};
        if (!callback) callback = {};

        $.alert($.extend({
            title: 'Ошибка запроса',
            content: 'Произошло необработанное исключение',
            type: 'red',
            buttons: {
                ok: {
                    text: 'Продолжить',
                    btnClass: 'btn-red',
                    action: callback.ok ? callback.ok : false
                }
            }
        }, option))
    };


    /**
     * Вывести диалог с подтверждением действия
     * @param option
     * @param callback
     */
    $.alertConfirm = function (option, callback) {
        if (!option) option = {};
        if (!callback) callback = {ok: false, cancel: false};

        $.alert($.extend({
            title: 'Подтвердите действие',
            content: 'Продолжить?',
            type: 'orange',
            buttons: {
                ok: {
                    text: 'Продолжить',
                    btnClass: option.type ? 'btn-' + option.type : 'btn-orange',
                    action: callback.ok ? callback.ok : false
                },
                cancel: {
                    text: 'Отмена',
                    btnClass: 'btn-default',
                    action: callback.cancel ? callback.cancel : false
                }
            }
        }, option))
    };

    /**
     * Сообщение о ведущейся разработке
     * @param option
     * @param callback
     */
    $.alertDev = function (option, callback) {
        if (!option) option = {};
        if (!callback) callback = {ok: false, cancel: false};

        $.alert($.extend({
            title: 'Информация недоступна',
            content: '',
            type: 'orange',
            buttons: {
                ok: {
                    text: 'Продолжить',
                    btnClass: 'btn-orange',
                },
            }
        }, option))
    };

    /**
     * Подтверждение действия с вводом ключевого слова
     * @param option
     * @param callback
     */
    $.alertConfirmForce = function (option, callback) {

        if (!option) option = {};
        if (!callback) callback = {ok: false, cancel: false};

        option.resume_word = option.resume_word ? option.resume_word : 'Подтверждаю';

        let field = option.field ? option.field : "<br><input type='text' name='continue-word' " +
            "class='name form-control' " +
            "data-word='" + option.resume_word + "'/>";

        if (option.content) option.content += field;

        $.alert($.extend({
            title: 'Внимание!',
            content: 'Для продолжения введите слово "' + option.resume_word + '" в поле ниже.' + field,
            type: 'red',
            buttons: {
                cancel: {
                    text: 'Отмена',
                    btnClass: 'btn-red',
                    action: callback.cancel ? callback.cancel : false
                },
                ok: {
                    text: 'Продолжить',
                    btnClass: 'btn-green',
                    action: function () {
                        var $i = this.$content.find('[name="continue-word"]');

                        if ($i.val().toLowerCase() === $i.attr('data-word').toLowerCase()) {

                            if (callback.ok) callback.ok();

                        } else {
                            alert('Слово введено некорректно');
                            return false;
                        }
                    }
                },
            }
        }, option));
    }

    /**
     *
     * @param option
     * @param callback
     */
    $.alertError = function (option, callback) {
        if (!option) option = {};
        if (!callback) callback = {};

        $.alert($.extend({
            title: 'Ошибка',
            content: '',
            type: 'red',
            scrollToPreviousElement: false,
            scrollToPreviousElementAnimate: false,
            buttons: {
                ok: {
                    text: 'Продолжить',
                    btnClass: 'btn-red',
                    keys: ['enter'],
                    action: callback.ok ? callback.ok : false
                }
            }
        }, option))
    };


})(window.$);
