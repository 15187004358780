<template>
  <div>
    <spinner-block v-model="dataProgress">
      <div v-if="data">

        <gui-file v-model="data.file_new" :error="dataError.file_new" label="Файл (apk | ipa)"/>

        <div class="mt-5">
          <button-save v-if="!data.id" @click="add()"/>
          <button-cancel @click="$emit('cancel')"/>
        </div>
      </div>
    </spinner-block>
  </div>
</template>

<script>

export default {
  components: {},
  data() {
    return {
      data: null,
      dataDefault: {
        file_new: '',
      },
      dataError: {
        file_new: '',
      },
      dataProgress: false,
    }
  },
  props: {
    id: [String, Number],
    defaultParams: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
  },
  methods: {

    init() {
      this.data = Object.assign({}, this.dataDefault, this.defaultParams)
      clearErrorList(this.dataError)
    },

    add() {
      axiosFast({
        url: route('/api/admin/mobile-app/create'),
        formData: {data: this.data},
        error: this.dataError,
        progress: e => this.dataProgress = e,
        result: (data) => {
          toastSuccess('Данные добавлены');
          this.$emit('add', data.id)
        },
      })
    },
  },
}
</script>