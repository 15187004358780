<template>
  <i class="fas fa-lock text-secondary small" v-if="modelValue" title="Заблокирован для изменения"></i>
</template>

<script>
export default {

  props: {
    modelValue: {
      type: [String, Boolean],
      default: ''
    }
  },
}
</script>