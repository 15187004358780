<template>
  <div>
    <error-page-block v-if="can('module.error.view')"/>
  </div>
</template>

<script>
import ErrorPageBlock from "@/components/Error/ErrorPageBlock";

export default {
  components: {ErrorPageBlock},
  data: function () {
    return {}
  },
  props: {},
  computed: {},
  mounted() {

  },
  methods: {}
}
</script>