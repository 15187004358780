<template>

  <gui-input-row :data="$props">
    <VueCtkDateTimePicker
        v-model="dateGetSet"
        locale="ru"
        no-label
        noButtonNow
        noClearButton
        onlyTime
        :minuteInterval="minuteInterval"
        :label="placeholder ? placeholder : label"
        format="HH:mm"
        formatted="HH:mm"
        class="custom-date-time"
        :class="{'is-invalid': error && error.length,  'sm': sm}"
    />
    <slot v-if="clearable && modelValue && edit" name="after">
      <div class="input-group-append">
        <button class="btn btn-sm btn-danger" @click.prevent="dateGetSet=''">
          <i class="fas fa-times"></i>
        </button>
      </div>
    </slot>

  </gui-input-row>

</template>

<script>

export default {
  components: {},
  data: function () {
    return {
      val: ''
    }
  },
  props: {
    w: Boolean,
    sm: Boolean,
    time: Boolean,
    hide: Boolean,
    name: String,
    label: String,
    minuteInterval: {
      type: Number,
      default: 10,
    },
    placeholder: String,
    error: [String, Object, Array],
    type: String,
    append: String,
    prepend: String,
    edit: {
      type: Boolean,
      default: true
    },
    modelValue: {
      type: String,
      default: ''
    },
    clearable: {
      type: Boolean,
      default: false
    }
  },
  computed: {

    dateGetSet: {
      get: function () {
        return this.modelValue ? this.modelValue : '';
      },
      set: function (newVal) {
        this.$emit('update:modelValue', newVal ? newVal : null);
      }
    }


  },
  mounted() {

  },
  methods: {}
}
</script>
