<template>
  <div>
    <div v-if="data && data.data && data.data.length">
      <table class="table table-hover">
        <thead>
        <tr>
          <th>Дата звонка</th>
          <th>Длительность</th>
          <th>Кто звонил</th>
          <th>Куда направили</th>
          <th>Связанный вызов</th>
          <th>Запись<br/>разговора</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="row in data.data" :key="row.id">
          <td>
            <div>
              {{ toTime(row.start_time) }}
            </div>
          </td>
          <td>
            <div>
              {{ row.total_time_duration ? row.total_time_duration : 0 }} с
            </div>
          </td>
          <td>
            <div v-if="row.call_user_type=='client'">
              <router-link :to="{name: 'client.detail', params: {id: row.call_user_id}}" target="_blank">
                {{ row.num_input }}
              </router-link>
              <div class="small text-secondary">Клиент</div>
            </div>
            <div v-else-if="row.call_user_type=='squad'">
              <router-link
                  :to="{name: 'squad.detail', params: {id: row.call_user_id}}" target="_blank">
                {{ row.num_input }}
              </router-link>
              <div class="small text-secondary">Отряд</div>
            </div>
            <div v-else>
              {{ row.num_input }}
              <div class="small text-secondary">Неопределён</div>
            </div>
          </td>
          <td>
            <div v-if="row.num_redirect">
              <div v-if="row.redirect_user_type=='client'">
                <router-link :to="{name: 'client.detail', params: {id: row.redirect_user_id}}" target="_blank">
                  {{ row.num_redirect }}
                </router-link>
                <div class="small text-secondary">Клиент</div>
              </div>
              <div v-else-if="row.redirect_user_type=='squad'">
                <router-link
                    :to="{name: 'squad.detail', params: {id: row.redirect_user_id}}" target="_blank">
                  {{ row.num_redirect }}
                </router-link>
                <div class="small text-secondary">Отряд</div>
              </div>
              <div v-else>
                {{ row.num_redirect }}
                <div class="small text-secondary">Неопределён</div>
              </div>

            </div>
            <div v-else class="text-secondary">—</div>
          </td>
          <td>
            <div v-if="row.client_call_id">
              <router-link
                  :to="{name: 'client-call.detail', params: {id: row.client_call_id}}" target="_blank">
                Вызов #{{ row.client_call_id }}
              </router-link>
            </div>
            <div v-else class="text-secondary">—</div>
          </td>
          <td>
            <div v-if="row.record_file">
              <a :href="row.record_file" target="_blank">Запись</a>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div v-else class="alert alert-info">Список пуст</div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: [Object],
      default: null
    }
  }
}
</script>