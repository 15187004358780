<template>
  <div>
    <div class="row">

      <div class="col-12 col-md-9">
        <card-field title="Название">
          <i class="fas fa-circle small"
             :title="data.is_active ? 'Активен' : 'Неактивен'"
             :class="data.is_active ? 'text-success' : 'text-danger'"></i>
          {{ data.name }}
        </card-field>

        <card-field :modelValue="data.subscription_tariff ? data.subscription_tariff.name : '—'" title="Тариф подписки"/>
      </div>
    </div>

    <div class="mt-3">

      <div class="float-right">
        <button-remove @click="remove" v-if="can('module.business_company.delete')"/>
      </div>


      <button-edit @click="$router.push({name: 'business_company.edit', params: {id: data.id}})"
                   v-if="can('module.business_company.update')"/>
    </div>
  </div>
</template>

<script>


export default {
  data() {
    return {}
  },
  props: {
    data: Object
  },
  mounted() {
  },
  methods: {

    remove() {
      $.alertConfirm({
        type: 'red',
      }, {
        ok: () => {
          axiosGetValue('/api/admin/business-company/delete', {id: this.data.id}, () => {
            toastSuccess('Удаление завершено');
            this.$router.push({name: 'business_company.list'})
          })
        }
      });
    },
  },
}
</script>
