<template>
  <div>
    <div class="row">
      <div class="d-block d-md-none col-md-3 mb-3">
        <img :src="data.logo_url ? data.logo_url : '/images/no-photo.jpg'" class="img-thumbnail avatar-middle"/>
      </div>
      <div class="col-12 col-md-9">
        <card-field title="Имя" :modelValue="data.name"/>
        <card-field :modelValue="data.contract_number" title="Номер договора"/>
        <card-field :modelValue="data.phone" title="Номер телефона"/>
        <card-field :modelValue="data.tinkoff_taxation ? data.tinkoff_taxation.name : '—'"
                    title="Система налогооблажения"/>
        <card-field :modelValue="data.tinkoff_vat ? data.tinkoff_vat.name : '—'" title="НДС"/>
        <card-field :modelValue="data.inn" title="ИНН"/>
      </div>
      <div class="d-none d-md-block col-md-3 text-right">
        <img :src="data.logo_url ? data.logo_url : '/images/no-photo.jpg'" class="img-thumbnail avatar-middle"/>
      </div>
    </div>

    <div class="mt-3">

      <div class="float-right">
        <button-remove @click="remove" v-if="can('module.organization.delete')"/>
      </div>


      <button-edit @click="$router.push({name: 'organization.edit', params: {id: data.id}})"
                   v-if="can('module.organization.update')"/>
    </div>
  </div>
</template>

<script>


export default {
  data() {
    return {}
  },
  props: {
    data: Object
  },
  mounted() {
  },
  methods: {

    remove() {
      $.alertConfirm({
        type: 'red',
      }, {
        ok: () => {
          axiosGetValue('/api/admin/organization/delete', {id: this.data.id}, () => {
            toastSuccess('Удаление завершено');
            this.$router.push({name: 'organization.list'})
          })
        }
      });
    },
  },
}
</script>