<template>

  <div class="form-group row" v-if="data.hide ? data.edit || data.value : true"
       :class="{'is-invalid': data.error && data.error.length > 0, 'mb-0': !data.label}"
  >
    <div :class="'col-12 col-md-'+col_label" v-if="data.label && !hide_label">
      <label :for="data.name" class="col-form-label semi-small" :class="data.sm ? 'col-form-label-sm' : '' ">
        {{ data.label }}
        <div v-if="data.sub_label" class="small text-secondary">{{ data.sub_label }}</div>
      </label>
    </div>
    <div :class="'col-12 col-md-'+col_input">

      <div class="input-group" :class="data.sm ? 'input-group-sm' : ''">
        <div v-if="data.prepend" class="input-group-prepend" v-html="data.prepend"></div>
        <slot></slot>
        <div v-if="data.append" class="input-group-append">
          <div class="input-group-text" v-html="data.append"></div>
        </div>
        <slot name="after"></slot>
      </div>
      <div v-if="data.subInfo">
        <small v-html="data.subInfo"></small>
      </div>
      <error-block :error="data.error"/>
    </div>
  </div>

</template>

<script>
import ErrorBlock from "../ErrorBlock";

export default {
  components: {ErrorBlock},
  data: function () {
    return {}
  },
  props: {
    data: Object,
    col: Object,
    hide_label: Boolean
  },
  computed: {

    col_input() {
      let i = (this.col && this.col.input ? this.col.input : 9);
      if (!this.data.label) i += this.col_label;
      if (this.data.w) i = 12;
      return i;
    },
    col_label() {
      let i = (this.col && this.col.label ? this.col.label : 3);
      if (this.data.w) i = 12;
      return i;
    },


  },
  mounted() {

  },
  methods: {}
}
</script>
