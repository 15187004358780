<template>
  <card-block>
    <spinner-block v-model="dataProgress">
      <div v-if="data">
        <card-header>
          <template v-if="id">
            <router-link :to="{name: 'discount.detail', params: {id: id}}">Скидка</router-link><!-- -->:
            редактирование

          </template>
          <template v-else>
            <router-link :to="{name: 'discount.list'}">Скидки</router-link><!-- -->:
            создание
          </template>


        </card-header>
        <card-body>

          <gui-checkbox v-model="data.is_active" :error="dataError.is_active" label="Активный"/>
          <gui-input v-model="data.name" :error="dataError.name" label="Название"/>
          <gui-discount-type-select v-model="data.discount_type_id" :error="dataError.discount_type_id"
                                    label="Тип скидки"/>

          <gui-input v-if="data.discount_type_id==1" v-model="data.sale_percent" :error="dataError.sale_percent"
                     label="Процент скидки"/>
          <gui-input v-if="data.discount_type_id==2" v-model="data.sale_sum" :error="dataError.sale_sum"
                     label="Сумма скидки"/>

          <div class="mt-5">
            <button-save v-if="!data.id" @click="add()"/>
            <button-save v-else @click="save"/>
            <button-back/>
          </div>
        </card-body>
      </div>
    </spinner-block>
  </card-block>
</template>

<script>


import GuiDiscountTypeSelect from "@/components/Widget/GuiDiscountTypeSelect.vue";

export default {
  components: {GuiDiscountTypeSelect},
  data() {
    return {
      data: null,
      dataDefault: {
        name: '',
        is_active: '',
        discount_type_id: '',
        sale_percent: '',
        sale_sum: '',
      },
      dataError: {
        name: '',
        is_active: '',
        discount_type_id: '',
        sale_percent: '',
        sale_sum: '',
      },
      dataProgress: false,
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    }
  },
  mounted() {
    this.init()
  },
  methods: {

    init() {
      if (this.id) {
        this.data = null
        this.loadData()
      } else {
        this.data = _.clone(this.dataDefault)
      }
    },
    loadData() {
      axiosGetValue('/api/admin/discount/get', {id: this.id}, e => this.data = e, e => this.dataProgress = e)
    },

    save() {
      axiosFast({
        url: route('/api/admin/discount/update'),
        data: {data: this.data, id: this.data.id},
        error: this.dataError,
        progress: e => this.dataProgress = e,
        result: () => {
          toastSuccess('Данные сохранены');
          this.$router.push({name: 'discount.detail', params: {id: this.data.id}})
        },
      })
    },
    add() {
      axiosFast({
        url: route('/api/admin/discount/create'),
        data: {data: this.data},
        error: this.dataError,
        progress: e => this.dataProgress = e,
        result: (data) => {
          toastSuccess('Данные добавлены');
          this.$router.push({name: 'discount.detail', params: {id: data.id}})
        },
      })
    },
  },
}
</script>
