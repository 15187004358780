import EmptyWrap from "@/components/Gui/EmptyWrap";

export default [
    {
        path: 'question',
        component: EmptyWrap,
        children: [
            {
                path: ':id',
                name: 'question.detail',
                component: require('@/views/Question/QuestionList').default,
            },
        ]
    },
]
