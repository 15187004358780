<template>
  <the-mask-span
      v-if="isValid"
      :mask="mask"
      :value="innerValue"
      v-on:input="onInput"
      :tokens="plusTokens"
      :placeholder="placeholder"
      :disabled="disabled"
      masked
      type="tel"
      :class="inputClass"
      ref="phoneMask"
  />
  <span v-else-if="innerValue" class="text-danger" title="Неверный формат номера">{{ value }}</span>
  <span v-else>—</span>
</template>

<script>
import {parsePhoneNumberFromString} from "libphonenumber-js";


import {
  findFirstCountryByAlpha2,
  findFirstCountryByCode,
  getMaskToLibPhoneNumber,
  isCanada
} from "vue-phone-mask-input/src/country_telephone_data.js";
import TheMaskSpan from "../TheMaskSpan";

export default {
  name: "PhoneFormat",
  props: {
    modelValue: {
      type: [String, Number]
    },
    showFlag: {
      type: Boolean,
      default: false
    },
    defaultCountry: {
      type: String
    },
    placeholder: {
      type: String
    },
    flagSize: {
      type: String,
      default: "normal"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    wrapperClass: {
      type: String,
      default: "phone-mask-wrapper-lib"
    },
    inputClass: {
      type: String,
      default: "phone-input-lib"
    },
    flagClass: {
      type: String,
      default: "country-flag-lib"
    }
  },
  components: {TheMaskSpan},
  data() {
    return {
      innerValue: this.modelValue,
      countryCode: "",
      mask: "*############",
      defaultMask: "*############",
      country: "",
      isValid: false,
      plusTokens: {
        "#": {
          pattern: /\d/
        },
        "*": {
          pattern: /\d|\+/
        }
      }
    };
  },
  computed: {
    currentNumber: function () {
      const plus = /^\+/.test(this.innerValue) || !this.innerValue ? "" : "+";
      const filteredNumberArr = this.innerValue
          ? this.innerValue.match(/[\d+]/g)
          : null;
      const filteredNumber = filteredNumberArr
          ? filteredNumberArr.join("")
          : "";

      return this.mask === this.defaultMask
          ? `${plus}${filteredNumber}`
          : filteredNumber;
    }
  },
  methods: {
    onInput: function (value) {
      value = value + '';
      let filteredValue = value.match(/[\d+]/g);
      this.innerValue = filteredValue ? filteredValue.join("") : "";
      this.updateMaskData();
    },

    setFocusToEnd: function () {
      const length = this.$refs.phoneMask.$el.value.length;
      this.$refs.phoneMask.$el.focus();
      this.$refs.phoneMask.$el.setSelectionRange(length, length);
    },

    updateMaskData: function () {
      let {
        currentNumber,
        visitorCountry,
        innerValue,
        countryCode,
        defaultMask,
        autoDetectCountry,
        defaultCountry
      } = this;

      let phoneInfo = parsePhoneNumberFromString(currentNumber);

      if (!phoneInfo && !currentNumber) {
        let country = findFirstCountryByAlpha2(defaultCountry);
        if (country) {
          phoneInfo = {country: defaultCountry};
          autoDetectCountry = false;
          this.innerValue = `+${country.code}`;
        }
      }

      if (phoneInfo && !phoneInfo.country) {
        switch (phoneInfo.countryCallingCode) {
          case "44": {
            phoneInfo.country = "GB";
            break;
          }
          case "1": {
            if (currentNumber.length > 4)
              phoneInfo.country = isCanada(currentNumber) ? "CA" : "US";
            break;
          }
        }
      } else if (autoDetectCountry && visitorCountry && !innerValue)
        phoneInfo = {country: visitorCountry};
      else if (!phoneInfo && currentNumber.length > 2)
        phoneInfo = {country: findFirstCountryByCode(currentNumber)};

      const computedMask = getMaskToLibPhoneNumber(phoneInfo);
      const computedCountry =
          phoneInfo && phoneInfo.country ? phoneInfo.country.toLowerCase() : "";

      if (autoDetectCountry && visitorCountry) {
        if (!innerValue) this.innerValue = computedMask.countryCode;
        this.visitorCountry = null;
      }

      if (computedMask && computedMask.mask) {
        this.mask = computedMask.mask;
        this.country = computedCountry;
        this.countryCode = computedMask.countryCode;
        this.isValid = !!phoneInfo && !!phoneInfo.isValid && phoneInfo.isValid();
      } else if (countryCode.length > currentNumber.length) {
        this.mask = defaultMask;
        this.country = "";
        this.countryCode = "";
        this.isValid = false;
      }
    }
  },
  watch: {
    modelValue(val) {
      this.onInput(val)
    },
    currentNumber(value) {
      this.$emit("input", value);
      this.$emit("onValidate", {
        number: value,
        isValidByLibPhoneNumberJs: this.isValid,
        country: this.country
      });
    }
  },
  beforeMount() {
    this.updateMaskData();
  },
  mounted() {
    if (this.$refs.phoneMask) {
      this.$refs.phoneMask.$el.onblur = () => {
        this.$emit("onBlur")
      };
      this.$refs.phoneMask.$el.onfocus = () => {
        this.$emit("onFocus")
      }
    }

  }
};
</script>
