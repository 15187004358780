<template>
  <gui-input-row :data="$props">
    <input
        :disabled="!edit"
        :type="type ? type : 'text'"
        :step="step"
        :name="name"
        :id="name"
        class="form-control"
        :class="{'is-invalid': error && error.length > 0, 'no_spin': no_spin, 'form-control-sm': sm}"
        :placeholder="edit ? placeholder || label : ''"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        @keyup="$emit('keyup', $event)"
        @keyup.enter="$emit('enter')"
    />
    <template v-slot:after>
      <slot name="after"></slot>
    </template>
  </gui-input-row>

</template>

<script>

export default {
  data: function () {
    return {
      localInvalid: false,
      localValue: '',
    }
  },
  props: {
    hide: Boolean,
    no_spin: Boolean,
    name: String,
    label: String,
    subInfo: String,
    placeholder: String,
    error: [String, Object, Array],
    type: String,
    step: String,
    append: String,
    prepend: String,
    edit: {
      type: Boolean,
      default: true
    },
    modelValue: {
      type: [String, Number],
      default: ''
    },
    sm: Boolean,
    w: Boolean,
  },
  computed: {},
  mounted() {

  },
  methods: {},
  watch: {}
}
</script>
