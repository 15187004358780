<template>
  <card-block>
    <card-header>
      Цепочки подписок пользователей
    </card-header>
    <card-body>
      <spinner-block v-model="progress">
        <div class="mb-3 row">
          <div class="col-12 col-md-3">

            <div class="small">Тип</div>
            <gui-select sm v-model="filter.active" @change="getList" :hilightOn="['all']">
              <option value="all">Все</option>
              <option value="active">Активные</option>
              <option value="deactive">Неактивные</option>
            </gui-select>
          </div>
        </div>
        <subscription-chain-list-block :data="data"/>
      </spinner-block>
      <gui-paginate :data="data" ref="paginate" @change="getList"/>
    </card-body>
  </card-block>
</template>

<script>

import SubscriptionChainListBlock from "@/views/SubscriptionChain/SubscriptionChainListBlock.vue";

export default {
  components: {SubscriptionChainListBlock},
  data: function () {
    return {
      data: null,
      progress: true,
      filter: {
        active: 'all',
        search: '',
      }
    }
  },
  props: {},
  computed: {},
  beforeMount() {
    this.filter = storageLoad('filter_' + this.$route.name, this.filter);
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {

      let v = _.clone(this.filter);
      v.search = '';
      storageSet('filter_' + this.$route.name, v);

      axiosGetValue('/api/admin/subscription-chain/list', {
            page: this.$refs.paginate.currentPage,
            per_page: this.$refs.paginate.perPage,
            filter: this.filter,
          },
          e => this.data = e, e => this.progress = e
      )
    }
  }
}
</script>
