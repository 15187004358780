<template>
  <div>

    <chat-messages-block :uuid="data.chat_uuid" v-if="data.chat_uuid" readonly/>
    <div v-else class="alert alert-info">
      Чат вызова отсутствует
    </div>

  </div>
</template>

<script>

import ChatMessagesBlock from "@/views/Chat/Block/ChatMessagesBlock";

export default {
  components: {ChatMessagesBlock},
  data() {
    return {}
  },
  props: {
    data: Object,
  },
  mounted() {
  },
  methods: {}
}
</script>