<template>
  <div>
    <div v-if="data && data.data && data.data.length">
      <table class="table table-hover">
        <thead>
        <tr>
          <th class="pr-0" style="width: 1rem;"></th>
          <th>Смена, отряд</th>
          <th>Статус</th>
          <th>Начало</th>
          <th>Окончание</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="row in data.data" :key="row.id">
          <th class="pr-0">
            <template v-if="row.squad_duty_status">
              <template v-if="row.squad_duty_status.code==='started'">
                <i class="fas fa-circle small text-success" :title="row.squad_duty_status.name"></i>
              </template>
              <template v-if="row.squad_duty_status.code==='breaks'">
                <i class="fas fa-circle small text-warning" :title="row.squad_duty_status.name"></i>
              </template>
            </template>
          </th>
          <td>
            <router-link :to="{name: 'squad-duty.detail', params: {id: row.id}}" class="main-link">
              #{{ row.id }} {{ row.squad.name ? row.squad.name : '—' }}
            </router-link>
          </td>
          <td>
            {{ row.squad_duty_status ? row.squad_duty_status.name : '—' }}
          </td>
          <td>
            {{ toTime(row.started_at) }}
          </td>
          <td>
            {{ toTime(row.ended_at) }}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div v-else class="alert alert-info">Список пуст</div>
  </div>
</template>

<script>

export default {
  props: {
    data: Object
  },
}
</script>