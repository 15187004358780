<template>
  <div>
    <div class="alert alert-warning" v-if="data.is_test_user">
      Тестовый отряд
    </div>
    <div class="alert alert-warning" v-if="data.is_health_check">
      Health check
    </div>
    <div class="row">
      <div class="d-block d-md-none col-md-3 mb-3">
        <img :src="data.photo_crew_chief_url ? data.photo_crew_chief_url : '/images/no-photo.jpg'"
             class="img-thumbnail avatar-middle"/>
      </div>
      <div class="col-12 col-md-9">
        <card-field title="Имя">
          <i class="fas fa-circle small"
             :title="data.is_active ? 'Активен' : 'Неактивен'"
             :class="data.is_active ? 'text-success' : 'text-danger'"></i>
          {{ data.name ? data.name : '—' }}
        </card-field>
        <card-field :modelValue="data.phone" title="Телефон"/>
        <card-field :modelValue="toTime(data.phone_verified_at)" title="Дата подтверждения телефона"/>
        <card-field title="Организация">
          <router-link :to="{name:'organization.detail', params: {id: data.organization_id}}"
                       v-if="data.organization_id">
            {{ data.organization ? data.organization.name : '—' }}
          </router-link>
          <div v-else>—</div>
        </card-field>
        <card-field :modelValue="data.squad_status ? data.squad_status.name : '—'" title="Статус"/>
        <card-field :modelValue="data.squad_number" title="Номер отряда"/>
      </div>

      <div class="d-none d-md-block col-md-3 text-right">
        <img :src="data.photo_crew_chief_url ? data.photo_crew_chief_url : '/images/no-photo.jpg'"
             class="img-thumbnail avatar-middle"/>
      </div>

    </div>

    <div class="mt-3">

      <div class="float-right">
        <button-remove @click="remove" v-if="can('module.squad.delete')"/>
      </div>


      <button-edit @click="$router.push({name: 'squad.edit', params: {id: data.id}})"
                   v-if="can('module.squad.update')"/>

      <button class="btn btn-secondary" @click.prevent="clearCache" v-if="can('module.squad.update')">
        Очистить кеш
      </button>
    </div>
  </div>
</template>

<script>


export default {
  data() {
    return {}
  },
  props: {
    data: Object
  },
  mounted() {
  },
  methods: {

    remove() {
      $.alertConfirm({
        type: 'red',
      }, {
        ok: () => {
          axiosGetValue('/api/admin/squad/delete', {id: this.data.id}, () => {
            toastSuccess('Удаление завершено');
            this.$router.push({name: 'squad.list'})
          })
        }
      });
    },

    clearCache() {
      $.alertConfirm({
        content: 'Использовать в случае когда информация в мобильном приложении отличается от данных в административной панеле. Продолжить?'
      }, {
        ok: () => {
          axiosGetValue('/api/admin/squad/clear-cache', {id: this.data.id}, () => {
            toastSuccess('Кеш очищен');
          })
        }
      });
    }
  },
}
</script>