<template>
  <div>
    <button class="btn btn-sm"
            v-if="day"
            :class="{'btn-primary': dateRangeLocal==='day', 'btn-secondary': dateRangeLocal!=='day'}"
            @click.prevent="setFilterDate('day'),getList()">День
    </button>
    <button class="btn btn-sm"
            v-if="week"
            :class="{'btn-primary': dateRangeLocal==='week', 'btn-secondary': dateRangeLocal!=='week'}"
            @click.prevent="setFilterDate('week'),getList()">Неделя
    </button>
    <button class="btn btn-sm"
            v-if="month"
            :class="{'btn-primary': dateRangeLocal==='month', 'btn-secondary': dateRangeLocal!=='month'}"
            @click.prevent="setFilterDate('month'),getList()">Месяц
    </button>
    <button class="btn btn-sm"
            v-if="quarter"
            :class="{'btn-primary': dateRangeLocal==='quarter', 'btn-secondary': dateRangeLocal!=='quarter'}"
            @click.prevent="setFilterDate('quarter'),getList()">Квартал
    </button>
    <button class="btn btn-sm"
            v-if="year"
            :class="{'btn-primary': dateRangeLocal==='year', 'btn-secondary': dateRangeLocal!=='year'}"
            @click.prevent="setFilterDate('year'),getList()">Год
    </button>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      dateRangeLocal: 'month'
    }
  },
  props: {
    day: Boolean,
    week: Boolean,
    month: Boolean,
    quarter: Boolean,
    year: Boolean,
    no_auto_set: Boolean,
    modelValue: Object,
    fromKey: {
      type: String,
      default: 'date_from'
    },
    toKey: {
      type: String,
      default: 'date_to'
    },
    dateRange: String,
  },
  computed: {},
  beforeMount() {
    if (this.dateRange) this.dateRangeLocal = this.dateRange;
  },
  mounted() {
    if (!this.no_auto_set) this.setFilterDate(this.dateRangeLocal);
    this.checkDayRange();
  },
  methods: {
    setFilterDate(range, date) {
      let r = this.getDateRange(range, date);
      this.$emit('update:modelValue', Object.assign(this.modelValue, {[this.fromKey]: r[0], [this.toKey]: r[1]}));

    },

    getDateRange(range, date) {
      let day = this.moment(date);
      return [day.startOf(range || 'day').format('YYYY-MM-DD'), day.endOf(range || 'day').format('YYYY-MM-DD')];
    },
    checkDayRange() {
      let result = false;

      let month = this.getDateRange('month');
      if (this.modelValue.date_from === month[0] && this.modelValue.date_to === month[1]) result = 'month';

      let day = this.getDateRange('day');
      if (this.modelValue.date_from === day[0] && this.modelValue.date_to === day[1]) result = 'day';

      let week = this.getDateRange('week');
      if (this.modelValue.date_from === week[0] && this.modelValue.date_to === week[1]) result = 'week';

      let quarter = this.getDateRange('quarter');
      if (this.modelValue.date_from === quarter[0] && this.modelValue.date_to === quarter[1]) result = 'quarter';

      let year = this.getDateRange('year');
      if (this.modelValue.date_from === year[0] && this.modelValue.date_to === year[1]) result = 'year';

      this.dateRangeLocal = result
    },
    getList() {
      this.$emit('update')
    },
  },
  watch: {
    modelValue: {
      handler() {
        this.checkDayRange();
      },
      deep: true
    }
  }
}
</script>
